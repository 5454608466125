import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import cash from '../../../../../../assets/icons/userCabinetSendReq/payment_cash.svg';
import card from '../../../../../../assets/icons/userCabinetSendReq/payment_card.svg';
import s from './SendRequestModal.module.scss';
const methodPayment = {
    cash: 'Оплата на месте',
    visaMasterCard: 'Оплата на месте картой Visa или MasterCard',
    unionPay: 'Оплата на месте картой UnionPay',
    JCB: 'Оплата на месте картой JCB',
};
export const PaymentBlock = () => {
    return (_jsxs("div", Object.assign({ className: s.PaymentBlock }, { children: [_jsxs("p", Object.assign({ className: s.PaymentBlock__payment_item }, { children: [_jsx("img", { className: s.PaymentBlock__icon, src: cash, alt: "cash" }), methodPayment.cash] })), _jsxs("p", Object.assign({ className: s.PaymentBlock__payment_item }, { children: [_jsx("img", { className: s.PaymentBlock__icon, src: card, alt: "card" }), methodPayment.visaMasterCard] })), _jsxs("p", Object.assign({ className: s.PaymentBlock__payment_item }, { children: [_jsx("img", { className: s.PaymentBlock__icon, src: card, alt: "card" }), methodPayment.unionPay] })), _jsxs("p", Object.assign({ className: s.PaymentBlock__payment_item }, { children: [_jsx("img", { className: s.PaymentBlock__icon, src: card, alt: "card" }), methodPayment.JCB] }))] })));
};
