import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CancelBooking } from 'components/BookingPropose/components/UsersActiveBookings/ActiveBookingCard/components/Buttons/CancelBooking';
import { CloseBnt } from 'components/UserCabinet/ModalW/Modal/CloseBnt';
import { useTranslation } from 'react-i18next';
import clockIcon from 'assets/icons/proposeCard/clock_karma.svg';
import s from './NoticeCancelBooking.module.scss';
export const NoticeCancelBooking = ({ close, onClick }) => {
    const { t } = useTranslation('userBooking');
    return (_jsxs("div", Object.assign({ className: s.container }, { children: [_jsx(CloseBnt, { close: close, styleName: s.go_back_bnt }), _jsxs("div", Object.assign({ className: s.button_popover }, { children: [_jsx("span", Object.assign({ className: s.button_popover__number }, { children: "- 5" })), _jsx("img", { src: clockIcon, alt: "clock" }), _jsx("span", Object.assign({ className: s.button_popover__title }, { children: " Booking Karma" }))] })), _jsx("h2", Object.assign({ className: s.title }, { children: t('cancelModalTitle') })), _jsx("p", Object.assign({ className: s.text }, { children: t('cancelModalText1') })), _jsxs("p", Object.assign({ className: s.text }, { children: [t('cancelModalText2'), _jsx("br", {}), t('cancelModalText3')] })), _jsx("p", Object.assign({ className: s.text }, { children: t('cancelModalText4') })), _jsxs("div", Object.assign({ className: s.buttons }, { children: [_jsx(CancelBooking, { onClick: onClick }), _jsx("button", Object.assign({ className: s.closeBtn, onClick: close }, { children: t('cancelModalClose') }))] }))] })));
};
