import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SubheaderUser } from './SubheaderUser';
import scss from './SubheaderCabinetUser.module.scss';
// import { useAuthContext } from 'context/AuthContext';
import { HeaderNobody } from '../Components/HeaderNobody';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
/** CabinetUser
 *  @see CABINET_USER '/cabinet-user'
 * 1. userType - subheader
 * 2. NO BODY  - Log in page ✅
 * 3. User     - User ✅
 * 4. Hotelier - # ✅
 * 5. Agent    - # ✅
 */
export const SubheaderCabinetUser = () => {
    const [isUser, setIsAgent] = useState(false);
    const user = useSelector((state) => state.auth);
    useEffect(() => {
        if (user.role === 'user') {
            setIsAgent(!!user);
        }
        else {
            setIsAgent(false);
        }
    }, [user]);
    return (_jsxs("div", Object.assign({ className: scss.subheaderCabinetUser }, { children: [!isUser && _jsx(HeaderNobody, {}), isUser && _jsx(SubheaderUser, {})] })));
};
