import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// import { useAuthContext } from 'context/AuthContext';
import { HeaderNobody } from '../Components/HeaderNobody';
import { SubheaderHotelier } from './SubheaderHotelier';
import scss from './SubheaderMainHotelier.module.scss';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
/** Main Hotelier
 *  @see MAIN_HOTELIER '/main-hotelier'
 * 1. userType - subheader
 * 2. NO BODY  - NO BODY ✅
 * 3. User     - NO BODY ✅
 * 4. Hotelier - Hotelier ✅
 * 5. Agent    - NO BODY ✅
 */
export const SubheaderMainHotelier = () => {
    const [isHotelier, setIsHotelier] = useState(false);
    const hotelier = useSelector((state) => state.auth);
    useEffect(() => {
        if (hotelier.role === 'hotelier') {
            setIsHotelier(!!hotelier);
        }
        else {
            setIsHotelier(false);
        }
    }, [hotelier]);
    return (_jsxs("div", Object.assign({ className: scss.subheaderMainHotelier }, { children: [!isHotelier && _jsx(HeaderNobody, {}), isHotelier && _jsx(SubheaderHotelier, {})] })));
};
