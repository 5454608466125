import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import dogS from '../image/animal/dogS.png';
import dogM from '../image/animal/dogM.png';
import dogL from '../image/animal/dogL.png';
import cat from '../image/animal/cat.png';
import other from '../image/animal/other.png';
import raccon from '../image/animal/raccon.png';
import s from './AnimalTravel.module.scss';
import { useTranslation } from 'react-i18next';
export const AnimalTravel = () => {
    const { t } = useTranslation('userCard');
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ className: s.wrapperMob }, { children: [_jsx("div", Object.assign({ className: s.title }, { children: t('canStay') })), _jsxs("ul", Object.assign({ className: s.list }, { children: [_jsxs("li", Object.assign({ className: s.item }, { children: [_jsx("img", { src: dogS, alt: "small dog", className: s.icon }), _jsx("p", Object.assign({ className: s.text }, { children: t('smallDog') }))] })), _jsxs("li", Object.assign({ className: s.item }, { children: [_jsx("img", { src: dogM, alt: "medium dog", className: s.icon }), _jsx("p", Object.assign({ className: s.text }, { children: t('mediumDog') }))] })), _jsxs("li", Object.assign({ className: s.item }, { children: [_jsx("img", { src: dogL, alt: "large dog", className: s.icon }), _jsx("p", Object.assign({ className: s.text }, { children: t('largeDog') }))] })), _jsxs("li", Object.assign({ className: s.item }, { children: [_jsx("img", { src: cat, alt: "cat", className: s.icon }), _jsx("p", Object.assign({ className: s.text }, { children: t('cat') }))] })), _jsxs("li", Object.assign({ className: s.item }, { children: [_jsx("img", { src: raccon, alt: "raccon", className: s.icon }), _jsx("p", Object.assign({ className: s.text }, { children: t('raccoon') }))] })), _jsxs("li", Object.assign({ className: s.item }, { children: [_jsx("img", { src: other, alt: "other animals", className: s.icon }), _jsx("p", Object.assign({ className: s.text }, { children: t('notPredator') }))] }))] }))] })) }));
};
