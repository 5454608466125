import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { NavLink } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
import { IconHeader } from '../IconHeader';
import { useAppSelector } from 'features';
// import { useLinkToMainHotelier } from 'hooks/routes';
import scss from './ForHoteliersLink.module.scss';
const IconHouseSmallGrey = () => _jsx(IconHeader, { iconId: "house-small-grey", className: scss.hotelierIcon });
export const LinkForHoteliers = ({ linkTo, textTo }) => {
    // const { t } = useTranslation();
    // const to = useLinkToMainHotelier();
    const theme = useAppSelector(state => state.theme.selectedTheme);
    const dark = theme === 'dark' ? scss.dark : '';
    return (_jsxs(NavLink, Object.assign({ to: linkTo, className: `${scss.hotelierLink} ${dark}` }, { children: [_jsx(IconHouseSmallGrey, {}), textTo] })));
};
