import { createSlice } from '@reduxjs/toolkit';
import { getThunkDataHotels } from './thunks';
export const hotelsSlice = createSlice({
    name: 'hotels',
    initialState: {
        hotels: [],
        loading: false,
        error: null,
    },
    extraReducers: {
        [getThunkDataHotels.pending]: state => {
            state.loading = true;
        },
        [getThunkDataHotels.fulfilled]: (state, action) => {
            state.hotels = action.payload;
            state.loading = false;
        },
        [getThunkDataHotels.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});
export default hotelsSlice.reducer;
