import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useRef } from 'react';
import { useAppSelector } from 'features';
import { usePickerContext } from '../PickerContext';
import { DropdownContent } from '../PickerContext';
import scss from './PickerDropdown.module.scss';
export const PickerDropdown = ({ children }) => {
    const [{ isDropdownOpen }] = usePickerContext();
    const dropdownRef = useRef(null);
    const closeTimeoutRef = useRef(null);
    const theme = useAppSelector(state => state.theme.selectedTheme);
    const dark = theme === 'dark' ? scss.dark : '';
    useEffect(() => {
        const handleMouseLeave = () => {
            if (dropdownRef.current) {
                closeTimeoutRef.current = setTimeout(() => {
                    if (dropdownRef.current) {
                        dropdownRef.current.style.display = 'none';
                    }
                }, 300);
            }
        };
        const handleMouseEnter = () => {
            if (closeTimeoutRef.current) {
                clearTimeout(closeTimeoutRef.current);
                closeTimeoutRef.current = null;
            }
        };
        if (isDropdownOpen) {
            if (dropdownRef.current) {
                dropdownRef.current.addEventListener('mouseenter', handleMouseEnter);
                dropdownRef.current.addEventListener('mouseleave', handleMouseLeave);
            }
        }
        return () => {
            if (dropdownRef.current) {
                dropdownRef.current.removeEventListener('mouseenter', handleMouseEnter);
                // eslint-disable-next-line react-hooks/exhaustive-deps
                dropdownRef.current.removeEventListener('mouseleave', handleMouseLeave);
            }
            if (closeTimeoutRef.current) {
                clearTimeout(closeTimeoutRef.current);
                closeTimeoutRef.current = null;
            }
        };
    }, [isDropdownOpen]);
    return isDropdownOpen ? (_jsx("div", Object.assign({ ref: dropdownRef }, { children: _jsx(DropdownContent, Object.assign({ className: `${scss.pickerDropdown} ${dark}` }, { children: children })) }))) : null;
};
