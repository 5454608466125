import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CommonContainer } from 'components/Common/CommonContainer';
import img from 'assets/images/cookies/cookies-banner.jpg';
import cl from './CookiesUsage.module.scss';
import { Footer } from 'components/Common/Footer';
import { useTranslation } from 'react-i18next';
export default function CookiesUsage() {
    const { t } = useTranslation('common');
    return (_jsxs("main", Object.assign({ className: cl.container }, { children: [_jsxs("section", Object.assign({ className: cl.imageWrapper }, { children: [_jsx("img", { src: img, alt: "cookies banner" }), _jsxs("div", Object.assign({ className: cl.imageText }, { children: [_jsx("span", { children: t('cookiesName') }), _jsx("span", { children: "COOKIES" })] }))] })), _jsxs(CommonContainer, { children: [_jsxs("div", Object.assign({ className: cl.content }, { children: [_jsx("p", Object.assign({ className: cl.p1 }, { children: t('cookiesPTitle') })), _jsx("p", Object.assign({ className: cl.pTitle }, { children: t('cookiesPVed') })), _jsx("p", Object.assign({ className: cl.pDesc }, { children: t('cookiesPVedDesc') })), _jsx("p", Object.assign({ className: cl.pTitle }, { children: t('cookiesPGoal') })), _jsx("p", Object.assign({ className: cl.pDesc }, { children: t('cookiesPGoalDesc') })), _jsx("p", Object.assign({ className: cl.pTitle }, { children: t('cookiesPAbsence') })), _jsx("p", Object.assign({ className: cl.pDesc }, { children: t('cookiesPAbsenceDesc') })), _jsx("p", Object.assign({ className: cl.pTitle }, { children: t('cookiesPDelete') })), _jsx("p", Object.assign({ className: cl.pDesc }, { children: t('cookiesPDeleteDesc') })), _jsx("p", Object.assign({ className: cl.pTitle }, { children: t('cookiesPSummary') })), _jsx("p", Object.assign({ className: cl.pDesc }, { children: t('cookiesPSummaryDesc') })), _jsxs("p", Object.assign({ className: cl.pDesc }, { children: [t('cookiesPInfo'), _jsx("a", Object.assign({ href: 'https://www.yourpricebooking.com/support' }, { children: "https://www.yourpricebooking.com/support" }))] }))] })), _jsx(Footer, { className: cl.footer })] })] })));
}
