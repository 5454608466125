import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import ReactModal from 'react-modal';
import { getRefs } from 'utils';
const { root, modalRoot } = getRefs();
ReactModal.setAppElement(root);
const parentSelector = () => modalRoot;
// object__window
// overlayClassName="object__backdrop"
/*
  general modal - may use in other components.
*/
export const Modal = ({ isOpen, children, onRequestClose, contentClassName, overlayClassName, closeTimeoutMS, contentLabel = 'Modal', }) => (_jsx(ReactModal, Object.assign({ isOpen: isOpen, onRequestClose: onRequestClose, className: contentClassName, overlayClassName: overlayClassName, closeTimeoutMS: closeTimeoutMS, contentLabel: contentLabel, portalClassName: "react-modal__portal", htmlOpenClassName: "ReactModal__Html--open", parentSelector: parentSelector }, { children: _jsx(_Fragment, { children: children }) })));
