import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAppSelector } from 'features';
import { selectCurrencyById, selectLanguageById } from 'features/common';
import { useCallback } from 'react';
import { CurrencyOptionButton } from '../CurrencyPicker/CurrencyOptionButton';
import { LanguageOptionButton } from '../LanguagePicker/LanguageOptionButton';
import { choosePickerOption, usePickerContext } from '../PickerContext';
import scss from './OptionItem.module.scss';
const OptionButton = ({ children, optionId }) => {
    const [{ isDark, activeOptionId }, dispatch] = usePickerContext();
    const chooseOption = useCallback(() => {
        dispatch(choosePickerOption(optionId));
    }, [dispatch, optionId]);
    const dark = isDark ? scss.dark : '';
    const active = optionId === activeOptionId ? scss.active : '';
    return (_jsx("button", Object.assign({ className: `${scss.optionButton} ${dark} ${active}`, onClick: chooseOption, type: "button" }, { children: children })));
};
export const OptionItem = ({ optionId }) => {
    var _a;
    const [{ options }] = usePickerContext();
    const item = (_a = options.entities) === null || _a === void 0 ? void 0 : _a[optionId];
    if (!item)
        return null;
    const { text, value } = item;
    return (_jsx("li", Object.assign({ className: scss.optionsItem }, { children: _jsxs(OptionButton, Object.assign({ optionId: optionId }, { children: [text, "\u00A0\u00A0\u00A0\u00A0", value] })) })));
};
export const LanguageOptionItem = ({ optionId }) => {
    const languageFrontend = useAppSelector(state => selectLanguageById(state, optionId));
    if (!languageFrontend)
        return null;
    const { text, value } = languageFrontend;
    const lowercaseLanguageCode = value.toLowerCase();
    return (_jsx("li", Object.assign({ className: scss.optionsItem }, { children: _jsxs(LanguageOptionButton, Object.assign({ optionId: optionId }, { children: [_jsx("span", { children: text }), _jsx("span", { children: lowercaseLanguageCode })] })) })));
};
export const CurrencyOptionItem = ({ optionId }) => {
    const currencyFrontend = useAppSelector(state => selectCurrencyById(state, optionId));
    if (!currencyFrontend)
        return null;
    const { text, value } = currencyFrontend;
    return (_jsx("li", Object.assign({ className: scss.optionsItem }, { children: _jsxs(CurrencyOptionButton, Object.assign({ optionId: optionId, title: `${text} ${value}` }, { children: [_jsx("span", { children: text }), _jsx("span", { children: value })] })) })));
};
