import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserMainRoom } from './UserMainRoom/UserMainRoom';
import { UserHostelRoom } from './UserHostelRoom/UserHostelRoom';
//import { typeOfBed } from './typeOfBed';
// import { MainRoom } from './MainRoom/MainRoom';
// import { HostelRoom } from './HostelRoom/HostelRoom';
// import room1x1 from '../BedOptions/image/icon/1x1.png';
// import room1x2 from '../BedOptions/image/icon/1x2.png';
// import { getImage } from '../BedOptions/image/getImage';
function sumObjectValues(obj) {
    let sum = 0;
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            sum += obj[key];
        }
    }
    return sum;
}
export const UserBedOptions = ({ options, styled = {}, user = true, requestCardMain = '' }) => {
    const [bed, setBed] = useState([]);
    const { t } = useTranslation('userCard');
    const typeOfBed = useMemo(() => [
        {
            id: 1,
            iconId: 'icon-1x1',
            title: t('bedroom1x1'),
            descriptionInResponseData: { single: 1, double: 0 },
            type: 'main',
        },
        {
            id: 2,
            iconId: 'icon-1x2',
            title: t('bedroom1x2'),
            descriptionInResponseData: {
            /*single: 2, double: 0 */
            },
            type: 'hotel',
        },
        {
            id: 3,
            iconId: 'icon-1x3',
            title: t('bedroom1x3'),
            descriptionInResponseData: { single: 3, double: 0 },
            type: 'hotel',
        },
        {
            id: 4,
            iconId: 'icon-2x1',
            title: t('bedroom2x1'),
            descriptionInResponseData: { single: 0, double: 1 },
            type: 'hotel',
        },
        {
            id: 5,
            iconId: 'icon-2x2',
            title: t('bedroom2x2'),
            descriptionInResponseData: { single: 0, double: 2 },
            type: 'hotel',
        },
        {
            id: 6,
            iconId: 'icon-2x3',
            title: t('bedroom2x3'),
            descriptionInResponseData: { single: 0, double: 3 },
            type: 'hotel',
        },
        {
            id: 7,
            iconId: 'icon-1x1_2x2',
            title: t('bedroom1x1_2x2'),
            descriptionInResponseData: { single: 1, double: 2 },
            type: 'hotel',
        },
        {
            id: 8,
            iconId: 'icon-1x2_2x1',
            title: t('bedroom1x2_2x1'),
            descriptionInResponseData: { single: 2, double: 1 },
            type: 'hotel',
        },
        {
            id: 9,
            iconId: 'icon-1x1_2x1',
            title: t('bedroom1x1_2x1'),
            descriptionInResponseData: { single: 1, double: 1 },
            type: 'hotel',
        },
    ], [t]);
    useEffect(() => {
        const result = Object.values(options).map(option => {
            if (option.hostel === true) {
                const findType = typeOfBed.find(bed => bed.type === 'hostel');
                return Object.assign(Object.assign({}, findType), { descriptionInResponseData: option });
            }
            return typeOfBed.find(bed => bed.descriptionInResponseData.single === option.single &&
                bed.descriptionInResponseData.double === option.double);
        });
        setBed(result);
    }, [options, typeOfBed]);
    return (_jsx(_Fragment, { children: typeOfBed.map((bed, id) => {
            if (!bed)
                return null;
            if (requestCardMain) {
                const sumOfBed = sumObjectValues(bed.descriptionInResponseData);
                if (sumOfBed === 1) {
                    return requestCardMain === 'hotel' ? (_jsx(UserMainRoom, { bed: bed, styled: styled }, id)) : (_jsx(UserHostelRoom, { bed: bed, styled: styled, user: user }, id));
                }
                return _jsx(_Fragment, {});
            }
            return bed.type === 'hotel' ? (_jsx(UserMainRoom, { bed: bed, styled: styled }, id)) : (_jsx(UserHostelRoom, { bed: bed, styled: styled, user: user }, id));
        }) }));
};
