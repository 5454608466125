var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import PropTypes from 'prop-types';
// import s from './FreeButton.module.scss';
const FreeButton = (_a) => {
    var { children, onClick } = _a, allyProps = __rest(_a, ["children", "onClick"]);
    return (_jsx("button", Object.assign({ type: "button", onClick: onClick }, allyProps, { children: children })));
};
FreeButton.defaultProps = {
    onClick: () => null,
    children: null,
};
FreeButton.propsTypes = {
    onClick: PropTypes.func,
    children: PropTypes.node,
    'aria-label': PropTypes.string.isRequired,
};
export default FreeButton;
