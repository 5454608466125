import photo1 from './components/image/testGallery-room/photo53.png';
import photo2 from './components/image/testGallery-room/photo54.png';
import photo3 from './components/image/testGallery-room/photo56.png';
import photo4 from './components/image/testGallery-room/photo57.png';
import photo5 from './components/image/testGallery-room/photo58.png';
import photo6 from './components/image/testGallery-room/photo59.png';
export const TEST_PROPS = {
    title: 'Дом Пабло Эскобара арарпаттчатчаптчтпттч',
    adress: 'ул. Ленина, д.88, г. Переяслав - Хмелницкий , Украина',
    srcVideo: 'https://www.youtube.com/embed/DG61Kl773qQ',
    descrText: 'Commons-logo.svg Медиафайлы на Викискладе Па́бло Эми́лио Эскоба́р Гави́рия (исп. Pablo Emilio Escobar Gaviria; 1 декабря 1949 — 2 декабря 1993) — колумбийский наркобарон и террорист. Эскобара называют «Королем кокаина», поскольку он был лидером Медельинского картеля, контролировавшего к концу 1980 года 80 % от мирового рынка кокаина[4].) — колумбийский наркобарон и террорист. Эскобара называют «Королем кокаина», поскольку он был лидером Медельинского картеля, контролировавшего к концу 1980 года 80 % от мирового рынка кокаина[4]. ',
    descrBookingVariant: 'Здесь мы пишем про вариант бронирования - описание варианта. Commons-logo.svg Медиафайлы на Викискладе Па́бло Эми́лио Эскоба́р Гави́рия (исп. Pablo Emilio Escobar Gaviria; 1 декабря 1949 — 2 декабря 1993) — колумбийский наркобарон и террорист. Эскобара называют «Королем кокаина», поскольку он был лидером Медельинского картеля, контролировавшего к концу 1980 года 80 % от мирового рынка кокаина[4]. На языке автора',
    descrTags: [
        'все включено',
        'первая линия от моря',
        'номера для некурящих',
        'бар',
        'доставка еды и напитков',
        'ужин включён',
        'частная парковка',
        'завтрак включен',
        'обед включен',
        'лобби-бар',
        'ресторан',
        'спорт зал',
        'удобства для гостей с ограниченными возможностями',
    ],
    payments: ['cash', 'Visa/MasterCard', 'UnionPay', 'JCB', 'AmericanExpress'],
    price: '1800 UAH',
    date: { from: '22 окт. 2022', to: '28 окт. 2022' },
    checkInTime: { in: '14:00', out: '15:00' },
    bedOptions: {
        1: { single: 1, double: 0 },
        // 2: { single: 0, double: 1 },
        // 3: { single: 1, double: 0 },
        // 4: { single: 0, double: 1 },
    },
    photos: [
        { name: photo1, id: 1 },
        { name: photo2, id: 2 },
        { name: photo3, id: 3 },
        { name: photo4, id: 4 },
        { name: photo5, id: 5 },
        { name: photo6, id: 6 },
    ],
    totalSq: '77 ',
    roomAmenities: [
        'туалетная бумага',
        'бесплатные туалетные принадлежности',
        'сейф',
        'ванна',
        'джакузи',
        'кофеварка',
        'чайник',
        'фен',
        'стиральная машина',
        'терраса',
        'утюг',
        'шкаф',
        'гардероб',
        'звукоизоляция',
    ],
};
