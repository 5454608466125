import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useTranslation } from 'react-i18next';
import urk from './UserRequestCard.module.scss';
export const BookingKarma = ({ setCurrentIndex }) => {
    const { t } = useTranslation('hotelierCabinet');
    return (_jsxs("div", Object.assign({ className: urk.style_container_karma }, { children: [_jsxs("div", Object.assign({ className: urk.style_title_string_karma }, { children: ["Booking Karma", _jsx("span", Object.assign({ className: urk.style_number_title_karma }, { children: "22" })), _jsx("div", Object.assign({ className: urk.close_wrapper, onClick: e => {
                            e.stopPropagation();
                            setCurrentIndex(null);
                        } }, { children: _jsx("a", Object.assign({ href: "#", className: urk.close }, { children: '' })) }))] })), _jsxs("ul", Object.assign({ className: urk.block_container_ul_karma }, { children: [_jsxs("li", Object.assign({ className: urk.style_block_li }, { children: [t('booked'), " ", _jsx("span", Object.assign({ className: urk.style_number_string }, { children: "35" }))] })), _jsxs("li", Object.assign({ className: urk.style_block_li }, { children: [t('canceled'), _jsx("span", Object.assign({ className: urk.style_number_string }, { children: "8" }))] })), _jsxs("li", Object.assign({ className: urk.style_block_li }, { children: [t('didNotArrive'), _jsx("span", Object.assign({ className: urk.style_number_string }, { children: "6" }))] })), _jsxs("li", Object.assign({ className: urk.style_block_li }, { children: [t('ratedFacilities'), _jsx("span", Object.assign({ className: urk.style_number_string }, { children: "4" }))] })), _jsxs("li", Object.assign({ className: urk.style_block_li }, { children: [t('leftReviewsOnVenues'), _jsx("span", Object.assign({ className: urk.style_number_string }, { children: "9" }))] })), _jsxs("li", Object.assign({ className: urk.style_block_li }, { children: [t('leftReviewsOnYourPriceBooking'), _jsx("span", Object.assign({ className: urk.style_number_string }, { children: "2" }))] })), _jsxs("li", Object.assign({ className: urk.style_block_li }, { children: [t('registeredYears'), _jsx("span", Object.assign({ className: urk.style_number_string }, { children: t('lessThan') }))] }))] })), _jsxs("button", Object.assign({ className: urk.style_btn_karma }, { children: [t('reviews'), " ", _jsx("span", { children: "33" })] }))] })));
};
