import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { useMedia } from 'hooks/UI';
import scss from './SubheaderHotelierJustLogined.module.scss';
import { useTranslation } from 'react-i18next';
export const SubheaderHotelierDefault = () => {
    const { isMobile } = useMedia();
    const { t } = useTranslation();
    const hotelier = useSelector((state) => state.auth);
    let hotelierEmail = '';
    if (hotelier === null || hotelier === void 0 ? void 0 : hotelier.loggedIn) {
        hotelierEmail = hotelier.email.length > 17 ? `${hotelier.email.slice(0, 17)}...` : hotelier.email;
    }
    // const hotelierEmail = hotelier.email.length > 15 ? `${hotelier.email.slice(0, 15)}...` : hotelier.email;
    // if (hotelier.role !== 'hotelier') return null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ className: scss.subheaderJustLogined }, { children: [_jsx("div", { className: scss.subheaderJustLogined__icon }), !isMobile && (_jsxs("p", Object.assign({ className: scss.subheaderJustLogined__text }, { children: [_jsx("p", Object.assign({ className: scss.subheaderJustLogined__title }, { children: t('hotelier') })), _jsx("p", { children: hotelierEmail })] })))] })) }));
};
