import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch, useSelector } from 'react-redux';
import { useState, useRef, useEffect } from 'react';
import { gbvcssSingleBedCountIncrement, gbvcssSingleBedCountDecrement, } from '../../../../redux/bedRooms/bedRooms.actions.js';
import SelectBed from '../../Assents/image/SelectBed.svg';
import './Gbvcss.scss';
import { useTranslation } from 'react-i18next';
import { useScreenResizing } from 'hooks/UI/useScreenResizing.js';
export default function Gbvcss({ backToSelectRoom, readyBtnGbvcss, showGbvcssAttetion, onChangeShowGbvcssAttetion, isBtnNextClick, }) {
    const viewPort = useScreenResizing();
    const isMobile = viewPort.width > 319 && viewPort.width < 768;
    const { t } = useTranslation('Calendar');
    const [showGbvcssBlockAttetion, setShowGbvcssBlockAttetion] = useState(showGbvcssAttetion);
    const dispatch = useDispatch();
    const singleBedCount = useSelector(state => state.bedRoomReducer.gbvcssSigleBedCount);
    const gbvcssRef = useRef(null);
    useEffect(() => {
        setShowGbvcssBlockAttetion(showGbvcssAttetion);
    }, [showGbvcssAttetion]);
    useEffect(() => {
        const handleClickOutside = e => {
            if (isBtnNextClick) {
                return;
            }
            if (gbvcssRef.current && gbvcssRef.current.contains(e.target)) {
                return;
            }
            setShowGbvcssBlockAttetion(false);
            if (!isBtnNextClick) {
                onChangeShowGbvcssAttetion(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isBtnNextClick, gbvcssRef]);
    const singleBedCountDecrement = () => {
        if (singleBedCount === 0) {
            return singleBedCount;
        }
        else {
            dispatch(gbvcssSingleBedCountDecrement(1));
        }
    };
    const singleBedCountIncrement = () => {
        if (singleBedCount < 12) {
            dispatch(gbvcssSingleBedCountIncrement(1));
            setShowGbvcssBlockAttetion(false);
            onChangeShowGbvcssAttetion(false);
        }
        else {
            return singleBedCount;
        }
    };
    const disableBtn = singleBedCount ? false : true;
    //handle btn
    const btnClick = () => {
        readyBtnGbvcss();
    };
    const showAttention = () => {
        if (!singleBedCount) {
            setShowGbvcssBlockAttetion(true);
            onChangeShowGbvcssAttetion(true);
        }
    };
    return (_jsxs("div", Object.assign({ className: "gbvcss__section" }, { children: [_jsx("h1", Object.assign({ className: "gbvcss_chapter" }, { children: t('rtbirwn') })), _jsx("h1", Object.assign({ className: "gbvcss_title" }, { children: t('gbvcssTitle') })), _jsx("div", Object.assign({ className: showGbvcssBlockAttetion ? 'gbvcss__block gbvcss__block--active' : 'gbvcss__block' }, { children: _jsx("div", Object.assign({ className: "gbvcss__selected__block" }, { children: _jsxs("div", Object.assign({ className: "gbvcss__bed gbvcss__bed--modif" }, { children: [_jsxs("div", Object.assign({ className: "gbvcss__hover" }, { children: [_jsx("svg", Object.assign({ className: "gbvcss__icon gbvcss__icon--single" }, { children: _jsx("use", { href: `${SelectBed}#singleBed` }) })), _jsx("div", Object.assign({ className: "gbvcss__overlay" }, { children: t('singleBed') }))] })), _jsx("button", Object.assign({ className: singleBedCount ? 'gbvcss__btn gbvcss__btn--single' : 'gbvcss__btn__start gbvcss__btn__start--single', onClick: singleBedCountDecrement }, { children: _jsx("svg", Object.assign({ className: "gbvcss__decrement" }, { children: _jsx("use", { href: `${SelectBed}#minus` }) })) })), _jsx("span", Object.assign({ className: "gbvcss__counter" }, { children: singleBedCount })), _jsx("button", Object.assign({ className: "gbvcss__btn", onClick: singleBedCountIncrement }, { children: _jsx("svg", Object.assign({ className: "gbvcss__increment" }, { children: _jsx("use", { href: `${SelectBed}#plus` }) })) }))] })) })) })), _jsxs("div", Object.assign({ className: "gbvcss__buttons_wrap" }, { children: [disableBtn ? (_jsx("button", Object.assign({ ref: gbvcssRef, className: "gbvcss__confirmBtn", type: "submit", onClick: showAttention }, { children: t('done') }))) : (_jsx("button", Object.assign({ className: disableBtn
                            ? 'gbvcss__confirmBtn'
                            : 'gbvcss__confirmBtn gbvcss__confirmBtn__eneble gbvcss__confirmBtn__active', type: "submit", disabled: disableBtn, onClick: btnClick }, { children: t('done') }))), isMobile && (_jsx("div", Object.assign({ className: "arrow__roomsicon__block", onClick: backToSelectRoom }, { children: _jsx("svg", Object.assign({ className: "arrow__icon" }, { children: _jsx("use", { href: `${SelectBed}#arrow` }) })) })))] }))] })));
}
