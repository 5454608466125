import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { usePopover } from 'hooks/UI';
import { useTranslation } from 'react-i18next';
import clockIcon from 'assets/icons/proposeCard/clock_karma.svg';
import s from '../subcard.module.scss';
export const ButtonCommentRate = ({ onClick, isPayed, type }) => {
    const { t } = useTranslation('userBooking');
    const [parentPopperAttributes, childPopperAttributes] = usePopover({
        offset: [0, 6],
        placement: 'top',
    });
    return (_jsxs("button", Object.assign({ className: s.button }, parentPopperAttributes, { onClick: onClick, disabled: !isPayed }, { children: [_jsx("span", { children: type === 'comment' ? t('commentButton') : t('rateButton') }), isPayed && (_jsxs("div", Object.assign({ className: s.button_popover }, childPopperAttributes, { children: [_jsx("span", Object.assign({ className: s.button_popover__number }, { children: type === 'comment' ? '+ 2' : '+ 1' })), _jsx("img", { src: clockIcon, alt: "clock" }), _jsx("span", Object.assign({ className: s.button_popover__title }, { children: " Booking Karma" }))] }))), !isPayed && (_jsxs("div", Object.assign({ className: s.waitForHotelierPaymentConfirm }, childPopperAttributes, { children: [_jsx("p", Object.assign({ className: s.waitForHotelierPaymentConfirmTextNormal }, { children: t('noProofOfPaymentPopup') })), _jsx("p", Object.assign({ className: s.accentedPaymentStatus }, { children: t('noProofOfPayment') })), _jsx("p", Object.assign({ className: s.waitForHotelierPaymentConfirmTextNormal }, { children: t('noProofOfPaymentPopupText') }))] })))] })));
};
