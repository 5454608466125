import { jsx as _jsx } from "react/jsx-runtime";
import { useMedia } from 'hooks/UI';
import { useToggleNoScroll } from 'hooks/UI/modal';
import scss from './ScrollContainer.module.scss';
import { useLocation } from 'react-router-dom';
export const ScrollContainer = ({ children }) => {
    const { isMobile, isTablet, isCoarsePointer } = useMedia();
    const { isModalOpen, scrollWidth, ref } = useToggleNoScroll();
    const loc = useLocation();
    const isMobileAndCoarse = isMobile && isCoarsePointer;
    const isTabletAndCoarse = isTablet && isCoarsePointer;
    const marginRight = isModalOpen && !isMobileAndCoarse && !isTabletAndCoarse ? `${scrollWidth}px` : '0';
    const overflowY = isModalOpen ? scss.overflowYHidden : scss.overflowYScroll;
    // I think this is a temporary solution for cabinet-hotelier bug - when you get cards list, the page gets really wide to the right, so turning off scrollX is working fine.
    const cabinetHotelierMobile = loc.pathname === '/cabinet-hotelier' ? scss.overflowXHidden : '';
    return (_jsx("div", Object.assign({ className: `${scss.AppScrollContainer} ${overflowY} ${cabinetHotelierMobile}`, style: { marginRight }, ref: ref }, { children: children })));
};
