import { createSlice } from '@reduxjs/toolkit';
import { getThunkDataHotelsItem } from './thunks';
export const hotelItemSlice = createSlice({
    name: 'hotelsByIdHotelier',
    initialState: {
        hotelsByIdHotelier: [],
        loading: false,
        error: null,
    },
    extraReducers: {
        [getThunkDataHotelsItem.pending]: state => {
            state.loading = true;
        },
        [getThunkDataHotelsItem.fulfilled]: (state, action) => {
            state.hotelsByIdHotelier = action.payload.result;
            state.loading = false;
        },
        [getThunkDataHotelsItem.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});
export default hotelItemSlice.reducer;
