import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useMedia, usePopover } from 'hooks/UI';
import { useTranslation } from 'react-i18next';
import room1x1 from '../../../../../../../UserCabinet/BedOptions/image/icon/1x1.png';
// import SelectBed from '../../../MainPage/Assents/image/SelectBed.svg';
// import { getImage } from '../image/getImage';
import s from './ModalBedOptions.module.scss';
export const ModalUserHostelRoom = ({ bed, styled, user, item, title }) => {
    const { t } = useTranslation('userCard');
    const [parentPopperAttributes] = usePopover({
        offset: [0, 13],
        placement: 'top',
    });
    const { isMobile, isTablet } = useMedia();
    const deviceType = isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop';
    const [roomNumbers, setRoomNumbers] = useState({ roomNumberInHotel: null });
    const generateRandomNumber = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1) + min);
    };
    useEffect(() => {
        // const numberRoom = generateRandomNumber(1, 100);
        const roomNumberInHotel = generateRandomNumber(1, 12);
        setRoomNumbers({ roomNumberInHotel });
    }, []);
    return (_jsxs("div", Object.assign({}, parentPopperAttributes, { className: `${s.userHostel_block} hostel_${deviceType}`, style: styled, onClick: e => {
            if (deviceType !== 'desktop') {
                const currentTarget = e.currentTarget;
                currentTarget.classList.add('active');
                setTimeout(() => {
                    currentTarget.classList.remove('active');
                }, 1500);
            }
        } }, { children: [_jsxs("div", Object.assign({ className: s.userHostel__bed_container }, { children: [_jsxs("p", Object.assign({ className: s.userHostel__bed_number }, { children: [bed.descriptionInResponseData.free, " ", roomNumbers.roomNumberInHotel] })), _jsx("img", { src: room1x1, alt: title })] })), _jsx("div", Object.assign({ className: s.userRoom_title }, { children: _jsx("p", { children: t('roommate') }) }))] })));
};
