import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import arrowdown from 'assets/icons/proposeCard/arrowdown.svg';
import s from '../../HoteliersProposeCard.module.scss';
export const CardDownButton = ({ listLength, onClick }) => {
    const [toggleButtonClick, setToggleButtonClick] = useState(false);
    const handleClick = () => {
        if (listLength > 1) {
            onClick();
            setToggleButtonClick(true);
        }
    };
    return (_jsx("button", Object.assign({ "data-action": "cardDown", className: toggleButtonClick ? `${s.card__btn_apply} ${s.card__btn_apply__active}` : s.card__btn_apply, onClick: handleClick }, { children: _jsx("svg", Object.assign({ "data-action": "cardDown" }, { children: _jsx("use", { href: `${arrowdown}#arrowdown`, "data-action": "cardDown" }) })) })));
};
