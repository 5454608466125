import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// import { useAuthContext } from 'context/AuthContext';
import { SubheaderAgent } from './SubheaderAgent';
import scss from './SubheaderCabinetAgent.module.scss';
import { HeaderNobody } from '../../Components/HeaderNobody';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
/** CabinetAgent
 *  @see CABINET_AGENT /cabinet-agent
 * 1. userType - subheader
 * 2. NO BODY  - Log in page ✅
 * 3. User     - # ✅
 * 4. Hotelier - # ✅
 * 5. Agent    - Agent ✅
 */
export const SubheaderCabinetAgent = () => {
    const [isAgent, setIsAgent] = useState(false);
    const agent = useSelector((state) => state.auth);
    useEffect(() => {
        if (agent.role === 'agent') {
            setIsAgent(!!agent);
        }
        else {
            setIsAgent(false);
        }
    }, [agent]);
    return (_jsxs("div", Object.assign({ className: scss.subheaderCabinetAgent }, { children: [!isAgent && _jsx(HeaderNobody, {}), isAgent && _jsx(SubheaderAgent, {})] })));
};
