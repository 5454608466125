import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { usePopover } from 'hooks/UI';
import { useTranslation } from 'react-i18next';
import clockIcon from 'assets/icons/proposeCard/clock_karma.svg';
import arrowfree from 'assets/icons/proposeCard/arrowfree.svg';
import s from './button.module.scss';
export const CancelBooking = ({ onClick }) => {
    const { t } = useTranslation('userBooking');
    const [parentPopperAttributes, childPopperAttributes] = usePopover({
        offset: [0, 6],
        placement: 'top',
    });
    return (_jsx(_Fragment, { children: _jsxs("button", Object.assign({ className: s.optionsButton }, parentPopperAttributes, { onClick: onClick }, { children: [_jsx("span", Object.assign({ className: s.optionsButton_text }, { children: t('buttonCancel') })), _jsxs("div", Object.assign({ className: s.icon_free_container }, { children: [_jsx("img", { src: arrowfree, alt: "arrow that symbolize free booking return" }), "Free"] })), _jsxs("div", Object.assign({ className: s.button_popover }, childPopperAttributes, { children: [_jsx("span", Object.assign({ className: s.button_popover__number }, { children: "- 5" })), _jsx("img", { src: clockIcon, alt: "clock" }), _jsx("span", Object.assign({ className: s.button_popover__title }, { children: " Booking Karma" }))] }))] })) }));
};
