var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import axios from 'components/LoginSingUp/axios';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeEntity } from 'utils';
import { useClickOutside, useMedia, useStateToggle } from 'hooks/UI';
// import { useAuthContext } from 'context/AuthContext';
import { _LOGOUT_AGENT_URL, MAIN_AGENT } from 'navigation/CONSTANTS';
import { useSelector, useDispatch } from 'react-redux';
import { logOut as logOutRedux } from 'redux/authentication/authentication.actions';
import { useNavigate } from 'react-router-dom';
import { IconSmallArrow } from '../SubheaderUser';
import { IconHeader } from '../../Components/IconHeader';
import { Avatar } from '../SubheaderUserCard/Avatar';
import spinner from 'components/LoginSingUp/spinner/circle752.svg';
import { SubheadersThemeSwitch } from '../SubheadersTheme/SubheadersThemeSwitch';
import { useAppSelector } from 'features';
import s from './SubheaderAgent.module.scss';
const AgentExitButton = () => {
    // const [, dispatch] = useAuthContext();
    const dispatchRedux = useDispatch();
    const navigation = useNavigate();
    const navigateToMainAgent = () => navigation(MAIN_AGENT);
    const state = useSelector((state) => state);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation('mainAgent');
    const onClickLogOut = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setIsLoading(true);
            const response = yield axios.get(_LOGOUT_AGENT_URL, {
                headers: {
                    Authorization: `Bearer ${state.auth.token}`,
                },
            });
            console.log(response, 'response logout agent');
            if (response.status === 200) {
                dispatchRedux(logOutRedux());
                //	dispatch(logOut());
                localStorage.removeItem('user');
                navigateToMainAgent();
            }
        }
        catch (err) {
            console.log(err, 'err log');
            console.error(err, 'err');
        }
        finally {
            setIsLoading(false);
        }
    });
    const LoadingIndicator = () => (_jsx("div", Object.assign({ className: s.loading }, { children: _jsx("img", { src: spinner, alt: "Loading..." }) })));
    const theme = useAppSelector(state => state.theme.selectedTheme);
    const dark = theme === 'dark' ? s.dark : '';
    return (_jsxs("div", Object.assign({ id: "popup", className: s.popup }, { children: [isLoading && LoadingIndicator(), _jsx("li", Object.assign({ className: s.agentSettingsItem }, { children: _jsxs("button", Object.assign({ className: `${s.settingsButton} ${s.bold} ${dark}`, onClick: onClickLogOut }, { children: [_jsx("div", Object.assign({ className: s.iconThumb }, { children: _jsx(IconHeader, { className: `${s.exit} ${dark}`, iconId: "exit" }) })), _jsx("span", Object.assign({ className: s.buttonTextWrapper }, { children: t('logout') }))] })) }))] })));
};
const arr = [
    { iconId: 'computer', iconClassName: s.computer, i18nKey: 'agentsWorkSpace' },
    { iconId: 'house-small-grey', iconClassName: s.houseSmall, i18nKey: 'objectsRegistered', value: '9900' },
    { iconId: 'two-persons', iconClassName: s.twoPersons, i18nKey: 'agentsRegistered', value: '9900' },
    { iconId: 'dollar', iconClassName: s.dollar, buttonClassName: s.bold, i18nKey: 'available', value: '0 USD' },
];
const agentSettings = makeEntity(arr);
const AgentMeta = () => {
    const { t } = useTranslation('mainAgent');
    const [agentId] = useState('0987989');
    const theme = useAppSelector(state => state.theme.selectedTheme);
    const dark = theme === 'dark' ? s.dark : '';
    return (_jsx("div", Object.assign({ className: `${s.agentNameWrapper} ${dark}` }, { children: _jsxs("span", Object.assign({ className: s.agentId }, { children: [t('agent'), " ID ", agentId] })) })));
};
const AgentCardMobile = () => {
    return (_jsxs("div", Object.assign({ className: s.agentCardMobile }, { children: [_jsx(Avatar, {}), _jsx(AgentMeta, {})] })));
};
const AgentCardDesktop = () => {
    const { isMobile } = useMedia();
    return (_jsxs("div", Object.assign({ className: s.agentCard }, { children: [_jsx(Avatar, {}), !isMobile && _jsx(AgentMeta, {})] })));
};
const AgentSettingsList = () => {
    const { isMobile } = useMedia();
    const { t } = useTranslation('mainAgent');
    const theme = useAppSelector(state => state.theme.selectedTheme);
    const dark = theme === 'dark' ? s.dark : '';
    const [settings] = useState(agentSettings);
    return (_jsxs("div", Object.assign({ className: `${s.agentSettingsDropdownOverlay} ${dark}` }, { children: [isMobile && _jsx(AgentCardMobile, {}), _jsxs("ul", Object.assign({ className: `${s.agentSettingList} ${dark}` }, { children: [settings.ids.map(id => {
                        const item = settings.entities[id];
                        if (!item)
                            return null;
                        const { i18nKey, value, buttonClassName, iconId, iconClassName } = item;
                        return (_jsx("li", Object.assign({ className: s.agentSettingsItem }, { children: _jsxs("button", Object.assign({ className: `${s.settingsButton} ${buttonClassName} ${dark}`, type: "button" }, { children: [_jsx("div", Object.assign({ className: s.iconThumb }, { children: _jsx(IconHeader, { className: iconClassName, iconId: iconId }) })), _jsxs("div", Object.assign({ className: s.buttonTextWrapper }, { children: [_jsx("span", Object.assign({ className: s.alignLeft }, { children: t(i18nKey) })), _jsx("span", Object.assign({ className: s.bold }, { children: value }))] }))] })) }), id));
                    }), _jsx(SubheadersThemeSwitch, {}), _jsx(AgentExitButton, {})] }))] })));
};
export const SubheaderAgent = () => {
    const { isMobile } = useMedia();
    const [showSettings, , closeSettings, toggleSettings] = useStateToggle();
    const toggleButtonRef = useRef(null);
    const containerRef = useClickOutside(closeSettings, toggleButtonRef);
    const theme = useAppSelector(state => state.theme.selectedTheme);
    const dark = theme === 'dark' ? s.dark : '';
    const toggleButtonClassName = `${s.toggleAgentSettingsButton} ${showSettings ? s.overlayOpen : ''} ${dark}`;
    const handleMouseLeave = () => {
        setTimeout(() => {
            closeSettings();
        }, 300);
    };
    return (_jsx("div", Object.assign({ className: s.subheaderAgent }, { children: _jsxs("div", Object.assign({ className: s.relativeContainer, ref: containerRef, onMouseLeave: handleMouseLeave }, { children: [_jsxs("button", Object.assign({ className: toggleButtonClassName, onClick: toggleSettings, type: "button", ref: toggleButtonRef }, { children: [_jsx(AgentCardDesktop, {}), !isMobile && _jsx(IconSmallArrow, { isUp: showSettings })] })), showSettings && _jsx(AgentSettingsList, {})] })) })));
};
