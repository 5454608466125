import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from 'react';
import { useClickOutside, useMedia, useStateToggle } from 'hooks/UI';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUserInitialLocation } from 'redux/initialLocation/initialLocaion.actions';
import { Avatar } from './Avatar';
import { PersonInfo } from './PersonInfo';
import { IconSmallArrow } from '../SubheaderUser';
import { UserSettingsList } from '../SubheaderUser';
import { MobileUserCard } from './MobileUserCard';
import { useAppSelector } from 'features';
import scss from './SubheaderUserCard.module.scss';
export const SubheaderUserCard = () => {
    const dispatch = useDispatch();
    const theme = useAppSelector(state => state.theme.selectedTheme);
    const dark = theme === 'dark' ? scss.dark : '';
    const { isMobile } = useMedia();
    const [showSettings, , closeSettings, toggleSettings] = useStateToggle();
    const toggleButtonRef = useRef(null);
    const containerRef = useClickOutside(closeSettings, toggleButtonRef);
    const open = showSettings ? scss.open : '';
    const initialLocation = useLocation();
    const handleClick = () => {
        toggleSettings();
        dispatch(setUserInitialLocation(initialLocation.pathname));
    };
    const handleMouseLeave = () => {
        setTimeout(() => {
            closeSettings();
        }, 300);
    };
    return (_jsxs("div", Object.assign({ className: scss.personContainer, ref: containerRef, onMouseLeave: handleMouseLeave }, { children: [_jsxs("button", Object.assign({ className: `${scss.userCardButton} ${open} ${dark}`, onClick: handleClick, ref: toggleButtonRef }, { children: [_jsxs("div", Object.assign({ className: scss.userCardContent }, { children: [_jsx(Avatar, {}), !isMobile && _jsx(PersonInfo, {})] })), !isMobile && _jsx(IconSmallArrow, { isUp: showSettings })] })), !showSettings && (_jsxs("div", Object.assign({ className: `${scss.userSettingsWrapper} ${dark}` }, { children: [isMobile && _jsx(MobileUserCard, { isUp: showSettings }), _jsx(UserSettingsList, { closeSettings: closeSettings })] })))] })));
};
