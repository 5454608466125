import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useEffect } from 'react';
import searchIcon from '../../../../MainPage/Assents/image/Search_icon.svg';
import s from './modalTooltip.module.scss';
import { useTranslation } from 'react-i18next';
export const ModalTooltip = ({ isTooltipActive, setIsTooltipActive }) => {
    const tooltipRef = useRef(null);
    const tooltipIconRef = useRef(null);
    const { t } = useTranslation('hotelierCabinet');
    useEffect(() => {
        if (isTooltipActive) {
            function handleClickOutSideTooltip(event) {
                if (tooltipRef.current &&
                    !tooltipRef.current.contains(event.target) &&
                    tooltipIconRef.current &&
                    !tooltipIconRef.current.contains(event.target)) {
                    setIsTooltipActive(false);
                }
            }
            document.addEventListener('mousedown', handleClickOutSideTooltip);
            return () => {
                document.removeEventListener('mousedown', handleClickOutSideTooltip);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTooltipActive]);
    return (_jsxs("div", Object.assign({ className: s.modal__tooltip }, { children: [_jsx("div", Object.assign({ className: s.modal__tooltip_wrapper }, { children: _jsx("div", Object.assign({ className: s.modal__tooltip_icon_block, ref: tooltipIconRef, onClick: e => {
                        e.stopPropagation();
                        setIsTooltipActive(prev => !prev);
                    } }, { children: _jsx("img", { src: searchIcon, alt: "modal info tooltip" }) })) })), isTooltipActive && (_jsx("p", Object.assign({ ref: tooltipRef, className: s.modal__tooltip_text }, { children: t('modalSetPrice1or2') })))] })));
};
