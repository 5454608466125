import { useMemo, useReducer } from 'react';
import { authReducer, authReducerInitialState } from './authReducer';
const getUserObject = (user) => {
    if (!user)
        return { user, isHotelier: false, isAgent: false, isUser: false, isNobody: true };
    const { userType } = user;
    switch (userType) {
        case 'hotelier':
            return { user, isHotelier: true, isAgent: false, isUser: false, isNobody: false };
        case 'agent':
            return { user, isHotelier: false, isAgent: true, isUser: false, isNobody: false };
        case 'user':
            return { user, isHotelier: false, isAgent: false, isUser: true, isNobody: false };
    }
};
export const useAuthReducer = () => {
    const [{ user }, dispatch] = useReducer(authReducer, authReducerInitialState);
    return useMemo(() => [getUserObject(user), dispatch], [user]);
};
