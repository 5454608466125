// one bedroom
export const ONE_BEDROOM_DOUBLE_BED_COUNT_INCREMENT = "bedRooms/oneBedRoomDoubleBedCountIcrement";
export const ONE_BEDROOM_DOUBLE_BED_COUNT_DECREMENT = "bedRooms/oneBedRoomDoubleBedCountDecrement";
export const ONE_BEDROOM_SINGLE_BED_COUNT_INCREMENT = "bedRooms/oneBedRoomSingleBedCountIcrement";
export const ONE_BEDROOM_SINGLE_BED_COUNT_DECREMENT = "bedRooms/oneBedRoomSingleBedCountDecrement";
// Two bedroom
// first room
export const TWO_BEDROOM_FIRST_ROOM_DOUBLE_COUNT_INCREMENT = "bedRooms/twoBedRoomFirstRoomDoubleCountIncrement";
export const TWO_BEDROOM_FIRST_ROOM_DOUBLE_COUNT_DECREMENT = "bedRooms/twoBedRoomFirstRoomDoubleCountDecrement";
export const TWO_BEDROOM_FIRST_ROOM_SINGLE_COUNT_INCREMENT = "bedRooms/twoBedRoomFirstRoomSingleCountIncrement";
export const TWO_BEDROOM_FIRST_ROOM_SINGLE_COUNT_DECREMENT = "bedRooms/twoBedRoomFirstRoomSingleCountDecrement";
//   second room
export const TWO_BEDROOM_SECOND_ROOM_DOUBLE_COUNT_INCREMENT = "bedRooms/twoBedRoomSecondRoomDoubleCountIncrement";
export const TWO_BEDROOM_SECOND_ROOM_DOUBLE_COUNT_DECREMENT = "bedRooms/twoBedRoomSecondRoomDoubleCountDecrement";
export const TWO_BEDROOM_SECOND_ROOM_SINGLE_COUNT_INCREMENT = "bedRooms/twoBedRoomSecondRoomSingleCountIncrement";
export const TWO_BEDROOM_SECOND_ROOM_SINGLE_COUNT_DECREMENT = "bedRooms/twoBedRoomSecondRoomSingleCountDecrement";
//Gbvcss
export const GBVCSS_SINGLE_BED_COUNT_INCREMENT = "bedRooms/gbvcssSigleBedCountIncrement";
export const GBVCSS_SINGLE_BED_COUNT_DECREMENT = "bedRooms/gbvcssSigleBedCountDecrement";
// three bedroom
// first room
export const THREE_BEDROOM_FIRST_ROOM_DOUBLE_COUNT_INCREMENT = "bedRooms/threeBedRoomFirstRoomDoubleCountIncrement";
export const THREE_BEDROOM_FIRST_ROOM_DOUBLE_COUNT_DECREMENT = "bedRooms/threeBedRoomFirstRoomDoubleCountDecrement";
export const THREE_BEDROOM_FIRST_ROOM_SINGLE_COUNT_INCREMENT = "bedRooms/threeBedRoomFirstRoomSingleCountIncrement";
export const THREE_BEDROOM_FIRST_ROOM_SINGLE_COUNT_DECREMENT = "bedRooms/threeBedRoomFirstRoomSingleCountDecrement";
//   second room
export const THREE_BEDROOM_SECOND_ROOM_DOUBLE_COUNT_INCREMENT = "bedRooms/threeBedRoomSecondRoomDoubleCountIncrement";
export const THREE_BEDROOM_SECOND_ROOM_DOUBLE_COUNT_DECREMENT = "bedRooms/threeBedRoomSecondRoomDoubleCountDecrement";
export const THREE_BEDROOM_SECOND_ROOM_SINGLE_COUNT_INCREMENT = "bedRooms/threeBedRoomSecondRoomSingleCountIncrement";
export const THREE_BEDROOM_SECOND_ROOM_SINGLE_COUNT_DECREMENT = "bedRooms/threeBedRoomSecondRoomSingleCountDecrement";
//   third room
export const THREE_BEDROOM_THIRD_ROOM_DOUBLE_COUNT_INCREMENT = "bedRooms/threeBedRoomThirdRoomDoubleCountIncrement";
export const THREE_BEDROOM_THIRD_ROOM_DOUBLE_COUNT_DECREMENT = "bedRooms/threeBedRoomThirdRoomDoubleCountDecrement";
export const THREE_BEDROOM_THIRD_ROOM_SINGLE_COUNT_INCREMENT = "bedRooms/threeBedRoomThirdRoomSingleCountIncrement";
export const THREE_BEDROOM_THIRD_ROOM_SINGLE_COUNT_DECREMENT = "bedRooms/threeBedRoomThirdRoomSingleCountDecrement";
//four bedroom
// first room
export const FOUR_BEDROOM_FIRST_ROOM_DOUBLE_COUNT_INCREMENT = "bedRooms/fourBedRoomFirstRoomDoubleCountIncrement";
export const FOUR_BEDROOM_FIRST_ROOM_DOUBLE_COUNT_DECREMENT = "bedRooms/fourBedRoomFirstRoomDoubleCountDecrement";
export const FOUR_BEDROOM_FIRST_ROOM_SINGLE_COUNT_INCREMENT = "bedRooms/fourBedRoomFirstRoomSingleCountIncrement";
export const FOUR_BEDROOM_FIRST_ROOM_SINGLE_COUNT_DECREMENT = "bedRooms/fourBedRoomFirstRoomSingleCountDecrement";
//   second room
export const FOUR_BEDROOM_SECOND_ROOM_DOUBLE_COUNT_INCREMENT = "bedRooms/fourBedRoomSecondRoomDoubleCountIncrement";
export const FOUR_BEDROOM_SECOND_ROOM_DOUBLE_COUNT_DECREMENT = "bedRooms/fourBedRoomSecondRoomDoubleCountDecrement";
export const FOUR_BEDROOM_SECOND_ROOM_SINGLE_COUNT_INCREMENT = "bedRooms/fourBedRoomSecondRoomSingleCountIncrement";
export const FOUR_BEDROOM_SECOND_ROOM_SINGLE_COUNT_DECREMENT = "bedRooms/fourBedRoomSecondRoomSingleCountDecrement";
//   third room
export const FOUR_BEDROOM_THIRD_ROOM_DOUBLE_COUNT_INCREMENT = "bedRooms/fourBedRoomThirdRoomDoubleCountIncrement";
export const FOUR_BEDROOM_THIRD_ROOM_DOUBLE_COUNT_DECREMENT = "bedRooms/fourBedRoomThirdRoomDoubleCountDecrement";
export const FOUR_BEDROOM_THIRD_ROOM_SINGLE_COUNT_INCREMENT = "bedRooms/fourBedRoomThirdRoomSingleCountIncrement";
export const FOUR_BEDROOM_THIRD_ROOM_SINGLE_COUNT_DECREMENT = "bedRooms/fourBedRoomThirdRoomSingleCountDecrement";
//   fourth room
export const FOUR_BEDROOM_FOURTH_ROOM_DOUBLE_COUNT_INCREMENT = "bedRooms/fourBedRoomFourthRoomDoubleCountIncrement";
export const FOUR_BEDROOM_FOURTH_ROOM_DOUBLE_COUNT_DECREMENT = "bedRooms/fourBedRoomFourthRoomDoubleCountDecrement";
export const FOUR_BEDROOM_FOURTH_ROOM_SINGLE_COUNT_INCREMENT = "bedRooms/fourBedRoomFourthRoomSingleCountIncrement";
export const FOUR_BEDROOM_FOURTH_ROOM_SINGLE_COUNT_DECREMENT = "bedRooms/fourBedRoomFourthRoomSingleCountDecrement";
export const CLEAR_STATE = "bedRooms/clearState";
