import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import request from 'assets/images/header/Request.svg';
import disable from 'assets/images/header/Disable.svg';
import friends from 'assets/images/header/Friends.svg';
import message from 'assets/images/header/Message.svg';
import window from 'assets/images/header/Window.jpg';
import { useState } from 'react';
import { makeEntity } from 'utils';
// import { useTranslation } from 'react-i18next';
import scss from './SubheaderParlour.module.scss';
const links = [
    { src: request, alt: 'Booking request', text: 'Ищут бронирование рядом', i18nKey: '' },
    { src: disable, alt: 'Cancel reservation', text: 'Отменил бронирование', i18nKey: '' },
    { src: friends, alt: 'Booking agreement', text: 'Договор бронирования', i18nKey: '' },
    { src: message, alt: 'Booking message', text: 'Сообщение', i18nKey: '' },
];
const SubheaderItem = ({ src, alt, text }) => {
    // const { t } = useTranslation();
    const translated = text; // t(i18nKey)
    return (_jsxs("li", Object.assign({ className: "container-box" }, { children: [_jsx("div", Object.assign({ className: "imgs-wrap" }, { children: _jsx("img", { src: src, alt: alt }) })), _jsx("div", Object.assign({ className: "overlay" }, { children: translated }))] })));
};
const initState = Object.freeze(makeEntity(links));
export const SubheaderList = () => {
    const [subheaderLinks] = useState(initState);
    return (_jsx("ul", Object.assign({ className: scss.requestsBox }, { children: subheaderLinks.ids.map(subheaderLinkId => {
            const link = subheaderLinks.entities[subheaderLinkId];
            if (!link)
                return null;
            const { src, alt, text, i18nKey } = link;
            return _jsx(SubheaderItem, { src: src, alt: alt, text: text, i18nKey: i18nKey }, subheaderLinkId);
        }) })));
};
export const SubheaderParlour = () => {
    return (_jsxs("div", Object.assign({ className: scss.requestsBoxWrapper }, { children: [_jsx(SubheaderList, {}), _jsx("img", { src: window, alt: "Hotel", className: "hotel-img" })] })));
};
