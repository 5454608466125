import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import checkoutIcon from 'assets/icons/proposeCard/checkout.svg';
import checkinIcon from 'assets/icons/proposeCard/checkin.svg';
import s from '../../HoteliersProposeCard.module.scss';
import { useTranslation } from 'react-i18next';
export const BookingDates = ({ dates, type }) => {
    const { t } = useTranslation('common');
    const { checkInTime, checkOutTime } = dates;
    return (_jsxs("div", Object.assign({ className: s.booking }, { children: [_jsx("div", Object.assign({ className: s.booking_complex }, { children: _jsxs("p", Object.assign({ className: s.booking_complex_title }, { children: ["\u0410\u043F\u0430\u0440\u0442-\u043E\u0442\u0435\u043B\u044C", type] })) })), _jsxs("div", Object.assign({ className: s.booking__checkin }, { children: [_jsx("img", { className: s.checkin__icon, src: checkinIcon, alt: "checkin" }), _jsx("p", Object.assign({ className: s.checkin__title }, { children: t('checkIn') })), _jsx("p", Object.assign({ className: s.checkin__time }, { children: checkInTime }))] })), _jsxs("div", Object.assign({ className: s.booking__checkout }, { children: [_jsx("img", { className: s.checkout__icon, src: checkoutIcon, alt: "checkout" }), _jsx("p", Object.assign({ className: s.checkout__title }, { children: t('checkOut') })), _jsx("p", Object.assign({ className: s.checkout__time }, { children: checkOutTime }))] }))] })));
};
