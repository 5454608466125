import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { threeBedRoomFirstRoomDoubleBedCountDecrement, threeBedRoomFirstRoomDoubleBedCountIncrement, threeBedRoomFirstRoomSingleBedCountIncrement, threeBedRoomFirstRoomSingleBedCountDecrement, threeBedRoomSecondRoomDoubleBedCountIncrement, threeBedRoomSecondRoomDoubleBedCountDecrement, threeBedRoomSecondRoomSingleBedCountIncrement, threeBedRoomSecondRoomSingleBedCountDecrement, threeBedRoomThirdRoomDoubleBedCountIncrement, threeBedRoomThirdRoomDoubleBedCountDecrement, threeBedRoomThirdRoomSingleBedCountIncrement, threeBedRoomThirdRoomSingleBedCountDecrement, } from '../../../../redux/bedRooms/bedRooms.actions.js';
import './ThreeBedRoom.scss';
import SelectBed from '../../Assents/image/SelectBed.svg';
import { useTranslation } from 'react-i18next';
import { useScreenResizing } from 'hooks/UI/useScreenResizing.js';
export default function ThreeBedRoom({ readyBtnThreeBedRoom, backToSelectRoom }) {
    const viewPort = useScreenResizing();
    const isMobile = viewPort.width > 319 && viewPort.width < 768;
    const { t } = useTranslation('Calendar');
    const [showTreeBedroomFirstBlockAttetion, setShowTreeBedroomFistBlockAttetion] = useState(false);
    const [showTreeBedroomSecondBlockAttetion, setShowTreeBedroomSecondBlockAttetion] = useState(false);
    const [showTreeBedroomThirdBlockAttetion, setShowTreeBedroomThirdBlockAttetion] = useState(false);
    const dispatch = useDispatch();
    const firstBedRoomDoubleBedCount = useSelector(state => state.bedRoomReducer.threeBedRoomFirstRoomDoubleCount);
    const firstBedRoomSingleBedCount = useSelector(state => state.bedRoomReducer.threeBedRoomFirstRoomSingleBedCount);
    const fistBedRoomCountAllBed = firstBedRoomDoubleBedCount + firstBedRoomSingleBedCount;
    const firstBedRoomDoubleCountDecrement = () => {
        if (firstBedRoomDoubleBedCount === 0) {
            return firstBedRoomDoubleBedCount;
        }
        else {
            dispatch(threeBedRoomFirstRoomDoubleBedCountDecrement(1));
        }
    };
    const firstBedRoomDoubleCountIncrement = () => {
        if (firstBedRoomDoubleBedCount < 3 && fistBedRoomCountAllBed < 3) {
            dispatch(threeBedRoomFirstRoomDoubleBedCountIncrement(1));
            setShowTreeBedroomFistBlockAttetion(false);
        }
        else {
            return firstBedRoomDoubleBedCount;
        }
    };
    const firstSingleBedCountDecrement = () => {
        if (firstBedRoomSingleBedCount === 0) {
            return firstBedRoomSingleBedCount;
        }
        else {
            dispatch(threeBedRoomFirstRoomSingleBedCountDecrement(1));
        }
    };
    const firstSingleBedCountIncrement = () => {
        if (firstBedRoomSingleBedCount < 3 && fistBedRoomCountAllBed < 3) {
            dispatch(threeBedRoomFirstRoomSingleBedCountIncrement(1));
            setShowTreeBedroomFistBlockAttetion(false);
        }
        else {
            return firstBedRoomSingleBedCount;
        }
    };
    //second bedroom
    const secondBedRoomDoubleBedCount = useSelector(state => state.bedRoomReducer.threeBedRoomSecondRoomDoubleCount);
    const secondBedRoomSingleBedCount = useSelector(state => state.bedRoomReducer.threeBedRoomSecondRoomSingleBedCount);
    const secondBedRoomCountAllBed = secondBedRoomDoubleBedCount + secondBedRoomSingleBedCount;
    const secondBedRoomDoubluCountDecrement = () => {
        if (secondBedRoomDoubleBedCount === 0) {
            return secondBedRoomDoubleBedCount;
        }
        else {
            dispatch(threeBedRoomSecondRoomDoubleBedCountDecrement(1));
        }
    };
    const secondBedRoomDoubluCountIncrement = () => {
        if (secondBedRoomDoubleBedCount < 3 && secondBedRoomCountAllBed < 3) {
            dispatch(threeBedRoomSecondRoomDoubleBedCountIncrement(1));
            setShowTreeBedroomSecondBlockAttetion(false);
        }
        else {
            return secondBedRoomDoubleBedCount;
        }
    };
    const secondSingleBedCountDecrement = () => {
        if (secondBedRoomSingleBedCount === 0) {
            return secondBedRoomSingleBedCount;
        }
        else {
            dispatch(threeBedRoomSecondRoomSingleBedCountDecrement(1));
        }
    };
    const secondSingleBedCountIncrement = () => {
        if (secondBedRoomSingleBedCount < 3 && secondBedRoomCountAllBed < 3) {
            dispatch(threeBedRoomSecondRoomSingleBedCountIncrement(1));
            setShowTreeBedroomSecondBlockAttetion(false);
        }
        else {
            return secondBedRoomSingleBedCount;
        }
    };
    //third bedroom
    const thirdBedRoomDoubleBedCount = useSelector(state => state.bedRoomReducer.threeBedRoomThirdRoomDoubleCount);
    const thirdBedRoomSingleBedCount = useSelector(state => state.bedRoomReducer.threeBedRoomThirdRoomSingleBedCount);
    const thirdBedRoomCountAllBed = thirdBedRoomDoubleBedCount + thirdBedRoomSingleBedCount;
    const thirdBedRoomDoubluCountDecrement = () => {
        if (thirdBedRoomDoubleBedCount === 0) {
            return thirdBedRoomDoubleBedCount;
        }
        else {
            dispatch(threeBedRoomThirdRoomDoubleBedCountDecrement(1));
        }
    };
    const thirdBedRoomDoubluCountIncrement = () => {
        if (thirdBedRoomDoubleBedCount < 3 && thirdBedRoomCountAllBed < 3) {
            dispatch(threeBedRoomThirdRoomDoubleBedCountIncrement(1));
            setShowTreeBedroomThirdBlockAttetion(false);
        }
        else {
            return thirdBedRoomDoubleBedCount;
        }
    };
    const thirdSingleBedCountDecrement = () => {
        if (thirdBedRoomSingleBedCount === 0) {
            return thirdBedRoomSingleBedCount;
        }
        else {
            dispatch(threeBedRoomThirdRoomSingleBedCountDecrement(1));
        }
    };
    const thirdSingleBedCountIncrement = () => {
        if (thirdBedRoomSingleBedCount < 3 && thirdBedRoomCountAllBed < 3) {
            dispatch(threeBedRoomThirdRoomSingleBedCountIncrement(1));
            setShowTreeBedroomThirdBlockAttetion(false);
        }
        else {
            return thirdBedRoomSingleBedCount;
        }
    };
    const disableBtn = (firstBedRoomDoubleBedCount || firstBedRoomSingleBedCount) &&
        (secondBedRoomDoubleBedCount || secondBedRoomSingleBedCount) &&
        (thirdBedRoomDoubleBedCount || thirdBedRoomSingleBedCount)
        ? false
        : true;
    //handle btn
    const btnClick = () => {
        readyBtnThreeBedRoom();
    };
    const showAttention = () => {
        if (!firstBedRoomDoubleBedCount && !firstBedRoomSingleBedCount) {
            setShowTreeBedroomFistBlockAttetion(true);
        }
        if (!secondBedRoomDoubleBedCount && !secondBedRoomSingleBedCount) {
            setShowTreeBedroomSecondBlockAttetion(true);
        }
        if (!thirdBedRoomDoubleBedCount && !thirdBedRoomSingleBedCount) {
            setShowTreeBedroomThirdBlockAttetion(true);
        }
    };
    return (_jsxs("div", Object.assign({ className: "threeBedRooms__section" }, { children: [_jsxs("div", Object.assign({ className: "threeBedRooms__group" }, { children: [_jsxs("div", Object.assign({ className: "threeBedRooms__div" }, { children: [_jsxs("p", Object.assign({ className: "threeBedRooms__text" }, { children: [t('bedroom'), " 1"] })), _jsx("div", Object.assign({ className: showTreeBedroomFirstBlockAttetion
                                    ? 'threeBedRooms__block threeBedRooms__block--active'
                                    : 'threeBedRooms__block' }, { children: _jsxs("div", Object.assign({ className: "threeBedRooms__selected__block" }, { children: [_jsxs("div", Object.assign({ className: "threeBedRooms__selected__bed" }, { children: [_jsxs("div", Object.assign({ className: "threeBedRooms__firstDoubleBedRoom__hover" }, { children: [_jsx("svg", Object.assign({ className: "threeBedRooms__icon" }, { children: _jsx("use", { href: `${SelectBed}#doubleBed` }) })), _jsx("div", Object.assign({ className: "threeBedRooms__firstDoubleBedRoom__overlay" }, { children: t('doubleBed') }))] })), _jsx("button", Object.assign({ className: firstBedRoomDoubleBedCount
                                                        ? 'threeBedRooms__btn threeBedRooms__btn--double'
                                                        : 'threeBedRooms__btn__start threeBedRooms__btn__start--double', onClick: firstBedRoomDoubleCountDecrement }, { children: _jsx("svg", Object.assign({ className: "threeBedRooms__decrement" }, { children: _jsx("use", { href: `${SelectBed}#minus` }) })) })), _jsx("span", Object.assign({ className: "threeBedRooms__counter" }, { children: firstBedRoomDoubleBedCount })), _jsx("button", Object.assign({ className: "threeBedRooms__btn", onClick: firstBedRoomDoubleCountIncrement }, { children: _jsx("svg", Object.assign({ className: "threeBedRooms__increment" }, { children: _jsx("use", { href: `${SelectBed}#plus` }) })) }))] })), _jsxs("div", Object.assign({ className: "threeBedRooms__selected__bed threeBedRooms__selected__bed--modif" }, { children: [_jsxs("div", Object.assign({ className: "threeBedRooms__firstSingleBed__hover" }, { children: [_jsx("svg", Object.assign({ className: "threeBedRooms__icon threeBedRooms__icon--single" }, { children: _jsx("use", { href: `${SelectBed}#singleBed` }) })), _jsx("div", Object.assign({ className: "threeBedRooms__firstSingleBed__overlay" }, { children: t('singleBed') }))] })), _jsx("button", Object.assign({ className: firstBedRoomSingleBedCount
                                                        ? 'threeBedRooms__btn threeBedRooms__btn--single'
                                                        : 'threeBedRooms__btn__start threeBedRooms__btn__start--single', onClick: firstSingleBedCountDecrement }, { children: _jsx("svg", Object.assign({ className: "threeBedRooms__decrement" }, { children: _jsx("use", { href: `${SelectBed}#minus` }) })) })), _jsx("span", Object.assign({ className: "threeBedRooms__counter" }, { children: firstBedRoomSingleBedCount })), _jsx("button", Object.assign({ className: "threeBedRooms__btn", onClick: firstSingleBedCountIncrement }, { children: _jsx("svg", Object.assign({ className: "threeBedRooms__increment" }, { children: _jsx("use", { href: `${SelectBed}#plus` }) })) }))] }))] })) }))] })), _jsxs("div", Object.assign({ className: "threeBedRooms__div" }, { children: [_jsxs("p", Object.assign({ className: "threeBedRooms__text" }, { children: [t('bedroom'), " 2"] })), _jsx("div", Object.assign({ className: showTreeBedroomSecondBlockAttetion
                                    ? 'threeBedRooms__block threeBedRooms__block--active'
                                    : 'threeBedRooms__block' }, { children: _jsxs("div", Object.assign({ className: "threeBedRooms__selected__block" }, { children: [_jsxs("div", Object.assign({ className: "threeBedRooms__selected__bed" }, { children: [_jsxs("div", Object.assign({ className: "threeBedRooms__secondDoubleBedRoom__hover" }, { children: [_jsx("svg", Object.assign({ className: "threeBedRooms__icon" }, { children: _jsx("use", { href: `${SelectBed}#doubleBed` }) })), _jsx("div", Object.assign({ className: "threeBedRooms__secondDoubleBedRoom__overlay" }, { children: t('doubleBed') }))] })), _jsx("button", Object.assign({ className: secondBedRoomDoubleBedCount
                                                        ? 'threeBedRooms__btn threeBedRooms__btn--double'
                                                        : 'threeBedRooms__btn__start threeBedRooms__btn__start--double', onClick: secondBedRoomDoubluCountDecrement }, { children: _jsx("svg", Object.assign({ className: "threeBedRooms__decrement" }, { children: _jsx("use", { href: `${SelectBed}#minus` }) })) })), _jsx("span", Object.assign({ className: "threeBedRooms__counter" }, { children: secondBedRoomDoubleBedCount })), _jsx("button", Object.assign({ className: "threeBedRooms__btn", onClick: secondBedRoomDoubluCountIncrement }, { children: _jsx("svg", Object.assign({ className: "threeBedRooms__increment" }, { children: _jsx("use", { href: `${SelectBed}#plus` }) })) }))] })), _jsxs("div", Object.assign({ className: "threeBedRooms__selected__bed threeBedRooms__selected__bed--modif" }, { children: [_jsxs("div", Object.assign({ className: "threeBedRooms__secondSingleBed__hover" }, { children: [_jsx("svg", Object.assign({ className: "threeBedRooms__icon threeBedRooms__icon--single" }, { children: _jsx("use", { href: `${SelectBed}#singleBed` }) })), _jsx("div", Object.assign({ className: "threeBedRooms__secondSingleBed__overlay" }, { children: t('singleBed') }))] })), _jsx("button", Object.assign({ className: secondBedRoomSingleBedCount
                                                        ? 'threeBedRooms__btn threeBedRooms__btn--single'
                                                        : 'threeBedRooms__btn__start threeBedRooms__btn__start--single', onClick: secondSingleBedCountDecrement }, { children: _jsx("svg", Object.assign({ className: "threeBedRooms__decrement" }, { children: _jsx("use", { href: `${SelectBed}#minus` }) })) })), _jsx("span", Object.assign({ className: "threeBedRooms__counter" }, { children: secondBedRoomSingleBedCount })), _jsx("button", Object.assign({ className: "threeBedRooms__btn", onClick: secondSingleBedCountIncrement }, { children: _jsx("svg", Object.assign({ className: "threeBedRooms__increment" }, { children: _jsx("use", { href: `${SelectBed}#plus` }) })) }))] }))] })) }))] })), _jsxs("div", Object.assign({ className: "threeBedRooms__div" }, { children: [_jsxs("p", Object.assign({ className: "threeBedRooms__text" }, { children: [t('bedroom'), " 3"] })), _jsx("div", Object.assign({ className: showTreeBedroomThirdBlockAttetion
                                    ? 'threeBedRooms__block threeBedRooms__block--active'
                                    : 'threeBedRooms__block' }, { children: _jsxs("div", Object.assign({ className: "threeBedRooms__selected__block" }, { children: [_jsxs("div", Object.assign({ className: "threeBedRooms__selected__bed" }, { children: [_jsxs("div", Object.assign({ className: "threeBedRooms__thirdDoubleBedRoom__hover" }, { children: [_jsx("svg", Object.assign({ className: "threeBedRooms__icon" }, { children: _jsx("use", { href: `${SelectBed}#doubleBed` }) })), _jsx("div", Object.assign({ className: "threeBedRooms__thirdDoubleBedRoom__overlay" }, { children: t('doubleBed') }))] })), _jsx("button", Object.assign({ className: thirdBedRoomDoubleBedCount
                                                        ? 'threeBedRooms__btn threeBedRooms__btn--double'
                                                        : 'threeBedRooms__btn__start threeBedRooms__btn__start--double', onClick: thirdBedRoomDoubluCountDecrement }, { children: _jsx("svg", Object.assign({ className: "threeBedRooms__decrement" }, { children: _jsx("use", { href: `${SelectBed}#minus` }) })) })), _jsx("span", Object.assign({ className: "threeBedRooms__counter" }, { children: thirdBedRoomDoubleBedCount })), _jsx("button", Object.assign({ className: "threeBedRooms__btn", onClick: thirdBedRoomDoubluCountIncrement }, { children: _jsx("svg", Object.assign({ className: "threeBedRooms__increment" }, { children: _jsx("use", { href: `${SelectBed}#plus` }) })) }))] })), _jsxs("div", Object.assign({ className: "threeBedRooms__selected__bed threeBedRooms__selected__bed--modif" }, { children: [_jsxs("div", Object.assign({ className: "threeBedRooms__thirdSingleBed__hover" }, { children: [_jsx("svg", Object.assign({ className: "threeBedRooms__icon threeBedRooms__icon--single" }, { children: _jsx("use", { href: `${SelectBed}#singleBed` }) })), _jsx("div", Object.assign({ className: "threeBedRooms__thirdSingleBed__overlay" }, { children: t('singleBed') }))] })), _jsx("button", Object.assign({ className: thirdBedRoomSingleBedCount
                                                        ? 'threeBedRooms__btn threeBedRooms__btn--single'
                                                        : 'threeBedRooms__btn__start threeBedRooms__btn__start--single', onClick: thirdSingleBedCountDecrement }, { children: _jsx("svg", Object.assign({ className: "threeBedRooms__decrement" }, { children: _jsx("use", { href: `${SelectBed}#minus` }) })) })), _jsx("span", Object.assign({ className: "threeBedRooms__counter" }, { children: thirdBedRoomSingleBedCount })), _jsx("button", Object.assign({ className: "threeBedRooms__btn", onClick: thirdSingleBedCountIncrement }, { children: _jsx("svg", Object.assign({ className: "threeBedRooms__increment" }, { children: _jsx("use", { href: `${SelectBed}#plus` }) })) }))] }))] })) }))] }))] })), disableBtn ? (_jsx("button", Object.assign({ className: "threeBedRooms__confirmBtn", type: "submit", onClick: showAttention }, { children: t('done') }))) : (_jsx("button", Object.assign({ className: disableBtn
                    ? 'threeBedRooms__confirmBtn'
                    : 'threeBedRooms__confirmBtn threeBedRooms__confirmBtn__eneble threeBedRooms__confirmBtn__active', type: "submit", disabled: disableBtn, onClick: btnClick }, { children: t('done') }))), isMobile && (_jsx("div", Object.assign({ className: "arrow__roomsicon__block", onClick: backToSelectRoom }, { children: _jsx("svg", Object.assign({ className: "arrow__icon" }, { children: _jsx("use", { href: `${SelectBed}#arrow` }) })) })))] })));
}
