// import photo11 from 'assets/images/place/11.png';
// import photo12 from 'assets/images/place/12.png';
// import photo13 from 'assets/images/place/13.png';
// import photo14 from 'assets/images/place/14.png';
// import photo21 from 'assets/images/place/21.png';
// import photo22 from 'assets/images/place/22.png';
// import photo23 from 'assets/images/place/23.png';
// import photo24 from 'assets/images/place/24.png';
// import photo31 from 'assets/images/place/31.png';
// import photo32 from 'assets/images/place/32.png';
// import photo33 from 'assets/images/place/33.png';
// import photo34 from 'assets/images/place/34.png';
import photo41 from 'assets/images/place/41.jpg';
import photo42 from 'assets/images/place/42.jpg';
import photo43 from 'assets/images/place/43.jpg';
import photo44 from 'assets/images/place/44.jpg';
import photo45 from 'assets/images/place/45.jpg';
import photo46 from 'assets/images/place/46.jpg';
import photo47 from 'assets/images/place/47.jpg';
import photo48 from 'assets/images/place/48.jpg';
import photo49 from 'assets/images/place/49.jpg';
import photo50 from 'assets/images/place/50.jpg';
import photo51 from 'assets/images/place/51.jpg';
import photo52 from 'assets/images/place/52.jpg';
import photo53 from 'assets/images/place/53.jpg';
import photo54 from 'assets/images/place/54.jpg';
import photo55 from 'assets/images/place/55.jpg';
import photo56 from 'assets/images/place/56.jpg';
import photo57 from 'assets/images/place/57.jpg';
import photo58 from 'assets/images/place/58.jpg';
import photo59 from 'assets/images/place/59.jpg';
import photo60 from 'assets/images/place/60.jpg';
import photo61 from 'assets/images/place/61.jpg';
import photo62 from 'assets/images/place/62.jpg';
import photo63 from 'assets/images/place/63.jpg';
import photo64 from 'assets/images/place/64.jpg';
import photo65 from 'assets/images/place/65.jpg';
import photo66 from 'assets/images/place/66.jpg';
import photo67 from 'assets/images/place/67.jpg';
import photo68 from 'assets/images/place/68.jpg';
import photo69 from 'assets/images/place/69.jpg';
import photo101 from 'assets/images/place/101.jpg';
import photo102 from 'assets/images/place/102.jpg';
import photo103 from 'assets/images/place/103.jpg';
import photo104 from 'assets/images/place/104.jpg';
import photo105 from 'assets/images/place/105.jpg';
import photo106 from 'assets/images/place/106.jpg';
import photo107 from 'assets/images/place/107.jpg';
import photo108 from 'assets/images/place/108.jpg';
import photo109 from 'assets/images/place/109.jpg';
import photo110 from 'assets/images/place/110.jpg';
import photo111 from 'assets/images/place/111.jpg';
import photo112 from 'assets/images/place/112.jpg';
import photo113 from 'assets/images/place/113.jpg';
import photo114 from 'assets/images/place/114.jpg';
import photo115 from 'assets/images/place/115.jpg';
import photo116 from 'assets/images/place/116.jpg';
import photo117 from 'assets/images/place/117.jpg';
import photo118 from 'assets/images/place/118.jpg';
import photo119 from 'assets/images/place/119.jpg';
import photo120 from 'assets/images/place/120.jpg';
import photo121 from 'assets/images/place/121.jpg';
import photo122 from 'assets/images/place/122.jpg';
import photo123 from 'assets/images/place/123.jpg';
import photo124 from 'assets/images/place/124.jpg';
import photo125 from 'assets/images/place/125.jpg';
import photo126 from 'assets/images/place/126.jpg';
import photo127 from 'assets/images/place/127.jpg';
export const houseCards = [
    {
        id: 1,
        title: 'Raj Palace Hotel',
        location: 'Левобережный массив',
        address: 'ул. Пражская, д. 33, г. Киев, Украина',
        city: 'cityKyiv',
        type: 'hotel',
        feedbackAmount: 789,
        minArea: 77,
        maxArea: 97,
        rating: '4,33',
        textDescription: 'Данный объект бронирования является тестовым и был создан для бэта тестирования данного раздела сайта бронирований гостиниц и посуточной аренды жилья Your Price Booking. Старт платформы бронирования запланирован на конец 2022 года. В данный момент вы видите тестовую версию сайта и сейчас бронирование еще не доступно. Первые 4 города в которых начинает работат Your Price Booking это: Киев, Одесса, Варшава и город Нью - Йорк. Огромным преимуществом для пользователей есть отсутствие паритета цен в отношениях с отельерами. Они могут понижать цену на свободные номера как для общего обозрения, так и делат индивидуалные ценовые предложения - конфиденциально.',
        url: photo42,
        urlImageSecond: photo44,
        videoLink: 'https://www.youtube.com/embed/eoGdSempEuQ',
        videoId: 'eoGdSempEuQ',
        checkIn: '14:00',
        checkOut: '15:00',
        minPrice: 1800,
        maxPrice: 3900,
        album: [
            { name: photo42, id: 1 },
            { name: photo44, id: 2 },
            { name: photo46, id: 3 },
            { name: photo48, id: 4 },
            { name: photo50, id: 5 },
            { name: photo52, id: 6 },
            { name: photo54, id: 7 },
            { name: photo56, id: 8 },
            { name: photo58, id: 9 },
            { name: photo60, id: 10 },
            { name: photo62, id: 11 },
            { name: photo64, id: 12 },
        ],
        description: [
            { id: 'objectService_01' },
            { id: 'objectService_04' },
            { id: 'objectService_07' },
            { id: 'objectService_10' },
            { id: 'objectService_13' },
            { id: 'objectService_16' },
            { id: 'objectService_19' },
            { id: 'objectService_22' },
            { id: 'objectService_25' },
            { id: 'objectService_28' },
            { id: 'objectService_31' },
            { id: 'objectService_34' },
            { id: 'objectService_37' },
            { id: 'objectService_40' },
            { id: 'objectService_43' },
            { id: 'objectService_46' },
            { id: 'objectService_49' },
            { id: 'objectService_52' },
        ],
        options: [
            {
                textDescription: 'Здесь мы пишем про вариант бронирования. Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti tenetur, quas amet molestias dolore animi nihilunde sunt nesciunt veritatis modi quia quis sapiente dolor odit dignissimos ad facere consequatur suscipit dolorum eius! Maxime eligendi molestias unde rem tempore quos, ut perferendis tempora ab minus in reiciendis ullam vel nostrum, quae deserunt ducimus quo voluptas sint, sequi quibusdam eveniet fuga. Odit provident illo perferendis exercitationem earum veritatis eveniet iusto doloribus deserunt expedita omnis voluptatibus quaerat iste atque ullam, dicta temporibus ut quod tenetur dolor incidunt cupiditate illum similique animi. Dolorem sequi alias ut eligendi modi eum fugiat eos blanditiis explicabo.',
                description: [
                    { id: 'optionService_01' },
                    { id: 'optionService_03' },
                    { id: 'optionService_05' },
                    { id: 'optionService_07' },
                    { id: 'optionService_09' },
                    { id: 'optionService_11' },
                    { id: 'optionService_13' },
                    { id: 'optionService_15' },
                    { id: 'optionService_17' },
                    { id: 'optionService_19' },
                    { id: 'optionService_21' },
                    { id: 'optionService_23' },
                    { id: 'optionService_25' },
                ],
                bedrooms: { 1: { single: 1, double: 0 }, 2: { single: 0, double: 1 }, 3: { single: 2, double: 0 } },
                album: [
                    { name: photo102, id: 1 },
                    { name: photo104, id: 2 },
                    { name: photo106, id: 3 },
                    { name: photo108, id: 4 },
                    { name: photo110, id: 5 },
                    { name: photo112, id: 6 },
                ],
                animals: ['smallDog', 'mediumDog', 'largeDog', 'raccoon', 'cat', 'notPredator'],
                area: 77,
                minPrice: 1800,
                maxPrice: 3900,
            },
            {
                textDescription: 'Здесь мы пишем про вариант бронирования. Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti tenetur, quas amet molestias dolore animi nihilunde sunt nesciunt veritatis modi quia quis sapiente dolor odit dignissimos ad facere consequatur suscipit dolorum eius! Maxime eligendi molestias unde rem tempore quos, ut perferendis tempora ab minus in reiciendis ullam vel nostrum, quae deserunt ducimus quo voluptas sint, sequi quibusdam eveniet fuga. Odit provident illo perferendis exercitationem earum veritatis eveniet iusto doloribus deserunt expedita omnis voluptatibus quaerat iste atque ullam, dicta temporibus ut quod tenetur dolor incidunt cupiditate illum similique animi. Dolorem sequi alias ut eligendi modi eum fugiat eos blanditiis explicabo.',
                description: [
                    { id: 'optionService_02' },
                    { id: 'optionService_04' },
                    { id: 'optionService_06' },
                    { id: 'optionService_08' },
                    { id: 'optionService_10' },
                    { id: 'optionService_12' },
                    { id: 'optionService_14' },
                    { id: 'optionService_16' },
                    { id: 'optionService_18' },
                    { id: 'optionService_20' },
                    { id: 'optionService_22' },
                    { id: 'optionService_24' },
                    { id: 'optionService_26' },
                ],
                bedrooms: {
                    1: { single: 1, double: 1 },
                    2: { single: 3, double: 0 },
                    3: { single: 2, double: 1 },
                    4: { single: 0, double: 2 },
                },
                album: [
                    { name: photo114, id: 1 },
                    { name: photo116, id: 2 },
                    { name: photo118, id: 3 },
                    { name: photo120, id: 4 },
                    { name: photo122, id: 5 },
                    { name: photo124, id: 6 },
                ],
                animals: ['smallDog', 'raccoon', 'cat', 'notPredator'],
                area: 97,
                minPrice: 0,
                maxPrice: 0,
            },
        ],
    },
    {
        id: 2,
        title: 'Hotel President Wilson',
        location: 'Минский массив',
        address: 'ул. Героев полка Азов, д. 17, г. Киев, Украина',
        city: 'cityKyiv',
        type: 'motel',
        feedbackAmount: 492,
        minArea: 56,
        maxArea: 88,
        rating: '4,1',
        textDescription: 'Данный объект бронирования является тестовым и был создан для бэта тестирования данного раздела сайта бронирований гостиниц и посуточной аренды жилья Your Price Booking. Старт платформы бронирования запланирован на конец 2022 года. В данный момент вы видите тестовую версию сайта и сейчас бронирование еще не доступно. Первые 4 города в которых начинает работат Your Price Booking это: Киев, Одесса, Варшава и город Нью - Йорк. Огромным преимуществом для пользователей есть отсутствие паритета цен в отношениях с отельерами. Они могут понижать цену на свободные номера как для общего обозрения, так и делат индивидуалные ценовые предложения - конфиденциально.',
        url: photo41,
        urlImageSecond: photo43,
        videoLink: 'https://www.youtube.com/embed/PhQyTB2_hLA',
        videoId: 'PhQyTB2_hLA',
        checkIn: '11:00',
        checkOut: '13:00',
        minPrice: 1650,
        maxPrice: 2800,
        album: [
            { name: photo41, id: 1 },
            { name: photo43, id: 2 },
            { name: photo45, id: 3 },
            { name: photo47, id: 4 },
            { name: photo49, id: 5 },
            { name: photo51, id: 6 },
            { name: photo53, id: 7 },
            { name: photo55, id: 8 },
            { name: photo57, id: 9 },
            { name: photo59, id: 10 },
            { name: photo61, id: 11 },
            { name: photo63, id: 12 },
        ],
        description: [
            { id: 'objectService_02' },
            { id: 'objectService_05' },
            { id: 'objectService_08' },
            { id: 'objectService_11' },
            { id: 'objectService_14' },
            { id: 'objectService_17' },
            { id: 'objectService_20' },
            { id: 'objectService_23' },
            { id: 'objectService_26' },
            { id: 'objectService_29' },
            { id: 'objectService_32' },
            { id: 'objectService_35' },
            { id: 'objectService_38' },
            { id: 'objectService_41' },
            { id: 'objectService_44' },
            { id: 'objectService_47' },
            { id: 'objectService_50' },
            { id: 'objectService_53' },
        ],
        options: [
            {
                textDescription: 'Здесь мы пишем про вариант бронирования. Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti tenetur, quas amet molestias dolore animi nihilunde sunt nesciunt veritatis modi quia quis sapiente dolor odit dignissimos ad facere consequatur suscipit dolorum eius! Maxime eligendi molestias unde rem tempore quos, ut perferendis tempora ab minus in reiciendis ullam vel nostrum, quae deserunt ducimus quo voluptas sint, sequi quibusdam eveniet fuga. Odit provident illo perferendis exercitationem earum veritatis eveniet iusto doloribus deserunt expedita omnis voluptatibus quaerat iste atque ullam, dicta temporibus ut quod tenetur dolor incidunt cupiditate illum similique animi. Dolorem sequi alias ut eligendi modi eum fugiat eos blanditiis explicabo.',
                description: [
                    { id: 'optionService_01' },
                    { id: 'optionService_03' },
                    { id: 'optionService_05' },
                    { id: 'optionService_07' },
                    { id: 'optionService_09' },
                    { id: 'optionService_11' },
                    { id: 'optionService_13' },
                    { id: 'optionService_15' },
                    { id: 'optionService_17' },
                    { id: 'optionService_19' },
                    { id: 'optionService_21' },
                    { id: 'optionService_23' },
                    { id: 'optionService_25' },
                ],
                bedrooms: { 1: { single: 1, double: 0 }, 2: { single: 0, double: 1 }, 3: { single: 2, double: 0 } },
                album: [
                    { name: photo101, id: 1 },
                    { name: photo103, id: 2 },
                    { name: photo105, id: 3 },
                    { name: photo107, id: 4 },
                    { name: photo109, id: 5 },
                    { name: photo111, id: 6 },
                ],
                animals: ['raccoon', 'cat', 'notPredator'],
                area: 56,
                minPrice: 1650,
                maxPrice: 2400,
            },
            {
                textDescription: 'Здесь мы пишем про вариант бронирования. Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti tenetur, quas amet molestias dolore animi nihilunde sunt nesciunt veritatis modi quia quis sapiente dolor odit dignissimos ad facere consequatur suscipit dolorum eius! Maxime eligendi molestias unde rem tempore quos, ut perferendis tempora ab minus in reiciendis ullam vel nostrum, quae deserunt ducimus quo voluptas sint, sequi quibusdam eveniet fuga. Odit provident illo perferendis exercitationem earum veritatis eveniet iusto doloribus deserunt expedita omnis voluptatibus quaerat iste atque ullam, dicta temporibus ut quod tenetur dolor incidunt cupiditate illum similique animi. Dolorem sequi alias ut eligendi modi eum fugiat eos blanditiis explicabo.',
                description: [
                    { id: 'optionService_02' },
                    { id: 'optionService_04' },
                    { id: 'optionService_06' },
                    { id: 'optionService_08' },
                    { id: 'optionService_10' },
                    { id: 'optionService_12' },
                    { id: 'optionService_14' },
                    { id: 'optionService_16' },
                    { id: 'optionService_18' },
                    { id: 'optionService_20' },
                    { id: 'optionService_22' },
                    { id: 'optionService_24' },
                    { id: 'optionService_26' },
                ],
                bedrooms: {
                    1: { single: 1, double: 1 },
                    2: { single: 3, double: 0 },
                    3: { single: 2, double: 1 },
                    4: { single: 0, double: 2 },
                },
                album: [
                    { name: photo113, id: 1 },
                    { name: photo115, id: 2 },
                    { name: photo117, id: 3 },
                    { name: photo119, id: 4 },
                    { name: photo121, id: 5 },
                    { name: photo123, id: 6 },
                ],
                animals: ['smallDog', 'raccoon', 'cat', 'notPredator'],
                area: 88,
                minPrice: 2200,
                maxPrice: 2800,
            },
        ],
    },
    {
        id: 3,
        title: 'The Mark Hotel',
        location: 'Пуща-Водица',
        address: 'ул. Первая линия, д. 99, г. Киев, Украина',
        city: 'cityKyiv',
        type: 'hotel_2stars',
        feedbackAmount: 564,
        minArea: 62,
        maxArea: 76,
        rating: '4,6',
        textDescription: 'Данный объект бронирования является тестовым и был создан для бэта тестирования данного раздела сайта бронирований гостиниц и посуточной аренды жилья Your Price Booking. Старт платформы бронирования запланирован на конец 2022 года. В данный момент вы видите тестовую версию сайта и сейчас бронирование еще не доступно. Первые 4 города в которых начинает работат Your Price Booking это: Киев, Одесса, Варшава и город Нью - Йорк. Огромным преимуществом для пользователей есть отсутствие паритета цен в отношениях с отельерами. Они могут понижать цену на свободные номера как для общего обозрения, так и делат индивидуалные ценовые предложения - конфиденциально.',
        url: photo69,
        urlImageSecond: photo68,
        videoLink: 'https://www.youtube.com/embed/js26litgniQ',
        videoId: 'js26litgniQ',
        checkIn: '12:00',
        checkOut: '14:00',
        minPrice: 2200,
        maxPrice: 2600,
        album: [
            { name: photo69, id: 1 },
            { name: photo68, id: 2 },
            { name: photo67, id: 3 },
            { name: photo66, id: 4 },
            { name: photo65, id: 5 },
            { name: photo64, id: 6 },
            { name: photo63, id: 7 },
            { name: photo62, id: 8 },
            { name: photo61, id: 9 },
            { name: photo60, id: 10 },
            { name: photo59, id: 11 },
            { name: photo58, id: 12 },
        ],
        description: [
            { id: 'objectService_03' },
            { id: 'objectService_06' },
            { id: 'objectService_09' },
            { id: 'objectService_12' },
            { id: 'objectService_15' },
            { id: 'objectService_18' },
            { id: 'objectService_21' },
            { id: 'objectService_24' },
            { id: 'objectService_27' },
            { id: 'objectService_30' },
            { id: 'objectService_33' },
            { id: 'objectService_36' },
            { id: 'objectService_39' },
            { id: 'objectService_42' },
            { id: 'objectService_45' },
            { id: 'objectService_48' },
            { id: 'objectService_51' },
            { id: 'objectService_54' },
        ],
        options: [
            {
                textDescription: 'Здесь мы пишем про вариант бронирования. Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti tenetur, quas amet molestias dolore animi nihilunde sunt nesciunt veritatis modi quia quis sapiente dolor odit dignissimos ad facere consequatur suscipit dolorum eius! Maxime eligendi molestias unde rem tempore quos, ut perferendis tempora ab minus in reiciendis ullam vel nostrum, quae deserunt ducimus quo voluptas sint, sequi quibusdam eveniet fuga. Odit provident illo perferendis exercitationem earum veritatis eveniet iusto doloribus deserunt expedita omnis voluptatibus quaerat iste atque ullam, dicta temporibus ut quod tenetur dolor incidunt cupiditate illum similique animi. Dolorem sequi alias ut eligendi modi eum fugiat eos blanditiis explicabo.',
                description: [
                    { id: 'optionService_01' },
                    { id: 'optionService_03' },
                    { id: 'optionService_05' },
                    { id: 'optionService_07' },
                    { id: 'optionService_09' },
                    { id: 'optionService_11' },
                    { id: 'optionService_13' },
                    { id: 'optionService_15' },
                    { id: 'optionService_17' },
                    { id: 'optionService_19' },
                    { id: 'optionService_21' },
                    { id: 'optionService_23' },
                    { id: 'optionService_25' },
                ],
                bedrooms: { 1: { single: 1, double: 0 }, 2: { single: 0, double: 1 }, 3: { single: 2, double: 0 } },
                album: [
                    { name: photo127, id: 1 },
                    { name: photo126, id: 2 },
                    { name: photo125, id: 3 },
                    { name: photo124, id: 4 },
                    { name: photo123, id: 5 },
                    { name: photo122, id: 6 },
                ],
                animals: ['cat'],
                area: 62,
                minPrice: 0,
                maxPrice: 0,
            },
            {
                textDescription: 'Здесь мы пишем про вариант бронирования. Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti tenetur, quas amet molestias dolore animi nihilunde sunt nesciunt veritatis modi quia quis sapiente dolor odit dignissimos ad facere consequatur suscipit dolorum eius! Maxime eligendi molestias unde rem tempore quos, ut perferendis tempora ab minus in reiciendis ullam vel nostrum, quae deserunt ducimus quo voluptas sint, sequi quibusdam eveniet fuga. Odit provident illo perferendis exercitationem earum veritatis eveniet iusto doloribus deserunt expedita omnis voluptatibus quaerat iste atque ullam, dicta temporibus ut quod tenetur dolor incidunt cupiditate illum similique animi. Dolorem sequi alias ut eligendi modi eum fugiat eos blanditiis explicabo.',
                description: [
                    { id: 'optionService_02' },
                    { id: 'optionService_04' },
                    { id: 'optionService_06' },
                    { id: 'optionService_08' },
                    { id: 'optionService_10' },
                    { id: 'optionService_12' },
                    { id: 'optionService_14' },
                    { id: 'optionService_16' },
                    { id: 'optionService_18' },
                    { id: 'optionService_20' },
                    { id: 'optionService_22' },
                    { id: 'optionService_24' },
                    { id: 'optionService_26' },
                ],
                bedrooms: {
                    1: { single: 1, double: 1 },
                    2: { single: 3, double: 0 },
                    3: { single: 2, double: 1 },
                    4: { single: 0, double: 2 },
                },
                album: [
                    { name: photo121, id: 1 },
                    { name: photo120, id: 2 },
                    { name: photo119, id: 3 },
                    { name: photo118, id: 4 },
                    { name: photo117, id: 5 },
                    { name: photo116, id: 6 },
                ],
                animals: ['smallDog', 'raccoon', 'cat', 'notPredator'],
                area: 76,
                minPrice: 2200,
                maxPrice: 2600,
            },
        ],
    },
    {
        id: 4,
        title: 'Laucala Island Resort',
        location: 'Южная Борщаговка',
        address: 'просп. Воссоединения, д. 27, г. Киев, Украина',
        city: 'cityKyiv',
        type: 'villa',
        feedbackAmount: 789,
        minArea: 77,
        maxArea: 97,
        rating: '4,0',
        textDescription: 'Данный объект бронирования является тестовым и был создан для бэта тестирования данного раздела сайта бронирований гостиниц и посуточной аренды жилья Your Price Booking. Старт платформы бронирования запланирован на конец 2022 года. В данный момент вы видите тестовую версию сайта и сейчас бронирование еще не доступно. Первые 4 города в которых начинает работат Your Price Booking это: Киев, Одесса, Варшава и город Нью - Йорк. Огромным преимуществом для пользователей есть отсутствие паритета цен в отношениях с отельерами. Они могут понижать цену на свободные номера как для общего обозрения, так и делат индивидуалные ценовые предложения - конфиденциально.',
        url: photo52,
        urlImageSecond: photo54,
        videoLink: 'https://www.youtube.com/embed/DG61Kl773qQ',
        videoId: 'DG61Kl773qQ',
        checkIn: '11:00',
        checkOut: '13:00',
        minPrice: 1800,
        maxPrice: 3900,
        album: [
            { name: photo52, id: 1 },
            { name: photo54, id: 2 },
            { name: photo46, id: 3 },
            { name: photo48, id: 4 },
            { name: photo50, id: 5 },
            { name: photo42, id: 6 },
            { name: photo44, id: 7 },
            { name: photo56, id: 8 },
            { name: photo58, id: 9 },
            { name: photo60, id: 10 },
            { name: photo62, id: 11 },
            { name: photo64, id: 12 },
        ],
        description: [
            { id: 'objectService_01' },
            { id: 'objectService_04' },
            { id: 'objectService_07' },
            { id: 'objectService_10' },
            { id: 'objectService_13' },
            { id: 'objectService_16' },
            { id: 'objectService_19' },
            { id: 'objectService_22' },
            { id: 'objectService_25' },
            { id: 'objectService_28' },
            { id: 'objectService_31' },
            { id: 'objectService_34' },
            { id: 'objectService_37' },
            { id: 'objectService_40' },
            { id: 'objectService_43' },
            { id: 'objectService_46' },
            { id: 'objectService_49' },
            { id: 'objectService_52' },
        ],
        options: [
            {
                textDescription: 'Здесь мы пишем про вариант бронирования. Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti tenetur, quas amet molestias dolore animi nihilunde sunt nesciunt veritatis modi quia quis sapiente dolor odit dignissimos ad facere consequatur suscipit dolorum eius! Maxime eligendi molestias unde rem tempore quos, ut perferendis tempora ab minus in reiciendis ullam vel nostrum, quae deserunt ducimus quo voluptas sint, sequi quibusdam eveniet fuga. Odit provident illo perferendis exercitationem earum veritatis eveniet iusto doloribus deserunt expedita omnis voluptatibus quaerat iste atque ullam, dicta temporibus ut quod tenetur dolor incidunt cupiditate illum similique animi. Dolorem sequi alias ut eligendi modi eum fugiat eos blanditiis explicabo.',
                description: [
                    { id: 'optionService_01' },
                    { id: 'optionService_03' },
                    { id: 'optionService_05' },
                    { id: 'optionService_07' },
                    { id: 'optionService_09' },
                    { id: 'optionService_11' },
                    { id: 'optionService_13' },
                    { id: 'optionService_15' },
                    { id: 'optionService_17' },
                    { id: 'optionService_19' },
                    { id: 'optionService_21' },
                    { id: 'optionService_23' },
                    { id: 'optionService_25' },
                ],
                bedrooms: { 1: { single: 1, double: 0 }, 2: { single: 0, double: 1 }, 3: { single: 2, double: 0 } },
                album: [
                    { name: photo102, id: 1 },
                    { name: photo104, id: 2 },
                    { name: photo106, id: 3 },
                    { name: photo108, id: 4 },
                    { name: photo110, id: 5 },
                    { name: photo112, id: 6 },
                ],
                animals: ['smallDog', 'mediumDog', 'largeDog', 'raccoon', 'cat', 'notPredator'],
                area: 77,
                minPrice: 1800,
                maxPrice: 3900,
            },
            {
                textDescription: 'Здесь мы пишем про вариант бронирования. Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti tenetur, quas amet molestias dolore animi nihilunde sunt nesciunt veritatis modi quia quis sapiente dolor odit dignissimos ad facere consequatur suscipit dolorum eius! Maxime eligendi molestias unde rem tempore quos, ut perferendis tempora ab minus in reiciendis ullam vel nostrum, quae deserunt ducimus quo voluptas sint, sequi quibusdam eveniet fuga. Odit provident illo perferendis exercitationem earum veritatis eveniet iusto doloribus deserunt expedita omnis voluptatibus quaerat iste atque ullam, dicta temporibus ut quod tenetur dolor incidunt cupiditate illum similique animi. Dolorem sequi alias ut eligendi modi eum fugiat eos blanditiis explicabo.',
                description: [
                    { id: 'optionService_02' },
                    { id: 'optionService_04' },
                    { id: 'optionService_06' },
                    { id: 'optionService_08' },
                    { id: 'optionService_10' },
                    { id: 'optionService_12' },
                    { id: 'optionService_14' },
                    { id: 'optionService_16' },
                    { id: 'optionService_18' },
                    { id: 'optionService_20' },
                    { id: 'optionService_22' },
                    { id: 'optionService_24' },
                    { id: 'optionService_26' },
                ],
                bedrooms: {
                    1: { single: 1, double: 1 },
                    2: { single: 3, double: 0 },
                    3: { single: 2, double: 1 },
                    4: { single: 0, double: 2 },
                },
                album: [
                    { name: photo114, id: 1 },
                    { name: photo116, id: 2 },
                    { name: photo118, id: 3 },
                    { name: photo120, id: 4 },
                    { name: photo122, id: 5 },
                    { name: photo124, id: 6 },
                ],
                animals: ['smallDog', 'raccoon', 'cat', 'notPredator'],
                area: 97,
                minPrice: 0,
                maxPrice: 0,
            },
        ],
    },
    {
        id: 5,
        title: 'Four Seasons Hotel',
        location: 'Турецкий городок',
        address: 'ул. Восставших турков, д. 111 А, г. Киев, Украина',
        city: 'cityKyiv',
        type: 'hotel_4stars',
        feedbackAmount: 492,
        minArea: 56,
        maxArea: 88,
        rating: '4,4',
        textDescription: 'Данный объект бронирования является тестовым и был создан для бэта тестирования данного раздела сайта бронирований гостиниц и посуточной аренды жилья Your Price Booking. Старт платформы бронирования запланирован на конец 2022 года. В данный момент вы видите тестовую версию сайта и сейчас бронирование еще не доступно. Первые 4 города в которых начинает работат Your Price Booking это: Киев, Одесса, Варшава и город Нью - Йорк. Огромным преимуществом для пользователей есть отсутствие паритета цен в отношениях с отельерами. Они могут понижать цену на свободные номера как для общего обозрения, так и делат индивидуалные ценовые предложения - конфиденциально.',
        url: photo51,
        urlImageSecond: photo53,
        videoLink: 'https://www.youtube.com/embed/qOtcuqT779M',
        videoId: 'qOtcuqT779M',
        checkIn: '14:00',
        checkOut: '15:00',
        minPrice: 1650,
        maxPrice: 2800,
        album: [
            { name: photo51, id: 1 },
            { name: photo53, id: 2 },
            { name: photo45, id: 3 },
            { name: photo47, id: 4 },
            { name: photo49, id: 5 },
            { name: photo41, id: 6 },
            { name: photo43, id: 7 },
            { name: photo55, id: 8 },
            { name: photo57, id: 9 },
            { name: photo59, id: 10 },
            { name: photo61, id: 11 },
            { name: photo63, id: 12 },
        ],
        description: [
            { id: 'objectService_02' },
            { id: 'objectService_05' },
            { id: 'objectService_08' },
            { id: 'objectService_11' },
            { id: 'objectService_14' },
            { id: 'objectService_17' },
            { id: 'objectService_20' },
            { id: 'objectService_23' },
            { id: 'objectService_26' },
            { id: 'objectService_29' },
            { id: 'objectService_32' },
            { id: 'objectService_35' },
            { id: 'objectService_38' },
            { id: 'objectService_41' },
            { id: 'objectService_44' },
            { id: 'objectService_47' },
            { id: 'objectService_50' },
            { id: 'objectService_53' },
        ],
        options: [
            {
                textDescription: 'Здесь мы пишем про вариант бронирования. Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti tenetur, quas amet molestias dolore animi nihilunde sunt nesciunt veritatis modi quia quis sapiente dolor odit dignissimos ad facere consequatur suscipit dolorum eius! Maxime eligendi molestias unde rem tempore quos, ut perferendis tempora ab minus in reiciendis ullam vel nostrum, quae deserunt ducimus quo voluptas sint, sequi quibusdam eveniet fuga. Odit provident illo perferendis exercitationem earum veritatis eveniet iusto doloribus deserunt expedita omnis voluptatibus quaerat iste atque ullam, dicta temporibus ut quod tenetur dolor incidunt cupiditate illum similique animi. Dolorem sequi alias ut eligendi modi eum fugiat eos blanditiis explicabo.',
                description: [
                    { id: 'optionService_01' },
                    { id: 'optionService_03' },
                    { id: 'optionService_05' },
                    { id: 'optionService_07' },
                    { id: 'optionService_09' },
                    { id: 'optionService_11' },
                    { id: 'optionService_13' },
                    { id: 'optionService_15' },
                    { id: 'optionService_17' },
                    { id: 'optionService_19' },
                    { id: 'optionService_21' },
                    { id: 'optionService_23' },
                    { id: 'optionService_25' },
                ],
                bedrooms: { 1: { single: 1, double: 0 }, 2: { single: 0, double: 1 }, 3: { single: 2, double: 0 } },
                album: [
                    { name: photo101, id: 1 },
                    { name: photo103, id: 2 },
                    { name: photo105, id: 3 },
                    { name: photo107, id: 4 },
                    { name: photo109, id: 5 },
                    { name: photo111, id: 6 },
                ],
                animals: ['raccoon', 'cat', 'notPredator'],
                area: 56,
                minPrice: 1650,
                maxPrice: 2400,
            },
            {
                textDescription: 'Здесь мы пишем про вариант бронирования. Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti tenetur, quas amet molestias dolore animi nihilunde sunt nesciunt veritatis modi quia quis sapiente dolor odit dignissimos ad facere consequatur suscipit dolorum eius! Maxime eligendi molestias unde rem tempore quos, ut perferendis tempora ab minus in reiciendis ullam vel nostrum, quae deserunt ducimus quo voluptas sint, sequi quibusdam eveniet fuga. Odit provident illo perferendis exercitationem earum veritatis eveniet iusto doloribus deserunt expedita omnis voluptatibus quaerat iste atque ullam, dicta temporibus ut quod tenetur dolor incidunt cupiditate illum similique animi. Dolorem sequi alias ut eligendi modi eum fugiat eos blanditiis explicabo.',
                description: [
                    { id: 'optionService_02' },
                    { id: 'optionService_04' },
                    { id: 'optionService_06' },
                    { id: 'optionService_08' },
                    { id: 'optionService_10' },
                    { id: 'optionService_12' },
                    { id: 'optionService_14' },
                    { id: 'optionService_16' },
                    { id: 'optionService_18' },
                    { id: 'optionService_20' },
                    { id: 'optionService_22' },
                    { id: 'optionService_24' },
                    { id: 'optionService_26' },
                ],
                bedrooms: {
                    1: { single: 1, double: 1 },
                    2: { single: 3, double: 0 },
                    3: { single: 2, double: 1 },
                    4: { single: 0, double: 2 },
                },
                album: [
                    { name: photo113, id: 1 },
                    { name: photo115, id: 2 },
                    { name: photo117, id: 3 },
                    { name: photo119, id: 4 },
                    { name: photo121, id: 5 },
                    { name: photo123, id: 6 },
                ],
                animals: ['smallDog', 'raccoon', 'cat', 'notPredator'],
                area: 88,
                minPrice: 2200,
                maxPrice: 2800,
            },
        ],
    },
    {
        id: 6,
        title: 'Hotel Martinez',
        location: 'Шулявка',
        address: 'просп. Победы, д. 31 Б, г. Киев Украина',
        city: 'cityKyiv',
        type: 'resort_hotel',
        feedbackAmount: 564,
        minArea: 62,
        maxArea: 76,
        rating: '3,9',
        textDescription: 'Данный объект бронирования является тестовым и был создан для бэта тестирования данного раздела сайта бронирований гостиниц и посуточной аренды жилья Your Price Booking. Старт платформы бронирования запланирован на конец 2022 года. В данный момент вы видите тестовую версию сайта и сейчас бронирование еще не доступно. Первые 4 города в которых начинает работат Your Price Booking это: Киев, Одесса, Варшава и город Нью - Йорк. Огромным преимуществом для пользователей есть отсутствие паритета цен в отношениях с отельерами. Они могут понижать цену на свободные номера как для общего обозрения, так и делат индивидуалные ценовые предложения - конфиденциально.',
        url: photo59,
        urlImageSecond: photo58,
        videoLink: 'https://www.youtube.com/embed/cJvLC8oReHw',
        videoId: 'cJvLC8oReHw',
        checkIn: '12:00',
        checkOut: '14:00',
        minPrice: 2200,
        maxPrice: 2600,
        album: [
            { name: photo59, id: 1 },
            { name: photo58, id: 2 },
            { name: photo67, id: 3 },
            { name: photo66, id: 4 },
            { name: photo65, id: 5 },
            { name: photo64, id: 6 },
            { name: photo63, id: 7 },
            { name: photo62, id: 8 },
            { name: photo61, id: 9 },
            { name: photo60, id: 10 },
            { name: photo69, id: 11 },
            { name: photo68, id: 12 },
        ],
        description: [
            { id: 'objectService_03' },
            { id: 'objectService_06' },
            { id: 'objectService_09' },
            { id: 'objectService_12' },
            { id: 'objectService_15' },
            { id: 'objectService_18' },
            { id: 'objectService_21' },
            { id: 'objectService_24' },
            { id: 'objectService_27' },
            { id: 'objectService_30' },
            { id: 'objectService_33' },
            { id: 'objectService_36' },
            { id: 'objectService_39' },
            { id: 'objectService_42' },
            { id: 'objectService_45' },
            { id: 'objectService_48' },
            { id: 'objectService_51' },
            { id: 'objectService_54' },
        ],
        options: [
            {
                textDescription: 'Здесь мы пишем про вариант бронирования. Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti tenetur, quas amet molestias dolore animi nihilunde sunt nesciunt veritatis modi quia quis sapiente dolor odit dignissimos ad facere consequatur suscipit dolorum eius! Maxime eligendi molestias unde rem tempore quos, ut perferendis tempora ab minus in reiciendis ullam vel nostrum, quae deserunt ducimus quo voluptas sint, sequi quibusdam eveniet fuga. Odit provident illo perferendis exercitationem earum veritatis eveniet iusto doloribus deserunt expedita omnis voluptatibus quaerat iste atque ullam, dicta temporibus ut quod tenetur dolor incidunt cupiditate illum similique animi. Dolorem sequi alias ut eligendi modi eum fugiat eos blanditiis explicabo.',
                description: [
                    { id: 'optionService_01' },
                    { id: 'optionService_03' },
                    { id: 'optionService_05' },
                    { id: 'optionService_07' },
                    { id: 'optionService_09' },
                    { id: 'optionService_11' },
                    { id: 'optionService_13' },
                    { id: 'optionService_15' },
                    { id: 'optionService_17' },
                    { id: 'optionService_19' },
                    { id: 'optionService_21' },
                    { id: 'optionService_23' },
                    { id: 'optionService_25' },
                ],
                bedrooms: { 1: { single: 1, double: 0 }, 2: { single: 0, double: 1 }, 3: { single: 2, double: 0 } },
                album: [
                    { name: photo127, id: 1 },
                    { name: photo126, id: 2 },
                    { name: photo125, id: 3 },
                    { name: photo124, id: 4 },
                    { name: photo123, id: 5 },
                    { name: photo122, id: 6 },
                ],
                animals: ['cat'],
                area: 62,
                minPrice: 0,
                maxPrice: 0,
            },
            {
                textDescription: 'Здесь мы пишем про вариант бронирования. Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti tenetur, quas amet molestias dolore animi nihilunde sunt nesciunt veritatis modi quia quis sapiente dolor odit dignissimos ad facere consequatur suscipit dolorum eius! Maxime eligendi molestias unde rem tempore quos, ut perferendis tempora ab minus in reiciendis ullam vel nostrum, quae deserunt ducimus quo voluptas sint, sequi quibusdam eveniet fuga. Odit provident illo perferendis exercitationem earum veritatis eveniet iusto doloribus deserunt expedita omnis voluptatibus quaerat iste atque ullam, dicta temporibus ut quod tenetur dolor incidunt cupiditate illum similique animi. Dolorem sequi alias ut eligendi modi eum fugiat eos blanditiis explicabo.',
                description: [
                    { id: 'optionService_02' },
                    { id: 'optionService_04' },
                    { id: 'optionService_06' },
                    { id: 'optionService_08' },
                    { id: 'optionService_10' },
                    { id: 'optionService_12' },
                    { id: 'optionService_14' },
                    { id: 'optionService_16' },
                    { id: 'optionService_18' },
                    { id: 'optionService_20' },
                    { id: 'optionService_22' },
                    { id: 'optionService_24' },
                    { id: 'optionService_26' },
                ],
                bedrooms: {
                    1: { single: 1, double: 1 },
                    2: { single: 3, double: 0 },
                    3: { single: 2, double: 1 },
                    4: { single: 0, double: 2 },
                },
                album: [
                    { name: photo121, id: 1 },
                    { name: photo120, id: 2 },
                    { name: photo119, id: 3 },
                    { name: photo118, id: 4 },
                    { name: photo117, id: 5 },
                    { name: photo116, id: 6 },
                ],
                animals: ['smallDog', 'raccoon', 'cat', 'notPredator'],
                area: 76,
                minPrice: 2200,
                maxPrice: 2600,
            },
        ],
    },
    {
        id: 7,
        title: 'Grand Resort Lagonissi',
        location: 'Новокараваевы дачи',
        address: 'ул. Волынская, д. 109, г. Киев, Украина',
        city: 'cityKyiv',
        type: 'hotel_1stars',
        feedbackAmount: 789,
        minArea: 77,
        maxArea: 97,
        rating: '4,8',
        textDescription: 'Данный объект бронирования является тестовым и был создан для бэта тестирования данного раздела сайта бронирований гостиниц и посуточной аренды жилья Your Price Booking. Старт платформы бронирования запланирован на конец 2022 года. В данный момент вы видите тестовую версию сайта и сейчас бронирование еще не доступно. Первые 4 города в которых начинает работат Your Price Booking это: Киев, Одесса, Варшава и город Нью - Йорк. Огромным преимуществом для пользователей есть отсутствие паритета цен в отношениях с отельерами. Они могут понижать цену на свободные номера как для общего обозрения, так и делат индивидуалные ценовые предложения - конфиденциально.',
        url: photo62,
        urlImageSecond: photo64,
        videoLink: 'https://www.youtube.com/embed/fGZzh66OmUg',
        videoId: 'fGZzh66OmUg',
        checkIn: '11:00',
        checkOut: '13:00',
        minPrice: 1800,
        maxPrice: 3900,
        album: [
            { name: photo62, id: 1 },
            { name: photo64, id: 2 },
            { name: photo46, id: 3 },
            { name: photo48, id: 4 },
            { name: photo50, id: 5 },
            { name: photo42, id: 6 },
            { name: photo44, id: 7 },
            { name: photo56, id: 8 },
            { name: photo58, id: 9 },
            { name: photo60, id: 10 },
            { name: photo52, id: 11 },
            { name: photo54, id: 12 },
        ],
        description: [
            { id: 'objectService_01' },
            { id: 'objectService_04' },
            { id: 'objectService_07' },
            { id: 'objectService_10' },
            { id: 'objectService_13' },
            { id: 'objectService_16' },
            { id: 'objectService_19' },
            { id: 'objectService_22' },
            { id: 'objectService_25' },
            { id: 'objectService_28' },
            { id: 'objectService_31' },
            { id: 'objectService_34' },
            { id: 'objectService_37' },
            { id: 'objectService_40' },
            { id: 'objectService_43' },
            { id: 'objectService_46' },
            { id: 'objectService_49' },
            { id: 'objectService_52' },
        ],
        options: [
            {
                textDescription: 'Здесь мы пишем про вариант бронирования. Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti tenetur, quas amet molestias dolore animi nihilunde sunt nesciunt veritatis modi quia quis sapiente dolor odit dignissimos ad facere consequatur suscipit dolorum eius! Maxime eligendi molestias unde rem tempore quos, ut perferendis tempora ab minus in reiciendis ullam vel nostrum, quae deserunt ducimus quo voluptas sint, sequi quibusdam eveniet fuga. Odit provident illo perferendis exercitationem earum veritatis eveniet iusto doloribus deserunt expedita omnis voluptatibus quaerat iste atque ullam, dicta temporibus ut quod tenetur dolor incidunt cupiditate illum similique animi. Dolorem sequi alias ut eligendi modi eum fugiat eos blanditiis explicabo.',
                description: [
                    { id: 'optionService_01' },
                    { id: 'optionService_03' },
                    { id: 'optionService_05' },
                    { id: 'optionService_07' },
                    { id: 'optionService_09' },
                    { id: 'optionService_11' },
                    { id: 'optionService_13' },
                    { id: 'optionService_15' },
                    { id: 'optionService_17' },
                    { id: 'optionService_19' },
                    { id: 'optionService_21' },
                    { id: 'optionService_23' },
                    { id: 'optionService_25' },
                ],
                bedrooms: { 1: { single: 1, double: 0 }, 2: { single: 0, double: 1 }, 3: { single: 2, double: 0 } },
                album: [
                    { name: photo102, id: 1 },
                    { name: photo104, id: 2 },
                    { name: photo106, id: 3 },
                    { name: photo108, id: 4 },
                    { name: photo110, id: 5 },
                    { name: photo112, id: 6 },
                ],
                animals: ['smallDog', 'mediumDog', 'largeDog', 'raccoon', 'cat', 'notPredator'],
                area: 77,
                minPrice: 1800,
                maxPrice: 3900,
            },
            {
                textDescription: 'Здесь мы пишем про вариант бронирования. Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti tenetur, quas amet molestias dolore animi nihilunde sunt nesciunt veritatis modi quia quis sapiente dolor odit dignissimos ad facere consequatur suscipit dolorum eius! Maxime eligendi molestias unde rem tempore quos, ut perferendis tempora ab minus in reiciendis ullam vel nostrum, quae deserunt ducimus quo voluptas sint, sequi quibusdam eveniet fuga. Odit provident illo perferendis exercitationem earum veritatis eveniet iusto doloribus deserunt expedita omnis voluptatibus quaerat iste atque ullam, dicta temporibus ut quod tenetur dolor incidunt cupiditate illum similique animi. Dolorem sequi alias ut eligendi modi eum fugiat eos blanditiis explicabo.',
                description: [
                    { id: 'optionService_02' },
                    { id: 'optionService_04' },
                    { id: 'optionService_06' },
                    { id: 'optionService_08' },
                    { id: 'optionService_10' },
                    { id: 'optionService_12' },
                    { id: 'optionService_14' },
                    { id: 'optionService_16' },
                    { id: 'optionService_18' },
                    { id: 'optionService_20' },
                    { id: 'optionService_22' },
                    { id: 'optionService_24' },
                    { id: 'optionService_26' },
                ],
                bedrooms: {
                    1: { single: 1, double: 1 },
                    2: { single: 3, double: 0 },
                    3: { single: 2, double: 1 },
                    4: { single: 0, double: 2 },
                },
                album: [
                    { name: photo114, id: 1 },
                    { name: photo116, id: 2 },
                    { name: photo118, id: 3 },
                    { name: photo120, id: 4 },
                    { name: photo122, id: 5 },
                    { name: photo124, id: 6 },
                ],
                animals: ['smallDog', 'raccoon', 'cat', 'notPredator'],
                area: 97,
                minPrice: 0,
                maxPrice: 0,
            },
        ],
    },
    {
        id: 8,
        title: 'Eden Rock St Barths',
        location: 'Виноградарь',
        address: 'ул. Наталии Ужвий, д. 8, г. Киев, Украина',
        city: 'cityKyiv',
        type: 'hostel',
        feedbackAmount: 492,
        minArea: 56,
        maxArea: 88,
        rating: '4,4',
        textDescription: 'Данный объект бронирования является тестовым и был создан для бэта тестирования данного раздела сайта бронирований гостиниц и посуточной аренды жилья Your Price Booking. Старт платформы бронирования запланирован на конец 2022 года. В данный момент вы видите тестовую версию сайта и сейчас бронирование еще не доступно. Первые 4 города в которых начинает работат Your Price Booking это: Киев, Одесса, Варшава и город Нью - Йорк. Огромным преимуществом для пользователей есть отсутствие паритета цен в отношениях с отельерами. Они могут понижать цену на свободные номера как для общего обозрения, так и делат индивидуалные ценовые предложения - конфиденциально.',
        url: photo61,
        urlImageSecond: photo63,
        videoLink: 'https://www.youtube.com/embed/TF1xsHLckYQ',
        videoId: 'TF1xsHLckYQ',
        checkIn: '14:00',
        checkOut: '15:00',
        minPrice: 1650,
        maxPrice: 2800,
        album: [
            { name: photo61, id: 1 },
            { name: photo63, id: 2 },
            { name: photo45, id: 3 },
            { name: photo47, id: 4 },
            { name: photo49, id: 5 },
            { name: photo41, id: 6 },
            { name: photo43, id: 7 },
            { name: photo55, id: 8 },
            { name: photo57, id: 9 },
            { name: photo59, id: 10 },
            { name: photo51, id: 11 },
            { name: photo53, id: 12 },
        ],
        description: [
            { id: 'objectService_02' },
            { id: 'objectService_05' },
            { id: 'objectService_08' },
            { id: 'objectService_11' },
            { id: 'objectService_14' },
            { id: 'objectService_17' },
            { id: 'objectService_20' },
            { id: 'objectService_23' },
            { id: 'objectService_26' },
            { id: 'objectService_29' },
            { id: 'objectService_32' },
            { id: 'objectService_35' },
            { id: 'objectService_38' },
            { id: 'objectService_41' },
            { id: 'objectService_44' },
            { id: 'objectService_47' },
            { id: 'objectService_50' },
            { id: 'objectService_53' },
        ],
        options: [
            {
                textDescription: 'Здесь мы пишем про вариант бронирования. Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti tenetur, quas amet molestias dolore animi nihilunde sunt nesciunt veritatis modi quia quis sapiente dolor odit dignissimos ad facere consequatur suscipit dolorum eius! Maxime eligendi molestias unde rem tempore quos, ut perferendis tempora ab minus in reiciendis ullam vel nostrum, quae deserunt ducimus quo voluptas sint, sequi quibusdam eveniet fuga. Odit provident illo perferendis exercitationem earum veritatis eveniet iusto doloribus deserunt expedita omnis voluptatibus quaerat iste atque ullam, dicta temporibus ut quod tenetur dolor incidunt cupiditate illum similique animi. Dolorem sequi alias ut eligendi modi eum fugiat eos blanditiis explicabo.',
                description: [
                    { id: 'optionService_01' },
                    { id: 'optionService_03' },
                    { id: 'optionService_05' },
                    { id: 'optionService_07' },
                    { id: 'optionService_09' },
                    { id: 'optionService_11' },
                    { id: 'optionService_13' },
                    { id: 'optionService_15' },
                    { id: 'optionService_17' },
                    { id: 'optionService_19' },
                    { id: 'optionService_21' },
                    { id: 'optionService_23' },
                    { id: 'optionService_25' },
                ],
                bedrooms: { 1: { single: 1, double: 0 }, 2: { single: 0, double: 1 }, 3: { single: 2, double: 0 } },
                album: [
                    { name: photo101, id: 1 },
                    { name: photo103, id: 2 },
                    { name: photo105, id: 3 },
                    { name: photo107, id: 4 },
                    { name: photo109, id: 5 },
                    { name: photo111, id: 6 },
                ],
                animals: ['raccoon', 'cat', 'notPredator'],
                area: 56,
                minPrice: 1650,
                maxPrice: 2400,
            },
            {
                textDescription: 'Здесь мы пишем про вариант бронирования. Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti tenetur, quas amet molestias dolore animi nihilunde sunt nesciunt veritatis modi quia quis sapiente dolor odit dignissimos ad facere consequatur suscipit dolorum eius! Maxime eligendi molestias unde rem tempore quos, ut perferendis tempora ab minus in reiciendis ullam vel nostrum, quae deserunt ducimus quo voluptas sint, sequi quibusdam eveniet fuga. Odit provident illo perferendis exercitationem earum veritatis eveniet iusto doloribus deserunt expedita omnis voluptatibus quaerat iste atque ullam, dicta temporibus ut quod tenetur dolor incidunt cupiditate illum similique animi. Dolorem sequi alias ut eligendi modi eum fugiat eos blanditiis explicabo.',
                description: [
                    { id: 'optionService_02' },
                    { id: 'optionService_04' },
                    { id: 'optionService_06' },
                    { id: 'optionService_08' },
                    { id: 'optionService_10' },
                    { id: 'optionService_12' },
                    { id: 'optionService_14' },
                    { id: 'optionService_16' },
                    { id: 'optionService_18' },
                    { id: 'optionService_20' },
                    { id: 'optionService_22' },
                    { id: 'optionService_24' },
                    { id: 'optionService_26' },
                ],
                bedrooms: {
                    1: { single: 1, double: 1 },
                    2: { single: 3, double: 0 },
                    3: { single: 2, double: 1 },
                    4: { single: 0, double: 2 },
                },
                album: [
                    { name: photo113, id: 1 },
                    { name: photo115, id: 2 },
                    { name: photo117, id: 3 },
                    { name: photo119, id: 4 },
                    { name: photo121, id: 5 },
                    { name: photo123, id: 6 },
                ],
                animals: ['smallDog', 'raccoon', 'cat', 'notPredator'],
                area: 88,
                minPrice: 2200,
                maxPrice: 2800,
            },
        ],
    },
    {
        id: 9,
        title: 'Atlantis Resort',
        location: 'Печерский район',
        address: 'проулок Лабораторный, д. 6, г. Киев, Украина',
        city: 'cityKyiv',
        type: 'hotel_3stars',
        feedbackAmount: 564,
        minArea: 62,
        maxArea: 76,
        rating: '4,5',
        textDescription: 'Данный объект бронирования является тестовым и был создан для бэта тестирования данного раздела сайта бронирований гостиниц и посуточной аренды жилья Your Price Booking. Старт платформы бронирования запланирован на конец 2022 года. В данный момент вы видите тестовую версию сайта и сейчас бронирование еще не доступно. Первые 4 города в которых начинает работат Your Price Booking это: Киев, Одесса, Варшава и город Нью - Йорк. Огромным преимуществом для пользователей есть отсутствие паритета цен в отношениях с отельерами. Они могут понижать цену на свободные номера как для общего обозрения, так и делат индивидуалные ценовые предложения - конфиденциально.',
        url: photo64,
        urlImageSecond: photo63,
        videoLink: 'https://www.youtube.com/embed/zp_S6VafqJ0',
        videoId: 'zp_S6VafqJ0',
        checkIn: '12:00',
        checkOut: '13:00',
        minPrice: 2200,
        maxPrice: 2600,
        album: [
            { name: photo64, id: 1 },
            { name: photo63, id: 2 },
            { name: photo67, id: 3 },
            { name: photo66, id: 4 },
            { name: photo65, id: 5 },
            { name: photo59, id: 6 },
            { name: photo58, id: 7 },
            { name: photo62, id: 8 },
            { name: photo61, id: 9 },
            { name: photo60, id: 10 },
            { name: photo69, id: 11 },
            { name: photo68, id: 12 },
        ],
        description: [
            { id: 'objectService_03' },
            { id: 'objectService_06' },
            { id: 'objectService_09' },
            { id: 'objectService_12' },
            { id: 'objectService_15' },
            { id: 'objectService_18' },
            { id: 'objectService_21' },
            { id: 'objectService_24' },
            { id: 'objectService_27' },
            { id: 'objectService_30' },
            { id: 'objectService_33' },
            { id: 'objectService_36' },
            { id: 'objectService_39' },
            { id: 'objectService_42' },
            { id: 'objectService_45' },
            { id: 'objectService_48' },
            { id: 'objectService_51' },
            { id: 'objectService_54' },
        ],
        options: [
            {
                textDescription: 'Здесь мы пишем про вариант бронирования. Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti tenetur, quas amet molestias dolore animi nihilunde sunt nesciunt veritatis modi quia quis sapiente dolor odit dignissimos ad facere consequatur suscipit dolorum eius! Maxime eligendi molestias unde rem tempore quos, ut perferendis tempora ab minus in reiciendis ullam vel nostrum, quae deserunt ducimus quo voluptas sint, sequi quibusdam eveniet fuga. Odit provident illo perferendis exercitationem earum veritatis eveniet iusto doloribus deserunt expedita omnis voluptatibus quaerat iste atque ullam, dicta temporibus ut quod tenetur dolor incidunt cupiditate illum similique animi. Dolorem sequi alias ut eligendi modi eum fugiat eos blanditiis explicabo.',
                description: [
                    { id: 'optionService_01' },
                    { id: 'optionService_03' },
                    { id: 'optionService_05' },
                    { id: 'optionService_07' },
                    { id: 'optionService_09' },
                    { id: 'optionService_11' },
                    { id: 'optionService_13' },
                    { id: 'optionService_15' },
                    { id: 'optionService_17' },
                    { id: 'optionService_19' },
                    { id: 'optionService_21' },
                    { id: 'optionService_23' },
                    { id: 'optionService_25' },
                ],
                bedrooms: { 1: { single: 1, double: 0 }, 2: { single: 0, double: 1 }, 3: { single: 2, double: 0 } },
                album: [
                    { name: photo127, id: 1 },
                    { name: photo126, id: 2 },
                    { name: photo125, id: 3 },
                    { name: photo124, id: 4 },
                    { name: photo123, id: 5 },
                    { name: photo122, id: 6 },
                ],
                animals: ['smallDog'],
                area: 62,
                minPrice: 0,
                maxPrice: 0,
            },
            {
                textDescription: 'Здесь мы пишем про вариант бронирования. Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti tenetur, quas amet molestias dolore animi nihilunde sunt nesciunt veritatis modi quia quis sapiente dolor odit dignissimos ad facere consequatur suscipit dolorum eius! Maxime eligendi molestias unde rem tempore quos, ut perferendis tempora ab minus in reiciendis ullam vel nostrum, quae deserunt ducimus quo voluptas sint, sequi quibusdam eveniet fuga. Odit provident illo perferendis exercitationem earum veritatis eveniet iusto doloribus deserunt expedita omnis voluptatibus quaerat iste atque ullam, dicta temporibus ut quod tenetur dolor incidunt cupiditate illum similique animi. Dolorem sequi alias ut eligendi modi eum fugiat eos blanditiis explicabo.',
                description: [
                    { id: 'optionService_02' },
                    { id: 'optionService_04' },
                    { id: 'optionService_06' },
                    { id: 'optionService_08' },
                    { id: 'optionService_10' },
                    { id: 'optionService_12' },
                    { id: 'optionService_14' },
                    { id: 'optionService_16' },
                    { id: 'optionService_18' },
                    { id: 'optionService_20' },
                    { id: 'optionService_22' },
                    { id: 'optionService_24' },
                    { id: 'optionService_26' },
                ],
                bedrooms: {
                    1: { single: 1, double: 1 },
                    2: { single: 3, double: 0 },
                    3: { single: 2, double: 1 },
                    4: { single: 0, double: 2 },
                },
                album: [
                    { name: photo121, id: 1 },
                    { name: photo120, id: 2 },
                    { name: photo119, id: 3 },
                    { name: photo118, id: 4 },
                    { name: photo117, id: 5 },
                    { name: photo116, id: 6 },
                ],
                animals: ['smallDog', 'raccoon', 'cat', 'notPredator'],
                area: 76,
                minPrice: 2200,
                maxPrice: 2600,
            },
        ],
    },
];
