var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'components/LoginSingUp/axios';
import { DebounceInput } from 'react-debounce-input';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useSearchParams } from 'react-router-dom';
import { setDefLocation, setLocation } from 'redux/LocationSearch/location.actions';
import { useTranslation } from 'react-i18next';
import { selectActiveLang } from 'features/common';
import s from './LocationSearch.module.scss';
import SelectBed from '../MainPage/Assents/image/SelectBed.svg';
import PensilIcon from '../../assets/icons/VectorInputPensil.svg';
export const LocationSearchMobile = ({ openLocationList, onChangeOpenLocationList, onChangeSelectedLocation, selectedValue, onSugLocStatusChange, }) => {
    const { t } = useTranslation('Calendar');
    const [isOpen, setIsOpen] = useState(openLocationList);
    const [error, setError] = useState(false);
    // const [placeholder, setPlaceholder] = useState(t('defaultLocation'));
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [filterText, setFilterText] = useState(selectedValue);
    const dropdownRef = useRef(null);
    const debounceInput = useRef(null);
    // const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const defLocationArray = useSelector(state => state.searchLocationReducer.defaultLocation);
    const enteredLocationArray = useSelector(state => state.searchLocationReducer.location);
    const activeLanguageCode = useSelector(selectActiveLang);
    useEffect(() => {
        if (selectedValue) {
            setFilterText(selectedValue);
        }
    }, [selectedValue]);
    useEffect(() => {
        if (openLocationList) {
            setIsOpen(true);
        }
    }, [openLocationList]);
    useEffect(() => {
        if (selectedValue) {
            focusInput();
        }
    }, [selectedValue]);
    useEffect(() => {
        if (!isOpen) {
            onChangeOpenLocationList(false);
        }
    }, [isOpen]);
    useEffect(() => {
        let isMounted = true;
        const getDefLocations = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const response = yield axios.get(`https://server-your-price-booking.onrender.com/api/autocomplete/get-all-city?language=${activeLanguageCode}`, { withCredentials: true });
                if (isMounted) {
                    dispatch(setDefLocation(response.data.result));
                    setError(false);
                }
            }
            catch (err) {
                if (isMounted) {
                    dispatch(setDefLocation([]));
                    setError(true);
                }
            }
        });
        if (isOpen && selectedValue === '') {
            getDefLocations();
        }
        return () => {
            isMounted = false;
        };
    }, [isOpen, selectedValue, activeLanguageCode, dispatch]);
    useEffect(() => {
        const handleClickOutside = e => {
            const isButtonNextClicked = e.target.classList.contains('bottom_btn_disabled');
            if (dropdownRef.current && !dropdownRef.current.contains(e.target) && !isButtonNextClicked) {
                setIsOpen(false);
                setIsInputFocused(false);
                // setPlaceholder(t('defaultLocation'));
                dispatch(setLocation([]));
                onChangeOpenLocationList(false);
                if (selectedValue !== filterText) {
                    setFilterText('');
                    onChangeSelectedLocation('');
                }
                return;
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [selectedValue, filterText]);
    const handleFocus = () => __awaiter(void 0, void 0, void 0, function* () {
        if (filterText !== '') {
            const valueArrayForRequest = filterText.split(',');
            try {
                const response = yield axios.get(`https://server-your-price-booking.onrender.com/api/autocomplete?search=${valueArrayForRequest[0]}&limit=8`, { withCredentials: true });
                const filteredOptions = [];
                setError(false);
                if (response.data.code === 404) {
                    throw new Error();
                }
                for (const i in response.data.data) {
                    filteredOptions.push(...response.data.data[i].filter(item => {
                        var _a, _b, _c, _d, _e, _f, _g;
                        for (const j in valueArrayForRequest) {
                            const searchText = filterText[j].toLowerCase();
                            return (((_a = item.cityName) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(searchText)) ||
                                ((_b = item.districtName) === null || _b === void 0 ? void 0 : _b.toLowerCase().includes(searchText)) ||
                                ((_c = item.stateName) === null || _c === void 0 ? void 0 : _c.toLowerCase().includes(searchText)) ||
                                ((_d = item.city) === null || _d === void 0 ? void 0 : _d.toLowerCase().includes(searchText)) ||
                                ((_e = item.district) === null || _e === void 0 ? void 0 : _e.toLowerCase().includes(searchText)) ||
                                ((_f = item.state) === null || _f === void 0 ? void 0 : _f.toLowerCase().includes(searchText)) ||
                                ((_g = item.country) === null || _g === void 0 ? void 0 : _g.toLowerCase().includes(searchText)));
                        }
                    }));
                }
                dispatch(setLocation(filteredOptions));
            }
            catch (err) {
                dispatch(setLocation([]));
                setError(true);
            }
        }
        setIsInputFocused(true);
        // setPlaceholder(t('placeHolderOnFocus'));
    });
    const focusInput = () => {
        if (debounceInput.current && debounceInput.current.focus) {
            debounceInput.current.focus();
        }
    };
    const handleOptionClick = (item) => __awaiter(void 0, void 0, void 0, function* () {
        const optionItemsArray = [
            item.cityName,
            item.districtName,
            item.stateName,
            item.city,
            item.state,
            item.country,
        ].filter(item => item !== undefined);
        const itemString = optionItemsArray.join(', ');
        onChangeSelectedLocation(itemString);
        setFilterText(itemString);
        // searchParams.set('location', item._id || item.id);
        // setSearchParams(searchParams);
        setIsOpen(false);
        onSugLocStatusChange(true);
    });
    const handleFilterChange = (event) => __awaiter(void 0, void 0, void 0, function* () {
        setIsOpen(true);
        onSugLocStatusChange(false);
        setFilterText(event.target.value);
        if (event.target.value === '') {
            setError(false);
            dispatch(setLocation([]));
            // setPlaceholder(t('defaultLocation'));
            return;
        }
        if (event.target.value !== '') {
            const valueArrayForRequest = event.target.value.split(',');
            try {
                const response = yield axios.get(`https://server-your-price-booking.onrender.com/api/autocomplete?search=${valueArrayForRequest[0]}&limit=8`, { withCredentials: true });
                const filteredOptions = [];
                setError(false);
                if (response.data.code === 404) {
                    throw new Error();
                }
                for (const i in response.data.data) {
                    filteredOptions.push(...response.data.data[i].filter(item => {
                        var _a, _b, _c, _d, _e, _f, _g;
                        for (const j in valueArrayForRequest) {
                            const searchText = event.target.value[j].toLowerCase();
                            return (((_a = item.cityName) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(searchText)) ||
                                ((_b = item.districtName) === null || _b === void 0 ? void 0 : _b.toLowerCase().includes(searchText)) ||
                                ((_c = item.stateName) === null || _c === void 0 ? void 0 : _c.toLowerCase().includes(searchText)) ||
                                ((_d = item.city) === null || _d === void 0 ? void 0 : _d.toLowerCase().includes(searchText)) ||
                                ((_e = item.district) === null || _e === void 0 ? void 0 : _e.toLowerCase().includes(searchText)) ||
                                ((_f = item.state) === null || _f === void 0 ? void 0 : _f.toLowerCase().includes(searchText)) ||
                                ((_g = item.country) === null || _g === void 0 ? void 0 : _g.toLowerCase().includes(searchText)));
                        }
                    }));
                }
                dispatch(setLocation(filteredOptions));
            }
            catch (err) {
                dispatch(setLocation([]));
                setError(true);
            }
        }
    });
    return (_jsxs("div", Object.assign({ ref: dropdownRef, className: s.custom_select_box }, { children: [_jsx("div", Object.assign({ className: `${s.custom_input_wrapper} ` }, { children: _jsxs(_Fragment, { children: [_jsx("svg", Object.assign({ className: isInputFocused ? `${s.icon_placeholder_focused_mobile}` : `${s.icon_placeholder_mobile}` }, { children: _jsx("use", { href: isInputFocused ? `${PensilIcon}#pensil` : `${SelectBed}#icon-search` }) })), _jsx(DebounceInput, { inputRef: debounceInput, type: "text", value: filterText, onChange: handleFilterChange, onFocus: handleFocus, minLength: 2, debounceTimeout: 300, className: `${s.custom_input_select} `, style: { paddingLeft: '33px' } })] }) })), isOpen && defLocationArray.length > 0 && filterText === '' && (_jsx("ul", Object.assign({ className: `${s.select_options} ${s.select_scroll_box}` }, { children: defLocationArray.map((item, index) => (_jsxs("li", Object.assign({ onClick: () => handleOptionClick(item), className: s.location_list_item }, { children: [_jsx("svg", Object.assign({ className: s.icon_list_item_location }, { children: _jsx("use", { href: `${SelectBed}#location` }) })), _jsxs("div", Object.assign({ className: s.location_wrapper }, { children: [_jsx("span", Object.assign({ className: s.location_text }, { children: item.city })), _jsx("span", Object.assign({ className: s.region_text }, { children: item.state })), _jsx("span", Object.assign({ className: s.country_text }, { children: item.country }))] }))] }), index))) }))), isOpen && enteredLocationArray.length > 0 && (_jsx("ul", Object.assign({ className: `${s.select_options} ${s.select_scroll_box}` }, { children: enteredLocationArray.map((item, index) => {
                    var _a, _b, _c, _d;
                    return (_jsxs("li", Object.assign({ onClick: () => handleOptionClick(item), className: s.location_list_item }, { children: [_jsx("svg", Object.assign({ className: s.icon_list_item_location }, { children: _jsx("use", { href: `${SelectBed}#location` }) })), _jsxs("div", Object.assign({ className: s.location_wrapper }, { children: [_jsx("span", Object.assign({ className: s.location_text, style: { marginRight: item.cityName ? '5px' : '0' } }, { children: (_a = item.cityName) !== null && _a !== void 0 ? _a : null })), _jsx("span", Object.assign({ className: s.region_text, style: { marginRight: item.districtName ? '5px' : '0' } }, { children: (_b = item.districtName + ",") !== null && _b !== void 0 ? _b : null })), _jsx("span", Object.assign({ className: s.region_text, style: { marginRight: item.stateName ? '5px' : '0' } }, { children: (_c = item.stateName) !== null && _c !== void 0 ? _c : null })), _jsx("span", Object.assign({ className: s.country_text }, { children: (_d = item.country) !== null && _d !== void 0 ? _d : null }))] }))] }), index));
                }) }))), enteredLocationArray.length === 0 && error && (_jsx("ul", Object.assign({ className: `${s.select_options} ${s.select_scroll_box}` }, { children: _jsxs("li", Object.assign({ className: s.location_list_item }, { children: [_jsx("svg", Object.assign({ className: s.icon_list_item_location }, { children: _jsx("use", { href: `${SelectBed}#location` }) })), _jsx("span", Object.assign({ className: s.location_text }, { children: t('noMatches') }))] })) })))] })));
};
