import { USER_INITIAL_LOCATION, HOTELIER_INITIAL_LOCATION } from './initialLocation.types';
const initialState = {
    userLocation: '',
    hotelierLocation: '',
};
export const initialLocationReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_INITIAL_LOCATION:
            return Object.assign(Object.assign({}, state), { userLocation: action.payload });
        case HOTELIER_INITIAL_LOCATION:
            return Object.assign(Object.assign({}, state), { hotelierLocation: action.payload });
        default:
            return state;
    }
};
