import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef, useState } from 'react';
import c from './sendFeedback.module.scss';
const SendCommentFeedback = ({ id, closeSendComment, setOpenCom, handleSubmit }) => {
    const [textlength] = useState(500);
    const [textPrint, setTextPrint] = useState('');
    const refText = useRef();
    const onChangeSendText = e => {
        setTextPrint(e.target.value);
    };
    return (_jsxs("div", Object.assign({ className: c.send_feedback_container }, { children: [_jsxs("div", Object.assign({ className: c.feedback_text_block }, { children: [_jsx("button", { onClick: closeSendComment, className: c.feedback_arrow_close }), _jsx("p", Object.assign({ className: c.feedback_text }, { children: "\u041D\u0430\u043F\u0438\u0448\u0438\u0442\u0435 \u043A\u043E\u043C\u043C\u0435\u043D\u0442\u0430\u0440\u0438\u0439" })), _jsxs("span", { children: ["(70/", textlength, ")"] })] })), _jsx("div", Object.assign({ className: c.form_block }, { children: _jsxs("form", Object.assign({ onSubmit: handleSubmit }, { children: [_jsx("textarea", { id: "feedback", type: "text", ref: refText, value: textPrint, maxLength: textlength, className: c.textarea_block, onChange: onChangeSendText }), _jsxs("label", Object.assign({ className: c.label_block, htmlFor: "feedback" }, { children: [textPrint.length, "/", textlength] })), _jsx("div", Object.assign({ className: c.btn_block }, { children: _jsx("button", Object.assign({ disabled: textPrint.length < 70, type: "submit", className: c.btn_send }, { children: "\u041E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u044C" })) }))] })) }))] })));
};
export default SendCommentFeedback;
