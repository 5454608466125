import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react';
// import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Calendar from 'react-calendar';
import './CalendarDouble.scss';
import next from './image/Vector next.svg';
import prew from './image/Vector back.svg';
import { selectActiveLang } from 'features/common';
export const CalendarDouble = ({ setOnChange, onModalOpen, selectedDataValue }) => {
    const [calendarValue, setCalendarValue] = useState(selectedDataValue);
    const [dateIntervale, setDateIntervale] = useState();
    const today = new Date();
    const [activeStartDate, setActiveStartDay] = useState(today);
    // const [searchParams, setSearchParams] = useSearchParams();
    const outsideRef = useRef(null);
    const [clickOutsideWhithNoRange, setClickOutsideWhithNoRange] = useState(false);
    useEffect(() => {
        if (calendarValue) {
            setDateIntervale(undefined);
            setOnChange(calendarValue);
            // const startDate = new Date(calendarValue[0]);
            // const endDate = new Date(calendarValue[1]);
            // const formatDate = date => {
            // 	const year = date.getFullYear().toString();
            // 	const month = (date.getMonth() + 1).toString().padStart(2, '0');
            // 	const day = date.getDate().toString().padStart(2, '0');
            // 	return `${year}-${month}-${day}`;
            // };
            // const formattedDate =
            // 	startDate.getTime() === endDate.getTime()
            // 		? formatDate(startDate)
            // 		: `${formatDate(startDate)}--${formatDate(endDate)}`;
            // searchParams.set('date', formattedDate);
            // setSearchParams(searchParams);
        }
    }, [calendarValue]);
    useEffect(() => {
        const handleClickOutside = e => {
            if (outsideRef.current &&
                outsideRef.current.contains &&
                !outsideRef.current.contains(e.target) &&
                dateIntervale) {
                setDateIntervale(undefined);
                setOnChange(undefined);
                setClickOutsideWhithNoRange(true);
                return;
            }
            if (outsideRef.current && outsideRef.current.contains && outsideRef.current.contains(e.target)) {
                setClickOutsideWhithNoRange(false);
                return;
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [dateIntervale]);
    useEffect(() => {
        if (!onModalOpen) {
            setDateIntervale(undefined);
            setOnChange(undefined);
            setCalendarValue(null);
            setClickOutsideWhithNoRange(false);
        }
    }, [onModalOpen]);
    const activeLanguageCode = useSelector(selectActiveLang);
    const minDate = new Date();
    const maxDate = new Date();
    maxDate.setMonth(maxDate.getMonth() + 6);
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    const onActiveStartDateChange = e => {
        if ((e === null || e === void 0 ? void 0 : e.value) !== null) {
            setDateIntervale(e === null || e === void 0 ? void 0 : e.value);
            setActiveStartDay(e.activeStartDate);
        }
        if (e && !Array.isArray(e.value)) {
            setActiveStartDay(e.activeStartDate);
            setCalendarValue(null);
            setDateIntervale(e.value);
            setOnChange(undefined);
            // searchParams.delete('date');
            // setSearchParams(searchParams);
            setClickOutsideWhithNoRange(false);
        }
        // eslint-disable-next-line eqeqeq
        if (e.action == 'next') {
            setActiveStartDay(e.activeStartDate);
        }
        if (e.action === 'prev') {
            setActiveStartDay(e.activeStartDate);
        }
        // eslint-disable-next-line eqeqeq
        if (e.action == 'onChange') {
            setActiveStartDay(activeStartDate);
        }
    };
    const formatShortWeekday = (locale, date) => {
        const weekdaysShort = getLocalizedWeekdaysShort(locale);
        return weekdaysShort[date.getDay()];
    };
    const getLocalizedWeekdaysShort = locale => {
        const weekdaysShort = {
            ua: ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
            ru: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
            en: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
            pl: ['Ni', 'Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'So'],
        };
        return weekdaysShort[locale] || weekdaysShort['en'];
    };
    const formatMonthYear = (locale, date) => {
        const options = { month: 'long', year: 'numeric' };
        if (locale === 'ru') {
            let formattedDate = date.toLocaleDateString('ru-RU', options);
            formattedDate = formattedDate.replace(/\s?[р|г]\.$/, '');
            return formattedDate;
        }
        if (locale === 'ua') {
            let formattedDate = date.toLocaleDateString('uk-UA', options);
            formattedDate = formattedDate.replace(/\s?[р|г]\.$/, '');
            return formattedDate;
        }
        return date.toLocaleDateString(locale, options);
    };
    const onClickDayHandler = date => {
        setDateIntervale(date);
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ ref: outsideRef }, { children: onModalOpen && !clickOutsideWhithNoRange && (_jsx(Calendar, { onChange: setCalendarValue, value: dateIntervale !== undefined ? dateIntervale : selectedDataValue || undefined, showDoubleView: true, selectRange: onModalOpen, activeStartDate: activeStartDate, onActiveStartDateChange: onActiveStartDateChange, locale: activeLanguageCode, formatShortWeekday: formatShortWeekday, formatMonthYear: formatMonthYear, minDetail: "month", defaultView: "month", prev2Label: null, next2Label: null, maxDate: maxDate, minDate: minDate, showFixedNumberOfWeeks: false, showNeighboringMonth: false, nextLabel: _jsx("img", { src: next, alt: "img-next" }), prevLabel: _jsx("img", { src: prew, alt: "img-prev" }) })) })), !onModalOpen && !clickOutsideWhithNoRange && (_jsx(Calendar, { showDoubleView: true, selectRange: onModalOpen, locale: activeLanguageCode, onClickDay: onClickDayHandler, minDetail: "month", defaultView: "month", formatShortWeekday: formatShortWeekday, formatMonthYear: formatMonthYear, prev2Label: null, next2Label: null, maxDate: maxDate, minDate: minDate, showFixedNumberOfWeeks: false, showNeighboringMonth: false, nextLabel: _jsx("img", { src: next, alt: "img-next" }), prevLabel: _jsx("img", { src: prew, alt: "img-prev" }) })), clickOutsideWhithNoRange && (_jsx(Calendar, { onChange: setCalendarValue, value: undefined, showDoubleView: true, selectRange: onModalOpen, activeStartDate: activeStartDate, onActiveStartDateChange: onActiveStartDateChange, locale: activeLanguageCode, formatShortWeekday: formatShortWeekday, formatMonthYear: formatMonthYear, minDetail: "month", defaultView: "month", prev2Label: null, next2Label: null, maxDate: maxDate, minDate: minDate, showFixedNumberOfWeeks: false, showNeighboringMonth: false, nextLabel: _jsx("img", { src: next, alt: "img-next" }), prevLabel: _jsx("img", { src: prew, alt: "img-prev" }) }))] }));
};
