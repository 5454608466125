import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './InfoComponent.scss';
import hotels from './hotels.json';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { useState } from 'react';
import { useToggleModal } from '../ModalW/Modal/useToggleModal';
import { ActiveBookingsList, BookingMainPage, SubNavigation } from 'components/BookingPropose/components';
import { HoteliersProposesPage, ModalCantBooking } from 'components/BookingPropose/components';
import { NoBooking } from 'components/BookingPropose/components/Notifications';
export default function InfoComponent() {
    const [isOpen, onOpen, onClose] = useToggleModal();
    const [activeSection, setActiveSection] = useState({ propose: true });
    const [bookingList, setBookingList] = useState(hotels); //temporary, change to [], when will load data from server
    const [proposeList, setProposeList] = useState(null);
    const [isProposeLoading, setIsProposeLoading] = useState(false);
    const [isNoBooking, setIsNoBooking] = useState(true); //temporary state
    const fetchProposes = (query) => {
        if (bookingList.length > 0) {
            onOpen();
            return;
        }
        // fetch('https://mydatabase.com/locations/v2/', query)
        //   .then(response => response.json())
        //   .then(response => setProposeList(response))
        //   .catch(err => console.error(err));
        setIsProposeLoading(true);
        // Making a simulation of fetching data from database
        setTimeout(() => {
            setProposeList(hotels);
            setIsProposeLoading(false);
            setActiveSection({ propose: true });
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }, 1000);
    };
    const togglerChangeActiveBooking = () => {
        setIsNoBooking(!isNoBooking);
    }; //temporary function
    return (_jsxs("div", Object.assign({ className: "container" }, { children: [_jsx(SubNavigation, { toggle: setActiveSection, activeSection: activeSection, numberOfPropose: proposeList ? proposeList.length : proposeList, haveActiveBooking: !isNoBooking }), activeSection.propose && !proposeList && !isProposeLoading && _jsx(BookingMainPage, { fetchProposes: fetchProposes }), activeSection.userBookings && !isProposeLoading && (_jsx("button", Object.assign({ style: { float: 'right', margin: '5px', width: '200px' }, onClick: togglerChangeActiveBooking }, { children: bookingList.length > 0 ? 'Show No Booking' : 'Show Active Booking' }))), activeSection.userBookings && !isProposeLoading && isNoBooking && _jsx(NoBooking, {}), activeSection.userBookings && !isProposeLoading && !isNoBooking && (_jsx(ActiveBookingsList, { list: bookingList, cancelBooking: (selectCardId) => {
                    setBookingList(bookingList.filter(({ _id }) => _id !== selectCardId));
                } })), activeSection.propose && (_jsx(HoteliersProposesPage, { list: proposeList, setProposeList: setProposeList, isProposeLoading: isProposeLoading, createBooking: (newBooking) => {
                    setIsProposeLoading(true);
                    setBookingList([newBooking]);
                }, setShowNotice: () => {
                    setActiveSection({ userBookings: true });
                    setProposeList(null);
                    setIsProposeLoading(false);
                } })), isProposeLoading && _jsx(LoadingSpinner, {}), _jsx(ModalCantBooking, { modalOptions: { isOpen, onOpen, onClose } })] })));
}
