import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Gallery from 'components/UserCards/Gallery';
import s from './HoteliersProposeCard.module.scss';
import { useTranslation } from 'react-i18next';
import { BookingDates, CardDownButton, Location, Price, VideoPreview } from './components';
import { OtherUserBookingNow } from '../../Notifications';
import { BedOptions } from 'components/UserCabinet/BedOptions/BedOptions';
import { FreeButton } from 'components/UserCabinet/FreeButton/FreeButton';
import React, { useEffect, useState } from 'react';
import dollar from 'assets/icons/proposeCard/dollar.svg';
import windowShine from 'assets/icons/proposeCard/cleaning-window-shine.svg';
import Rating from './components/Rating/Rating';
import { Date } from './components/Date/Date';
import { MobileVideoPreview } from './components/VideoPreview/MobileVideoPreview';
export const HoteliersProposeCard = ({ data, onClickArrowDown, listLength, onCardClick, apartmentsSize }) => {
    const { t } = useTranslation('userCard');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 767);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const handleClick = (e) => {
        if (e.target instanceof HTMLElement) {
            const notButtonCardDown = e.target.dataset.action !== 'cardDown';
            notButtonCardDown && onCardClick();
        }
    };
    const handleClickModal = () => {
        if (window.innerWidth <= 1199) {
            onCardClick();
        }
    };
    const handleVideoClick = (e) => {
        e.stopPropagation();
    };
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ className: s.propose__card, onClick: (e) => {
                handleClick(e);
                // openModal(data);
                handleClickModal();
            } }, { children: [_jsxs("div", Object.assign({ className: s.card__price_action, onClick: handleVideoClick }, { children: [_jsx(Rating, { title: data.name, rating: data.rating }), _jsxs("div", Object.assign({ className: s.wrapper_money }, { children: [_jsx("img", { className: s.dollar_icon, src: dollar, alt: "dollar" }), _jsx(Price, { price: data.price })] })), _jsxs("div", Object.assign({ className: s.wrapper_hotel_size }, { children: [_jsx("img", { className: s.window_shine_icon, src: windowShine, alt: "location pin" }), _jsxs("p", Object.assign({ className: s.hotel__appsize }, { children: [apartmentsSize, t('area')] }))] })), _jsxs("div", Object.assign({ className: s.wrapper_card_btn }, { children: [_jsx(FreeButton, { color: '' }), _jsx(CardDownButton, { listLength: listLength, onClick: onClickArrowDown })] }))] })), _jsx("div", Object.assign({ className: s.card__photo, onClick: handleVideoClick }, { children: isMobile ? (_jsx(MobileVideoPreview, { video: data.video, title: data.name, coverImage: data.photos[0], coverImageSecond: data === null || data === void 0 ? void 0 : data.photos[1] })) : (_jsx(Gallery, { title: data.name, isShowText: false, url: data.photos[0], urlImageSecond: data === null || data === void 0 ? void 0 : data.photos[1] })) })), _jsxs("div", Object.assign({ className: s.wrapper_location_dates }, { children: [_jsx(Location, { city: data.city, hotelName: data.name, address: data.address }), _jsx(Date, { dates: {
                                dateFrom: data.dateFrom,
                                dateTo: data.dateTo,
                            } })] })), _jsx(BookingDates, { type: "", dates: {
                        checkInTime: data.timeCheckIn,
                        checkOutTime: data.timeCheckOut,
                    } }), _jsxs("div", Object.assign({ className: s.wrap_tablet_display }, { children: [_jsx("ul", Object.assign({ className: s.bed__options }, { children: _jsx(BedOptions, { options: data.bedOptions }) })), _jsx("div", Object.assign({ onClick: handleVideoClick }, { children: _jsx(VideoPreview, { video: data.video, title: data.name }) }))] })), data.bookingNow && _jsx(OtherUserBookingNow, { className: s.card__disabled })] })) }));
};
