var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CABINET_USER, USER_ACCOUNT_SETTING, USER_PROFILE, USER_BOOKING_KARMA, USER_BOOKING_HISTORY, _LOGOUT_USER_URL, MAIN, } from 'navigation/CONSTANTS';
import axios from 'components/LoginSingUp/axios';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { logOut as logOutRedux } from 'redux/authentication/authentication.actions';
// import { useAuthContext } from 'context/AuthContext';
import { makeEntity } from 'utils';
import spinner from 'components/LoginSingUp/spinner/circle752.svg';
import scss from './UserSettingsList.module.scss';
import { IconHeader } from '../../Components/IconHeader';
import { SubheadersThemeSwitch } from '../SubheadersTheme/SubheadersThemeSwitch';
import { useAppSelector } from 'features';
const settingsLinksArr = [
    {
        link: CABINET_USER,
        icon: 'computer',
        i18nKey: 'userWorkingSpace',
    },
    {
        link: USER_PROFILE,
        icon: 'person',
        i18nKey: 'userMyProfile',
    },
    {
        link: USER_ACCOUNT_SETTING,
        icon: 'settings',
        i18nKey: 'userAccountSettings',
    },
    {
        link: USER_BOOKING_KARMA,
        icon: 'karma',
        i18nKey: 'userBookingKarma',
    },
    {
        link: USER_BOOKING_HISTORY,
        icon: 'ship',
        i18nKey: 'userBookingHistory',
    },
];
const settingsLinks = Object.freeze(makeEntity(settingsLinksArr));
export const SettingsIcon = ({ iconSrc }) => (_jsx("div", Object.assign({ className: scss.settingsIconWrapper }, { children: _jsx(IconHeader, { iconId: iconSrc, className: scss.settingsIcon }) })));
const SettingsExitButton = () => {
    const dispatchRedux = useDispatch();
    const navigation = useNavigate();
    const navigateToMainUser = () => navigation(MAIN);
    //const state = useSelector(state => state);
    const state = useSelector((state) => state);
    const [isLoading, setIsLoading] = useState(false);
    const theme = useAppSelector(state => state.theme.selectedTheme);
    const dark = theme === 'dark' ? scss.dark : '';
    const { t } = useTranslation();
    const onClickLogOut = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setIsLoading(true);
            const response = yield axios.get(_LOGOUT_USER_URL, {
                headers: {
                    Authorization: `Bearer ${state.auth.token}`,
                },
            });
            if (response.status === 200) {
                dispatchRedux(logOutRedux());
                localStorage.removeItem('user');
                navigateToMainUser();
            }
        }
        catch (err) {
            console.error(err, 'err');
            console.log(err, ' log err');
        }
        finally {
            setIsLoading(false);
        }
    });
    const LoadingIndicator = () => (_jsx("div", Object.assign({ className: scss.loading }, { children: _jsx("img", { src: spinner, alt: "Loading..." }) })));
    return (_jsxs("div", Object.assign({ id: "popup", className: scss.popup }, { children: [isLoading && LoadingIndicator(), _jsx("li", Object.assign({ className: scss.settingsItem }, { children: _jsxs("button", Object.assign({ className: scss.userSettingsLink, onClick: onClickLogOut }, { children: [_jsx("div", Object.assign({ className: scss.settingsIconWrapper }, { children: _jsx(IconHeader, { className: `${scss.iconExit} ${dark}`, iconId: "exit" }) })), _jsx("span", Object.assign({ className: `${scss.settingsItemText} ${dark}` }, { children: t('exitButton') }))] })) }))] })));
};
export const UserSettingsList = ({ closeSettings }) => {
    const [settingsEntity] = useState(settingsLinks);
    const { t } = useTranslation();
    const theme = useAppSelector(state => state.theme.selectedTheme);
    const dark = theme === 'dark' ? scss.dark : '';
    return (_jsxs("ul", Object.assign({ className: `${scss.userSettingsList} ${dark}` }, { children: [settingsEntity.ids.map(id => {
                const item = settingsEntity.entities[id];
                if (!item)
                    return null;
                const { link, i18nKey, icon } = item;
                return (_jsx("li", Object.assign({ className: scss.settingsItem }, { children: _jsxs(Link, Object.assign({ to: link, className: `${scss.userSettingsLink} ${dark}` }, { children: [_jsx(SettingsIcon, { iconSrc: icon }), t(i18nKey)] })) }), id));
            }), _jsx(SubheadersThemeSwitch, {}), _jsx(SettingsExitButton, {})] })));
};
