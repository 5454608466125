import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const prod = true;
export const api = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: prod ? 'https://server-your-price-booking.onrender.com/api/' : 'http://localhost:3000/api',
        //  TODO: 'ADD authorization'
        prepareHeaders: (headers, { getState }) => {
            const state = getState();
            const token = state.auth.token;
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    keepUnusedDataFor: 120,
    tagTypes: [
        'Language',
        'Country',
        'State',
        'City',
        'District',
        'Hotel',
        'Object',
        'Autocomplete',
        'Transaction',
        'ObjectType',
        'ObjectService',
        'ObjectPaymentMethod',
        'BookingServices',
        'HotelServices',
        'HostelServices',
    ],
    endpoints: () => ({}),
});
export const endpoints = {
    // locationDistrict: '/location-district' as const,
    // locationCity: '/location-city' as const,
    // locationState: '/location-state' as const,
    // countries: '/countries' as const,
    // languages: '/languages' as const,
    hotels: '/hotels',
    transactions: '/transactions',
    objects: '/objects',
    // autocomplete: '/autocomplete' as const,
    autocomplete: '/autocomplete/get-city-for-create-object',
    getObjectTypes: '/get-objects',
    services: '/services',
    paymentsMethod: '/payments-method',
    bookingServices: '/booking-services',
    bookingOptionHotel: '/bookingOption-hotel',
    bookingOptionHostel: '/bookingOption-hostel',
    catalogForHotelier: '/catalogForHotelier/get-by-query',
    startVerification: '/hotels-verification/start-verification',
};
export const frozenArr = Object.freeze([]);
export const tagList = (result, type) => [
    { type, id: 'LIST' },
    ...(result ? result.ids.map(id => ({ type, id })) : []),
];
export const tag = (id, type) => [{ type, id }];
