import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// import { useState } from 'react';
import s from './Modal.module.scss';
import { PriceBlock } from './PriceBlock';
import { ArrowIcon } from './ArrowIcon/ArrowIcon';
// import sprite from '../../../MainPage/Assents/image/SelectBed.svg';
import { SingleBed } from '../SingleBed';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
export const PriceModal = ({ priceNumber, btnText, disabledElement, 
// errorText,
handleChangeInput, price, changingPrice, handleClickChangePrice, isError, el, applyToAllBeds, setApplyToAllBeds, backFromChangingPrice }) => {
    const [isHighlightedText, setHighlightedText] = useState(true);
    let labelClassName = s.modal__label_price;
    let valueTextClassName = s.modal__value_text;
    let btnClassName = null;
    const { t } = useTranslation('hotelierCabinet');
    if (priceNumber === '1') {
        labelClassName = labelClassName + ' ' + (s.modal__label_price__primary);
        valueTextClassName = valueTextClassName + ' ' + s.modal__value_text__primary;
        btnClassName = s.priceblock__modal__btn__primary;
    }
    if (priceNumber === '2') {
        labelClassName = labelClassName + ' ' + (s.modal__label_price__secondary);
        valueTextClassName = valueTextClassName + ' ' + s.modal__value_text__secondary;
        btnClassName = s.priceblock__modal__btn__secondary;
    }
    const titleText = !changingPrice
        ? t('modalSetPrice', { number: priceNumber })
        : t('modalSelectDatesForPrice', { number: priceNumber });
    const btnInnerText = !changingPrice
        ? t('modalApplyPrice', { number: priceNumber })
        : t('modalApplyPriceToDates', { number: priceNumber });
    useEffect(() => {
        setHighlightedText(true);
        const timeoutId = setTimeout(() => {
            setHighlightedText(false);
        }, 2000);
        return () => clearTimeout(timeoutId);
    }, [changingPrice]);
    return (_jsxs("div", Object.assign({ className: s.price__block__wrapper }, { children: [_jsx("h3", Object.assign({ className: isError ? s.price__modal__title_error : s.price__modal__title }, { children: isHighlightedText ? _jsx("span", Object.assign({ className: s.price__highlighted_text }, { children: titleText })) : titleText })), changingPrice && _jsx(ArrowIcon, { onClick: backFromChangingPrice }), _jsx(PriceBlock, { btnText: t('modalPrice', { number: priceNumber }), disabledElement: disabledElement, 
                // errorText={errorText}
                handleChangeInput: handleChangeInput, labelClassName: labelClassName, price: price, valueTextClassName: valueTextClassName }), _jsx("div", Object.assign({ className: s.priceblock__modal__btn__block }, { children: _jsx("button", Object.assign({ className: btnClassName, onClick: e => { handleClickChangePrice(e); }, disabled: !price.length }, { children: btnInnerText })) })), el.hostel ?
                _jsxs("label", Object.assign({ className: s.hostel__checkbox + ' ' + (priceNumber === '1' ? s.hostel__checkbox_primary : s.hostel__checkbox_secondary), htmlFor: 'toAllDeds' }, { children: [_jsxs("span", { children: [t('modalApplyPrice', { number: priceNumber }), " ", t('toAll')] }), _jsx(SingleBed, {}), _jsx("span", { children: t('bedsInRoom') }), _jsx("input", { type: 'checkbox', name: 'toAllDeds', id: 'toAllDeds', className: s.hostel__checkbox_input, checked: applyToAllBeds, onChange: () => { setApplyToAllBeds(prev => !prev); } }), _jsx("span", { className: s.hostel__checkbox_checkmark })] }))
                : null] })));
};
