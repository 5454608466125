import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Light from 'assets/images/header/Light_small.svg';
import Dark from 'assets/images/header/Dark_small.svg';
import { useTranslation } from 'react-i18next';
import scss from './HeaderContentThemeSwitch.module.scss';
import { useAppDispatch, useAppSelector } from 'features';
import { setSelectedTheme } from 'redux/themes/themes.action';
export const HeaderContentThemeSwitch = () => {
    const appDispatch = useAppDispatch();
    const { t } = useTranslation('common');
    const theme = useAppSelector(state => state.theme.selectedTheme);
    const handleClick = () => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        appDispatch(setSelectedTheme(newTheme));
    };
    const themeIcon = theme === 'light' ? Dark : Light;
    const themeText = theme === 'light' ? t('darkTheme') : t('lightTheme');
    const dark = theme === 'dark' ? scss.dark : scss.light;
    return (_jsx("div", Object.assign({ onClick: handleClick }, { children: _jsxs("div", Object.assign({ className: `${scss.theme_switcher} ${dark}` }, { children: [_jsx("img", { src: themeIcon, className: `theme_icon ${dark}`, alt: 'theme_icon' }), _jsx("span", { children: themeText })] })) })));
};
