import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { Description } from 'components/UserCabinet/Description/';
import UsersCard from 'components/UserCabinet/UsersCard/UsersCard';
import s from './BookingMainPage.module.scss';
import Calendar from 'components/MainPage/Calendar/Calendar';
export const BookingMainPage = ({ fetchProposes }) => {
    const [openSelector, setSelector] = useState(false);
    console.log('BookingMainPage');
    return (_jsxs(_Fragment, { children: [_jsx(Calendar, { suggest: openSelector, setSuggest: setSelector, onSubmit: fetchProposes }), _jsx("div", { children: _jsxs("div", Object.assign({ className: s.section }, { children: [_jsx(Description, { openSelector: () => setSelector(true) }), _jsx(UsersCard, {})] })) })] }));
};
