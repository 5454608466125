import { createEntityAdapter } from '@reduxjs/toolkit';
import { getManyAutocompleteResults } from 'features/api';
export const autocompleteAdapter = createEntityAdapter({
    selectId: location => location.id,
    sortComparer: (a, b) => {
        if ('city' in a && 'city' in b) {
            return a.city.localeCompare(b.city);
        }
        if ('city' in a && !('city' in b)) {
            return -1;
        }
        if ('state' in a && 'state' in b) {
            return a.state.localeCompare(b.state);
        }
        return a.country.localeCompare(b.country);
    },
});
export const autocompleteAdapterInitState = autocompleteAdapter.getInitialState();
export const saveManyAutocompleteResults = (results) => {
    const autocompleteResults = getManyAutocompleteResults(results);
    return autocompleteAdapter.setAll(autocompleteAdapterInitState, autocompleteResults);
};
