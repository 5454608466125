import { useCallback, useMemo, useState } from 'react';
export const useStateToggle = (init = false) => {
    const [isTrue, setIsTrue] = useState(init);
    const setTrue = useCallback(() => {
        setIsTrue(true);
    }, []);
    const setFalse = useCallback(() => {
        setIsTrue(false);
    }, []);
    const toggle = useCallback(() => {
        setIsTrue(prev => !prev);
    }, []);
    return useMemo(() => [isTrue, setTrue, setFalse, toggle], [isTrue, setTrue, setFalse, toggle]);
};
