import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from 'react';
import { useClickOutside, useMedia, useStateToggle } from 'hooks/UI';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setHotelierInitialLocation } from 'redux/initialLocation/initialLocaion.actions';
import { IconSmallArrow } from '../SubheaderUser';
import { SubheaderHotelierButtonsList } from './ButtonsList';
import { HotelNameAndRating } from './HotelNameAndRating';
import { HotelImage } from './HotelImage';
import { HotelierSettingsList } from './HotelierSettingsList';
import { useAppSelector } from 'features';
import scss from './SubheaderHotelier.module.scss';
import { SubheaderHotelierDefault } from './SubheaderHotelierDefault';
// const HotelMetaDesktop = () => {
// 	const { isMobile } = useMedia();
// 	return (
// 		<div className={scss.hotelMetaDesktop}>
// 			<HotelImage />
// 			{!isMobile && <HotelNameAndRating />}
// 		</div>
// 	);
// };
const HotelMetaMobile = () => (_jsxs("div", Object.assign({ className: scss.hotelMetaMobile }, { children: [_jsx(HotelImage, {}), _jsx(HotelNameAndRating, {})] })));
const MobileHotelCard = ({ isUp }) => {
    const theme = useAppSelector(state => state.theme.selectedTheme);
    const dark = theme === 'dark' ? scss.dark : '';
    return (_jsxs("div", Object.assign({ className: `${scss.hotelCardMobileWrapper} ${dark}` }, { children: [_jsx(HotelMetaMobile, {}), _jsx(IconSmallArrow, { isUp: isUp })] })));
};
const Dropdown = ({ children }) => {
    const theme = useAppSelector(state => state.theme.selectedTheme);
    const dark = theme === 'dark' ? scss.dark : '';
    return _jsx("div", Object.assign({ className: `${scss.hotelierSettingsDropdownOverlay} ${dark}` }, { children: children }));
};
const SubheaderHotelierCard = () => {
    const dispatch = useDispatch();
    const { isMobile } = useMedia();
    const [showHotelierSettings, , closeHotelierSettings, toggleHotelierSettings] = useStateToggle();
    const toggleBtnRef = useRef(null);
    const containerRef = useClickOutside(closeHotelierSettings, toggleBtnRef);
    const open = showHotelierSettings ? scss.open : '';
    const initialLocation = useLocation();
    const theme = useAppSelector(state => state.theme.selectedTheme);
    const dark = theme === 'dark' ? scss.dark : '';
    const handleClick = () => {
        toggleHotelierSettings();
        dispatch(setHotelierInitialLocation(initialLocation.pathname));
    };
    const handleMouseLeave = () => {
        setTimeout(() => {
            closeHotelierSettings();
        }, 300);
    };
    return (_jsxs("div", Object.assign({ className: `${scss.subheaderHotelierCard} ${open} `, ref: containerRef, onMouseLeave: handleMouseLeave }, { children: [_jsxs("button", Object.assign({ className: `${scss.hotelCardButton} ${dark} ${open}`, onClick: handleClick, ref: toggleBtnRef, type: "button" }, { children: [_jsx(SubheaderHotelierDefault, {}), !isMobile && _jsx(IconSmallArrow, { isUp: showHotelierSettings })] })), showHotelierSettings && (_jsxs(Dropdown, { children: [isMobile && _jsx(MobileHotelCard, { isUp: showHotelierSettings }), _jsx(HotelierSettingsList, {})] }))] })));
};
export const SubheaderHotelier = () => (_jsxs("div", Object.assign({ className: scss.subheaderHotelier }, { children: [_jsx(SubheaderHotelierButtonsList, {}), _jsx(SubheaderHotelierCard, {})] })));
