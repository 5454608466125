import { jsx as _jsx } from "react/jsx-runtime";
import { useAuthContext } from 'context/AuthContext';
import { IconHeader } from '../../Components/IconHeader';
import scss from './Avatar.module.scss';
export const Avatar = () => {
    const [{ user }] = useAuthContext();
    if (!user)
        return _jsx("div", { className: scss.avatarThumb });
    const { photo } = user;
    return (_jsx("div", Object.assign({ className: scss.avatarThumb }, { children: photo ? (_jsx("img", { src: photo, className: scss.avatar, alt: "avatar" })) : (_jsx(IconHeader, { className: scss.avatar, iconId: "person" })) })));
};
