import { useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import preventOverflow from '@popperjs/core/lib/modifiers/preventOverflow.js';
import flip from '@popperjs/core/lib/modifiers/flip.js';
export const usePopover = ({ offset = [0, 20], placement = 'bottom', }) => {
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const [arrowElement, setArrowElement] = useState(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement,
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset,
                },
            },
            { name: 'arrow', options: { element: arrowElement } },
            preventOverflow,
            flip,
        ],
    });
    const parentPopperAttributes = {
        ref: setReferenceElement,
    };
    const childPopperAttributes = useMemo(() => {
        const attr = (attributes === null || attributes === void 0 ? void 0 : attributes.popper) || {};
        return Object.assign({ ref: setPopperElement, style: styles.popper }, attr);
    }, [styles, attributes]);
    const arrowAttributes = {
        ref: setArrowElement,
        style: styles.arrow,
    };
    return [parentPopperAttributes, childPopperAttributes, arrowAttributes];
};
