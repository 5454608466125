import { jsx as _jsx } from "react/jsx-runtime";
// import type { Mode } from 'types';
import { useAppSelector } from 'features';
import { selectCurrenciesIds, selectLanguagesIds } from 'features/common';
import { usePickerContext } from '../PickerContext';
import { CurrencyOptionItem, LanguageOptionItem, OptionItem } from './OptionItem';
import scss from './OptionsList.module.scss';
export const OptionsList = () => {
    const [{ options, isDark }] = usePickerContext();
    const listClassName = `${scss.optionsList} ${isDark ? scss.dark : ''}`;
    // const theme: Mode = useAppSelector(state => state.theme.selectedTheme);
    // const dark = theme === 'dark' ? scss.dark : '';
    return (_jsx("ul", Object.assign({ className: listClassName }, { children: options.ids.map(id => (_jsx(OptionItem, { optionId: id }, id))) })));
};
export const LanguageOptionsList = () => {
    const languagesIds = useAppSelector(selectLanguagesIds);
    const [{ isDark }] = usePickerContext();
    // const theme: Mode = useAppSelector(state => state.theme.selectedTheme);
    // const dark = theme === 'dark' ? scss.dark : '';
    const listClassName = `${scss.optionsList} ${isDark ? scss.dark : ''}`;
    return (_jsx("ul", Object.assign({ className: listClassName }, { children: languagesIds.map(id => (_jsx("li", { children: _jsx(LanguageOptionItem, { optionId: id }) }, id))) })));
};
export const CurrencyOptionsList = () => {
    const currenciesIds = useAppSelector(selectCurrenciesIds);
    const [{ isDark }] = usePickerContext();
    const listClassName = `${scss.optionsList} ${isDark ? scss.dark : ''}`;
    // const theme: Mode = useAppSelector(state => state.theme.selectedTheme);
    // const dark = theme === 'dark' ? scss.dark : '';
    return (_jsx("ul", Object.assign({ className: listClassName }, { children: currenciesIds.map(id => (_jsx("li", { children: _jsx(CurrencyOptionItem, { optionId: id }, id) }))) })));
};
