import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import urc from './ButtonDelete.module.scss';
import { useTranslation } from 'react-i18next';
const ButtonDelete = () => {
    const { t } = useTranslation('hotelierCabinet');
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1199);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (_jsxs("div", { children: [!isMobile && _jsx("button", Object.assign({ className: urc.buttons_style }, { children: t('delete') })), isMobile && _jsx("button", { className: urc.delete_button })] }));
};
export default ButtonDelete;
