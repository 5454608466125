import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import s from '../HousePage.module.scss';
export default function GalleryComponent({ size = 'medium', children, album = [], collums = '4', childrenPosition }) {
    const [bigPhoto, setBigPhoto] = useState(album.length > 0 ? album[0].name : ''); // Проверяем наличие элементов в массиве album
    const changePhoto = (photoName) => {
        setBigPhoto(photoName);
    };
    const sizeGallery = (size === 'small' && s.smallS) || (size === 'medium' && s.mediumS);
    const collumsStyle = collums === '3' && s.treeCollums;
    return (_jsxs("div", Object.assign({ className: s.gallery }, { children: [_jsxs("div", Object.assign({ className: `${s.content_wrapper} ${sizeGallery} ${collumsStyle}` }, { children: [_jsx("img", { src: bigPhoto, alt: "img of propose", className: `${s.big__photo} ${sizeGallery}` }), childrenPosition === 'bottom left' && children] })), _jsxs("div", { children: [childrenPosition === 'top right' && children, _jsx("div", Object.assign({ className: `${s.photo_gallery} ${sizeGallery} ${collumsStyle}` }, { children: album.map((photo, index) => {
                            if (index <= 8) { // Ограничиваем количество отображаемых миниатюр до 9
                                return (_jsx("img", { src: photo.name, alt: `mini img of propose ${index}`, className: `${s.small_photo} ${collumsStyle}`, onClick: () => changePhoto(photo.name) }, photo.id));
                            }
                            return null; // Возвращаем null для пропуска лишних элементов
                        }) }))] })] })));
}
