import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'features';
import { chooseObjectLocation, selectActiveObjectId, selectObjectActiveLocationId, selectObjectEditedFields, selectObjectStatus, setObjectEditedFields, setObjectStatus, } from 'features/objects';
import { useGetAutocompleteQuery } from 'features/autocomplete';
import { ObjectIcon } from './ObjectIcon';
import { useGetHotelQuery } from 'features/hotels';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import { selectActiveLang } from 'features/common';
export const upperCaseLangCodes = {
    ua: 'UA',
    en: 'EN',
    ru: 'RU',
    pl: 'PL',
};
const LocationItem = ({ locationId, isActive, onClickChooseLocation }) => {
    const lang = useAppSelector(selectActiveLang);
    const { item } = useGetAutocompleteQuery({ lang: upperCaseLangCodes[lang] }, { selectFromResult: ({ data }) => { var _a; return ({ item: (_a = data === null || data === void 0 ? void 0 : data.entities) === null || _a === void 0 ? void 0 : _a[locationId] }); } });
    const onClickChooseObjectLocation = useCallback(() => onClickChooseLocation(locationId), [locationId, onClickChooseLocation]);
    if (!item)
        return null;
    const { country, city, state } = item;
    const active = isActive ? 'active' : '';
    return (_jsx("li", Object.assign({ className: "selectlocation__city-item" }, { children: _jsxs("button", Object.assign({ className: `selectlocation__city-button ${active}`, onClick: onClickChooseObjectLocation, type: "button" }, { children: [_jsx("span", Object.assign({ className: "selectlocation__city-span" }, { children: _jsx(ObjectIcon, { className: "selectlocation__city-icon", iconId: "location" }) })), _jsxs("p", Object.assign({ className: "selectlocation__city-text" }, { children: [_jsx("span", Object.assign({ className: "selectlocation__city-city" }, { children: city })), _jsx("span", Object.assign({ className: "selectlocation__city-region" }, { children: state })), _jsx("span", Object.assign({ className: "selectlocation__city-country" }, { children: country }))] }))] })) })));
};
export const ObjectAddLocation = () => {
    const { t } = useTranslation();
    return (_jsxs("section", Object.assign({ className: "selectlocation-section" }, { children: [_jsx("h3", Object.assign({ className: "selectlocation-text" }, { children: t('chooseObjectLocation') })), _jsx("div", Object.assign({ className: "selectlocation" }, { children: _jsx(LocationList, {}) })), _jsxs("p", Object.assign({ className: "object__window-text" }, { children: [_jsx(ObjectIcon, { className: "object__window-text-icon", iconId: "Clock" }), _jsx("span", { children: t('addingObjectAvgDuration') })] }))] })));
};
// [x] 1. after click on return button from ObjectAddDocument page -> set isEditing to true
// [x] 2. after click on submit button from AddObjectSubmitForm -> set isEditing to false
// [x] 3. after click on next button from ObjectTabsButtons -> set isEditing to false
const LocationList = () => {
    var _a;
    const { t } = useTranslation();
    const lang = useAppSelector(selectActiveLang);
    const status = useAppSelector(selectObjectStatus);
    const activeObjectId = useAppSelector(selectActiveObjectId);
    const activeLocationId = useAppSelector(selectObjectActiveLocationId);
    const objectEditedFields = useAppSelector(selectObjectEditedFields);
    const { data: locations, isLoading, isSuccess, isError, error, } = useGetAutocompleteQuery({ lang: upperCaseLangCodes[lang] });
    const { objectLocationIdFromBackend } = useGetHotelQuery(activeObjectId, {
        selectFromResult: ({ data }) => { var _a, _b, _c; return ({ objectLocationIdFromBackend: (_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.entities) === null || _a === void 0 ? void 0 : _a[activeObjectId]) === null || _b === void 0 ? void 0 : _b.location) === null || _c === void 0 ? void 0 : _c.id }); },
        skip: !activeObjectId,
    });
    const isLocationEdited = objectEditedFields.includes('location');
    const displayedActiveLocationId = !objectLocationIdFromBackend || (objectLocationIdFromBackend && isLocationEdited)
        ? activeLocationId
        : objectLocationIdFromBackend;
    const appDispatch = useAppDispatch();
    const onClickChooseLocation = useCallback((locationId) => {
        appDispatch(chooseObjectLocation(locationId));
        // for editing functionality
        if (!objectLocationIdFromBackend || objectLocationIdFromBackend === locationId || isLocationEdited)
            return;
        appDispatch(setObjectEditedFields([...objectEditedFields, 'location']));
    }, [isLocationEdited, objectEditedFields, objectLocationIdFromBackend, appDispatch]);
    useEffect(() => {
        if (displayedActiveLocationId) {
            if (!status)
                appDispatch(setObjectStatus(true));
            return;
        }
        if (status)
            appDispatch(setObjectStatus(false));
    }, [displayedActiveLocationId, status, appDispatch]);
    let content = null;
    if (isLoading) {
        content = _jsx(LoadingSpinner, { size: "20px" });
    }
    else if (isSuccess) {
        content = (_jsxs(_Fragment, { children: [_jsx("div", { className: "selectlocation__city-blue" }), _jsx("div", Object.assign({ className: "selectlocation__city-select" }, { children: _jsx("ul", Object.assign({ className: "selectlocation__city-list" }, { children: ((_a = locations === null || locations === void 0 ? void 0 : locations.ids) === null || _a === void 0 ? void 0 : _a.length) ? (locations.ids.map(locationId => (_jsx(LocationItem, { locationId: locationId, isActive: displayedActiveLocationId === locationId, onClickChooseLocation: onClickChooseLocation }, locationId)))) : (_jsx("li", Object.assign({ className: "selectlocation__city-item" }, { children: t('noQueryResults') }))) })) }))] }));
    }
    else if (isError && error) {
        content = _jsx("p", { children: JSON.stringify(error, null, '\t') });
    }
    return _jsx("div", Object.assign({ className: "selectlocation__city" }, { children: content }));
};
