import { frozenArr } from './api';
const obj = {};
// export const getManyServices = ({ data: { services } = {} }: ManyServices = {}) => services ?? frozenArr;
// export const getManyLanguages = ({ data: { languages } = {} }: ManyLanguages = {}) => languages ?? frozenArr;
// export const getManyCountries = ({ data: { countries } = {} }: ManyCountries = {}) => countries ?? frozenArr;
// export const getManyStates = ({ data: { states } = {} }: ManyStates = {}) => states ?? frozenArr;
// export const getManyCities = ({ data: { cities } = {} }: ManyCities = {}) => cities ?? frozenArr;
// export const getManyDistricts = ({ data: { districts } = {} }: ManyDistricts = {}) => districts ?? frozenArr;
export const getManyHotels = ({ data: { hotels } = obj } = obj) => hotels !== null && hotels !== void 0 ? hotels : frozenArr;
export const getManyTransactions = ({ data: { transactions } = obj } = obj) => transactions !== null && transactions !== void 0 ? transactions : frozenArr;
export const getManySearchObjects = ({ data: { objects } = obj } = obj) => objects !== null && objects !== void 0 ? objects : frozenArr;
export const getManyObjectTypes = ({ data: { object } = obj } = obj) => { var _a; return (_a = object === null || object === void 0 ? void 0 : object[0].objects) !== null && _a !== void 0 ? _a : frozenArr; };
export const getManyObjectServices = ({ data: { services } = obj } = obj) => { var _a; return (_a = services === null || services === void 0 ? void 0 : services[0].services) !== null && _a !== void 0 ? _a : frozenArr; };
export const getManyHotelServices = ({ data: { bookingServices } = obj } = obj) => bookingServices !== null && bookingServices !== void 0 ? bookingServices : frozenArr;
export const getManyHostelServices = ({ data: { bookingServices } = obj } = obj) => { var _a; return (_a = bookingServices === null || bookingServices === void 0 ? void 0 : bookingServices[0].services) !== null && _a !== void 0 ? _a : frozenArr; };
export const getManyObjectPaymentsMethods = ({ data: { payment } = obj } = obj) => { var _a; return (_a = payment === null || payment === void 0 ? void 0 : payment[0].payments) !== null && _a !== void 0 ? _a : frozenArr; };
export const getManyAutocompleteResults = (data) => {
    return data !== null && data !== void 0 ? data : frozenArr;
};
// export const getOneLanguage = ({ data: { language } = {} }: OneLanguageInArray = {}) => language?.[0];
// export const getOneCountry = ({ data: { country } = {} }: OneCountry = {}) => country;
// export const getOneState = ({ data: { state } = {} }: OneState = {}) => state;
// export const getOneCity = ({ data: { city } = {} }: OneCity = {}) => city;
// export const getOneDistrict = ({ data: { district } = {} }: OneDistrict = {}) => district;
export const getOneHotel = ({ data: { hotel } = obj } = obj) => hotel;
export const getOneTransaction = ({ data: { transaction } = obj } = obj) => transaction;
export const getOneSearchObject = ({ data: { object } = obj } = obj) => object;
export const getBookingHotel = ({ data: { newHotel } = obj } = obj) => newHotel;
export const getBookingHostel = ({ data: { hostel } = obj } = obj) => hostel;
// export const getOneLanguageId = (result?: OneLanguageInArray) => getOneLanguage(result)?._id;
// export const getOneCountryId = (result?: OneCountry) => getOneCountry(result)?._id;
// export const getOneStateId = (result?: OneState) => getOneState(result)?._id;
// export const getOneCityId = (result?: OneCity) => getOneCity(result)?._id;
// export const getOneDistrictId = (result?: OneDistrict) => getOneDistrict(result)?._id;
// export const getOneObjectId = (result?: OneObject) => getOneObject(result)?._id;
