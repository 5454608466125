import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAppSelector } from 'features';
import { selectActiveCurrencyCode, selectActiveCurrencyText } from 'features/common';
import { TogglePickerButton } from '../../TogglePickerButton';
import scss from './CurrencyPickerBtn.module.scss';
export const CurrencyPickerBtn = () => {
    const text = useAppSelector(selectActiveCurrencyText);
    const code = useAppSelector(selectActiveCurrencyCode);
    const currency = text.length > 9 ? text.split(' ')[0] : text;
    return (_jsxs(TogglePickerButton, Object.assign({ className: scss.currencyPickerBtn, title: 'Select currency' }, { children: [_jsx("span", { children: currency }), _jsx("span", { children: code })] })));
};
