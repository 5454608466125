import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import Calendar from 'react-calendar';
import next from './image/Vector next.svg';
import prew from './image/Vector back.svg';
import './Calendar.scss';
import { useSelector } from 'react-redux';
import { selectActiveLang } from 'features/common';
export const CalendarOnMonth = ({ setAllActiveDay, allActiveDay, showNotOurService, calendarValue, setOnChange, notOurServiceDays, setNotOurServiceDays, isPrice1ModalOpen, isPrice2ModalOpen, tileDisabled, isNotOurServicesModalOpen, isOurService, isAlreadyBooking, priceOneDays, setPriceOneDays, priceTwoDays, setPriceTwoDays }) => {
    const currentDate = new Date();
    const minDate = new Date(new Date().setMonth(currentDate.getMonth() - 12));
    const maxDate = new Date(new Date().setMonth(currentDate.getMonth() + 6));
    const lang = useSelector(selectActiveLang);
    useEffect(() => {
        if (!calendarValue)
            return;
        if (!isPrice1ModalOpen && !isPrice2ModalOpen && !isNotOurServicesModalOpen)
            return;
        if (!showNotOurService) {
            setAllActiveDay(prevState => {
                const addDay = prevState.findIndex(activeDay => activeDay.toDateString() === calendarValue.toDateString());
                if (Number(addDay) !== -1) {
                    const newActivesDay = [...prevState].filter((el, index) => index !== addDay);
                    return newActivesDay;
                }
                return [...prevState, calendarValue];
            });
        }
        if (showNotOurService) {
            setNotOurServiceDays(prevState => {
                const addDay = prevState.findIndex(activeDay => activeDay.toDateString() === calendarValue.toDateString());
                if (Number(addDay) !== -1) {
                    const newActivesDay = [...prevState].filter((el, index) => index !== addDay);
                    return newActivesDay;
                }
                return [...prevState, calendarValue];
            });
        }
        if (priceOneDays.some(day => day.toDateString() === calendarValue.toDateString()) && !isPrice1ModalOpen) {
            setPriceOneDays(prev => prev.filter(day => day.toDateString() !== calendarValue.toDateString()));
        }
        if (priceTwoDays.some(day => day.toDateString() === calendarValue.toDateString()) && !isPrice2ModalOpen) {
            setPriceTwoDays(prev => prev.filter(day => day.toDateString() !== calendarValue.toDateString()));
        }
        if (notOurServiceDays.some(day => day.toDateString() === calendarValue.toDateString()) && !isNotOurServicesModalOpen) {
            setNotOurServiceDays(prev => prev.filter(day => day.toDateString() !== calendarValue.toDateString()));
        }
        if (showNotOurService) {
            setNotOurServiceDays(prevState => {
                const addDay = prevState.findIndex(activeDay => activeDay.toDateString() === calendarValue.toDateString());
                if (Number(addDay) !== -1) {
                    const newActivesDay = [...prevState].filter((el, index) => index !== addDay);
                    return newActivesDay;
                }
                return [...prevState, calendarValue];
            });
        }
    }, [calendarValue, setAllActiveDay, setNotOurServiceDays, showNotOurService]);
    function tileClassName({ date, view }) {
        const tileClassNameCommon = isPrice1ModalOpen
            ? "hover__background__primary"
            : isPrice2ModalOpen
                ? "hover__background__secondary"
                : isNotOurServicesModalOpen
                    ? "hover__background__notOurService"
                    : "";
        const year = new Date().getFullYear();
        const month = new Date().getMonth();
        const day = new Date().getDate();
        const today = new Date(year, month, day);
        const prevDays = today.getTime() > date.getTime() ? 'myClassName__past' : '';
        let result = '';
        if (isPrice1ModalOpen) {
            result = tileClassNameCommon +
                ' ' +
                (calendarClassName(date, view, notOurServiceDays, 'notOurService') ||
                    calendarClassName(date, view, allActiveDay, (isPrice1ModalOpen ? 'myClassName__primary' : isPrice2ModalOpen ? 'myClassName__secondary' : 'notOurService')) ||
                    calendarClassName(date, view, isOurService, 'isOurService') ||
                    calendarClassName(date, view, isAlreadyBooking, 'isAlreadyBooking') ||
                    calendarClassName(date, view, priceTwoDays, 'myClassName__secondary') ||
                    'calendar__tile-custom') + ' ' + prevDays;
            return result;
        }
        if (isPrice2ModalOpen) {
            result = tileClassNameCommon +
                ' ' +
                (calendarClassName(date, view, notOurServiceDays, 'notOurService') ||
                    calendarClassName(date, view, allActiveDay, (isPrice1ModalOpen ? 'myClassName__primary' : isPrice2ModalOpen ? 'myClassName__secondary' : 'notOurService')) ||
                    calendarClassName(date, view, isOurService, 'isOurService') ||
                    calendarClassName(date, view, isAlreadyBooking, 'isAlreadyBooking') ||
                    calendarClassName(date, view, priceOneDays, 'myClassName__primary') ||
                    'calendar__tile-custom') + ' ' + prevDays;
            return result;
        }
        if (isNotOurServicesModalOpen) {
            result = tileClassNameCommon +
                ' ' +
                (calendarClassName(date, view, allActiveDay, (isPrice1ModalOpen ? 'myClassName__primary' : isPrice2ModalOpen ? 'myClassName__secondary' : 'notOurService')) ||
                    calendarClassName(date, view, isOurService, 'isOurService') ||
                    calendarClassName(date, view, isAlreadyBooking, 'isAlreadyBooking') ||
                    calendarClassName(date, view, priceOneDays, 'myClassName__primary') ||
                    calendarClassName(date, view, priceTwoDays, 'myClassName__secondary') ||
                    'calendar__tile-custom') + ' ' + prevDays;
            return result;
        }
        result = tileClassNameCommon +
            ' ' +
            (calendarClassName(date, view, notOurServiceDays, 'notOurService') ||
                calendarClassName(date, view, isOurService, 'isOurService') ||
                calendarClassName(date, view, isAlreadyBooking, 'isAlreadyBooking') ||
                calendarClassName(date, view, priceOneDays, 'myClassName__primary') ||
                calendarClassName(date, view, priceTwoDays, 'myClassName__secondary') ||
                'calendar__tile-custom') + ' ' + prevDays;
        return result;
    }
    function calendarClassName(date, view, arr, className) {
        if (view === 'month' && arr.length !== 0) {
            const finds = arr.find(activeDay => activeDay.getTime() === date.getTime());
            // console.log(date.getDate(), !!finds);
            if (finds) {
                return className;
            }
        }
    }
    return (_jsx(Calendar, { onChange: setOnChange, value: calendarValue, minDetail: "month", tileClassName: tileClassName, tileDisabled: tileDisabled, defaultView: "month", prev2Label: null, next2Label: null, minDate: minDate, maxDate: maxDate, showNeighboringMonth: false, nextLabel: _jsx("img", { src: next, alt: "arrow right" }), prevLabel: _jsx("img", { src: prew, alt: "arrow left" }), locale: lang === "ua" ? "uk" : lang }));
};
