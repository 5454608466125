import { jsx as _jsx } from "react/jsx-runtime";
import burgerIconLight from 'assets/icons/Header/icon-burger.svg';
import burgerIconDark from 'assets/icons/Header/icon-burger-dark.svg';
import scss from './BurgerButton.module.scss';
import { useAppSelector } from 'features';
export const BurgerButton = ({ openDrawer }) => {
    const theme = useAppSelector(state => state.theme.selectedTheme);
    const icon = theme === 'dark' ? burgerIconDark : burgerIconLight;
    return (_jsx("button", Object.assign({ className: scss.burgerButton, onClick: openDrawer, "aria-label": "open menu" }, { children: _jsx("img", { src: icon, className: scss.burgerIcon, "aria-hidden": true, alt: 'burgerIcon' }) })));
};
