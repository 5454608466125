import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { usePickerContext } from '../PickerContext';
import { togglePicker } from '../PickerContext/pickerActions';
import { IconHeader } from '../../IconHeader';
import scss from './TogglePickerButton.module.scss';
export const ToggleArrow = ({ isActive, className = '' }) => (_jsx(IconHeader, { iconId: "arrow-up-chevron", className: `${scss.arrow} ${className} ${isActive ? scss.active : ''}` }));
const useGetButtonClassName = ({ className = '' }) => {
    const [{ isDark }] = usePickerContext();
    const dark = isDark ? scss.dark : '';
    return {
        buttonClassName: typeof className === 'object' ? `${className.separate} ${dark}` : `${scss.toggleButton} ${className} ${dark}`,
        iconClassName: dark,
    };
};
export const TogglePickerButton = ({ className, title, children }) => {
    const [{ isDropdownOpen }, dispatch] = usePickerContext();
    const { buttonClassName, iconClassName } = useGetButtonClassName({ className });
    const toggleDropdown = useCallback(() => {
        dispatch(togglePicker());
    }, [dispatch]);
    return (_jsxs("button", Object.assign({ className: buttonClassName, onClick: toggleDropdown, type: "button", title: title }, { children: [_jsx("div", Object.assign({ className: scss.buttonText }, { children: children })), _jsx(ToggleArrow, { isActive: isDropdownOpen, className: iconClassName })] })));
};
