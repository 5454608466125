import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { CommonContainer } from '../CommonContainer';
import { HeaderContent } from './Components';
import scss from './Header.module.scss';
import { useAppSelector } from 'features';
export const HeaderWrapper = ({ children }) => {
    const theme = useAppSelector(state => state.theme.selectedTheme);
    const dark = theme === 'dark' ? scss.dark : '';
    return (_jsxs(_Fragment, { children: [' ', _jsx("header", Object.assign({ className: `${scss.header} ${dark}` }, { children: _jsx(CommonContainer, { children: children }) })), _jsx("div", { style: { height: 52 }, className: scss.content })] }));
};
export const Header = ({ children }) => (_jsx(HeaderWrapper, { children: _jsx(HeaderContent, { children: children }) }));
