import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import s from './TagsComponent.module.scss';
export const tagsBlockOptions = [
    'optionService_08',
    'optionService_13',
    'optionService_14',
    'optionService_25',
    'optionService_24',
    'optionService_19',
    'optionService_20',
    'optionService_21',
    'optionService_22',
    'optionService_09',
    'optionService_10',
    'optionService_11',
    'optionService_12',
    'optionService_26',
];
export const TagsComponent = ({ tags, color }) => {
    const { t } = useTranslation('userCard');
    return (_jsx("div", Object.assign({ className: s.container }, { children: tags.map((tag, id) => (_jsx("div", Object.assign({ className: s.description, style: { color } }, { children: t(tag) }), id))) })));
};
