import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useMedia, usePopover } from 'hooks/UI';
import { getImage } from '../../../../../../../UserCabinet/BedOptions/image/getImage';
import s from './ModalBedOptions.module.scss';
import { useState } from 'react';
export const ModalUserMainRoom = ({ bed, styled }) => {
    const [parentPopperAttributes, childPopperAttributes] = usePopover({
        offset: [0, 13],
        placement: 'top',
    });
    // For adaptive design
    const { isMobile, isTablet } = useMedia();
    const deviceType = isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop';
    // /For adaptive design
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const isMobileText = windowWidth >= 320 && windowWidth <= 768;
    const { t } = useTranslation('userCard');
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({}, parentPopperAttributes, { className: `${s.userRoom_icon_block} mainroom_${deviceType}`, style: styled, onClick: e => {
                if (deviceType !== 'desktop') {
                    const currentTarget = e.currentTarget;
                    currentTarget.classList.add('active');
                    setTimeout(() => {
                        currentTarget.classList.remove('active');
                    }, 1500);
                }
            } }, { children: [_jsx("div", Object.assign({}, childPopperAttributes, { className: s.userRoom_icon_title }, { children: bed.title })), isMobileText && (_jsx("div", Object.assign({ className: s.bedOptionsText }, { children: _jsx("p", { children: t('liveRoom') }) }))), _jsxs("div", Object.assign({ className: s.wrapMobBed }, { children: [isMobileText && (_jsx("span", Object.assign({ className: s.numberBed }, { children: bed.descriptionInResponseData.single }))), getImage(bed), isMobileText && (_jsx("div", Object.assign({ className: s.textBedRooms }, { children: _jsx("p", { children: t('totalNumberBedroom') }) })))] }))] })) }));
};
