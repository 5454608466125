import { createEntityAdapter } from '@reduxjs/toolkit';
import { getManySearchObjects, getOneSearchObject } from 'features/api';
export const searchObjectsAdapter = createEntityAdapter({
    selectId: searchObject => searchObject._id,
    sortComparer: (a, b) => {
        var _a, _b, _c, _d, _e;
        const byCountry = (_a = a.country) === null || _a === void 0 ? void 0 : _a.localeCompare(b.country);
        if (byCountry) {
            return byCountry;
        }
        const byState = (_b = a.state) === null || _b === void 0 ? void 0 : _b.localeCompare(b.state);
        if (byState) {
            return byState;
        }
        const byCity = (_c = a.city) === null || _c === void 0 ? void 0 : _c.localeCompare(b.city);
        if (byCity) {
            return byCity;
        }
        if (a.district && b.district) {
            const byDistrict = (_d = a.district) === null || _d === void 0 ? void 0 : _d.localeCompare(b.district);
            if (byDistrict) {
                return byDistrict;
            }
        }
        if (typeof a._id === 'string' && typeof b._id === 'string') {
            return (_e = a._id) === null || _e === void 0 ? void 0 : _e.localeCompare(b._id);
        }
        if (typeof a._id === 'number' && typeof b._id === 'number') {
            return a._id - b._id;
        }
        return 0;
    },
});
export const searchObjectsAdapterInitState = searchObjectsAdapter.getInitialState();
/** @returns object { ids; entities } saved to RTK Query endpoint via searchObjectsAdapter.setAll */
export const saveManyObjects = (result) => {
    const objects = getManySearchObjects(result);
    return searchObjectsAdapter.setAll(searchObjectsAdapterInitState, objects);
};
/** @returns object { ids; entities } saved to RTK Query endpoint via searchObjectsAdapter.upsertOne */
export const saveOneObject = (result) => {
    const object = getOneSearchObject(result);
    return object ? searchObjectsAdapter.upsertOne(searchObjectsAdapterInitState, object) : undefined;
};
