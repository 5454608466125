import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useClickOutside, useMedia, useStateToggle } from 'hooks/UI';
import { HeaderButton } from '../../Components/HeaderButton';
import { useTranslation } from 'react-i18next';
import close from 'components/ModalSelectLocation/img/close.svg';
import closeMobile from 'components/ModalSelectLocation/img/closeMobile.svg';
import scss from './DepositIndicator.module.scss';
const AccountDetails = ({ hasDeposit, balance }) => {
    const { isDesktop } = useMedia();
    const { t } = useTranslation('hotelierMenu');
    const topLabelClassName = `header-ScoreBox ${hasDeposit ? '' : 'dislike-h'}`;
    const topLabel = hasDeposit ? t('activeAccaunt') : t('disabledAccaunt');
    const mainScoreBoxText1 = hasDeposit ? t('instructionPart1') : t('negativeIntPart1');
    const mainScoreBoxText2 = hasDeposit ? t('instructionPart2') : t('negativeIntPart2');
    const mainScoreBoxText3 = hasDeposit ? t('instructionPart3') : t('negativeIntPart3');
    const labelConditionsForAccountStop = hasDeposit ? t('instructionPart4') : t('negativeIntPart4');
    const balanceAmountClassName = isDesktop ? 'price-ScoreBox' : scss.balanceAmountMobile;
    return (_jsxs(_Fragment, { children: [_jsx("p", Object.assign({ className: topLabelClassName }, { children: topLabel })), _jsx("p", Object.assign({ className: "main-ScoreBox" }, { children: mainScoreBoxText1 })), _jsx("p", Object.assign({ className: "main-ScoreBox" }, { children: mainScoreBoxText2 })), _jsx("p", Object.assign({ className: "main-ScoreBox" }, { children: mainScoreBoxText3 })), _jsx("p", Object.assign({ className: "text-ScoreBox" }, { children: labelConditionsForAccountStop })), _jsxs("p", Object.assign({ className: "value-ScoreBox dagger" }, { children: [t('arrears'), " ", _jsxs("span", Object.assign({ className: balanceAmountClassName }, { children: [balance.debt, " USD"] }))] })), _jsxs("p", Object.assign({ className: "value-ScoreBox good" }, { children: [t('deposit'), " ", _jsxs("span", Object.assign({ className: balanceAmountClassName }, { children: [balance.deposit, " USD"] }))] })), _jsx("div", Object.assign({ className: "deposit-btn-style" }, { children: _jsx("button", Object.assign({ className: "deposit-btn" }, { children: t('topUpDeposit') })) }))] }));
};
const DepositStatus = ({ hasDeposit, toggleHasDeposit, overlayClassName, hideDetails, onClose, }) => {
    const balance = { deposit: hasDeposit ? '33' : '0', debt: hasDeposit ? '0' : '33' };
    const { isMobile } = useMedia();
    return (_jsxs("div", Object.assign({ className: `${scss.depositStatus} ${overlayClassName}` }, { children: [_jsx("button", Object.assign({ type: "button", className: scss.closeButton_wrap, onClick: hideDetails }, { children: isMobile ? (_jsx("img", { className: scss.closeButton_icon, src: closeMobile, alt: "close" })) : (_jsx("img", { className: scss.closeButton_icon, src: close, alt: "close" })) })), _jsx(HeaderButton, Object.assign({ buttonClassName: scss.toggleHasDepositBtn, tooltipClassName: scss.tooltip, onClick: toggleHasDeposit, buttonTooltip: hasDeposit ? 'deposit' : 'debt' }, { children: hasDeposit ? '💰' : '🙀' })), _jsx(AccountDetails, { balance: balance, hasDeposit: hasDeposit })] })));
};
export const DepositIndicator = ({ buttonClassName, overlayClassName, tooltipClassName, depositIndicatorClassName, onClose, }) => {
    const [hasDeposit, , , toggleHasDeposit] = useStateToggle(true);
    const [showDetails, , hideDetails, toggleShowDetails] = useStateToggle();
    const containerRef = useClickOutside(hideDetails);
    const iconClassName = hasDeposit ? scss.thumbUp : scss.thumbDown;
    return (_jsxs("div", Object.assign({ className: `${scss.depositIndicator} ${depositIndicatorClassName}`, ref: containerRef }, { children: [_jsx(HeaderButton, { iconId: "thumb-up", buttonClassName: buttonClassName, tooltipClassName: tooltipClassName, iconClassName: iconClassName, buttonTooltip: ``, onClick: toggleShowDetails }), showDetails && (_jsx(DepositStatus, { overlayClassName: overlayClassName, hasDeposit: hasDeposit, toggleHasDeposit: toggleHasDeposit, hideDetails: hideDetails, onClose: toggleShowDetails }))] })));
};
