import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocation } from 'react-router-dom';
// import { isProd } from 'config';
import { isOnHotelierCatalog, isOnUserCatalog } from 'navigation/CONSTANTS';
import { useMedia } from 'hooks/UI';
import { DrawerCtxProvider } from 'context/DrawerContext';
import { Logo } from '../Logo';
import { DesktopPickers } from '../Pickers';
import { MobileHeader } from '../MobileHeader';
// import { useAppSelector } from 'features';
// import type { Mode } from 'types';
import scss from './HeaderContent.module.scss';
export const HeaderContent = ({ children }) => {
    const { isDesktop } = useMedia();
    const { pathname } = useLocation();
    let routeSpecificStyle = '';
    if (isOnHotelierCatalog(pathname)) {
        routeSpecificStyle = scss.hotelierCatalog;
    }
    else if (isOnUserCatalog(pathname)) {
        routeSpecificStyle = scss.userCatalog;
    }
    return (_jsx(DrawerCtxProvider, { children: _jsxs("div", Object.assign({ className: `${scss.flexRow} ${routeSpecificStyle}` }, { children: [_jsx(Logo, {}), isDesktop && _jsx(DesktopPickers, {}), children, !isDesktop && _jsx(MobileHeader, {})] })) }));
};
