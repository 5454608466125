const defaultState = {
    filterName: "",
};
export const filterNameReducer = (state = defaultState, action) => {
    switch (action.type) {
        case 'FILTER_NAME':
            return Object.assign(Object.assign({}, state), { filterName: action.payload });
        default:
            return state;
    }
};
export const filterName = payload => ({ type: 'FILTER_NAME', payload });
export const changeUKToUA = lang => {
    if (lang === 'ua') {
        return 'uk';
    }
    return lang;
};
