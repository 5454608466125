import { ns } from 'types';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/en.json';
import translationUA from './locales/ua/ua.json';
import translationPL from './locales/pl/pl.json';
import translationRU from './locales/ru/ru.json';
const en = translationEN;
const ua = translationUA;
const pl = translationPL;
const ru = translationRU;
i18n.use(initReactI18next).init({
    ns,
    defaultNS: 'common',
    resources: { en, ua, pl, ru },
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
    },
});
