import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// import { useTranslation } from 'react-i18next';
import { useMedia, usePopover } from 'hooks/UI';
import { getImage } from '../image/getImage';
import s from '../BedOptions.module.scss';
export const MainRoom = ({ bed }) => {
    const [parentPopperAttributes, childPopperAttributes] = usePopover({
        offset: [0, 13],
        placement: 'top',
    });
    // For adaptive design
    const { isMobile, isTablet } = useMedia();
    const deviceType = isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop';
    // /For adaptive design
    // const { t } = useTranslation('userCard');
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({}, parentPopperAttributes, { className: `${s.room_icon_block} mainroom_${deviceType}`, onClick: e => {
                if (deviceType !== 'desktop') {
                    const currentTarget = e.currentTarget;
                    currentTarget.classList.add('active');
                    setTimeout(() => {
                        currentTarget.classList.remove('active');
                    }, 1500);
                }
            } }, { children: [getImage(bed), _jsx("div", Object.assign({}, childPopperAttributes, { className: s.room_icon_title }, { children: bed.title }))] })) }));
};
