import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { ActiveBookingCard } from '../ActiveBookingCard';
export const ActiveBookingsList = ({ list, cancelBooking }) => {
    const [selectBookingCard, setSelectBookingCard] = useState();
    return (_jsx("div", { children: list.map(item => (_jsx(ActiveBookingCard, { data: item, cancelBooking: () => {
                if (selectBookingCard)
                    cancelBooking(selectBookingCard._id);
            }, setCard: () => setSelectBookingCard(item) }, item._id))) }));
};
