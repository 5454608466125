import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, createContext } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { CalendarOnMonth } from './Calendar/Calendar';
import { ModalBoxPrice } from './ModalBoxPrice/ModalBoxPrice';
import { PriceModal } from './PriceModal';
import { NotOurServiceModal } from './NotOurServiceModal';
import { ArrowIcon } from './ArrowIcon/ArrowIcon';
import { ModalTooltip } from './ModalTooltip';
import getClassOptionsRoom from '../getClassOptionsRoom';
import getClassIconBed from '../getClassIconBed';
import pencil from '../../../MainPage/Assents/image/SelectBed.svg';
// import { t } from 'i18next';
import s from './Modal.module.scss';
import primeStyles from '../infoComponentPrime.module.scss';
import ors from './ModalOptionRoom.module.scss';
import { useTranslation } from 'react-i18next';
// import { setActiveBookingId } from 'features/bookingOptionHotel';
export const PriceContext = createContext();
const notOurServiceInitial = [new Date('July 15, 2024'), new Date('July 16, 2024')];
const ourServiceInitial = [
    new Date('July 18, 2024'),
    new Date('July 19, 2024'),
];
const alreadyBookingInitial = [new Date('July 21, 2024')];
const isPriceOneInitital = [
    new Date('June 2, 2024'),
    new Date('July 2, 2024'),
    new Date('July 3, 2024'),
    new Date('July 7, 2024'),
    new Date('July 8, 2024'),
];
const priceTwoInitital = [new Date('July 24, 2024')];
export default function ModalWindow({ isRoom, el, quantityRooms, activeIconBed, isOpenHostelModal, totalQuantityofBeds, isObjectVerify, makeOptionClass, onClick, HTC, openbedSettingsModalWindow }) {
    const [calendarValue, setOnChange] = useState();
    const [isError, setIsError] = useState(false);
    const [price, setPrice] = useState('');
    const [price1, setPrice1] = useState('1');
    const [price2, setPrice2] = useState('2');
    const [allActiveDay, setAllActiveDay] = useState([]);
    const [showNotOurService, setShowNotOurService] = useState(false);
    const [applyToAllBeds, setApplyToAllBeds] = useState(false);
    const [isOurService, setIsOurService] = useState(ourServiceInitial);
    const [isAlreadyBooking, setIsAlreadyBooking] = useState(alreadyBookingInitial);
    const [priceOneDays, setPriceOneDays] = useState(isPriceOneInitital);
    const [priceTwoDays, setPriceTwoDays] = useState(priceTwoInitital);
    const [notOurServiceDays, setNotOurServiceDays] = useState(notOurServiceInitial);
    const [disabledElement, setDisabledElement] = useState(true);
    const [isPrice1ModalOpen, setIsPrice1ModalOpen] = useState(false);
    const [isPrice2ModalOpen, setIsPrice2ModalOpen] = useState(false);
    const [isNotOurServicesModalOpen, setIsNotOurServicesModalOpen] = useState(false);
    const [changingPrice, setChangingPrice] = useState(false);
    const [isTooltipActive, setIsTooltipActive] = useState(false);
    const notify = text => toast.success(text);
    const { t } = useTranslation('hotelierCabinet');
    const handleSubmit = e => {
        e.preventDefault();
    };
    const backToDefault = () => {
        setAllActiveDay([]);
        setOnChange(null);
        if (isPrice1ModalOpen) {
            setIsPrice1ModalOpen(false);
        }
        if (isPrice2ModalOpen) {
            setIsPrice2ModalOpen(false);
        }
        if (isNotOurServicesModalOpen) {
            setIsNotOurServicesModalOpen(false);
        }
    };
    const handleChangeInput = e => {
        const value = e.target.value;
        if (/^[0-9,.]*$/.test(value) || value === '') {
            setPrice(value);
        }
    };
    const openChangePriceModal = id => {
        if (id === 'price1') {
            setIsPrice1ModalOpen(true);
            setAllActiveDay(priceOneDays);
            setPrice(price1);
        }
        if (id === 'price2') {
            setIsPrice2ModalOpen(true);
            setAllActiveDay(priceTwoDays);
            setPrice(price2);
        }
    };
    const openNotOurServiceInfo = e => {
        e.preventDefault();
        setIsNotOurServicesModalOpen(true);
        if (isNotOurServicesModalOpen) {
            if (!allActiveDay.length) {
                setIsError(true);
            }
            else {
                setIsError(false);
                notify(t('modalDatesNotBookedYPB'));
                setTimeout(() => {
                    // closeModal();
                    setNotOurServiceDays(allActiveDay);
                    setAllActiveDay([]);
                    setApplyToAllBeds(false);
                    backToDefault();
                }, 3005);
            }
        }
        else {
            setAllActiveDay(notOurServiceDays);
        }
    };
    const handleClickChangePrice = e => {
        e.preventDefault();
        setDisabledElement(false);
        setChangingPrice(true);
        if (changingPrice) {
            if (allActiveDay.length && (price1 || price2)) {
                setIsError(false);
                notify(isPrice1ModalOpen
                    ? t('modalPriceApplied', { number: 1 })
                    : t('modalPriceApplied', { number: 2 }));
                setTimeout(() => {
                    // closeModal();
                    if (isPrice1ModalOpen) {
                        setPriceOneDays(allActiveDay);
                        setPrice1(price);
                    }
                    if (isPrice2ModalOpen) {
                        setPriceTwoDays(allActiveDay);
                        setPrice2(price);
                    }
                    setAllActiveDay([]);
                    setApplyToAllBeds(false);
                    backToDefault();
                    setPrice('');
                    setChangingPrice(false);
                }, 3005);
            }
            else {
                setIsError(true);
            }
        }
    };
    const backFromChangingPrice = () => {
        setIsError(false);
        setChangingPrice(false);
        setDisabledElement(false);
        setIsPrice1ModalOpen(false);
        setIsPrice2ModalOpen(false);
        setPrice('');
        setOnChange(null);
    };
    const tileDisabled = ({ date }) => {
        const checkedAsIsOurService = isOurService.find(d => new Date(date).toString() === new Date(d).toString());
        const checkedAsAlreadyBooking = isAlreadyBooking.find(d => new Date(date).toString() === new Date(d).toString());
        const year = new Date().getFullYear();
        const month = new Date().getMonth();
        const day = new Date().getDate();
        const today = new Date(year, month, day);
        const pastDays = today.getTime() > date.getTime();
        if (isPrice1ModalOpen || isPrice2ModalOpen || isNotOurServicesModalOpen) {
            return checkedAsIsOurService ||
                checkedAsAlreadyBooking ||
                pastDays;
        }
        return true;
    };
    let modalCalendarBoxClassname = s.modal__calendar_box;
    if (isPrice1ModalOpen && !changingPrice) {
        modalCalendarBoxClassname += ' ' + s.modal__gradient_primary;
    }
    if (isPrice2ModalOpen && !changingPrice) {
        modalCalendarBoxClassname += ' ' + s.modal__gradient_secondary;
    }
    let modalBoxCheckBoxClassName = s.modal__box_checkbox;
    if (isPrice1ModalOpen && changingPrice) {
        modalBoxCheckBoxClassName += ' ' + s.modal__gradient_primary;
    }
    if (isPrice2ModalOpen && changingPrice) {
        modalBoxCheckBoxClassName += ' ' + s.modal__gradient_secondary;
    }
    if (isNotOurServicesModalOpen) {
        modalBoxCheckBoxClassName += ' ' + s.modal__gradient_blue;
    }
    let buttonClearClassName = s.modal__button_clear;
    if (isNotOurServicesModalOpen) {
        buttonClearClassName += ' ' + s.modal__button_clear_active;
    }
    const calendarWrapperClassName = (isPrice1ModalOpen || isPrice2ModalOpen) && !changingPrice && !isNotOurServicesModalOpen
        ? s.calendar__wrapper_disabled
        : '';
    return (_jsxs("div", { children: [_jsx(ModalTooltip, { isTooltipActive: isTooltipActive, setIsTooltipActive: setIsTooltipActive }), isRoom ? (_jsx("div", Object.assign({ className: ors.modal__optionalRoom_wrapper }, { children: !el.hostel ? (_jsxs("li", Object.assign({ className: ors.hotelier_roms__item }, { children: [_jsx("div", Object.assign({ className: ors.hotelier_roms__box_bed_img }, { children: _jsx("div", Object.assign({ className: ors.hotelier_roms__box_number_room }, { children: _jsx("p", Object.assign({ className: ors.hotelier_roms__number_room }, { children: el.number })) })) })), _jsx("ul", Object.assign({ className: ors.modal__optionalRoom_list }, { children: el.optionsroom.map(room => {
                                return (_jsx(_Fragment, { children: _jsxs("li", Object.assign({ className: `${getClassOptionsRoom(quantityRooms)}`, onClick: () => { } }, { children: [room.twoBedroom !== 0 && (_jsxs("div", Object.assign({ className: ors.hotelier_roms__box_icon }, { children: [_jsx("svg", Object.assign({ fill: "#787878", className: `${getClassIconBed(quantityRooms, 'twoBed', room.singleBedroom)}` }, { children: _jsx("use", { href: `${pencil}#doubleBed` }) })), _jsx("p", Object.assign({ className: s.hotelier_roms__quantity_room }, { children: room.twoBedroom }))] }))), room.singleBedroom !== 0 && (_jsxs("div", Object.assign({ className: ors.hotelier_roms__box_icon }, { children: [_jsx("svg", Object.assign({ fill: "#787878", className: `${getClassIconBed(quantityRooms, 'singleBed', room.twoBedroom)}` }, { children: _jsx("use", { href: `${pencil}#singleBed` }) })), _jsx("p", Object.assign({ className: s.hotelier_roms__quantity_room }, { children: room.singleBedroom }))] })))] }), room.key) }));
                            }) }))] }))) : null }))) : (_jsxs("div", Object.assign({ className: ors.modal__optionalRoom_wrapper }, { children: [(isPrice1ModalOpen || isPrice2ModalOpen || isNotOurServicesModalOpen) && (_jsx("div", { className: ors.modal__bed_disabled_block })), _jsx("div", Object.assign({ className: ors.hotelier_roms__box_bed_img }, { children: _jsx("div", Object.assign({ className: ors.hotelier_roms__box_number_room }, { children: _jsx("p", Object.assign({ className: ors.hotelier_roms__number_room }, { children: el.number })) })) })), _jsxs("div", Object.assign({ className: primeStyles.hotelier_options_hostel_room }, { children: [_jsxs("div", Object.assign({ className: primeStyles.hotelier_options_hostel_room__box_number }, { children: [_jsxs("p", Object.assign({ className: primeStyles.hotelier_options_hostel_room__text }, { children: [t('totalInRoom'), ' ', _jsx("span", Object.assign({ className: primeStyles.hotelier_options_hostel_room__number }, { children: totalQuantityofBeds[0].length }))] })), _jsx("svg", Object.assign({ className: primeStyles.hotelier_options_rooms__icon_bed }, { children: _jsx("use", { href: `${pencil}#singleBed` }) }))] })), _jsx("div", Object.assign({ className: primeStyles.hotelier_options_hostel_room__box_list_bed }, { children: _jsx("ul", Object.assign({ className: primeStyles.hotelier_options_hostel_room__list, onClick: isObjectVerify === false && HTC && onClick }, { children: totalQuantityofBeds[0].map((el, index) => {
                                        return (_jsx("li", Object.assign({ className: isObjectVerify === false ? makeOptionClass(index) : primeStyles.hotelier_options_hostel_room__item_notverify }, { children: _jsxs("div", Object.assign({ className: primeStyles.hotelier_options_hostel_room__price }, { children: [el.price === 0 && (_jsx("p", { style: { display: 'none' }, className: primeStyles.hotelier_options_hostel_room__pricePerRoom })), _jsx("svg", Object.assign({ className: primeStyles.hotelier_options_rooms__icon_single_bed, onClick: e => openbedSettingsModalWindow(e, index), style: {
                                                            fill: index === activeIconBed && isOpenHostelModal ? '#006bbe' : '#787878',
                                                            strokeWidth: index === activeIconBed && isOpenHostelModal ? '1' : null,
                                                            stroke: index === activeIconBed && isOpenHostelModal ? '#006bbe' : null,
                                                        } }, { children: _jsx("use", { href: `${pencil}#singleBed` }) })), _jsx("p", Object.assign({ className: primeStyles.hotelier_options_hostel_room__text }, { children: el.id }))] })) }), index));
                                    }) })) }))] }))] }))), isPrice1ModalOpen || isPrice2ModalOpen || isNotOurServicesModalOpen ? (_jsx(ArrowIcon, { onClick: backToDefault })) : null, _jsxs("form", Object.assign({ onSubmit: handleSubmit }, { children: [!isPrice1ModalOpen && !isPrice2ModalOpen && !isNotOurServicesModalOpen ? (_jsxs(_Fragment, { children: [_jsx(ModalBoxPrice, { color: "primary", btnText: "1", id: "price1", openChangePriceModal: openChangePriceModal, disabledElement: disabledElement, setDisabledElement: setDisabledElement, price: price1, setPrice: setPrice1 }), _jsx(ModalBoxPrice, { color: "secondary", btnText: "2", id: "price2", openChangePriceModal: openChangePriceModal, disabledElement: disabledElement, setDisabledElement: setDisabledElement, price: price2, setPrice: setPrice2 })] })) : null, isPrice1ModalOpen ? (_jsx(PriceModal, { priceNumber: "1", btnText: "\u0426\u0435\u043D\u0430 1", disabledElement: disabledElement, 
                        // errorText={errorText}
                        handleChangeInput: handleChangeInput, price: price, changingPrice: changingPrice, handleClickChangePrice: handleClickChangePrice, isError: isError, el: el, applyToAllBeds: applyToAllBeds, setApplyToAllBeds: setApplyToAllBeds, backFromChangingPrice: backFromChangingPrice })) : null, isPrice2ModalOpen ? (_jsx(PriceModal, { priceNumber: "2", btnText: "\u0426\u0435\u043D\u0430 2", disabledElement: disabledElement, 
                        // errorText={errorText}
                        handleChangeInput: handleChangeInput, price: price, changingPrice: changingPrice, handleClickChangePrice: handleClickChangePrice, isError: isError, el: el, applyToAllBeds: applyToAllBeds, setApplyToAllBeds: setApplyToAllBeds, backFromChangingPrice: backFromChangingPrice })) : null, isNotOurServicesModalOpen ? _jsx(NotOurServiceModal, { isError: isError }) : null, _jsxs("div", Object.assign({ className: modalCalendarBoxClassname }, { children: [_jsx("div", Object.assign({ className: calendarWrapperClassName }, { children: _jsx(CalendarOnMonth, { setAllActiveDay: setAllActiveDay, allActiveDay: allActiveDay, showNotOurService: showNotOurService, calendarValue: calendarValue, setOnChange: setOnChange, notOurServiceDays: notOurServiceDays, setNotOurServiceDays: setNotOurServiceDays, isPrice1ModalOpen: isPrice1ModalOpen, isPrice2ModalOpen: isPrice2ModalOpen, isNotOurServicesModalOpen: isNotOurServicesModalOpen, isOurService: isOurService, isAlreadyBooking: isAlreadyBooking, tileDisabled: tileDisabled, priceOneDays: priceOneDays, setPriceOneDays: setPriceOneDays, priceTwoDays: priceTwoDays, setPriceTwoDays: setPriceTwoDays }) })), !isPrice1ModalOpen && !isPrice2ModalOpen ? (_jsx("div", Object.assign({ className: s.modal__box_button }, { children: _jsxs("button", Object.assign({ className: buttonClearClassName, type: "button", onClick: e => {
                                        openNotOurServiceInfo(e);
                                    } }, { children: [_jsx("span", Object.assign({ className: s.modal__button_clear_text }, { children: t('modalMarkDatesNotBookedYPB') })), _jsx("span", { className: s.modal__button_clear_icon })] })) }))) : null, _jsxs("div", Object.assign({ className: modalBoxCheckBoxClassName }, { children: [_jsx("p", Object.assign({ className: s.modal__label_price_all }, { children: t('modalBookedYPB') })), _jsx("p", Object.assign({ className: `${s.modal__label_price_all} ${s.modal__label_price_all_nos}` }, { children: t('modalNotBookedYPB') })), _jsxs("div", Object.assign({ className: s.modal__label_price_all_block }, { children: [_jsx("span", Object.assign({ className: `${s.modal__label_price_all} ${s.modal__label_price_all_small} ${s.modal__label_price_all_small_primary}` }, { children: t('modalPrice', { number: 1 }) })), _jsx("span", Object.assign({ className: `${s.modal__label_price_all} ${s.modal__label_price_all_small} ${s.modal__label_price_all_small_secondary}` }, { children: t('modalPrice', { number: 2 }) }))] })), _jsx("p", Object.assign({ className: `${s.modal__label_price_all} ${s.modal__label_price_all_ab}` }, { children: t('modalIsBooking') }))] }))] }))] })), _jsx(Toaster, {})] }));
}
