import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import s from './FilterButton.module.scss';
import n from './img/new.png';
import lowPrice from './img/lowPrice.png';
import { useTranslation } from 'react-i18next';
const FilterButton = () => {
    let [show, setShow] = useState('none');
    const { t } = useTranslation('customSelect');
    const [selected, setSelected] = useState({
        id: 1,
        obj: t('new'),
        img: n,
    });
    const [filter, setFilter] = useState([
        { id: 1, obj: t('new'), img: n },
        { id: 2, obj: t('lowerPrice'), img: lowPrice },
    ]);
    const timeHide = () => {
        setTimeout(() => {
            setShow('none');
        }, 500);
    };
    const selectFilter = (id, img, obj) => {
        setSelected({ id: id, obj: obj, img: img });
    };
    useEffect(() => {
        const updateTranslations = () => {
            // setSelected({
            //     ...selected,
            //     obj: t('new'),
            // });
            setFilter([
                { id: 1, obj: t('new'), img: n },
                { id: 2, obj: t('lowerPrice'), img: lowPrice },
            ]);
        };
        updateTranslations();
    }, [t]);
    return (_jsxs("div", Object.assign({ className: s.container, onMouseLeave: timeHide }, { children: [_jsxs("div", Object.assign({ className: s.selected_wrap, onClick: () => setShow('block') }, { children: [_jsxs("div", Object.assign({ className: s.text_block }, { children: [_jsx("div", Object.assign({ className: s.top_img }, { children: _jsx("img", { src: selected.img, alt: "clock" }) })), _jsx("div", Object.assign({ style: { marginTop: '2px' } }, { children: selected.obj }))] })), _jsx("div", { className: s.arrow })] })), _jsx("div", Object.assign({ className: s.filter_wrap, style: { display: show } }, { children: _jsxs("div", Object.assign({ className: s.filter_dox }, { children: [_jsx("div", Object.assign({ className: s.box_title }, { children: _jsx("div", Object.assign({ style: { margin: '0 0 5px 4px' } }, { children: t('showFirst') })) })), filter.map(o => {
                            return (_jsxs("div", Object.assign({ className: s.filter, onClick: () => selectFilter(o.id, o.img, o.obj) }, { children: [_jsx("div", { children: _jsx("img", { src: o.img, className: s.box_img, alt: "icon" }) }), o.obj] }), o.id));
                        })] })) }))] })));
};
export default FilterButton;
