import { jsx as _jsx } from "react/jsx-runtime";
import { ModalWindow } from '../../../../UserCabinet/ModalW/Modal/Modal';
import { NoticeCancelBooking } from './component/NoticeCancelBooking';
export const ModalCancelBooking = ({ modalOptions, onClick }) => {
    return (_jsx(ModalWindow, Object.assign({ modalOptions: modalOptions, style: {
            overlay: {
                background: 'rgba(76, 76, 76, 0.28)',
                backdropFilter: 'blur(5px)',
            },
            content: { height: 'auto', transform: 'translate(65%, -55%)', inset: ' 50% auto auto 0' },
        } }, { children: _jsx(NoticeCancelBooking, { close: modalOptions.onClose, onClick: onClick }) })));
};
