import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import scss from './LoginButton.module.scss';
import { LOGIN_AGENT, LOGIN_HOTELIER, LOGIN_USER, MAIN, MAIN_AGENT } from 'navigation/CONSTANTS';
import { Link, useLocation } from 'react-router-dom';
export const LoginButton = () => {
    const { t } = useTranslation();
    const [linkToLoginPage, setLinkToLoginPage] = useState(LOGIN_HOTELIER);
    const location = useLocation();
    useEffect(() => {
        switch (location.pathname) {
            case MAIN:
                setLinkToLoginPage(LOGIN_USER);
                break;
            case MAIN_AGENT:
                setLinkToLoginPage(LOGIN_AGENT);
                break;
            default:
                setLinkToLoginPage(LOGIN_HOTELIER);
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsx(Link, Object.assign({ className: scss.loginButton, to: linkToLoginPage }, { children: t('login') }))
    // <button className={scss.loginButton} onClick={onClickLogIn}>
    // 	{t('login')}
    // </button>
    );
};
