import { jsx as _jsx } from "react/jsx-runtime";
import { useAppDispatch, useAppSelector } from 'features';
import { chooseLanguage, selectActiveLanguageId } from 'features/common';
import { useCallback } from 'react';
import scss from './LanguageOptionButton.module.scss';
import { useTranslation } from 'react-i18next';
export const LanguageOptionButton = ({ optionId, children }) => {
    const activeLanguageId = useAppSelector(selectActiveLanguageId);
    const { i18n } = useTranslation();
    const theme = useAppSelector(state => state.theme.selectedTheme);
    const dark = theme === 'dark' ? scss.dark : '';
    const appDispatch = useAppDispatch();
    const onClickChooseLanguage = useCallback(() => {
        appDispatch(chooseLanguage(optionId));
        if (typeof optionId !== 'string')
            return;
        i18n.changeLanguage(optionId);
    }, [appDispatch, i18n, optionId]);
    //const dark = isDark ? scss.dark : '';
    const active = optionId === activeLanguageId ? scss.active : '';
    return (_jsx("button", Object.assign({ className: `${scss.languageOptionButton} ${dark} ${active}`, onClick: onClickChooseLanguage, type: "button" }, { children: children })));
};
