import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { BedOptions } from 'components/UserCabinet/BedOptions/BedOptions';
import { ModalCancelBooking } from 'components/BookingPropose/components/ModalW';
import { useToggleModal } from 'components/UserCabinet/ModalW/Modal/useToggleModal';
import { CancelBooking } from '../Buttons';
// import { Location } from './index';
// import { Location } from './index';
import { ProofOfPayment } from '../Buttons/ProofOfPayment';
import Gallery from 'components/UserCards/Gallery';
import s from './MainCard.module.scss';
import bedStyle from 'components/BookingPropose/components/HoteliersProposes/HoteliersProposeCard/HoteliersProposeCard.module.scss';
import dollar from 'assets/icons/userCabinetSendReq/dollar.svg';
import windowShine from 'assets/icons/proposeCard/cleaning-window-shine.svg';
import Rating from 'components/BookingPropose/components/HoteliersProposes/HoteliersProposeCard/components/Rating/Rating';
import { Date } from 'components/BookingPropose/components/HoteliersProposes/HoteliersProposeCard/components/Date/Date';
import { Location, Price, BookingDates, } from 'components/BookingPropose/components/HoteliersProposes/HoteliersProposeCard/components';
import { useTranslation } from 'react-i18next';
export const ProposeCard = ({ data, cancelBooking, setCard, isPayed, openFeedbackBookingVariantModal, openRateBookingVariantModal, }) => {
    const [isOpen, onOpen, onClose] = useToggleModal();
    const { t } = useTranslation('userBooking');
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: `${s.propose__card} ${s.propose_card__container}` }, { children: [_jsxs("div", Object.assign({ className: s.card__price_action }, { children: [_jsx(Rating, { title: data.name, rating: data.rating }), _jsxs("div", Object.assign({ className: bedStyle.wrapper_money }, { children: [_jsx("img", { className: bedStyle.dollar_icon, src: dollar, alt: "dollar" }), _jsx(Price, { price: data.price })] })), _jsxs("div", Object.assign({ className: bedStyle.wrapper_hotel_size }, { children: [_jsx("img", { className: bedStyle.window_shine_icon, src: windowShine, alt: "location pin" }), _jsxs("p", Object.assign({ className: bedStyle.hotel__appsize }, { children: [data.apartmentsSize, " ", t('hotelArea')] }))] })), _jsx("div", Object.assign({ className: bedStyle.wrapper_card_btn }, { children: " " }))] })), _jsxs("div", Object.assign({ className: isPayed ? s.optionsButton_container_paid : s.optionsButton_container }, { children: [_jsx(ProofOfPayment, { isPayed: isPayed, openFeedbackBookingVariantModal: openFeedbackBookingVariantModal, openRateBookingVariantModal: openRateBookingVariantModal }), !isPayed && (_jsx(CancelBooking, { onClick: () => {
                                    setCard();
                                    onOpen();
                                } }))] })), _jsx("div", Object.assign({ className: s.card__photo }, { children: _jsx(Gallery, { title: data.name, isShowText: false, url: data.photos[0], urlImageSecond: data === null || data === void 0 ? void 0 : data.photos[1] }) })), _jsxs("div", Object.assign({ className: `  ${s.locationAdaptive} ${bedStyle.wrapper_location_dates}` }, { children: [_jsx(Location, { city: data.city, hotelName: data.name, address: data.address }), _jsx(Date, { dates: { dateFrom: data.dateFrom, dateTo: data.dateTo } })] })), _jsx(BookingDates, { dates: {
                            checkInTime: data.timeCheckIn,
                            checkOutTime: data.timeCheckOut,
                        }, type: data.type }), _jsx("div", Object.assign({ className: s.adaptive_BedWrapper }, { children: _jsx("ul", Object.assign({ className: bedStyle.bed__options }, { children: _jsx(BedOptions, { options: data.bedOptions }) })) }))] })), _jsx(ModalCancelBooking, { modalOptions: { isOpen, onOpen, onClose }, onClick: cancelBooking }), _jsx(ModalCancelBooking, { modalOptions: { isOpen, onOpen, onClose }, onClick: cancelBooking })] }));
};
