import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocation } from 'react-router-dom';
import { isOnHotelierCatalog, isOnUserCatalog } from 'navigation/CONSTANTS';
import { LanguagePicker } from './LanguagePicker';
import { CurrencyPicker } from './CurrencyPicker';
import { useAppSelector } from 'features';
import scss from './DesktopPickers.module.scss';
export const DesktopPickers = () => {
    const { pathname } = useLocation();
    const theme = useAppSelector(state => state.theme.selectedTheme);
    const light = theme === 'light' ? scss.light : '';
    let routeSpecificStyle = '';
    if (isOnHotelierCatalog(pathname)) {
        routeSpecificStyle = scss.hotelierCatalog;
    }
    else if (isOnUserCatalog(pathname)) {
        routeSpecificStyle = scss.userCatalog;
    }
    return (_jsxs("div", Object.assign({ className: `${scss.desktopPickers} ${routeSpecificStyle} ${light}` }, { children: [_jsx(LanguagePicker, {}), _jsx(CurrencyPicker, {})] })));
};
