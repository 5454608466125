import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Gallery from 'components/UserCards/Gallery';
import s from './ModalHoteliersProposeCard.module.scss';
import { BedOptions } from 'components/UserCabinet/BedOptions/BedOptions';
import { FreeButton } from 'components/UserCabinet/FreeButton/FreeButton';
import dollar from 'assets/icons/proposeCard/dollar.svg';
import windowShine from 'assets/icons/proposeCard/cleaning-window-shine.svg';
import Rating from '../../Rating/Rating';
import { Price } from '../../Price/Price';
import { Location } from '../../Location/Location';
import { Date } from '../../Date/Date';
import { BookingDates } from '../../BookingDates/BookingDates';
import { VideoPreview } from '../../VideoPreview/VideoPreview';
import { OtherUserBookingNow } from 'components/BookingPropose/components/Notifications';
export const ModalHoteliersProposeCard = ({ data, apartmentsSize }) => {
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ className: s.propose__card }, { children: [_jsxs("div", Object.assign({ className: s.card__price_action }, { children: [_jsx(Rating, { title: data.name, rating: data.rating }), _jsxs("div", Object.assign({ className: s.wrapper_money }, { children: [_jsx("img", { className: s.dollar_icon, src: dollar, alt: "dollar" }), _jsx(Price, { price: data.price })] })), _jsxs("div", Object.assign({ className: s.wrapper_hotel_size }, { children: [_jsx("img", { className: s.window_shine_icon, src: windowShine, alt: "location pin" }), _jsxs("p", Object.assign({ className: s.hotel__appsize }, { children: [apartmentsSize, " \u043C.\u043A\u0432."] }))] })), _jsx("div", Object.assign({ className: s.wrapper_card_btn }, { children: _jsx(FreeButton, { color: '' }) }))] })), _jsx("div", Object.assign({ className: s.card__photo }, { children: _jsx(Gallery, { title: data.name, isShowText: false, url: data.photos[0], urlImageSecond: data === null || data === void 0 ? void 0 : data.photos[1] }) })), _jsxs("div", Object.assign({ className: s.wrapper_location_dates }, { children: [_jsx(Location, { city: data.city, hotelName: data.name, address: data.address }), _jsx(Date, { dates: {
                                dateFrom: data.dateFrom,
                                dateTo: data.dateTo,
                            } })] })), _jsx(BookingDates, { type: "", dates: {
                        checkInTime: data.timeCheckIn,
                        checkOutTime: data.timeCheckOut,
                    } }), _jsxs("div", Object.assign({ className: s.wrap_tablet_display }, { children: [_jsx("ul", Object.assign({ className: s.bed__options }, { children: _jsx(BedOptions, { options: data.bedOptions }) })), _jsx(VideoPreview, { video: data.video, title: data.name })] })), data.bookingNow && _jsx(OtherUserBookingNow, { className: s.card__disabled })] })) }));
};
