import { createSlice, createSelector } from '@reduxjs/toolkit';
import { objectPhotosAdapter, objectPhotosAdapterInitState } from './objectPhotosAdapter';
export const initObjectPhotos = new Array(12).fill(1).reduce(({ ids, entities }, _, i) => ({
    ids: [...ids, `${i}`],
    entities: Object.assign(Object.assign({}, entities), { [`${i}`]: { _id: `${i}`, localUrl: '', position: `${i}` } }),
}), { ids: [], entities: {} });
export const initObjectAddress = {
    country: '',
    state: '',
    city: '',
    street: '',
    house: '',
    apartment: '',
    zipCode: '',
    phone: '',
    email: '',
};
export const initObjectBookingTime = {
    checkin: '',
    checkout: '',
};
const initialState = {
    activeObjectId: '',
    isEditingExistingObjectBeforeVerification: false,
    status: false,
    activeLocationId: '',
    typeId: '',
    objectName: '',
    address: initObjectAddress,
    description: '',
    photos: initObjectPhotos,
    time: initObjectBookingTime,
    activeServicesIds: [],
    activePaymentsIds: [],
    activeTabId: 0,
    editedFields: [],
    isUnfinishedObjectRegistration: false,
    startCreationTime: 0,
};
const objectSlice = createSlice({
    name: 'object',
    initialState,
    reducers: {
        setActiveObjectId(state, { payload }) {
            state.activeObjectId = payload;
        },
        setIsEditingExistingObjectBeforeVerification(state, { payload }) {
            state.isEditingExistingObjectBeforeVerification = payload;
        },
        setObjectEditedFields(state, { payload }) {
            state.editedFields = payload;
        },
        setObjectStatus(state, { payload }) {
            state.status = payload;
        },
        chooseObjectLocation(state, { payload }) {
            state.activeLocationId = payload;
        },
        chooseObjectTypeId(state, { payload }) {
            state.typeId = payload;
        },
        setObjectName(state, { payload }) {
            state.objectName = payload;
        },
        setObjectAddress(state, { payload }) {
            state.address = payload;
        },
        setObjectDescription(state, { payload }) {
            state.description = payload;
        },
        setObjectPhotos(state, { payload }) {
            state.photos = objectPhotosAdapter.setAll(state.photos, payload);
        },
        updateObjectPhoto(state, { payload }) {
            state.photos = objectPhotosAdapter.upsertOne(state.photos, payload);
        },
        deleteObjectPhoto(state, { payload }) {
            state.photos = objectPhotosAdapter.removeOne(state.photos, payload);
        },
        setObjectTime(state, { payload }) {
            state.time = payload;
        },
        chooseObjectServices(state, { payload }) {
            state.activeServicesIds = payload;
        },
        chooseObjectPayments(state, { payload }) {
            state.activePaymentsIds = payload;
        },
        setObjectActiveTab(state, { payload }) {
            state.activeTabId = payload;
        },
        objectOpenNextTab(state) {
            state.activeTabId += 1;
        },
        objectOpenPrevTab(state) {
            state.activeTabId -= 1;
        },
        resetObjectState: () => initialState,
        setIsUnfinishedObjectRegistration(state, { payload }) {
            state.isUnfinishedObjectRegistration = payload;
        },
        setStartCreationTime(state, { payload }) {
            state.startCreationTime = payload;
        },
    },
});
export const { setActiveObjectId, setIsEditingExistingObjectBeforeVerification, setObjectEditedFields, setObjectStatus, chooseObjectLocation, chooseObjectTypeId, setObjectName, setObjectAddress, setObjectDescription, setObjectPhotos, updateObjectPhoto, deleteObjectPhoto, setObjectTime, chooseObjectServices, chooseObjectPayments, setObjectActiveTab, objectOpenNextTab, objectOpenPrevTab, resetObjectState, setIsUnfinishedObjectRegistration, setStartCreationTime, } = objectSlice.actions;
export const objectReducer = objectSlice.reducer;
export const selectActiveObjectId = (state) => state.object.activeObjectId;
export const selectIsEditingExistingObjectBeforeVerification = (state) => state.object.isEditingExistingObjectBeforeVerification;
export const selectObjectEditedFields = (state) => state.object.editedFields;
export const selectObjectStatus = (state) => state.object.status;
export const selectObjectActiveLocationId = (state) => state.object.activeLocationId;
export const selectObjectTypeId = (state) => state.object.typeId;
export const selectObjectName = (state) => state.object.objectName;
export const selectObjectAddress = (state) => state.object.address;
export const selectObjectDescription = (state) => state.object.description;
export const selectObjectPhotos = (state) => state.object.photos;
export const selectObjectTime = (state) => state.object.time;
export const selectObjectActiveServicesIds = (state) => state.object.activeServicesIds;
export const selectObjectActivePaymentsIds = (state) => state.object.activePaymentsIds;
export const selectObjectActiveTabId = (state) => state.object.activeTabId;
export const selectIsUnfinishedObjectRegistration = (state) => state.object.isUnfinishedObjectRegistration;
export const selectStartCreationTime = (state) => state.object.startCreationTime;
export const { selectAll: selectAllObjectPhotos, selectIds: selectObjectPhotosIds, selectById: selectObjectPhotoById, } = objectPhotosAdapter.getSelectors(state => { var _a; return (_a = selectObjectPhotos(state)) !== null && _a !== void 0 ? _a : objectPhotosAdapterInitState; });
export const selectObjectPhotosFilesOrUrls = createSelector(selectAllObjectPhotos, objectPhotosArray => objectPhotosArray.reduce((acc, photo) => {
    const photoUrl = 'localUrl' in photo && photo.localUrl ? photo.localUrl : 'url' in photo && photo.url;
    return photoUrl ? [...acc, photoUrl] : acc;
}, []));
export const selectObjectPhotosLocalUrls = createSelector([selectAllObjectPhotos], objectPhotosArray => objectPhotosArray.reduce((acc, photo, i) => [
    ...acc,
    ...('localUrl' in photo && photo.localUrl ? [{ localUrl: photo.localUrl, position: `${i}` }] : []),
], []));
