import { catalogHoteilerPageReducer } from './../features/hoteilerCatalog/hoteilerCatalogSlice';
import { combineReducers } from 'redux';
// redux-persist
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { authReducer } from './authentication/authentication.reducer';
import { bedRoomReducer } from './bedRooms/bedRooms.reducer';
import { searchLocationReducer } from './LocationSearch/location.reducer';
import { bookingOptionsReducer } from './BookingOptions/bookingOptions.reducer';
// import { hotelierOrderListSlice } from './hotelier`sOrderList/hotelier`sOrderList.reducer';
import { initialLocationReducer } from './initialLocation/initialLocation.reducer';
import { themeReducer } from './themes/themes.reducer';
import hotelsSlice from 'redux/hotelierCabinet/getHotels/reducers';
import hotelItemSlice from 'redux/hotelierCabinet/getHotelsById/reducers';
// RTK Query
import { api } from 'features/api';
import { objectReducer } from 'features/objects';
import { searchReducer } from 'features/search';
import { bookingHotelReducer } from 'features/bookingOptionHotel';
import { bookingHostelReducer } from 'features/bookingOptionHostel';
import { modalSliceReducer, userSettingsReducer } from 'features/common';
import { filterNameReducer } from 'features/filter/FilterNameReducer';
// const rootPersistConfig = {
//   key: 'root',
//   storage,
//   whitelist: ['header'],
// };
const userSettingsPersistConfig = {
    key: 'userSettings',
    storage,
    whitelist: ['activeLanguageId', 'activeCurrencyId'],
};
const persistedUserSettingsReducer = persistReducer(userSettingsPersistConfig, userSettingsReducer);
export const rootReducer = combineReducers({
    object: objectReducer,
    bookingHotel: bookingHotelReducer,
    bookingHostel: bookingHostelReducer,
    auth: authReducer,
    bedRoomReducer,
    searchLocationReducer,
    search: searchReducer,
    bookingOptions: bookingOptionsReducer,
    modal: modalSliceReducer,
    userSettings: persistedUserSettingsReducer,
    [api.reducerPath]: api.reducer,
    filterName: filterNameReducer,
    catalogHoteilerPage: catalogHoteilerPageReducer,
    // hotelierOrderList: hotelierOrderListSlice.reducer,
    initialLocation: initialLocationReducer,
    getHotels: hotelsSlice,
    getHotelsByIdHotelier: hotelItemSlice,
    theme: themeReducer,
});
// export const persistedRootReducer = persistReducer(rootPersistConfig, rootReducer);
