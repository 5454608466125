// import { useAppSelector } from 'features';
const userTypesArr = ['hotelier', 'agent', 'user'];
export const allowedUserTypesArr = Object.freeze(userTypesArr);
export const TEST_USER = {
    _id: 'user0000000000000000',
    userType: 'user',
    name: 'Игорь',
    surname: 'Петров',
    email: 'user@mail.com',
    photo: '',
    role: '',
};
export const TEST_AGENT = {
    _id: 'agent0000000000000001',
    userType: 'agent',
    name: 'Agent',
    surname: 'Smith',
    email: 'agent@mail.com',
    photo: '',
    role: '',
};
export const TEST_HOTELIER = {
    _id: 'hotelier0000000000000002',
    userType: 'hotelier',
    name: 'John',
    surname: 'Snow',
    email: 'hotelier@mail.com',
    photo: '',
    role: '',
};
//let user = JSON.parse(localStorage.getItem('user') || '{}');
//const user = useAppSelector(state => state);
const initUser = { user: null };
// const initUser = user ? { ...user } : { user: null };
//const initUser = user ? user.role : { user: null };
export const authReducerInitialState = initUser;
export const authContextInitialState = Object.assign(Object.assign({}, initUser), { isHotelier: false, isAgent: false, isUser: false, isNobody: true });
const authActions = ['chooseUserType', 'setUserEmail', 'logIn', 'logOut'];
export const chooseUserType = (payload) => ({ type: authActions[0], payload });
export const setUserEmail = (payload) => ({ type: authActions[1], payload });
export const logIn = (payload) => ({ type: authActions[2], payload });
export const logOut = () => ({ type: authActions[3] });
export const authReducer = (state, { type, payload }) => {
    switch (type) {
        case authActions[0]:
            return !state.user ? state : Object.assign(Object.assign({}, state), { user: Object.assign(Object.assign({}, state.user), { userType: payload }) });
        case authActions[1]:
            return !state.user ? state : Object.assign(Object.assign({}, state), { user: Object.assign(Object.assign({}, state.user), { email: payload }) });
        case authActions[2]:
            return Object.assign(Object.assign({}, state), { user: payload });
        case authActions[3]:
            return Object.assign(Object.assign({}, state), { user: null });
        default:
            return state;
    }
};
