import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCloseOnResize } from 'hooks/UI';
import { DrawerBody } from '../DrawerBody';
import { DrawerFooter } from '../DrawerFooter';
import { DrawerTopRow } from '../DrawerTopRow';
import scss from './DrawerContent.module.scss';
export const DrawerContent = ({ closeDrawer }) => {
    useCloseOnResize(closeDrawer);
    return (_jsx("div", Object.assign({ className: scss.drawerContentWide }, { children: _jsxs("div", Object.assign({ className: scss.drawerContentSlim }, { children: [_jsx(DrawerTopRow, { closeDrawer: closeDrawer }), _jsxs("div", Object.assign({ className: scss.drawerBodyAndFooter }, { children: [_jsx(DrawerBody, { closeDrawer: closeDrawer }), _jsx(DrawerFooter, {})] }))] })) })));
};
