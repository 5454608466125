import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import s from './Modal.module.scss';
import { useSelector } from 'react-redux';
import { selectActiveCurrencyCode } from 'features/common';
export const PriceBlock = ({ disabledElement, errorText, labelClassName, btnText, valueTextClassName, price, handleChangeInput, size }) => {
    const inputClassNme = s.modal__value_price + ' ' + (size === 'small' ? s.modal__value_price_small : '');
    const { t } = useTranslation('hotelierCabinet');
    const currentCurrencyCode = useSelector(selectActiveCurrencyCode);
    return (_jsxs("div", Object.assign({ className: s.price__block }, { children: [_jsxs("label", Object.assign({ className: labelClassName }, { children: [_jsx("span", { children: btnText }), _jsx("input", { disabled: disabledElement, type: "text", value: price, name: "price", className: inputClassNme, onChange: handleChangeInput, maxLength: 6 }), _jsx("span", Object.assign({ className: s.modal__value_price_text }, { children: t('modalPerDay') }))] })), _jsx("span", Object.assign({ className: valueTextClassName }, { children: currentCurrencyCode }))] })));
};
