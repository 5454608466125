import { AUTH_CODE, AUTH_PAGE, AUTH_PHONE, SHOW_TEXT, SET_USER_DATA, LOG_OUT } from './authentication.types';
export const setPage = item => ({
    type: AUTH_PAGE,
    payload: item,
});
export const setCode = item => ({
    type: AUTH_CODE,
    payload: item,
});
export const setAuthPhone = item => ({
    type: AUTH_PHONE,
    payload: item,
});
export const setShowText = item => ({
    type: SHOW_TEXT,
    payload: item,
});
export const setUserData = item => ({
    type: SET_USER_DATA,
    payload: item,
});
export const logOut = () => {
    return {
        type: LOG_OUT,
    };
};
