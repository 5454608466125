import { TPicker } from '../pickerTypes';
export const pickerReducer = (state, action) => {
    switch (action.type) {
        case TPicker.open:
            return Object.assign(Object.assign({}, state), { isDropdownOpen: true });
        case TPicker.close:
            return Object.assign(Object.assign({}, state), { isDropdownOpen: false });
        case TPicker.toggle:
            return Object.assign(Object.assign({}, state), { isDropdownOpen: !state.isDropdownOpen });
        case TPicker.onOptionClick:
            return Object.assign(Object.assign({}, state), { activeOptionId: action.payload });
        case TPicker.setPickerTheme:
            return Object.assign(Object.assign({}, state), { theme: action.payload });
        default:
            return state;
    }
};
