import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { testUsersPosts } from '../testArrPostComments';
import { UserFeedbackItems } from './userFeedbackItems';
const UsersFeedback = () => {
    const [arrUsersFeedback] = useState(testUsersPosts);
    // const onClickLike = id => {
    //   setArrUsersFeedback(prevState =>
    //     prevState.map(item =>
    //       item.id === id
    //         ? { ...item, like: !item.like, likeCount: !item.like ? item.likeCount++ : item.likeCount-- }
    //         : item
    //     )
    //   );
    // };
    return (_jsx("ul", { children: arrUsersFeedback.map((obj, index) => (_jsx(UserFeedbackItems, Object.assign({}, obj), obj.id))) }));
};
export default UsersFeedback;
