import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import scss from './Footer.module.scss';
export const AllRightsReserved = ({ mode }) => {
    const { t } = useTranslation();
    const textClassName = mode === 'dark' ? scss.darkAllRightsReserved : scss.lightAllRightsReserved;
    const currentYear = new Date().getFullYear();
    const text = t('allRightsReserved', { currentYear });
    return _jsx("p", Object.assign({ className: textClassName }, { children: text }));
};
