import { AUTH_CODE, AUTH_PAGE, AUTH_PHONE, SHOW_TEXT, SET_USER_DATA, LOG_OUT } from './authentication.types';
let user = JSON.parse(localStorage.getItem('user'));
const initialState = user
    ? Object.assign(Object.assign({}, user), { loggedIn: true }) : {
    role: null,
    token: null,
    id: null,
    firstName: null,
    lastName: null,
    email: null,
    language: null,
    loggedIn: false,
};
export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTH_PAGE:
            return Object.assign(Object.assign({}, state), { page: +action.payload });
        case AUTH_CODE:
            return Object.assign(Object.assign({}, state), { code: action.payload });
        case AUTH_PHONE:
            return Object.assign(Object.assign({}, state), { phone: action.payload });
        case SHOW_TEXT:
            return Object.assign(Object.assign({}, state), { isShowText: action.payload });
        case SET_USER_DATA:
            return Object.assign(Object.assign(Object.assign({}, state), action.payload), { loggedIn: true });
        case LOG_OUT:
            return Object.assign(Object.assign({}, state), { role: null, token: null, id: null, firstName: null, lastName: null, email: null, language: null, loggedIn: false });
        default:
            return state;
    }
};
export const authPage = state => state.auth.page;
export const authCode = state => state.auth.code;
export const authPhone = state => state.auth.phone;
export const userCardShowText = state => state.auth.isShowText;
export const authRole = state => state.auth.role;
// export const setUserData = state => state.auth.user;
