var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import axios from 'components/LoginSingUp/axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { CABINET_HOTELIER, HOTELIER_ACCOUNT_SETTINGS, HOTELIER_FINANCES, HOTELIER_FEEDBACK, HOTELIER_SMS_SETTINGS, HOTELIER_SALES_HISTORY, _LOGOUT_HOTELIER_URL, MAIN_HOTELIER_EN, } from 'navigation/CONSTANTS';
// import { useAuthContext } from 'context/AuthContext';
import { makeEntity } from 'utils';
import { useSelector, useDispatch } from 'react-redux';
import { logOut as logOutRedux } from 'redux/authentication/authentication.actions';
import { IconHeader } from '../../Components/IconHeader';
import spinner from 'components/LoginSingUp/spinner/circle752.svg';
import scss from './HotelierSettingsList.module.scss';
import { SubheadersThemeSwitch } from '../SubheadersTheme/SubheadersThemeSwitch';
import { useAppSelector } from 'features';
const hotelierLinks = [
    { to: CABINET_HOTELIER, className: scss.gear, iconId: 'computer', i18key: 'linkHotelierCabinet' },
    { to: HOTELIER_ACCOUNT_SETTINGS, className: scss.gear, iconId: 'gear', i18key: 'linkHotelierAccountSettings' },
    { to: HOTELIER_FINANCES, className: scss.eyeTriangle, iconId: 'eye-triangle', i18key: 'linkHotelierFinances' },
    { to: HOTELIER_FEEDBACK, className: scss.star, iconId: 'star', i18key: 'linkHotelierRatingAndFeedbacks' },
    {
        to: HOTELIER_SMS_SETTINGS,
        className: scss.sliders,
        iconId: 'slidersH',
        i18key: 'linkHotelierNotificationSettings',
    },
    { to: HOTELIER_SALES_HISTORY, className: scss.karma, iconId: 'karma', i18key: 'linkHotelierSalesHistory' },
];
const hotelierSettingsList = Object.freeze(makeEntity(hotelierLinks));
const HotelierLink = ({ to, text, iconId, iconClassName, mode }) => (_jsxs(Link, Object.assign({ to: to, className: `${scss.hotelierLink} ${mode}` }, { children: [_jsx("div", Object.assign({ className: `${scss.hotelierLinkIconWrapper} ${mode}` }, { children: _jsx(IconHeader, { iconId: iconId, className: `${scss.hotelierMobileIcon} ${iconClassName}${mode}` }) })), text] })));
const HotelierLogOutButton = ({ mode }) => {
    // const [, dispatch] = useAuthContext();
    const dispatchRedux = useDispatch();
    const state = useSelector((state) => state);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const navigation = useNavigate();
    const navigateToMainHotelier = () => navigation(MAIN_HOTELIER_EN);
    const onClickLogOut = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setIsLoading(true);
            const response = yield axios.get(_LOGOUT_HOTELIER_URL, {
                headers: {
                    Authorization: `Bearer ${state.auth.token}`,
                },
            });
            if (response.status === 200) {
                dispatchRedux(logOutRedux());
                localStorage.removeItem('user');
                navigateToMainHotelier();
            }
        }
        catch (err) {
            console.log(err, 'err log');
            console.error(err, 'err');
        }
        finally {
            setIsLoading(false);
        }
    });
    const LoadingIndicator = () => (_jsx("div", Object.assign({ className: scss.loading }, { children: _jsx("img", { src: spinner, alt: "Loading..." }) })));
    return (_jsxs("div", Object.assign({ id: "popup", className: scss.popup }, { children: [isLoading && LoadingIndicator(), _jsx("li", Object.assign({ className: `${scss.hotelierSettingsItem} ${mode}` }, { children: _jsxs("button", Object.assign({ className: `${scss.hotelierLogOutButton} ${mode}`, onClick: onClickLogOut }, { children: [_jsx("div", Object.assign({ className: `${scss.hotelierLinkIconWrapper} ${mode}` }, { children: _jsx(IconHeader, { iconId: "exit", className: `${scss.hotelierMobileIcon} ${scss.exit} ${mode}` }) })), _jsx("span", { children: t('linkHotelierExit') })] })) }))] })));
};
export const HotelierSettingsList = () => {
    const { t } = useTranslation();
    const [settings] = useState(hotelierSettingsList);
    const theme = useAppSelector(state => state.theme.selectedTheme);
    const dark = theme === 'dark' ? scss.dark : '';
    return (
    // <ul className={`${scss.hotelierSettingsList} ${dark}`}>
    _jsxs("ul", Object.assign({ className: `${scss.hotelierSettingsList} ${dark}` }, { children: [hotelierSettingsList.ids.map(id => {
                const item = settings.entities[id];
                if (!item)
                    return null;
                const { to, className, iconId, i18key } = item;
                const text = t(i18key);
                return (_jsx("li", Object.assign({ className: `${scss.hotelierSettingsItem} ${dark}` }, { children: _jsx(HotelierLink, { to: to, iconClassName: className, iconId: iconId, text: text, mode: dark }) }), id));
            }), _jsx(SubheadersThemeSwitch, {}), _jsx(HotelierLogOutButton, { mode: dark })] })));
};
