import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import scss from './RateBookingVariant.module.scss';
import iconStar from 'assets/images/sidemenu/rating/Star 13.svg';
import iconCarma from 'assets/icons/proposeCard/clock_karma.svg';
import { CloseFeedbackModalButton } from 'components/BookingFeedBack/sendFeedback';
import { useTranslation } from 'react-i18next';
const initArray = [
    { label: 'staff', rating: 0 },
    { label: 'location', rating: 0 },
    { label: 'cleanliness', rating: 0 },
    { label: 'amenities', rating: 0 },
];
const initState = initArray.reduce(({ ids, entities }, item, i) => ({ ids: [...ids, i], entities: Object.assign(Object.assign({}, entities), { [i]: Object.assign({ id: i }, item) }) }), { ids: [], entities: {} });
const RatingItem = ({ id, label, rating, chooseRating }) => {
    const { t } = useTranslation();
    const [ratingButtons] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
    const category = t(`${label}`);
    return (_jsxs("li", Object.assign({ className: scss.RatingRow }, { children: [_jsxs("span", Object.assign({ className: scss.RatingCategory }, { children: [category, ":"] })), _jsx("ul", Object.assign({ className: scss.RatingButtonsList }, { children: ratingButtons.map(number => {
                    const inputId = `rate${label[0].toUpperCase() + label.slice(1)}Input${number}`;
                    const isActive = number === rating;
                    const inputClassName = `visually-hidden ${scss.BookingVariantRatingInput}`;
                    const labelClassName = `${scss.BookingVariantRatingLabel} ${isActive ? scss.BookingVariantRatingLabelActive : ''}`;
                    return (_jsxs("li", Object.assign({ className: scss.ItemClassName }, { children: [_jsx("input", { id: inputId, className: inputClassName, type: "radio", name: label, value: number, onChange: () => chooseRating(id, number), defaultChecked: isActive, required: true }), _jsx("label", Object.assign({ htmlFor: inputId, className: labelClassName }, { children: number }))] }), number));
                }) }))] })));
};
export const RateBookingVariantContent = ({ closeModal }) => {
    const { t } = useTranslation();
    const [ratingEntity, setRatingEntity] = useState(initState);
    const chooseRating = useCallback((id, rating) => {
        const newRatingEntity = Object.assign(Object.assign({}, ratingEntity), { entities: Object.assign(Object.assign({}, ratingEntity.entities), { [id]: Object.assign(Object.assign({}, ratingEntity.entities[id]), { rating }) }) });
        setRatingEntity(newRatingEntity);
    }, [ratingEntity]);
    const onRatingSubmit = useCallback((e) => {
        e.preventDefault();
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ratingEntity]);
    const isValid = Object.values(ratingEntity.entities).every(({ rating }) => Boolean(rating));
    const submitButtonClassName = `${scss.SubmitRatingButton} ${isValid ? scss.SubmitRatingButtonValid : ''}`;
    const tooltipClassName = `${scss.SubmitRatingButtonTooltip} ${isValid ? scss.SubmitRatingButtonTooltipValid : ''}`;
    return (_jsxs("div", Object.assign({ className: scss.ContentWrapper }, { children: [_jsx("div", Object.assign({ className: scss.CloseButtonWrapper }, { children: _jsx(CloseFeedbackModalButton, { closeModal: closeModal }) })), _jsxs("form", Object.assign({ className: scss.RateBookingVariantForm, onSubmit: onRatingSubmit }, { children: [_jsxs("h2", Object.assign({ className: scss.RateBookingVariantModalTitle }, { children: [_jsx("img", { className: scss.StarIcon, src: iconStar, alt: "star" }), _jsx("span", { children: t('rateYourStay') })] })), _jsx("ul", Object.assign({ className: scss.RateBookingVariantModalRatingRows }, { children: ratingEntity.ids.map(ratingItemId => {
                            const { label, rating } = ratingEntity.entities[ratingItemId];
                            return (_jsx(RatingItem, { id: ratingItemId, label: label, rating: rating, chooseRating: chooseRating }, ratingItemId));
                        }) })), _jsxs("div", Object.assign({ className: scss.SubmitButtonWrapper }, { children: [_jsx("button", Object.assign({ className: submitButtonClassName, type: "submit" }, { children: t('submitRating') })), _jsxs("div", Object.assign({ className: tooltipClassName }, { children: [_jsx("span", Object.assign({ className: scss.BookingKarmaTooltipNumber }, { children: "+\u00A01" })), _jsx("img", { className: scss.BookingKarmaTooltipCarmaIcon, src: iconCarma, alt: "carma clock" }), _jsxs("span", Object.assign({ className: scss.BookingKarmaTooltipText }, { children: ["Booking ", _jsx("br", {}), "Karma"] }))] }))] }))] }))] })));
};
