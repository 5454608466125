import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { SendRequestModal } from './SendReqestModal/SendRequestModal';
import { Modal } from '../../../../../components/QueriesSuggestionsUser/UserBookingList/Modal/Modal';
import close from '../../../../../assets/icons/close.svg';
import { useModal } from 'hooks/UI';
const TestModalPage = () => {
    const [isModalOpen, showModal, hideModal] = useModal();
    const handleOpenModal = () => {
        showModal();
    };
    return (_jsxs("div", { children: [_jsx("button", Object.assign({ onClick: handleOpenModal }, { children: "Open Modal" })), isModalOpen && (_jsx(Modal, Object.assign({ toggleModal: isModalOpen, closeIcon: close }, { children: _jsx("div", Object.assign({ className: "karma-user" }, { children: _jsx(SendRequestModal, { onClose: hideModal, id: 1 }) })) })))] }));
};
export default TestModalPage;
