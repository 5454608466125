import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import active from 'assets/icons/Header/SubheaderHotelier/activeSub.svg';
import noActive from 'assets/icons/Header/SubheaderHotelier/noActiveSub.svg';
import s from './SubNavigation.module.scss';
export const SubNavigation = ({ toggle, activeSection, numberOfPropose, haveActiveBooking }) => {
    const { t } = useTranslation('userCabinet');
    numberOfPropose = 1; //видалити, тестовий код
    return (_jsxs("div", Object.assign({ className: s.tab__section }, { children: [_jsxs("div", Object.assign({ className: `${s.tab} ${activeSection.propose && s.tab_active} ${numberOfPropose && numberOfPropose > 0 && s.tab_accent}`, onClick: () => toggle({ propose: true }) }, { children: [_jsxs("div", Object.assign({ className: s.proposeContainer }, { children: [_jsx("span", Object.assign({ className: `${s.tab__text} ${s.textWithDot}` }, { children: t('userRequests') })), activeSection.propose ? _jsx("img", { src: active, alt: "active tab" }) : _jsx("img", { src: noActive, alt: "no active tab" })] })), _jsxs("div", Object.assign({ className: s.offersContainer }, { children: [_jsxs("span", Object.assign({ className: `${s.tab__text} ` }, { children: [" ", t('userOffers')] })), _jsx("span", Object.assign({ style: { color: activeSection.propose ? '#006bbe' : '#6eb6ec' }, className: s.tab__count }, { children: 223 }))] }))] })), _jsx("div", Object.assign({ className: `${s.tab} ${activeSection.userBookings && s.tab_active} ${haveActiveBooking && s.tab_accent}`, onClick: () => toggle({ userBookings: true }) }, { children: _jsxs("div", Object.assign({ className: s.userBookingContainer }, { children: [_jsx("span", Object.assign({ className: `${s.tab__text} ${s.userBooking} ` }, { children: t('userBooking') })), haveActiveBooking && (activeSection.userBookings ? (_jsx("img", { src: active, alt: "active tab" })) : (_jsx("img", { src: noActive, alt: "no active tab" })))] })) }))] })));
};
