import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import s from './ModalTags.module.scss';
export const tagsOptions = [
    'objectService_01',
    'objectService_02',
    'objectService_03',
    'objectService_04',
    'objectService_05',
    'objectService_06',
    'objectService_07',
    'objectService_08',
    'objectService_09',
    'objectService_10',
    'objectService_11',
    'objectService_12',
    'objectService_13',
    'objectService_14',
    'objectService_15',
    'objectService_16',
    'objectService_17',
    'objectService_18',
];
export const ModalTags = ({ tags, color }) => {
    const { t } = useTranslation('userCard');
    return (_jsx("div", Object.assign({ className: s.container }, { children: tags.map((tag, id) => (_jsx("div", Object.assign({ className: s.description, style: { color } }, { children: t(tag) }), id))) })));
};
