import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import s from './SendRequestModal.module.scss';
const feedbacks = [
// { name: 'Іван', text: 'Дуже задоволений сервісом!' },
// { name: 'Марія', text: 'Все чудово, рекомендую!' },
];
export const Feedback = () => {
    return (_jsxs("div", Object.assign({ className: s.FeedbackBlock }, { children: [_jsx("h2", Object.assign({ className: s.FeedbackTitle }, { children: "\u041E\u0442\u0437\u044B\u0432\u044B" })), feedbacks.length === 0 ? (_jsx("div", Object.assign({ className: s.FeedbackList }, { children: _jsx("p", Object.assign({ className: s.FeedbackItem }, { children: "\u041E\u0442\u0437\u044B\u0432\u043E\u0432 \u0435\u0449\u0435 \u043D\u0435\u0442" })) }))) : (_jsx("ul", Object.assign({ className: s.FeedbackList }, { children: feedbacks.map((feedback, index) => (_jsxs("li", Object.assign({ className: s.FeedbackItem }, { children: [_jsxs("p", { children: ["\u0418\u043C\u044F: ", feedback.name] }), _jsxs("p", { children: ["\u0422\u0435\u043A\u0441\u0442: ", feedback.text] })] }), index))) })))] })));
};
