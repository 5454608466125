import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TERMS_CONDITIONS_USERS, PRIVACY_POLICY_USERS, TERMS_CONDITIONS_HOTELIERS, PRIVACY_POLICY_HOTELIERS, TEAM, MAIN_AGENT, 
// YOUR_PRICE_BOOKING_LLC_ESTONIA,
OUR_MISSION, ABOUT_COMPANY, WANDERE_BLOG, FACEBOOK_KYIV, FACEBOOK_ODESA, FACEBOOK_WARSAW, FACEBOOK_NYC, INSTAGRAM_PB, REDDIT_YPB, LINKEDIN_YPB, YOUTUBE_ALL_OBJECTS_YPB, YOUTUBE_OFFICIAL_YPB, SUPPORT, COOKIES_USAGE, X_PB, } from 'navigation/CONSTANTS';
import scss from './Footer.module.scss';
import { makeEntity } from 'utils';
const linksArray = [
    { href: TERMS_CONDITIONS_USERS, i18nKey: 'linkTermsUsers' },
    { href: PRIVACY_POLICY_USERS, i18nKey: 'linkPrivacyPolicyUsers' },
    { href: TERMS_CONDITIONS_HOTELIERS, i18nKey: 'linkTermsHoteliers' },
    { href: PRIVACY_POLICY_HOTELIERS, i18nKey: 'linkPrivacyPolicyHoteliers' },
    { href: COOKIES_USAGE, i18nKey: 'Cookies' },
    { href: TEAM, i18nKey: 'linkTeam' },
    { href: MAIN_AGENT, i18nKey: 'linkForAgents' },
    { href: ABOUT_COMPANY, i18nKey: 'linkYPBookingEstonia' },
    { href: OUR_MISSION, i18nKey: 'linkOurMission' },
    { href: SUPPORT, i18nKey: 'linkSupportInTelegram' },
    { href: WANDERE_BLOG, i18nKey: 'linkTravelQBlog' },
    { href: FACEBOOK_KYIV, i18nKey: 'linkFacebookKyiv' },
    { href: FACEBOOK_ODESA, i18nKey: 'linkFacebookOdesa' },
    { href: FACEBOOK_WARSAW, i18nKey: 'linkFacebookWarsaw' },
    { href: FACEBOOK_NYC, i18nKey: 'linkFacebookNYC' },
    { href: INSTAGRAM_PB, i18nKey: 'linkInstagramPB' },
    { href: REDDIT_YPB, i18nKey: 'linkRedditYPB' },
    { href: X_PB, i18nKey: 'linkTwitterPB' },
    { href: LINKEDIN_YPB, i18nKey: 'linkLinkedInYPB' },
    { href: YOUTUBE_ALL_OBJECTS_YPB, i18nKey: 'linkYouTubeAllObjectsYPB' },
    { href: YOUTUBE_OFFICIAL_YPB, i18nKey: 'linkYouTubeOfficialYPB' },
];
const linksInitialState = Object.freeze(makeEntity(linksArray));
const FooterLink = ({ href, text, className }) => {
    const target = href.includes('http') ? '_blank' : undefined;
    const rel = target ? 'noopener noreferrer' : undefined;
    return (_jsx("li", Object.assign({ className: scss.footerItem }, { children: target ? (_jsx("a", Object.assign({ href: href, target: target, className: className, rel: rel }, { children: text }))) : (_jsx(Link, Object.assign({ to: href, className: className }, { children: text }))) })));
};
export const FooterLinks = ({ mode }) => {
    const { t } = useTranslation();
    const [footerLinks] = useState(linksInitialState);
    const linkClassName = mode === 'dark' ? scss.footerLinkDark : scss.footerLink;
    return (_jsx("ul", Object.assign({ className: mode === 'dark' ? scss.footerListDark : scss.footerList }, { children: footerLinks.ids.map(linkId => {
            const link = footerLinks.entities[linkId];
            if (!link)
                return null;
            const { i18nKey, href } = link;
            const text = t(i18nKey);
            return _jsx(FooterLink, { className: linkClassName, id: linkId, href: href, text: text }, linkId);
        }) })));
};
