import { api, endpoints, tag, tagList } from 'features/api';
import { saveManyObjects, saveOneObject } from './searchObjectsAdapter';
const searchObjectsApi = api.injectEndpoints({
    endpoints: build => ({
        getSearchObjects: build.query({
            query: () => endpoints.objects,
            transformResponse: (result) => saveManyObjects(result),
            providesTags: result => tagList(result, 'Object'),
        }),
        getSearchObject: build.query({
            query: id => `${endpoints.objects}/${id}`,
            transformResponse: (result) => saveOneObject(result),
            providesTags: (_, __, id) => tag(id, 'Object'),
        }),
    }),
});
export const { useLazyGetSearchObjectsQuery } = searchObjectsApi;
export const selectSearchObjectsResult = searchObjectsApi.endpoints.getSearchObjects.select();
