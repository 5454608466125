import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMedia } from 'hooks/UI';
import { DownloadAppLink } from '../DownloadAppLink';
import { LinkForHoteliers } from '../LinkForHoteliers';
import { LoginButton } from '../LoginButton';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLinkToMainHotelier } from 'hooks/routes';
import { MAIN } from 'navigation/CONSTANTS';
import scss from './HeaderNobody.module.scss';
import { HeaderContentThemeSwitch } from '../HeaderContent/HeaderContentThemeSwitch';
export const HeaderNobody = () => {
    const { t } = useTranslation();
    const toHotelier = useLinkToMainHotelier();
    const { pathname } = useLocation();
    const { isMobile, isDesktop } = useMedia();
    let linkFor = _jsx(LinkForHoteliers, { linkTo: toHotelier, textTo: t('forHoteliers') });
    if (pathname === '/dla-hotelarzy' ||
        pathname === '/dlya-otelerov' ||
        pathname === '/for-hoteliers' ||
        pathname === '/dlya-gotelyeriv') {
        linkFor = _jsx(LinkForHoteliers, { linkTo: MAIN, textTo: t('forUsers') });
    }
    return (_jsx("div", Object.assign({ className: scss.headerNobody }, { children: _jsxs("div", Object.assign({ className: scss.headerNobodyContent }, { children: [isMobile && _jsx(DownloadAppLink, {}), isDesktop && linkFor, !isMobile && _jsx(HeaderContentThemeSwitch, {}), _jsx(LoginButton, {})] })) })));
};
