import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { 
// hotelier
KVARTIRY_CATALOG, APART_HOTEL_CATALOG, DOM_CATALOG, GOSTINICY_CATALOG, HOTEL_CATALOG, HOSTEL_CATALOG, KOMNATA_CATALOG, KOMNATA_DOM_CATALOG, GUEST_HOUSE_CATALOG, CAPSULE_HOTEL_CATALOG, MOTEL_CATALOG, HOTELIER_CATALOG, } from 'navigation/CONSTANTS';
import { makeEntity } from 'utils';
import { useAppSelector } from 'features';
import { selectActiveLang } from 'features/common';
import { selectActiveSearchId, selectSearchTerm } from 'features/search';
import { useGetAutocompleteQuery } from 'features/autocomplete';
import { IconHeader } from 'components/Common/Header/Components/IconHeader';
import ModalSelectLocation from 'components/ModalSelectLocation/ModalSelectLocation';
import s from './RentList.module.scss';
import { upperCaseLangCodes } from 'components/AddObject/ObjectAddLocation';
const rentLinksArr = [
    { to: KVARTIRY_CATALOG, i18key: 'rentAFlatDaily' },
    { to: APART_HOTEL_CATALOG, i18key: 'rentAnApartmentDaily' },
    { to: DOM_CATALOG, i18key: 'rentAHouseDaily' },
    { to: GOSTINICY_CATALOG, i18key: 'rentAHotelRoomInHotelOfAllTypes' },
    { to: HOTEL_CATALOG, i18key: 'rentAHotelRoomDaily' },
    { to: HOSTEL_CATALOG, i18key: 'rentAPlaceInAHostel' },
    { to: KOMNATA_CATALOG, i18key: 'rentARoomInAFlatDaily' },
    { to: KOMNATA_DOM_CATALOG, i18key: 'rentARoomInAHouseDaily' },
    { to: GUEST_HOUSE_CATALOG, i18key: 'rentARoomInGuestHouse' },
    { to: CAPSULE_HOTEL_CATALOG, i18key: 'rentARoomInCapsuleHotel' },
    { to: MOTEL_CATALOG, i18key: 'rentAMotelRoom' },
    { to: HOTELIER_CATALOG, i18key: 'rentOutHousingDaily' },
];
export const rentLinks = Object.freeze(makeEntity(rentLinksArr, 'to'));
const useRentList = ({ rentLinks, mode, closeDrawer }) => {
    const { pathname } = useLocation();
    const onClickCloseDrawer = useCallback(() => {
        if (!closeDrawer)
            return;
        closeDrawer();
    }, [closeDrawer]);
    const [links] = useState(rentLinks);
    const dark = mode === 'dark' ? s.dark : '';
    const listClassName = `${s.rentList} ${dark}`;
    const itemClassName = `${s.rentOptionItem} ${dark}`;
    const iconClassName = `${s.activeRentOptionIcon} ${dark}`;
    return useMemo(() => ({
        links,
        onClickCloseDrawer,
        pathname,
        listClassName,
        itemClassName,
        iconClassName,
        dark,
    }), [links, onClickCloseDrawer, pathname, listClassName, itemClassName, iconClassName, dark]);
};
export const RentListMainHotelier = ({ mode, closeDrawer }) => {
    const { t } = useTranslation('mainHotelier');
    const [isOpen, setIsOpen] = useState(false);
    const [path, setPath] = useState('');
    const { links, pathname, listClassName, itemClassName, iconClassName, dark } = useRentList({
        rentLinks,
        mode,
        closeDrawer,
    });
    const handleOpenModal = event => {
        const { target } = event;
        if (target instanceof HTMLParagraphElement || target instanceof HTMLLIElement) {
            const id = target.dataset.id;
            if (id) {
                setPath(id);
                const newUrl = `${pathname}${id}`;
                window.history.replaceState(null, '', newUrl);
            }
        }
        setIsOpen(true);
    };
    const handleCloseModal = () => {
        setIsOpen(false);
        window.history.replaceState(null, '', pathname);
    };
    const itemClass = dark ? `${s.active} ${dark}` : `${s.rentLink}`;
    return (_jsxs("div", { children: [_jsx("ul", Object.assign({ className: listClassName }, { children: links.ids.map((id, index) => {
                    const item = links.entities[id];
                    if (!item)
                        return null;
                    const { to, i18key } = item;
                    let text = '';
                    text = t(i18key);
                    return (_jsxs("li", Object.assign({ className: itemClassName, "data-id": id }, { children: [path === to && _jsx(IconHeader, { iconId: "two-arrows-up-overlap", className: iconClassName }), _jsx("p", Object.assign({ onClick: handleOpenModal, className: itemClass, "data-id": id }, { children: text }))] }), id));
                }) })), isOpen && _jsx(ModalSelectLocation, { onClose: handleCloseModal })] }));
};
export const RentListHotelierCatalog = ({ mode, closeDrawer }) => {
    const { t } = useTranslation('mainHotelier');
    const { links, pathname, listClassName, itemClassName, iconClassName, dark } = useRentList({
        rentLinks,
        mode,
        closeDrawer,
    });
    const activeSearchId = useAppSelector(selectActiveSearchId);
    const search = useAppSelector(selectSearchTerm);
    const lang = useAppSelector(selectActiveLang);
    // TODO: replace api from `/api/autocomplete` to `api/search`
    const { cityName } = useGetAutocompleteQuery({ lang: upperCaseLangCodes[lang] }, {
        selectFromResult: ({ data }) => {
            var _a, _b, _c;
            return ({
                cityName: (_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.entities) === null || _a === void 0 ? void 0 : _a[activeSearchId]) === null || _b === void 0 ? void 0 : _b.city) !== null && _c !== void 0 ? _c : null,
            });
        },
        skip: !search || !activeSearchId,
    });
    return (_jsx("div", { children: _jsx("ul", Object.assign({ className: listClassName }, { children: links.ids.map(id => {
                const item = links.entities[id];
                if (!item)
                    return null;
                const { to, i18key } = item;
                const text = t(i18key);
                return (_jsxs("li", Object.assign({ className: itemClassName }, { children: [pathname === to && _jsx(IconHeader, { iconId: "two-arrows-up-overlap", className: iconClassName }), _jsxs(NavLink, Object.assign({ to: to, className: ({ isActive }) => `${s.rentLink} ${isActive && s.active} ${dark}` }, { children: [text, " ", cityName] }))] }), id));
            }) })) }));
};
