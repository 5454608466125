import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import s from '../Modal.module.scss';
import { PriceBlock } from '../PriceBlock';
export const ModalBoxPrice = ({ color, btnText, id, openChangePriceModal, disabledElement, setDisabledElement, price, setPrice }) => {
    const handleChangeInput = e => {
        setPrice(e.target.value);
    };
    const handleSubmitBtnClick = (e) => {
        e.preventDefault();
        openChangePriceModal(id);
        setDisabledElement(false);
    };
    const { t } = useTranslation('hotelierCabinet');
    let labelClassName = s.modal__label_price;
    let valueTextClassName = s.modal__value_text;
    let submitButtonClassName = s.modal__button_submit;
    // let valuePriceTextClassName = s.modal__value_price_text;
    if (color === 'primary') {
        labelClassName = labelClassName + ' ' + (s.modal__label_price__primary);
        valueTextClassName = valueTextClassName + ' ' + s.modal__value_text__primary;
        submitButtonClassName = submitButtonClassName + ' ' + s.modal__button_submit__primary;
        // valuePriceTextClassName = valuePriceTextClassName + ' ' + s.modal__value_price_text__primary;
    }
    if (color === 'secondary') {
        labelClassName = labelClassName + ' ' + (s.modal__label_price__secondary);
        valueTextClassName = valueTextClassName + ' ' + s.modal__value_text__secondary;
        submitButtonClassName = submitButtonClassName + ' ' + s.modal__button_submit__secondary;
        // valuePriceTextClassName = valuePriceTextClassName + ' ' + s.modal__value_price_text__secondary;
    }
    return (_jsxs("div", Object.assign({ className: s.modal__box_price }, { children: [_jsx(PriceBlock, { disabledElement: true, setDisabledElement: setDisabledElement, labelClassName: labelClassName, btnText: t('modalPrice', { number: btnText }), valueTextClassName: valueTextClassName, price: price, handleChangeInput: handleChangeInput, size: 'small' }), _jsx("button", Object.assign({ type: 'submit', className: submitButtonClassName, onClick: e => handleSubmitBtnClick(e) }, { children: _jsx("span", { children: t('modalChangePrice', { number: btnText }) }) }))] })));
};
