import { jsx as _jsx } from "react/jsx-runtime";
import { Modal } from 'components/AddObject/Modal';
import rateSCSS from './RateBookingVariant.module.scss';
export const RateBookingVariantModal = ({ isModalOpen, closeModal, children }) => {
    return (_jsx(Modal, Object.assign({ onRequestClose: closeModal, isOpen: isModalOpen, contentClassName: rateSCSS.RateBookingVariantModal, overlayClassName: {
            base: rateSCSS.UserCabinetRateBookingVariantBackdropBase,
            afterOpen: rateSCSS.RateBookingVariantBackdropAfterOpen,
            beforeClose: rateSCSS.RateBookingVariantBackdropBeforeClose,
        }, closeTimeoutMS: 250, contentLabel: "Modal - Rate booking variant" }, { children: children })));
};
export const FeedbackBookingVariantModal = ({ isModalOpen, closeModal, children }) => {
    return (_jsx(Modal, Object.assign({ onRequestClose: closeModal, isOpen: isModalOpen, contentClassName: rateSCSS.RateBookingVariantModal, overlayClassName: {
            base: rateSCSS.UserCabinetRateBookingVariantBackdropBase,
            afterOpen: rateSCSS.RateBookingVariantBackdropAfterOpen,
            beforeClose: rateSCSS.RateBookingVariantBackdropBeforeClose,
        }, closeTimeoutMS: 250, contentLabel: "Modal - Give feedback for booking variant" }, { children: children })));
};
