import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useAppSelector } from 'features';
import { useClickOutside } from 'hooks/UI';
import { closePicker } from './pickerActions';
import { usePickerContext } from './usePickerContext';
import scss from './DropdownContent.module.scss';
export const DropdownContent = ({ children, className = '', mode }) => {
    const [, dispatch] = usePickerContext();
    const theme = useAppSelector(state => state.theme.selectedTheme);
    const dark = theme === 'dark' ? scss.dark : '';
    const closeDropdown = useCallback(() => {
        dispatch(closePicker());
    }, [dispatch]);
    const containerRef = useClickOutside(closeDropdown);
    const dropdownContentClassName = `${scss.dropdownContent} ${className} ${dark}`;
    return (_jsx("div", Object.assign({ className: dropdownContentClassName, ref: containerRef }, { children: children })));
};
