import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import s from '../ModalPayment.module.scss';
import { PaymentComponent } from 'components/UserCabinet/ModalW/DetailPropose/components';
import { CloseBnt } from 'components/UserCabinet/ModalW';
import { PaymentThanksComponent, Timer } from './';
export const Payment = ({ closeModal, createBooking, userHasPaid, setUserHasPaid, showNotice }) => {
    const handleClick = (type) => {
        setUserHasPaid(type);
        createBooking();
    };
    return (_jsxs("div", Object.assign({ className: s.payment__container }, { children: [!userHasPaid && _jsx(CloseBnt, { close: closeModal }), _jsx(Timer, { duration: 240, target: closeModal, activeStyle: userHasPaid }), !userHasPaid && (_jsx(PaymentComponent, { type: "selector", payments: ['cash', 'Visa/MasterCard', 'UnionPay', 'JCB', 'AmericanExpress'], title: "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0441\u043F\u043E\u0441\u043E\u0431 \u043E\u043F\u043B\u0430\u0442\u044B:", onClick: handleClick })), userHasPaid && _jsx(PaymentThanksComponent, { showNotice: showNotice })] })));
};
