import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import close from 'assets/icons/close.svg';
import { createPortal } from 'react-dom';
import { useEffect } from 'react';
import s from './Modal.module.scss';
const modalRoot = document.querySelector('#modal-root');
export function Modal({ children, toggleModal, closeIcon }) {
    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    });
    const handleKeyDown = e => {
        if (e.code === 'Escape') {
            toggleModal();
        }
    };
    const handleOverlayClick = event => {
        if (event.currentTarget === event.target) {
            toggleModal();
        }
    };
    return createPortal(_jsx("div", Object.assign({ className: s.overlay, onClick: handleOverlayClick, style: { backgroundColor: closeIcon === true && 'rgba(0, 0, 0, 0.0)' } }, { children: _jsxs("div", Object.assign({ className: s.modal }, { children: [closeIcon === undefined && _jsx("img", { src: close, alt: "test", className: s.close, onClick: toggleModal }), children] })) })), modalRoot);
}
