import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import Arrow from './image/arrow-6.svg';
import './AmountMoney.scss';
import eyesL from './image/cute-cartoon-eyes-looking-right-15467.svg';
import eyesR from './image/cute-cartoon-eyes-looking-left-15466.svg';
import clock from './image/circle752.svg';
import calendar from './image/weekly-calendar.png';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveCurrencyCode } from 'features/common';
import { selectActiveCurrencyText } from 'features/common';
import { useTranslation } from 'react-i18next';
import { clearState } from 'redux/bedRooms/bedRooms.actions';
export const AmountMoney = ({ setSugAmount, closeSelector, onSubmit, bookingDateSelected }) => {
    const dispatch = useDispatch();
    const { t: tCalendar } = useTranslation('Calendar');
    const { t: tDate } = useTranslation('date');
    const activeCurrencyCode = useSelector(selectActiveCurrencyCode);
    const activeCurrencyText = useSelector(selectActiveCurrencyText);
    const [currency, setCurrency] = useState(300);
    const [count, setCount] = useState(1);
    const [louder, setLouder] = useState(false);
    const [goback, setGoBack] = useState(true);
    const [isHovered, setHovered] = useState(false);
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [isDotVisible, setIsDotVisible] = useState(true);
    useEffect(() => {
        let timer = null;
        if (louder) {
            timer = setInterval(() => {
                if (count > 0) {
                    setCount(prev => prev - 1);
                }
                else if (count <= 0) {
                    stopTimer();
                    clearInterval(timer);
                    setCount(1);
                }
            }, 1000);
        }
        return () => {
            clearInterval(timer);
        };
    }, [count, louder]);
    const stopTimer = () => {
        setLouder(false);
    };
    const onChangeAmount = e => {
        setCurrency(e.target.value);
        setLouder(true);
    };
    // const KeyPressHandler = (e) => {
    //   const { value, maxLength } = e.target
    //   if (value.length >= maxLength) {
    //     e.preventDefault()
    //     return
    //   }
    // }
    const onFocusHandler = () => {
        setIsInputFocused(true);
        setIsDotVisible(false);
    };
    const onBlurHandler = () => {
        setIsInputFocused(false);
        setIsDotVisible(true);
    };
    const inputHandler = e => {
        const { value, maxLength } = e.target;
        e.target.value = value.slice(0, maxLength);
    };
    const goBack = useCallback(() => {
        setGoBack(false);
    }, []);
    useEffect(() => {
        setSugAmount(goback);
    }, [goback, setSugAmount]);
    const handleSubmit = () => {
        dispatch(clearState());
    };
    const checkInDate = new Date(bookingDateSelected[0]);
    const checkOutDate = new Date(bookingDateSelected[1]);
    const checkInMonth = checkInDate.getMonth();
    const checkInDay = checkInDate.getDate();
    const checkIntMonthName = tDate(`months.${checkInMonth}`);
    const checkInYear = checkInDate.getFullYear();
    const translationArrivalDate = `${checkInDay} ${checkIntMonthName} ${checkInYear}`;
    const checkOutMonth = checkOutDate.getMonth();
    const checkOutDay = checkOutDate.getDate();
    const checkOutMonthName = tDate(`months.${checkOutMonth}`);
    const checkOutYear = checkOutDate.getFullYear();
    const translationDepartureDate = `${checkOutDay} ${checkOutMonthName} ${checkOutYear}`;
    const areArrivalDepartureDatesEqual = translationArrivalDate === translationDepartureDate;
    const bookingDateUser = areArrivalDepartureDatesEqual
        ? `${translationArrivalDate}`
        : `${translationArrivalDate} - ${translationDepartureDate}`;
    return (_jsxs("div", Object.assign({ className: "amount__container" }, { children: [_jsx("button", Object.assign({ className: "arrow__btn", onClick: goBack }, { children: _jsx("svg", Object.assign({ className: "icon__arrow-btn" }, { children: _jsx("use", { href: `${Arrow}#arrow` }) })) })), _jsx("p", Object.assign({ className: "title__text1" }, { children: tCalendar('specifyTheAmount') })), _jsx("p", Object.assign({ className: "title__text2" }, { children: tCalendar('spend') })), _jsxs("form", Object.assign({ className: "amount__form", onSubmit: e => {
                    e.preventDefault();
                    onSubmit();
                    closeSelector();
                } }, { children: [_jsxs("div", Object.assign({ className: "amount__input-wrapper" }, { children: [_jsx("input", { id: "amount", type: "number", maxLength: "7", className: "amount__input", 
                                //value={value}
                                onChange: onChangeAmount, onInput: inputHandler, 
                                //onKeyPress={KeyPressHandler}
                                // required
                                placeholder: isInputFocused ? '' : tCalendar('amountPlaceholderText'), onFocus: onFocusHandler, onBlur: onBlurHandler }), _jsxs("div", Object.assign({ className: 'dot_wrapper' }, { children: [_jsx("span", { className: `blue__dot` }), _jsxs("div", Object.assign({ className: 'currency-text__wrapper' }, { children: [_jsx("p", Object.assign({ className: "currency-text" }, { children: activeCurrencyText })), _jsxs("p", Object.assign({ className: "currency-code" }, { children: [activeCurrencyCode, " "] }))] }))] }))] })), _jsxs("div", Object.assign({ className: "date__wrapper " }, { children: [_jsx("p", Object.assign({ className: "date__text" }, { children: tCalendar('period') })), _jsx("img", { className: "date__calendar-img", src: calendar, alt: "icon" }), _jsx("p", Object.assign({ className: "date__detail" }, { children: bookingDateUser }))] })), _jsxs("button", Object.assign({ type: "submit", className: "options__button", onClick: handleSubmit, onMouseEnter: () => setHovered(true), onMouseLeave: () => setHovered(false) }, { children: [_jsx("div", Object.assign({ className: "options__button-img" }, { children: _jsx("img", { width: 32, height: 32, src: isHovered ? eyesR : eyesL, alt: "eyes" }) })), _jsx("p", Object.assign({ className: "options__button-text1" }, { children: tCalendar('seeOptions') })), _jsxs("p", Object.assign({ className: "options__button-text2" }, { children: [louder && _jsx("img", { src: clock, alt: "clock" }), !louder && _jsx("span", { children: currency === '' ? 300 : currency })] }))] }))] }))] })));
};
