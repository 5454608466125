import { jsx as _jsx } from "react/jsx-runtime";
import { SubheaderHotelier } from '../SubheaderHotelier';
import scss from './SubheaderCabinetHotelier.module.scss';
/** CabinetHotelier
 *  @see CABINET_HOTELIER /cabinet-hotelier
 * 1. userType - subheader
 * 2. NO BODY  - Log in page ✅
 * 3. User     - # ✅
 * 4. Hotelier - Hotelier ✅
 * 5. Agent    - # ✅
 */
export const SubheaderCabinetHotelier = () => {
    return (_jsx("div", Object.assign({ className: scss.subheaderCabinetHotelier }, { children: _jsx(SubheaderHotelier, {}) })));
};
