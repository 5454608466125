import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import ls from './SearchBar.module.scss';
import { useTranslation } from 'react-i18next';
const CloseSvg = () => {
    return (_jsxs("svg", Object.assign({ width: "7", height: "7", viewBox: "0 0 7 6", fill: "#FFFFFF", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("line", { x1: "0.752513", y1: "5.70173", x2: "5.70226", y2: "0.751984", stroke: "#4C4C4C", strokeWidth: "0.7" }), _jsx("line", { x1: "1.24749", y1: "0.752513", x2: "6.19723", y2: "5.70226", stroke: "#4C4C4C", strokeWidth: "0.7" })] })));
};
const arrLocation = [
    {
        id: 1,
        location: 'Киев',
        region: 'Киевская область',
        country: 'Украина',
    },
    {
        id: 2,
        location: 'Киев центр',
        region: 'Киевская область',
        country: 'Украина',
    },
    {
        id: 3,
        location: 'Киев Подол',
        region: 'Киевская область',
        country: 'Украина',
    },
    {
        id: 4,
        location: 'Киев ЖД',
        region: 'Киевская область',
        country: 'Украина',
    },
    {
        id: 5,
        location: 'Киев Жуляны',
        region: 'Киевская область',
        country: 'Украина',
    },
    {
        id: 6,
        location: 'Нью-Йорк',
        region: 'штат Нью-Йорк',
        country: 'США',
    },
    {
        id: 7,
        location: 'Нью-Йорк Бронкс',
        region: 'штат Нью-Йорк',
        country: 'США',
    },
    {
        id: 8,
        location: 'Нью-Йорк нижний Манхеттен',
        region: 'штат Нью-Йорк',
        country: 'США',
    },
    {
        id: 9,
        location: 'Нью-Йорк Гарлем',
        region: 'штат Нью-Йорк',
        country: 'США',
    },
    {
        id: 10,
        location: 'Нью-Йорк Бруклин',
        region: 'штат Нью-Йорк',
        country: 'США',
    },
    {
        id: 11,
        location: 'Варшава',
        region: 'Мазоветское Воеводство',
        country: 'Польша',
    },
    {
        id: 12,
        location: 'Варшава Мокотов',
        region: 'Мазоветское Воеводство',
        country: 'Польща',
    },
    {
        id: 13,
        location: 'Варшава Кабаты',
        region: 'Мазоветское Воеводство',
        country: 'Польща',
    },
];
export const SearchBar = () => {
    const [search, setSearch] = useState(arrLocation);
    const [showOverlay, setShowOverlay] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const { t } = useTranslation();
    const codeTouch = () => {
        setSearch(arrLocation);
        setShowOverlay(true);
        const res = arrLocation.filter(value => value.location.toLowerCase().includes(searchValue.toLowerCase()) ||
            value.region.toLowerCase().includes(searchValue.toLowerCase()) ||
            value.country.toLowerCase().includes(searchValue.toLowerCase()));
        setSearch(res);
    };
    const handleInputBlur = () => {
        setTimeout(() => {
            setShowOverlay(false);
        }, 150);
    };
    const LocationSearch = (e) => {
        setSearchValue(e.target.value);
        const res = arrLocation.filter(value => value.location.toLowerCase().includes(e.target.value.toLowerCase()) ||
            value.region.toLowerCase().includes(e.target.value.toLowerCase()) ||
            value.country.toLowerCase().includes(e.target.value.toLowerCase()));
        setSearchValue(e.target.value);
        setSearch(res);
    };
    const selectLoc = (location) => {
        setSearchValue(location);
        setShowOverlay(false);
    };
    const handleClearInput = () => {
        setSearchValue('');
    };
    const locList = search.map(item => {
        const { id, location, region, country } = item;
        return (_jsxs("li", Object.assign({ className: ls.list_item, onClick: () => selectLoc(location) }, { children: [_jsxs("span", Object.assign({ className: ls.location_text }, { children: [" ", location] })), _jsx("span", Object.assign({ className: ls.region_text }, { children: region })), _jsx("span", Object.assign({ className: ls.country_text }, { children: country }))] }), id));
    });
    return (_jsx("div", Object.assign({ className: ls.container }, { children: _jsxs("div", Object.assign({ className: ls.item_container }, { children: [_jsxs("div", Object.assign({ className: ls.input_box }, { children: [_jsx("input", { type: "text", name: "search", onChange: LocationSearch, className: ls.input, onClick: codeTouch, value: searchValue, maxLength: 50, placeholder: t('searchPlaceholder'), onFocus: () => setShowOverlay(true), onBlur: handleInputBlur }), searchValue.length !== 0 && (_jsx("button", Object.assign({ className: `${ls.clear_inputBtn}  ${showOverlay === true ? ls.color_btn : ''}`, type: "button", onClick: handleClearInput }, { children: _jsx(CloseSvg, {}) })))] })), showOverlay && (_jsx("div", Object.assign({ className: ls.list_container }, { children: _jsxs("ul", Object.assign({ className: ls.list }, { children: [" ", locList] })) })))] })) })));
};
export default SearchBar;
