import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// import { useAuthContext } from 'context/AuthContext';
import { SubheaderUser } from '../SubheaderUser';
import { HeaderNobody } from '../../Components/HeaderNobody';
import { SubheaderHotelier } from '../SubheaderHotelier';
import scss from './SubheaderMain.module.scss';
import { useSelector } from 'react-redux';
import { authRole } from 'redux/authentication/authentication.reducer';
/** Main
 *  @see MAIN '/main'
 * 1. userType - subheader
 * 2. NO BODY  - NO BODY ✅
 * 3. User     - User ✅
 * 4. Hotelier - Hotelier ✅
 * 5. Agent    - NO BODY ✅
 */
export const SubheaderMain = () => {
    const role = useSelector(authRole);
    return (_jsxs("div", Object.assign({ className: scss.subheaderMain }, { children: [role === 'hotelier' && _jsx(SubheaderHotelier, {}), role === 'user' && _jsx(SubheaderUser, {}), !role && _jsx(HeaderNobody, {})] })));
};
