import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import scss from './HotelNameAndRating.module.scss';
import { useAppSelector } from 'features';
const HotelName = () => {
    const theme = useAppSelector(state => state.theme.selectedTheme);
    const dark = theme === 'dark' ? scss.dark : '';
    const [hotelName] = useState('Hilton');
    return _jsx("p", Object.assign({ className: `${scss.hotelName} ${dark}` }, { children: hotelName }));
};
const HotelRating = () => {
    const [rating] = useState('4,33');
    return (_jsx("div", Object.assign({ className: scss.hotelRatingBox }, { children: _jsx("span", Object.assign({ className: scss.hotelRating }, { children: rating })) })));
};
export const HotelNameAndRating = () => {
    return (_jsxs("div", Object.assign({ className: scss.hotelNameAndRating }, { children: [_jsx(HotelName, {}), _jsx(HotelRating, {})] })));
};
