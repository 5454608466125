import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MainRoom } from './MainRoom/MainRoom';
import { HostelRoom } from './HostelRoom/HostelRoom';
export const BedOptions = ({ options }) => {
    const [beds, setBeds] = useState([]);
    const { t } = useTranslation('userCard');
    const typeOfBed = useMemo(() => [
        {
            id: 1,
            iconId: 'icon-1x1',
            title: t('bedroom1x1'),
            descriptionInResponseData: { single: 1, double: 0 },
            type: 'main',
        },
        {
            id: 2,
            iconId: 'icon-1x2',
            title: t('bedroom1x2'),
            descriptionInResponseData: { single: 2, double: 0 },
            type: 'main',
        },
        {
            id: 3,
            iconId: 'icon-1x3',
            title: t('bedroom1x3'),
            descriptionInResponseData: { single: 3, double: 0 },
            type: 'main',
        },
        {
            id: 4,
            iconId: 'icon-2x1',
            title: t('bedroom2x1'),
            descriptionInResponseData: { single: 0, double: 1 },
            type: 'main',
        },
        {
            id: 5,
            iconId: 'icon-2x2',
            title: t('bedroom2x2'),
            descriptionInResponseData: { single: 0, double: 2 },
            type: 'main',
        },
        {
            id: 6,
            iconId: 'icon-2x3',
            title: t('bedroom2x3'),
            descriptionInResponseData: { single: 0, double: 3 },
            type: 'main',
        },
        {
            id: 7,
            iconId: 'icon-1x1_2x2',
            title: t('bedroom1x1_2x2'),
            descriptionInResponseData: { single: 1, double: 2 },
            type: 'main',
        },
        {
            id: 8,
            iconId: 'icon-1x2_2x1',
            title: t('bedroom1x2_2x1'),
            descriptionInResponseData: { single: 2, double: 1 },
            type: 'main',
        },
        {
            id: 9,
            iconId: 'icon-1x1',
            title: t('bedroom1x1'),
            descriptionInResponseData: { single: 1, double: 1 },
            type: 'main',
        },
    ], [t]);
    useEffect(() => {
        const result = Object.values(options).map((option) => {
            if (option.hostel === true) {
                return { iconId: 'icon-1x1', title: t('bedroom1x1'), descriptionInResponseData: option, type: 'hostel' };
            }
            return typeOfBed.find((bed) => bed.descriptionInResponseData.single === option.single &&
                bed.descriptionInResponseData.double === option.double);
        });
        setBeds(result);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options, typeOfBed]);
    return (_jsx(_Fragment, { children: beds.map((bed, id) => {
            if (!bed)
                return null;
            return bed.type === 'hostel' ? _jsx(HostelRoom, { bed: bed }, id) : _jsx(MainRoom, { bed: bed }, id);
        }) }));
};
