export var TPicker;
(function (TPicker) {
    TPicker["open"] = "open";
    TPicker["close"] = "close";
    TPicker["toggle"] = "toggle";
    TPicker["onOptionClick"] = "onOptionClick";
    TPicker["setPickerTheme"] = "setPickerTheme";
    TPicker["setPickerToggleButtonRef"] = "setPickerToggleButtonRef";
})(TPicker || (TPicker = {}));
export const initPickerState = {
    isDropdownOpen: false,
    activeOptionId: '',
    options: { ids: [], entities: {} },
    theme: 'light',
    isDark: false,
};
