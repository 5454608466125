import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuthContext } from 'context/AuthContext';
import { HOTELIER_ACCOUNT_SETTINGS, LOGIN_AGENT, LOGIN_HOTELIER, LOGIN_USER, ROOT, USER_ACCOUNT_SETTING, } from 'navigation/CONSTANTS';
import { useSelector } from 'react-redux';
import { useLinkToMainHotelier } from 'hooks/routes';
// screen if you're not yet authenticated.
export const PrivateRoute = ({ navigateTo }) => {
    const [{ user }] = useAuthContext();
    const from = useLocation();
    return user ? _jsx(Outlet, {}) : _jsx(Navigate, { to: navigateTo, replace: true, state: { from } });
};
export const PrivateRouteHotelier = () => {
    // const [{ isHotelier }] = useAuthContext();
    const { loggedIn } = useSelector(state => state.auth);
    const mainHotelierLink = useLinkToMainHotelier();
    const from = useLocation();
    if (!loggedIn && from.pathname === HOTELIER_ACCOUNT_SETTINGS) {
        return _jsx(Navigate, { replace: true, to: mainHotelierLink });
    }
    return loggedIn ? _jsx(Outlet, {}) : _jsx(Navigate, { to: LOGIN_HOTELIER, replace: true, state: { from } });
};
export const PrivateRouteAgent = () => {
    // const [{ isAgent }] = useAuthContext();
    const { loggedIn } = useSelector(state => state.auth);
    const from = useLocation();
    return loggedIn ? _jsx(Outlet, {}) : _jsx(Navigate, { to: LOGIN_AGENT, replace: true, state: { from } });
};
export const PrivateRouteUser = () => {
    // const [{ isUser }] = useAuthContext();
    const { loggedIn } = useSelector(state => state.auth);
    const from = useLocation();
    if (!loggedIn && from.pathname === USER_ACCOUNT_SETTING) {
        return _jsx(Navigate, { replace: true, to: ROOT });
    }
    return loggedIn ? _jsx(Outlet, {}) : _jsx(Navigate, { to: LOGIN_USER, replace: true, state: { from } });
};
