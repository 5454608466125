import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import s from './SendRequestModal.module.scss';
const amenitiesData = {
    amenities: {
        'Все включено': true,
        'Первая линия от моря': true,
        'Номера для некурящих': true,
        Бар: true,
        'Доставка еды и напитков': true,
        'Ужин включён': true,
        'Частная пакровка': true,
        'Завтрак включен': true,
        'Обед включен': true,
        'Лобби-бар': true,
        Ресторан: true,
        'Спорт зал': true,
        'Ночной бар': true,
        'Удобства для гостей с ограниченными возможностями': true,
        'Цн не має рендеритись': false,
    },
};
const Amenities = () => {
    return (
    // <div className={s.AmenitiesBlock}>
    _jsx("ul", Object.assign({ className: s.AmenitiesBlock1 }, { children: Object.entries(amenitiesData.amenities).map(([amenity, value], index) => value && (_jsx("li", Object.assign({ className: s.AmenitiesBlock__item }, { children: amenity }), index))) }))
    // </div>
    );
};
export default Amenities;
