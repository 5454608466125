import { jsx as _jsx } from "react/jsx-runtime";
import { useAppDispatch, useAppSelector } from 'features';
import { chooseCurrency, selectActiveCurrencyId } from 'features/common';
import { useCallback } from 'react';
import scss from './CurrencyOptionButton.module.scss';
export const CurrencyOptionButton = ({ optionId, title, children }) => {
    const activeCurrencyId = useAppSelector(selectActiveCurrencyId);
    // const [{ isDark }] = usePickerContext();
    const theme = useAppSelector(state => state.theme.selectedTheme);
    const dark = theme === 'dark' ? scss.dark : '';
    const appDispatch = useAppDispatch();
    const onClickChooseCurrency = useCallback(() => {
        appDispatch(chooseCurrency(optionId));
    }, [appDispatch, optionId]);
    //const dark = isDark ? scss.dark : '';
    const active = optionId === activeCurrencyId ? scss.active : '';
    return (_jsx("button", Object.assign({ className: `${scss.currencyOptionButton} ${dark} ${active}`, onClick: onClickChooseCurrency, type: "button", title: title }, { children: children })));
};
