import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { isProd } from 'config';
import { Header } from 'components/Common/Header';
import { SubheaderMainHotelier, SubheaderMainAgent, SubheaderMain, SubheaderCabinetUser, SubheaderCabinetAgent, SubheaderCabinetHotelier, SubheaderUserCatalog, SubheaderHotelierCatalog, SubheaderSupport, SubheaderParlour, } from 'components/Common/Header/Subheaders';
import { Nav } from 'pages/Home/components/NavLinks';
export const Layout = ({ children }) => (_jsxs(_Fragment, { children: [_jsxs(Header, { children: [!isProd && _jsx(Nav, {}), children] }), _jsx(Suspense, Object.assign({ fallback: "" }, { children: _jsx(Outlet, {}) }))] }));
/** Home
 * @see ROOT '/'
 */
export const LayoutParlour = () => (_jsx(Layout, { children: _jsx(SubheaderParlour, {}) }));
/** MainHotelier
 *  @see MAIN_HOTELIER '/main-hotelier'
 * 1. userType - subheader
 * 2. NO BODY  - NO BODY ✅
 * 3. User     - NO BODY ✅
 * 4. Hotelier - Hotelier ✅
 * 5. Agent    - NO BODY ✅
 */
export const LayoutMainHotelier = () => (_jsx(Layout, { children: _jsx(SubheaderMainHotelier, {}) }));
/** MainAgents
 * @see MAIN_AGENT '/main-agent'
 * 1. userType - subheader
 * 2. NO BODY  - NO BODY ✅
 * 3. User     - NO BODY ✅
 * 4. Hotelier - NO BODY ✅
 * 5. Agent    - Agent ✅
 */
export const LayoutMainAgent = () => (_jsx(Layout, { children: _jsx(SubheaderMainAgent, {}) }));
/** Main
 *  @see MAIN '/main'
 * 1. userType - subheader
 * 2. NO BODY  - NO BODY ✅
 * 3. User     - User ✅
 * 4. Hotelier - Hotelier ✅
 * 5. Agent    - NO BODY ✅
 */
export const LayoutMain = () => (_jsx(Layout, { children: _jsx(SubheaderMain, {}) }));
/** CabinetUser
 *  @see CABINET_USER '/cabinet-user'
 * 1. userType - subheader
 * 2. NO BODY  - Log in page ✅
 * 3. User     - User ✅
 * 4. Hotelier - # ✅
 * 5. Agent    - # ✅
 */
export const LayoutCabinetUser = () => (_jsx(Layout, { children: _jsx(SubheaderCabinetUser, {}) }));
/** CabinetAgent
 *  @see CABINET_AGENT '/cabinet-agent'
 * 1. userType - subheader
 * 2. NO BODY  - Log in page ✅
 * 3. User     - # ✅
 * 4. Hotelier - # ✅
 * 5. Agent    - Agent ✅
 */
export const LayoutCabinetAgent = () => (_jsx(Layout, { children: _jsx(SubheaderCabinetAgent, {}) }));
/** CabinetHotelier
 *  @see CABINET_HOTELIER '/cabinet-hotelier'
 * 1. userType - subheader
 * 2. NO BODY  - Log in page ✅
 * 3. User     - # ✅
 * 4. Hotelier - Hotelier ✅
 * 5. Agent    - # ✅
 */
export const LayoutCabinetHotelier = () => (_jsx(Layout, { children: _jsx(SubheaderCabinetHotelier, {}) }));
/** HotelierCatalog
 *  @see HOTELIER_CATALOG '/hotelier-catalog'
 * 1. userType - subheader
 * 2. NO BODY  - Hot Catalog ✅
 * 3. User     - Hot Catalog ✅
 * 4. Hotelier - Hot Catalog ✅
 * 5. Agent    - Hot Catalog ✅
 */
export const LayoutHotelierCatalog = () => (_jsx(Layout, { children: _jsx(SubheaderHotelierCatalog, {}) }));
/** UserCatalog
 *  @see USER_CATALOG '/user-catalog'
 * 1. userType - subheader
 * 2. NO BODY  - Us Catalog 🔳
 * 3. User     - Us Catalog 🔳
 * 4. Hotelier - Us Catalog 🔳
 * 5. Agent    - Us Catalog 🔳
 */
export const LayoutUserCatalog = () => (_jsx(Layout, { children: _jsx(SubheaderUserCatalog, {}) }));
/** Support
 *  @see SUPPORT '/support'
 *  @see TERMS_CONDITIONS_USERS '/terms-conditions-users'
 *  @see OUR_MISSION '/our-mission'
 *  @see ABOUT_COMPANY '/about-company'
 * 1. userType - subheader
 * 2. NO BODY  - NO BODY ✅
 * 3. User     - User ✅
 * 4. Hotelier - Hotelier ✅
 * 5. Agent    - Agent ✅
 */
export const LayoutSupport = () => (_jsx(Layout, { children: _jsx(SubheaderSupport, {}) }));
