import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ProposeCard } from './components/MainCard';
import { ButtonCommentRate, ChatHotelier, Contact, UserInfo, } from './components/SubCard';
import s from './ActiveBookingCard.module.scss';
// import { FeedbackModal } from 'components/BookingFeedBack/sendFeedback';
import { useCallback, useState } from 'react';
import { RateBookingVariant } from 'components/UserCabinet/InfoComponent/RateBookingVariant';
import { useModal } from 'hooks/UI';
import { FeedbackBookingVariant } from 'components/UserCabinet/InfoComponent/RateBookingVariant/FeedbackBookingVariant';
export const ActiveBookingCard = ({ data, cancelBooking, setCard }) => {
    const [isPayed, setIsPayed] = useState(true);
    const toggleIsPayed = useCallback(() => {
        setIsPayed((prev) => !prev);
    }, []);
    const [isRateBookingVariantModalOpen, openRateBookingModal, closeRateBookingVariantModal] = useModal();
    const [isFeedbackBookingVariantModalOpen, openFeedbackBookingModal, closeFeedbackBookingVariantModal] = useModal();
    const openRateBookingVariantModal = useCallback(() => {
        if (!isPayed)
            return;
        openRateBookingModal();
    }, [isPayed, openRateBookingModal]);
    const openFeedbackBookingVariantModal = useCallback(() => {
        if (!isPayed)
            return;
        openFeedbackBookingModal();
    }, [isPayed, openFeedbackBookingModal]);
    const isRateModalVisible = isPayed && isRateBookingVariantModalOpen;
    const isFeedbackModalVisible = isPayed && isFeedbackBookingVariantModalOpen;
    return (_jsxs("div", Object.assign({ className: s.card__wrapper }, { children: [_jsxs("div", Object.assign({ className: s.adaptive_menu }, { children: [_jsx(ProposeCard, { data: data, cancelBooking: cancelBooking, setCard: setCard, isPayed: isPayed, openRateBookingVariantModal: openRateBookingVariantModal, openFeedbackBookingVariantModal: openFeedbackBookingVariantModal }), _jsxs("div", Object.assign({ className: s.subCard }, { children: [_jsx(Contact, {}), _jsx("div", { className: s.adaptiveRevievBlock }), _jsx("div", Object.assign({ className: s.wrapperWaitForPayment }, { children: _jsxs("div", Object.assign({ className: s.button_container }, { children: [_jsx(ButtonCommentRate, { onClick: openFeedbackBookingVariantModal, isPayed: isPayed, type: "comment" }), _jsx(ButtonCommentRate, { onClick: openRateBookingVariantModal, isPayed: isPayed, type: "rate" })] })) })), _jsxs("div", Object.assign({ className: s.adaptiveContainer }, { children: [_jsx(UserInfo, {}), _jsx(ChatHotelier, {})] }))] }))] })), _jsx(RateBookingVariant, { isModalOpen: isRateModalVisible, closeModal: closeRateBookingVariantModal }), _jsx(FeedbackBookingVariant, { isModalOpen: isFeedbackModalVisible, closeModal: closeFeedbackBookingVariantModal })] })));
};
