import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// import { useMedia } from 'hooks/UI';
import { IconHeader } from '../IconHeader';
import scss from './HeaderButton.module.scss';
const emptyFn = () => { };
export const HeaderButton = ({ unreadMsgs, buttonClassName, tooltipClassName, iconId, iconClassName = scss.headerIcon, onClick = emptyFn, buttonTooltip, children, }) => {
    const buttonClassNameApplied = `${scss.headerButton} ${buttonClassName}`;
    const tooltipClassNameApplied = `${scss.tooltipOverlay} ${scss.delay} ${tooltipClassName}`;
    return (_jsxs("div", Object.assign({ className: scss.headerButtonWrapper }, { children: [_jsxs("button", Object.assign({ className: buttonClassNameApplied, onClick: onClick, type: "button" }, { children: [iconId && _jsx(IconHeader, { className: `${iconClassName} ${scss.headerIcon}`, iconId: iconId }), children, unreadMsgs ? _jsx("span", Object.assign({ className: scss.unreadMsgs }, { children: unreadMsgs })) : null] })), buttonTooltip && (_jsx("div", Object.assign({ className: tooltipClassNameApplied }, { children: _jsx("p", Object.assign({ className: scss.buttonTooltip }, { children: buttonTooltip })) })))] })));
};
