import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { USER_PROFILE } from 'navigation/CONSTANTS';
import { useTranslation } from 'react-i18next';
import './UsersCard.scss';
import Sprite from './img/usersCard-sprite.svg';
import Image from './img/JackieChan.jpg';
import IconClock from './img/iconClock.svg';
import { BookingKarma } from 'components/UserRequestCard/BookingKarma';
import { useEffect, useState } from 'react';
import { usePopover } from 'hooks/UI';
import { useSelector } from 'react-redux';
import { formatDate } from 'utils/date';
export default function UsersCard() {
    const { t: tUserCabinet } = useTranslation('userCabinet'); // Переменная для блока UserCabinet
    const user = useSelector(state => state.auth);
    const name = user.firstName || 'Джеки';
    const surname = user.lastName || 'Чан';
    const fullName = `${name} ${surname}`;
    const createdAt = user.createdAt | '22.05.2020';
    const [toggleKarma, setToggleKarma] = useState(false);
    const [parentPopperAttributes, childPopperAttributes] = usePopover({
        offset: [16, 4],
        placement: 'bottom',
    });
    useEffect(() => {
        const checkClose = () => {
            if (toggleKarma)
                setToggleKarma(false);
        };
        document.addEventListener('click', checkClose);
        return () => {
            document.removeEventListener('click', checkClose);
        };
    }, [toggleKarma]);
    return (_jsxs("div", Object.assign({ className: "usersCard__section" }, { children: [_jsx(Link, Object.assign({ className: "usersCard__link", to: USER_PROFILE }, { children: _jsx("svg", Object.assign({ className: "usersCard__icon-gear" }, { children: _jsx("use", { href: `${Sprite}#icon-gear` }) })) })), _jsx("div", Object.assign({ className: "usersCard__thumb" }, { children: _jsx("img", { src: Image, alt: "user avatar", className: "usersCard__image" }) })), _jsx("p", Object.assign({ className: "usersCard__title" }, { children: fullName })), _jsxs("div", Object.assign({ className: "usersCard__infoBlock" }, { children: [_jsxs("div", Object.assign({ className: toggleKarma ? ' usersCard__infoBlock__wrapper active ' : ' usersCard__infoBlock__wrapper ' }, parentPopperAttributes, { onClick: () => setToggleKarma(!toggleKarma) }, { children: [_jsxs("div", Object.assign({ className: "usersCard__infoBlock__title" }, { children: [_jsx("img", { src: IconClock, alt: "", className: "usersCard__infoBlock__icon" }), _jsx("p", Object.assign({ className: "usersCard__infoBlock__text" }, { children: "Booking Karma" }))] })), _jsx("span", Object.assign({ className: "usersCard__infoBlock__number" }, { children: "22" })), _jsx("div", Object.assign({ className: toggleKarma ? 'karma__container_open' : 'karma__container_close' }, childPopperAttributes, { children: _jsx(BookingKarma, {}) }))] })), _jsxs("div", Object.assign({ className: "usersCard__infoBlock__wrapper" }, { children: [_jsxs("div", Object.assign({ className: "usersCard__infoBlock__title" }, { children: [_jsx("svg", Object.assign({ className: "usersCard__infoBlock__icon-weekly" }, { children: _jsx("use", { href: `${Sprite}#icon-weekly-calendar` }) })), _jsx("p", Object.assign({ className: "usersCard__infoBlock__text" }, { children: tUserCabinet('registrationDate') }))] })), _jsx("span", Object.assign({ className: "usersCard__infoBlock__date" }, { children: formatDate(createdAt) }))] }))] }))] })));
}
