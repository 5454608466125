import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SEARCH_LOCATION } from 'navigation/CONSTANTS';
import { useMedia } from 'hooks/UI';
// import { selectActiveLang } from 'features/common';
import { useAppSelector } from 'features/hooks';
import { selectLocalActiveObject } from 'features/search';
// import { useGetAutocompleteQuery } from 'features/autocomplete';
import { SearchBar } from './SearchBar';
import scss from './SubheaderHotelierCatalog.module.scss';
import { rentLinks } from 'pages/MainHotelier/RentList';
// import { upperCaseLangCodes } from 'components/AddObject/ObjectAddLocation';
export const useActiveRentOption = () => {
    var _a, _b;
    const { t } = useTranslation('mainHotelier');
    const { pathname } = useLocation();
    const page = useAppSelector(state => state.catalogHoteilerPage.page);
    if (page) {
        return (_a = page === null || page === void 0 ? void 0 : page.description) === null || _a === void 0 ? void 0 : _a.replace(/-/g, ' ');
    }
    if (rentLinks.ids.includes(pathname)) {
        const item = (_b = rentLinks.entities) === null || _b === void 0 ? void 0 : _b[pathname];
        if (!item)
            return '';
        return t(item.i18key);
    }
    switch (pathname) {
        case SEARCH_LOCATION:
            return t('rentOutHousingDaily');
        default:
            return '';
    }
};
const HotelierRentOptionText = () => {
    const activeRentOption = useActiveRentOption();
    return _jsx("p", Object.assign({ className: scss.hotelierRentOption }, { children: activeRentOption }));
};
export const LocationCard = ({ children }) => {
    const page = useAppSelector(state => state.catalogHoteilerPage.page);
    // const { t } = useTranslation('mainHotelier');
    const localActiveObject = useAppSelector(selectLocalActiveObject);
    // const activeSearchId = useAppSelector(selectActiveSearchId);
    // const search = useAppSelector(selectSearchTerm);
    // const lang = useAppSelector(selectActiveLang);
    // TODO: remove city and country placeholder before deploy to production
    // const frontEndPlaceholderActiveCityName = localActiveObject?.i18nCity ? t(localActiveObject.i18nCity) : '';
    // const frontEndPlaceholderActiveCountryName = localActiveObject?.i18nCountry ? t(localActiveObject.i18nCountry) : '';
    // TODO: replace api from `/api/autocomplete` to `api/search`
    // const { cityName, country } = useGetAutocompleteQuery(
    //   { lang: upperCaseLangCodes[lang] },
    //   {
    //     selectFromResult: ({ data }) => {
    //       const locationObj = data?.entities?.[activeSearchId];
    //       return {
    //         cityName: locationObj?.city,
    //         country: locationObj?.country,
    //       };
    //     },
    //     skip: !activeSearchId,
    //   }
    // );
    const frontEndPlaceholderImg = localActiveObject === null || localActiveObject === void 0 ? void 0 : localActiveObject.localImg; // TODO: remove img placeholder before deploy to production
    const alt = 'location';
    return (_jsxs("div", Object.assign({ className: scss.locationCard }, { children: [_jsx("div", Object.assign({ className: scss.imageThumb }, { children: _jsx("img", { src: frontEndPlaceholderImg, alt: alt, className: scss.locationImg, title: alt }) })), _jsxs("div", Object.assign({ className: scss.textWrapper }, { children: [_jsxs("p", Object.assign({ className: scss.cityAndCountry }, { children: [_jsxs("span", Object.assign({ className: scss.city }, { children: [" ", page === null || page === void 0 ? void 0 : page.district, ' '] })), _jsxs("span", Object.assign({ className: scss.country }, { children: [" ", page === null || page === void 0 ? void 0 : page.country] }))] })), children] }))] })));
};
/** HotelierCatalog
 *  @see HOTELIER_CATALOG '/hotelier-catalog'
 * 1. userType - subheader
 * 2. NO BODY  - Hot Catalog ✅
 * 3. User     - Hot Catalog ✅
 * 4. Hotelier - Hot Catalog ✅
 * 5. Agent    - Hot Catalog ✅
 */
export const SubheaderHotelierCatalog = () => {
    const { isMobile } = useMedia();
    return (_jsxs("div", Object.assign({ className: scss.subheaderHotelierCatalog }, { children: [!isMobile && _jsx(SearchBar, {}), _jsx(LocationCard, { children: _jsx(HotelierRentOptionText, {}) })] })));
};
