import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { twoBedRoomFirstRoomDoubleBedCountIncrement, twoBedRoomFirstRoomDoubleBedCountDecrement, twoBedRoomFirstRoomSingleBedCountIncrement, twoBedRoomFirstRoomSingleBedCountDecrement, twoBedRoomSecondRoomDoubleBedCountDecrement, twoBedRoomSecondRoomDoubleBedCountIncrement, twoBedRoomSecondRoomSingleBedCountIncrement, twoBedRoomSecondRoomSingleBedCountDecrement, } from '../../../../redux/bedRooms/bedRooms.actions.js';
import './TwoBedRoom.scss';
import SelectBed from '../../Assents/image/SelectBed.svg';
import { useTranslation } from 'react-i18next';
import { useScreenResizing } from 'hooks/UI/useScreenResizing.js';
export default function TwoBedRoom({ readyBtnTwoBedRoom, backToSelectRoom }) {
    const viewPort = useScreenResizing();
    const isMobile = viewPort.width > 319 && viewPort.width < 768;
    const { t } = useTranslation('Calendar');
    const [showTwoBedroomFirstBlockAttetion, setShowTwoBedroomFistBlockAttetion] = useState(false);
    const [showTwoBedroomSecondBlockAttetion, setShowTwoBedroomSecondBlockAttetion] = useState(false);
    const dispatch = useDispatch();
    const firstBedRoomDoubleBedCount = useSelector(state => state.bedRoomReducer.twoBedRoomFirstRoomDoubleCount);
    const firstBedRoomSingleBedCount = useSelector(state => state.bedRoomReducer.twoBedRoomFirstRoomSingleBedCount);
    const fistBedRoomAllBed = firstBedRoomDoubleBedCount + firstBedRoomSingleBedCount;
    const firstBedRoomDoubleCountDecrement = () => {
        if (firstBedRoomDoubleBedCount === 0) {
            return firstBedRoomDoubleBedCount;
        }
        else {
            dispatch(twoBedRoomFirstRoomDoubleBedCountDecrement(1));
        }
    };
    const firstBedRoomDoubleCountIncrement = () => {
        if (firstBedRoomDoubleBedCount < 3 && fistBedRoomAllBed < 3) {
            dispatch(twoBedRoomFirstRoomDoubleBedCountIncrement(1));
            setShowTwoBedroomFistBlockAttetion(false);
        }
        else {
            return firstBedRoomDoubleBedCount;
        }
    };
    const firstSingleBedCountDecrement = () => {
        if (firstBedRoomSingleBedCount === 0) {
            return firstBedRoomSingleBedCount;
        }
        else {
            dispatch(twoBedRoomFirstRoomSingleBedCountDecrement(1));
        }
    };
    const firstSingleBedCountIncrement = () => {
        if (firstBedRoomSingleBedCount < 3 && fistBedRoomAllBed < 3) {
            dispatch(twoBedRoomFirstRoomSingleBedCountIncrement(1));
            setShowTwoBedroomFistBlockAttetion(false);
        }
        else {
            return firstBedRoomSingleBedCount;
        }
    };
    // second room
    const secondBedRoomDoubleBedCount = useSelector(state => state.bedRoomReducer.twoBedRoomSecondRoomDoubleCount);
    const secondBedRoomSingleBedCount = useSelector(state => state.bedRoomReducer.twoBedRoomSecondRoomSingleBedCount);
    const secondBedRoomAllBed = secondBedRoomDoubleBedCount + secondBedRoomSingleBedCount;
    const secondBedRoomDoubluCountDecrement = () => {
        if (secondBedRoomDoubleBedCount === 0) {
            return secondBedRoomDoubleBedCount;
        }
        else {
            dispatch(twoBedRoomSecondRoomDoubleBedCountDecrement(1));
        }
    };
    const secondBedRoomDoubluCountIncrement = () => {
        if (secondBedRoomDoubleBedCount < 3 && secondBedRoomAllBed < 3) {
            dispatch(twoBedRoomSecondRoomDoubleBedCountIncrement(1));
            setShowTwoBedroomSecondBlockAttetion(false);
        }
        else {
            return secondBedRoomDoubleBedCount;
        }
    };
    const secondSingleBedCountDecrement = () => {
        if (secondBedRoomSingleBedCount === 0) {
            return secondBedRoomSingleBedCount;
        }
        else {
            dispatch(twoBedRoomSecondRoomSingleBedCountDecrement(1));
        }
    };
    const secondSingleBedCountIncrement = () => {
        if (secondBedRoomSingleBedCount < 3 && secondBedRoomAllBed < 3) {
            dispatch(twoBedRoomSecondRoomSingleBedCountIncrement(1));
            setShowTwoBedroomSecondBlockAttetion(false);
        }
        else {
            return secondBedRoomSingleBedCount;
        }
    };
    const disableBtn = (firstBedRoomDoubleBedCount || firstBedRoomSingleBedCount) &&
        (secondBedRoomDoubleBedCount || secondBedRoomSingleBedCount)
        ? false
        : true;
    //handle btn
    const btnClick = () => {
        readyBtnTwoBedRoom();
    };
    const showAttention = () => {
        if (!firstBedRoomDoubleBedCount && !firstBedRoomSingleBedCount) {
            setShowTwoBedroomFistBlockAttetion(true);
        }
        if (!secondBedRoomDoubleBedCount && !secondBedRoomSingleBedCount) {
            setShowTwoBedroomSecondBlockAttetion(true);
        }
    };
    return (_jsxs("div", Object.assign({ className: "twoBedRooms__section" }, { children: [_jsxs("div", Object.assign({ className: "twoBedRooms__group" }, { children: [_jsxs("div", Object.assign({ className: "twoBedRooms__div" }, { children: [_jsxs("p", Object.assign({ className: "twoBedRooms__text" }, { children: [t('bedroom'), " 1"] })), _jsx("div", Object.assign({ className: showTwoBedroomFirstBlockAttetion ? 'twoBedRooms__block twoBedRooms__block--active' : 'twoBedRooms__block' }, { children: _jsxs("div", Object.assign({ className: "twoBedRooms__selected__block" }, { children: [_jsxs("div", Object.assign({ className: "twoBedRooms__selected__bed" }, { children: [_jsxs("div", Object.assign({ className: "twoBedRooms__firstDoubleBedRoom__hover" }, { children: [_jsx("svg", Object.assign({ className: "twoBedRooms__icon" }, { children: _jsx("use", { href: `${SelectBed}#doubleBed` }) })), _jsx("div", Object.assign({ className: "twoBedRooms__firstDoubleBedRoom__overlay" }, { children: t('doubleBed') }))] })), _jsx("button", Object.assign({ className: firstBedRoomDoubleBedCount
                                                        ? 'twoBedRooms__btn twoBedRooms__btn--double'
                                                        : 'twoBedRooms__btn__start twoBedRooms__btn__start--double', onClick: firstBedRoomDoubleCountDecrement }, { children: _jsx("svg", Object.assign({ className: "twoBedRooms__decrement" }, { children: _jsx("use", { href: `${SelectBed}#minus` }) })) })), _jsx("span", Object.assign({ className: "twoBedRooms__counter" }, { children: firstBedRoomDoubleBedCount })), _jsx("button", Object.assign({ className: "twoBedRooms__btn", onClick: firstBedRoomDoubleCountIncrement }, { children: _jsx("svg", Object.assign({ className: "twoBedRooms__increment" }, { children: _jsx("use", { href: `${SelectBed}#plus` }) })) }))] })), _jsxs("div", Object.assign({ className: "twoBedRooms__selected__bed twoBedRooms__selected__bed--modif" }, { children: [_jsxs("div", Object.assign({ className: "twoBedRooms__firstSingleBed__hover" }, { children: [_jsx("svg", Object.assign({ className: "twoBedRooms__icon twoBedRooms__icon--single" }, { children: _jsx("use", { href: `${SelectBed}#singleBed` }) })), _jsx("div", Object.assign({ className: "twoBedRooms__firstSingleBed__overlay" }, { children: t('singleBed') }))] })), _jsx("button", Object.assign({ className: firstBedRoomSingleBedCount
                                                        ? 'twoBedRooms__btn twoBedRooms__btn--single'
                                                        : 'twoBedRooms__btn__start twoBedRooms__btn__start--single', onClick: firstSingleBedCountDecrement }, { children: _jsx("svg", Object.assign({ className: "twoBedRooms__decrement" }, { children: _jsx("use", { href: `${SelectBed}#minus` }) })) })), _jsx("span", Object.assign({ className: "twoBedRooms__counter" }, { children: firstBedRoomSingleBedCount })), _jsx("button", Object.assign({ className: "twoBedRooms__btn", onClick: firstSingleBedCountIncrement }, { children: _jsx("svg", Object.assign({ className: "twoBedRooms__increment" }, { children: _jsx("use", { href: `${SelectBed}#plus` }) })) }))] }))] })) }))] })), _jsxs("div", { children: [_jsxs("p", Object.assign({ className: "twoBedRooms__text" }, { children: [t('bedroom'), " 2"] })), _jsx("div", Object.assign({ className: showTwoBedroomSecondBlockAttetion ? 'twoBedRooms__block twoBedRooms__block--active' : 'twoBedRooms__block' }, { children: _jsxs("div", Object.assign({ className: "twoBedRooms__selected__block" }, { children: [_jsxs("div", Object.assign({ className: "twoBedRooms__selected__bed" }, { children: [_jsxs("div", Object.assign({ className: "twoBedRooms__secondDoubleBedRoom__hover" }, { children: [_jsx("svg", Object.assign({ className: "twoBedRooms__icon" }, { children: _jsx("use", { href: `${SelectBed}#doubleBed` }) })), _jsx("div", Object.assign({ className: "twoBedRooms__secondDoubleBedRoom__overlay" }, { children: t('doubleBed') }))] })), _jsx("button", Object.assign({ className: secondBedRoomDoubleBedCount
                                                        ? 'twoBedRooms__btn twoBedRooms__btn--double'
                                                        : 'twoBedRooms__btn__start twoBedRooms__btn__start--double', onClick: secondBedRoomDoubluCountDecrement }, { children: _jsx("svg", Object.assign({ className: "twoBedRooms__decrement" }, { children: _jsx("use", { href: `${SelectBed}#minus` }) })) })), _jsx("span", Object.assign({ className: "twoBedRooms__counter" }, { children: secondBedRoomDoubleBedCount })), _jsx("button", Object.assign({ className: "twoBedRooms__btn", onClick: secondBedRoomDoubluCountIncrement }, { children: _jsx("svg", Object.assign({ className: "twoBedRooms__increment" }, { children: _jsx("use", { href: `${SelectBed}#plus` }) })) }))] })), _jsxs("div", Object.assign({ className: "twoBedRooms__selected__bed twoBedRooms__selected__bed--modif" }, { children: [_jsxs("div", Object.assign({ className: "twoBedRooms__secondSingleBed__hover" }, { children: [_jsx("svg", Object.assign({ className: "twoBedRooms__icon twoBedRooms__icon--single" }, { children: _jsx("use", { href: `${SelectBed}#singleBed` }) })), _jsx("div", Object.assign({ className: "twoBedRooms__secondSingleBed__overlay" }, { children: t('singleBed') }))] })), _jsx("button", Object.assign({ className: secondBedRoomSingleBedCount
                                                        ? 'twoBedRooms__btn twoBedRooms__btn--single'
                                                        : 'twoBedRooms__btn__start twoBedRooms__btn__start--single', onClick: secondSingleBedCountDecrement }, { children: _jsx("svg", Object.assign({ className: "twoBedRooms__decrement" }, { children: _jsx("use", { href: `${SelectBed}#minus` }) })) })), _jsx("span", Object.assign({ className: "twoBedRooms__counter" }, { children: secondBedRoomSingleBedCount })), _jsx("button", Object.assign({ className: "twoBedRooms__btn", onClick: secondSingleBedCountIncrement }, { children: _jsx("svg", Object.assign({ className: "twoBedRooms__increment" }, { children: _jsx("use", { href: `${SelectBed}#plus` }) })) }))] }))] })) }))] })] })), disableBtn ? (_jsx("button", Object.assign({ className: "twoBedRooms__confirmBtn", type: "submit", onClick: showAttention }, { children: t('done') }))) : (_jsx("button", Object.assign({ className: disableBtn
                    ? 'twoBedRooms__confirmBtn'
                    : 'twoBedRooms__confirmBtn twoBedRooms__confirmBtn__eneble twoBedRooms__confirmBtn__active', type: "submit", disabled: disableBtn, onClick: btnClick }, { children: t('done') }))), isMobile && (_jsx("div", Object.assign({ className: "arrow__roomsicon__block", onClick: backToSelectRoom }, { children: _jsx("svg", Object.assign({ className: "arrow__icon" }, { children: _jsx("use", { href: `${SelectBed}#arrow` }) })) })))] })));
}
