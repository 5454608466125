import { jsx as _jsx } from "react/jsx-runtime";
// import { isProd } from 'config';
import { useAuthContext } from 'context/AuthContext';
import { LoginOrRegisterBtn } from '../LoginOrRegisterBtn';
// import { UserTypePicker } from '../Pickers';
import scss from './DrawerFooter.module.scss';
export const DrawerFooter = () => {
    const [{ isNobody }] = useAuthContext();
    return (_jsx("div", Object.assign({ className: scss.drawerFooter }, { children: isNobody && _jsx(LoginOrRegisterBtn, {}) })));
};
