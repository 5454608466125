import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import loupe from './img/loupe.svg';
import './ListSelectLocation.scss';
const arrLocation = [
    {
        id: 1,
        photo: 'https://res.cloudinary.com/your-price-booking/image/upload/v1683179297/Photos%20of%20locations/Kyiv/Kyiv_lshapx.png',
        city: 'Киев',
        region: 'Киевская область',
        country: 'Украина',
    },
    {
        id: 2,
        photo: 'https://res.cloudinary.com/your-price-booking/image/upload/v1678965218/Photos%20of%20locations/Odesa/odesa_vjpzjf.png',
        city: 'Одесса',
        region: 'Одесская область',
        country: 'Украина',
    },
    {
        id: 3,
        photo: 'https://res.cloudinary.com/your-price-booking/image/upload/v1683179975/Photos%20of%20locations/NYC/new-york-city_vniikz.png',
        city: 'Нью-Йорк',
        region: 'Штат Нью-Йорк',
        country: 'США',
    },
    {
        id: 4,
        photo: 'https://res.cloudinary.com/your-price-booking/image/upload/v1683179663/Photos%20of%20locations/Warsaw/Warszawa_r2x36n.png',
        city: 'Варшава',
        region: 'Мазоветское Воеводство',
        country: 'Польша',
    },
];
export const ListSelectLocation = ({ onChangeValue }) => {
    const [search, setSearch] = useState(arrLocation);
    const codeTouch = () => {
        setSearch(arrLocation);
    };
    const handleSubmit = e => {
        e.preventDefault();
    };
    const LocationSearch = e => {
        const res = arrLocation.filter(value => value.city.toLowerCase().includes(e.target.value.toLowerCase()) ||
            value.region.toLowerCase().includes(e.target.value.toLowerCase()) ||
            value.country.toLowerCase().includes(e.target.value.toLowerCase()));
        setSearch(res);
    };
    const selectLoc = (el, item) => {
        el.stopPropagation();
        // onChangeValue(item);
    };
    const locList = search.map(item => {
        const { id, city, region, country, photo } = item;
        return (_jsxs("li", Object.assign({ className: "list_location_item", onClick: e => selectLoc(e, item), value: city }, { children: [_jsx("img", { src: photo, alt: "city", className: "list_location_photo", width: "52", height: "46" }), _jsxs("div", Object.assign({ className: "list_location_text" }, { children: [_jsxs("span", Object.assign({ className: "city_text" }, { children: [" ", city, " \u00A0"] })), _jsxs("span", Object.assign({ className: "region_text" }, { children: [region, " \u00A0"] })), _jsx("span", Object.assign({ className: "country_text" }, { children: country }))] }))] }), id));
    });
    return (_jsxs("div", Object.assign({ className: "location_body" }, { children: [_jsxs("div", Object.assign({ className: "input_container" }, { children: [_jsx("img", { src: loupe, alt: "search", className: "search_icon" }), _jsx("input", { type: "text/html", maxLength: "45", onChange: LocationSearch, className: "input", onSubmit: handleSubmit, onClick: codeTouch })] })), _jsx("div", Object.assign({ className: "location_container" }, { children: _jsx("div", Object.assign({ className: "list_container" }, { children: _jsxs("ul", Object.assign({ className: "list_location" }, { children: [" ", locList] })) })) }))] })));
};
