import { useAppDispatch } from 'features';
import { decreaseModalCount, increaseModalCount } from 'features/common';
import { useCallback, useMemo, useState } from 'react';
export const useModal = () => {
    const appDispatch = useAppDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = useCallback(() => {
        if (!isModalOpen) {
            appDispatch(increaseModalCount());
        }
        setIsModalOpen(true);
    }, [appDispatch, isModalOpen, setIsModalOpen]);
    const closeModal = useCallback(() => {
        if (isModalOpen) {
            appDispatch(decreaseModalCount());
        }
        setIsModalOpen(false);
    }, [appDispatch, isModalOpen, setIsModalOpen]);
    const toggleModal = useCallback(() => {
        if (isModalOpen) {
            closeModal();
            return;
        }
        openModal();
    }, [isModalOpen, openModal, closeModal]);
    return useMemo(() => [isModalOpen, openModal, closeModal, toggleModal], [isModalOpen, openModal, closeModal, toggleModal]);
};
