import { api, endpoints, tagList } from 'features/api';
import { saveManyAutocompleteResults } from './autocompleteAdapter';
const autocompleteApi = api.injectEndpoints({
    endpoints: build => ({
        getAutocomplete: build.query({
            query: ({ lang }) => `${endpoints.autocomplete}?language=${lang}`,
            transformResponse: (result) => saveManyAutocompleteResults(result.result),
            providesTags: result => tagList(result, 'Autocomplete'),
        })
    }),
});
export const { useGetAutocompleteQuery, useLazyGetAutocompleteQuery } = autocompleteApi;
/*
  url for request get-all-cities
  https://server-your-price-booking.onrender.com/api/autocomplete/get-all-city?language=RU

  old query for cities
  query: ({ search, lang }) => `${endpoints.autocomplete}?search=${search}&limit=10&lang=${lang}`,

*/
