import { api, endpoints, tag, tagList } from 'features/api/api';
import { saveManyHotels, saveManyObjectPaymentsMethod, saveManyObjectServices, saveManyObjectTypes, saveOneHotel, } from './hotelsAdapter';
import { changeUKToUA } from 'features/filter/FilterNameReducer';
export const hotelsApi = api.injectEndpoints({
    endpoints: build => ({
        getHotels: build.query({
            query: () => endpoints.hotels,
            transformResponse: (result) => saveManyHotels(result),
            providesTags: result => tagList(result, 'Hotel'),
        }),
        getHotel: build.query({
            query: id => `${endpoints.hotels}/${id}`,
            transformResponse: (result) => saveOneHotel(result),
            providesTags: (_, __, id) => tag(id, 'Hotel'),
        }),
        getObjectTypes: build.query({
            query: lang => `${endpoints.getObjectTypes}?search=${changeUKToUA(lang)}`,
            transformResponse: (result) => saveManyObjectTypes(result),
            providesTags: (_, __, lang) => tag(lang, 'ObjectType'),
        }),
        getObjectServices: build.query({
            query: lang => `${endpoints.services}?search=${changeUKToUA(lang)}`,
            transformResponse: (result) => saveManyObjectServices(result),
            providesTags: (_, __, lang) => tag(lang, 'ObjectService'),
        }),
        getObjectPaymentsMethod: build.query({
            query: lang => `${endpoints.paymentsMethod}?search=${changeUKToUA(lang)}`,
            transformResponse: (result) => saveManyObjectPaymentsMethod(result),
            providesTags: (_, __, lang) => tag(lang, 'ObjectPaymentMethod'),
        }),
        addHotel: build.mutation({
            query: body => ({
                url: endpoints.hotels,
                method: 'POST',
                body,
            }),
            transformResponse: (result) => saveOneHotel(result),
            // invalidatesTags: (_, __, arg) => tag(arg._id, 'Hotel'),
        }),
        editHotel: build.mutation({
            query: ({ _id, editObjectFormData }) => ({
                url: `${endpoints.hotels}/${_id}`,
                method: 'PATCH',
                body: editObjectFormData,
            }),
            transformResponse: (result) => saveOneHotel(result),
            // invalidatesTags: (_, __, arg) => tag(arg._id, 'Hotel'),
        }),
    }),
});
export const { useGetHotelsQuery, useLazyGetHotelsQuery, useGetHotelQuery, useLazyGetHotelQuery, useAddHotelMutation, useGetObjectTypesQuery, useGetObjectServicesQuery, useGetObjectPaymentsMethodQuery, useEditHotelMutation, } = hotelsApi;
export const selectHotelsResult = hotelsApi.endpoints.getHotels.select();
