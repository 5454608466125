import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import Calendar from 'react-calendar';
import { selectActiveLang } from 'features/common';
import './CalendarDouble.scss';
import next from './image/Vector next.svg';
import prew from './image/Vector back.svg';
export const CalendarDoubleMobile = ({ openCalendar, setOnChange, selectedDataValue, onChangeOpenDatesList }) => {
    const [isOpenCalendar, setIsOpenCalendar] = useState(false);
    const today = new Date();
    const [cachedDateIntervale, setCachedDateIntervale] = useState([]);
    const [dateIntervale, setDateIntervale] = useState([]);
    const [activeStartDate, setActiveStartDate] = useState(today);
    const [isAnim, setIsAnim] = useState(false);
    const outsideRef = useRef(null);
    useEffect(() => {
        if (openCalendar) {
            setIsOpenCalendar(true);
        }
    }, [openCalendar]);
    useEffect(() => {
        if (selectedDataValue && dateIntervale.length === 0) {
            setDateIntervale(selectedDataValue || []);
            setCachedDateIntervale(selectedDataValue || []);
        }
    }, [selectedDataValue]);
    useEffect(() => {
        const handleClickOutside = e => {
            if (outsideRef.current &&
                outsideRef.current.contains &&
                !outsideRef.current.contains(e.target) &&
                selectedDataValue.length > 0 &&
                cachedDateIntervale.length > 0 &&
                dateIntervale.length === 1) {
                setCachedDateIntervale([]);
                setDateIntervale([]);
                setOnChange([]);
                setIsOpenCalendar(false);
                onChangeOpenDatesList(false);
                return;
            }
            if (outsideRef.current &&
                outsideRef.current.contains &&
                !outsideRef.current.contains(e.target) &&
                selectedDataValue.length === 0 &&
                cachedDateIntervale.length === 0 &&
                dateIntervale.length === 0) {
                setCachedDateIntervale([]);
                setDateIntervale([]);
                setOnChange([]);
                setIsOpenCalendar(false);
                onChangeOpenDatesList(false);
                return;
            }
            if (outsideRef.current &&
                outsideRef.current.contains &&
                !outsideRef.current.contains(e.target) &&
                selectedDataValue.length === 0 &&
                cachedDateIntervale.length === 0 &&
                dateIntervale.length === 1) {
                setCachedDateIntervale([]);
                setDateIntervale([]);
                setOnChange([]);
                setIsOpenCalendar(false);
                onChangeOpenDatesList(false);
                return;
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [cachedDateIntervale, dateIntervale, selectedDataValue]);
    const activeLanguageCode = useSelector(selectActiveLang);
    const minDate = new Date();
    const maxDate = new Date();
    maxDate.setMonth(maxDate.getMonth() + 6);
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    useEffect(() => {
        if (dateIntervale.length === 2 && !areArraysEqual(dateIntervale, cachedDateIntervale)) {
            setIsAnim(true);
            setTimeout(() => {
                setOnChange(dateIntervale);
                setIsOpenCalendar(false);
                onChangeOpenDatesList(false);
            }, 2000);
        }
        else {
            setIsAnim(false);
        }
    }, [dateIntervale, cachedDateIntervale]);
    const areArraysEqual = (array1, array2) => {
        if (array1.length !== array2.length) {
            return false;
        }
        for (let i = 0; i < array1.length; i++) {
            if (array1[i] !== array2[i]) {
                return false;
            }
        }
        return true;
    };
    const onActiveStartDateChange = e => {
        if (e && (e.action === 'next' || e.action === 'prev')) {
            setActiveStartDate(e.activeStartDate);
        }
        if (e && !Array.isArray(e.value)) {
            setDateIntervale([e.value]);
        }
        if (e.action == 'onChange') {
            setActiveStartDate(activeStartDate);
        }
    };
    const formatShortWeekday = (locale, date) => {
        const weekdaysShort = getLocalizedWeekdaysShort(locale);
        return weekdaysShort[date.getDay()];
    };
    const getLocalizedWeekdaysShort = locale => {
        const weekdaysShort = {
            ua: ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
            ru: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
            en: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
            pl: ['Ni', 'Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'So'],
        };
        return weekdaysShort[locale] || weekdaysShort['en'];
    };
    const formatMonthYear = (locale, date) => {
        const options = { month: 'long', year: 'numeric' };
        if (locale === 'ru' || locale === 'ua') {
            let formattedDate = date.toLocaleDateString(locale, options);
            formattedDate = formattedDate.replace(/\s?[р|г]\.$/, '');
            return formattedDate;
        }
        return date.toLocaleDateString(locale, options);
    };
    return (_jsx(_Fragment, { children: _jsx("div", Object.assign({ style: isAnim ? { animation: "disappear 2s ease-in-out forwards" } : null, className: "calendar_wrapper_mobile", ref: outsideRef }, { children: _jsx(Calendar, { onChange: setDateIntervale, value: dateIntervale !== '' ? dateIntervale : undefined, showDoubleView: true, selectRange: true, activeStartDate: activeStartDate, onActiveStartDateChange: onActiveStartDateChange, locale: activeLanguageCode, formatShortWeekday: formatShortWeekday, formatMonthYear: formatMonthYear, minDetail: "month", defaultView: "month", prev2Label: null, next2Label: null, maxDate: maxDate, minDate: minDate, showFixedNumberOfWeeks: false, showNeighboringMonth: false, nextLabel: _jsx("img", { src: next, alt: "img-next" }), prevLabel: _jsx("img", { src: prew, alt: "img-prev" }) }) })) }));
};
