import { SEARCH_LOCATION } from './location.types';
import { SEARCH_DEFAULT_LOCATION } from './location.types';
import { SEARCH_PLACE_LOCATION } from './location.types';
export const setDefLocation = item => ({
    type: SEARCH_DEFAULT_LOCATION,
    payload: item,
});
export const setLocation = item => ({
    type: SEARCH_LOCATION,
    payload: item,
});
export const setPlaceLocation = item => ({
    type: SEARCH_PLACE_LOCATION,
    payload: item,
});
