import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import s from './SendRequestModal.module.scss';
const ObjDescr = {
    title: ' Здесь мы пишем про объект - описание объекта. ',
    src: 'Commons-logo.svg Медиафайлы на Викискладе',
    text: 'Па́бло Эми́лио Эскоба́р Гави́рия (исп. Pablo Emilio Escobar Gaviria; 1 декабря 1949 — 2 декабря 1993) — колумбийский наркобарон и террорист. Эскобара называют «Королем кокаина», поскольку он был лидером Медельинского картеля, контролировавшего к концу 1980 года 80 % от мирового рынка кокаина[4].',
};
export const DescriptionBlock = () => {
    return (_jsxs("div", Object.assign({ className: s.DescriptionBlock }, { children: [_jsx("h4", Object.assign({ className: s.DescriptionBlock__title }, { children: ObjDescr.title })), _jsx("p", Object.assign({ className: s.DescriptionBlock__text }, { children: ObjDescr.src })), _jsx("p", Object.assign({ className: s.DescriptionBlock__text }, { children: ObjDescr.text }))] })));
};
