import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import s from './Modal.module.scss';
import { useTranslation } from 'react-i18next';
export const NotOurServiceModal = ({ isError }) => {
    const [isHighlightedText, setHighlightedText] = useState(true);
    const { t } = useTranslation('hotelierCabinet');
    const titleText = t('modalSelectDatesNotBookedYPB');
    useEffect(() => {
        setHighlightedText(true);
        const timeoutId = setTimeout(() => {
            setHighlightedText(false);
        }, 2000);
        return () => clearTimeout(timeoutId);
    }, []);
    return (_jsx("h3", Object.assign({ className: !isError ? s.notOurServiceTitle : (s.notOurServiceTitle + ' ' + s.notOurServiceTitle_error) }, { children: isHighlightedText ? _jsx("span", Object.assign({ className: s.price__highlighted_text }, { children: titleText })) : titleText })));
};
