import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    page: {},
};
const catalogHoteilerPageSlice = createSlice({
    name: 'catalogHoteilerPage',
    initialState,
    reducers: {
        setInfo(state, action) {
            state.page = action.payload;
        },
    },
});
export const { setInfo } = catalogHoteilerPageSlice.actions;
export const catalogHoteilerPageReducer = catalogHoteilerPageSlice.reducer;
