import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useMedia } from 'hooks/UI';
import { DepositIndicator } from './DepositIndicator';
import { HeaderButton } from '../../Components/HeaderButton';
import scss from './ButtonsList.module.scss';
import { useAppSelector } from 'features';
export const SubheaderHotelierButtonsList = () => {
    const { t } = useTranslation('tooltips');
    const { isMobile } = useMedia();
    const theme = useAppSelector(state => state.theme.selectedTheme);
    const dark = theme === 'dark' ? scss.dark : '';
    return (_jsxs("ul", Object.assign({ className: scss.buttonsList }, { children: [!isMobile && (_jsx("li", Object.assign({ className: scss.buttonsItem }, { children: _jsx(DepositIndicator, {}) }))), _jsx("li", Object.assign({ className: scss.buttonsItem }, { children: _jsx(HeaderButton, { unreadMsgs: 0, iconId: "radar", iconClassName: `${scss.radar} ${dark}`, 
                    // TODO update translation keys for hotelier
                    buttonTooltip: t('searchForBookingNearby') }) })), _jsx("li", Object.assign({ className: scss.buttonsItem }, { children: _jsx(HeaderButton, { unreadMsgs: 2, iconId: "friends", iconClassName: `${scss.friends} ${dark}`, 
                    // buttonClassName={scss.friendsButton}
                    // TODO update translation keys for hotelier
                    buttonTooltip: t('newBooking') }) })), _jsx("li", Object.assign({ className: scss.buttonsItem }, { children: _jsx(HeaderButton, { unreadMsgs: 5, iconId: "envelope", iconClassName: `${scss.envelope} ${dark}`, buttonTooltip: t('message') }) }))] })));
};
export const SubheaderUserButtonsList = () => {
    var _a;
    const { t } = useTranslation();
    const theme = useAppSelector(state => state.theme.selectedTheme);
    const dark = theme === 'dark' ? scss.dark : '';
    return (_jsxs("ul", Object.assign({ className: scss.buttonsList }, { children: [_jsx("li", Object.assign({ className: scss.buttonsItem }, { children: _jsx(HeaderButton, { unreadMsgs: 21, iconId: "envelope", iconClassName: `${scss.envelope} ${dark}`, buttonTooltip: t('btnUserMessages') }) })), _jsx("li", Object.assign({ className: scss.buttonsItem }, { children: _jsx(HeaderButton, { unreadMsgs: 99, iconId: "simple-house-with-door", iconClassName: (_a = `${scss.simpleHouseWithDoor} ${dark}`) !== null && _a !== void 0 ? _a : '', 
                    // TODO update translation keys for hotelier
                    buttonTooltip: t('btnHotelierProposal') }) }))] })));
};
