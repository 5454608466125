import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { HeaderNobody } from '../Components/HeaderNobody';
import { SubheaderAgent } from './SubheaderAgent';
import scss from './SubheaderMainAgent.module.scss';
import { useSelector } from 'react-redux';
/** Main Agent
 * @see MAIN_AGENT '/main-agent'
 * 1. userType - subheader
 * 2. NO BODY  - NO BODY ✅
 * 3. User     - NO BODY ✅
 * 4. Hotelier - NO BODY ✅
 * 5. Agent    - Agent ✅
 */
export const SubheaderMainAgent = () => {
    const [isAgent, setIsAgent] = useState(false);
    const agent = useSelector((state) => state.auth);
    useEffect(() => {
        if (agent.role === 'agent') {
            setIsAgent(!!agent);
        }
        else {
            setIsAgent(false);
        }
    }, [agent]);
    return (_jsxs("div", Object.assign({ className: scss.mainAgentHeader }, { children: [!isAgent && _jsx(HeaderNobody, {}), isAgent && _jsx(SubheaderAgent, {})] })));
};
