import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
// import { useState } from 'react';
// import { useAuthContext } from 'context/AuthContext';
import { useSelector } from 'react-redux';
import scss from './PersonInfo.module.scss';
import { useAppSelector } from 'features';
export const PersonInfo = () => {
    // const [userKarma] = useState(3);
    // const [{ user }] = useAuthContext();
    const user = useSelector((state) => state.auth);
    const theme = useAppSelector(state => state.theme.selectedTheme);
    const dark = theme === 'dark' ? scss.dark : '';
    const { bookingKarma, firstName, lastName } = user;
    if (!user)
        return null;
    return (_jsxs("div", Object.assign({ className: scss.personWrapper }, { children: [_jsxs("span", Object.assign({ className: `${scss.userName} ${dark}` }, { children: [firstName, " ", lastName] })), _jsxs("p", { children: [_jsxs("span", Object.assign({ className: scss.karmaNumber }, { children: [`${bookingKarma}`, " "] })), _jsx("span", Object.assign({ className: `${scss.bookingKarma} ${dark}` }, { children: "Booking Karma" }))] })] })));
};
