import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    openedModalCount: 0,
};
const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        increaseModalCount: state => {
            state.openedModalCount += 1;
        },
        decreaseModalCount: state => {
            state.openedModalCount -= 1;
        },
    },
});
export const { increaseModalCount, decreaseModalCount } = modalSlice.actions;
export const modalSliceReducer = modalSlice.reducer;
export const selectModalCount = (state) => state.modal.openedModalCount;
export const selectIsModalOpen = (state) => state.modal.openedModalCount > 0;
