import { FOR_HOTELIER_SEO } from 'components/Common/Seo/seoData';
import { useAuthContext } from 'context/AuthContext';
import { SUPPORT, isOnLogin, isOnRegister, isOnMain, isOnCabinet, isOnCatalog } from 'navigation/CONSTANTS';
import { useLocation } from 'react-router-dom';
const getTitle = (pathname) => {
    if (isOnLogin(pathname))
        return 'Login';
    if (isOnRegister(pathname))
        return 'Register';
    if (isOnMain(pathname))
        return 'Main';
    if (isOnCabinet(pathname))
        return 'Cabinet';
    if (isOnCatalog(pathname))
        return 'Catalog';
    if (pathname === SUPPORT)
        return 'Support';
};
export const useUpdateDocumentTitle = () => {
    var _a, _b, _c, _d, _e;
    const { pathname } = useLocation();
    const [{ user }] = useAuthContext();
    if (pathname === ((_a = FOR_HOTELIER_SEO.entities['0']) === null || _a === void 0 ? void 0 : _a.path))
        return FOR_HOTELIER_SEO.entities['0'];
    if (pathname === ((_b = FOR_HOTELIER_SEO.entities['1']) === null || _b === void 0 ? void 0 : _b.path))
        return FOR_HOTELIER_SEO.entities['1'];
    if (pathname === ((_c = FOR_HOTELIER_SEO.entities['2']) === null || _c === void 0 ? void 0 : _c.path))
        return FOR_HOTELIER_SEO.entities['2'];
    if (pathname === ((_d = FOR_HOTELIER_SEO.entities['3']) === null || _d === void 0 ? void 0 : _d.path))
        return FOR_HOTELIER_SEO.entities['3'];
    const title = (_e = getTitle(pathname)) !== null && _e !== void 0 ? _e : 'YPBooking';
    const titleWithEMail = `${title}${(user === null || user === void 0 ? void 0 : user.email) ? ' - ' + user.email : ''}`;
    return {
        title: titleWithEMail ? titleWithEMail : 'YPBooking',
        description: 'Your Price Booking - service for travelers and guests who want to book a hotel, apartment or house from hoteliers',
    };
};
