import { jsx as _jsx } from "react/jsx-runtime";
import { StrictMode } from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from 'redux/store';
import { ProvideAuth } from 'navigation/Auth/ProvideAuth';
import { AuthCtxProvider } from 'context/AuthContext';
import { BrowserRouter } from 'react-router-dom';
import { AppRouter } from 'routes';
hydrate(_jsx(StrictMode, { children: _jsx(Provider, Object.assign({ store: store }, { children: _jsx(PersistGate, Object.assign({ persistor: persistor }, { children: _jsx(ProvideAuth, { children: _jsx(AuthCtxProvider, { children: _jsx(BrowserRouter, { children: _jsx(AppRouter, {}) }) }) }) })) })) }), document.getElementById('root'));
