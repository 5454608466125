import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import f from './sendFeedback.module.scss';
import UserFeedback from './userFeedback/userFeedback';
import { useTranslation } from 'react-i18next';
const MAX = 500;
const MIN = 70;
export const CloseFeedbackModalButton = ({ closeModal }) => (_jsx("button", Object.assign({ className: f.modal_close, onClick: closeModal }, { children: "Close" })));
export const FeedbackModalContent = () => {
    const { t } = useTranslation();
    const [feedbackLength, setFeedbackLength] = useState(0);
    const onChangeText = useCallback(e => {
        setFeedbackLength(e.target.value.length);
    }, []);
    const handleSubmit = useCallback(ev => {
        ev.preventDefault();
        const { value } = ev.currentTarget.feedback;
        if (!value.length) {
            return;
        }
    }, []);
    const withinRange = MIN <= feedbackLength && feedbackLength <= MAX;
    const requiredLength = `(${MIN}/${MAX})`;
    const currentLength = `${feedbackLength}/${MAX}`;
    return (_jsxs("div", Object.assign({ className: f.send_feedback_container }, { children: [_jsxs("div", Object.assign({ className: f.feedback_text_block }, { children: [_jsx("p", Object.assign({ className: f.feedback_text }, { children: t('writeYourReview') })), _jsx("span", Object.assign({ className: f.feedback_text }, { children: requiredLength }))] })), _jsxs("form", Object.assign({ onSubmit: handleSubmit }, { children: [_jsxs("div", Object.assign({ className: f.textarea_div_block }, { children: [_jsx("textarea", { id: "feedbackId", name: "feedback", className: f.textarea_block, onChange: onChangeText, minLength: MIN, maxLength: MAX, required: true }), _jsx("label", Object.assign({ className: f.label_block, htmlFor: "feedbackId" }, { children: currentLength }))] })), _jsxs("div", Object.assign({ className: f.btn_block }, { children: [_jsx("button", Object.assign({ disabled: !withinRange, type: "submit", className: f.btn_send }, { children: t('submitFeedback') })), _jsxs("button", Object.assign({ disabled: !withinRange, className: f.btn_karma }, { children: [_jsx("span", Object.assign({ className: f.btn_karma_text1 }, { children: "+" })), _jsx("span", Object.assign({ className: f.btn_karma_text1 }, { children: "2" })), _jsx("span", Object.assign({ className: f.btn_karma_text2 }, { children: "Booking Karma" }))] }))] }))] }))] })));
};
const SendFeedback = () => {
    return (_jsx("div", Object.assign({ className: f.feedbackPositioned }, { children: _jsx(UserFeedback, {}) })));
};
export default SendFeedback;
