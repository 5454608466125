import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import './ChatHotelier.scss';
import chatIcon from './img/chatIcon.svg';
import { useTranslation } from 'react-i18next';
function ChatHotelier() {
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const { t } = useTranslation();
    function sendMessage() {
        if (message.trim()) {
            setMessages([...messages, message.trim()]);
            setMessage('');
        }
    }
    const isTextareaEmpty = message.trim() === '';
    const sendButtonClass = isTextareaEmpty ? 'send-button empty' : 'send-button';
    return (_jsxs("div", Object.assign({ className: "chat-container" }, { children: [_jsxs("div", Object.assign({ className: "logo-section" }, { children: [_jsx("h2", Object.assign({ className: "chat-title" }, { children: t('chatHotelierTitle') })), _jsx("img", { className: "chat-icon", width: "50", src: chatIcon, alt: "chat-logo" })] })), _jsxs("div", Object.assign({ className: "send-message-section" }, { children: [_jsx("div", Object.assign({ className: "show-message-wrapper" }, { children: messages.map((message, index) => (_jsx("div", Object.assign({ className: "show-message" }, { children: message }), index))) })), _jsxs("div", Object.assign({ className: "input-wrapper" }, { children: [_jsx("textarea", { type: "text", value: message, onChange: event => setMessage(event.target.value), className: "input-message" }), _jsx("button", Object.assign({ className: sendButtonClass, onClick: sendMessage }, { children: _jsx("svg", Object.assign({ className: "send-icon", width: "15", height: "14", viewBox: "0 0 15 14" }, { children: _jsx("path", { d: "M14.5079 6.20052L0.751659 0.0462407C0.532617 -0.0501764 0.271248 0.00726363 0.11887 0.189841C-0.0345652 0.372418 -0.0398561 0.631923 0.106172 0.819629L4.62987 6.6662L0.106172 12.5128C-0.0398561 12.7005 -0.0345652 12.961 0.117812 13.1426C0.220455 13.2667 0.373891 13.3333 0.529442 13.3333C0.604573 13.3333 0.679703 13.3179 0.750601 13.2861L14.5069 7.13187C14.6952 7.04776 14.8148 6.86621 14.8148 6.6662C14.8148 6.46618 14.6952 6.28463 14.5079 6.20052Z" }) })) }))] }))] }))] })));
}
export default ChatHotelier;
