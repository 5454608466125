import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import arrowfree from 'assets/icons/proposeCard/arrowfree.svg';
import s from './FreeButton.module.scss';
import { usePopover } from 'hooks/UI';
import { Popup } from 'components/UserCards/Popup/withPopper/Popup';
export const FreeButton = ({ color, isHover = true }) => {
    const offset = color === 'grey' ? [0, 5] : [8, -10];
    const [parentPopperAttributes, childPopperAttributes, arrowAttributes] = usePopover({
        placement: 'top',
        offset,
    });
    return (_jsxs("div", Object.assign({ className: s.card__btn_free__wrapper }, parentPopperAttributes, { children: [_jsxs("button", Object.assign({ className: color === 'grey' ? s.card__btn_free__grey : s.card__btn_free, type: "button" }, { children: [_jsx("img", { src: arrowfree, alt: "arrow that symbolize free booking return" }), "Free"] })), isHover && _jsx(Popup, { popper: childPopperAttributes, arrow: arrowAttributes })] })));
};
