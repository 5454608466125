import { SEARCH_LOCATION } from './location.types';
import { SEARCH_DEFAULT_LOCATION } from './location.types';
import { SEARCH_PLACE_LOCATION } from './location.types';
const initialState = { defaultLocation: [], location: [], placeLoaction: {} };
export const searchLocationReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_DEFAULT_LOCATION:
            return Object.assign(Object.assign({}, state), { defaultLocation: action.payload });
        case SEARCH_LOCATION:
            return Object.assign(Object.assign({}, state), { location: action.payload });
        case SEARCH_PLACE_LOCATION:
            return Object.assign(Object.assign({}, state), { placeLocation: action.payload });
        default:
            return state;
    }
};
// export const searchLocation = state => state.location;
