import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './TravelCheckbox.scss';
export const TravelCheckbox = ({ onChangeIsWorkTraveling, isWorkTraveling, setAmountOfClicksInCheckbox, suggest }) => {
    const [isWork, setIsWork] = useState(isWorkTraveling);
    const marginLeftInCalendar = 225;
    const marginRightInCalendarCheckBox = 150;
    useEffect(() => {
        if (!onChangeIsWorkTraveling) {
            return;
        }
        onChangeIsWorkTraveling(isWork);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isWork]);
    function updateAnimationX() {
        const screenWidth = window.innerWidth;
        if (screenWidth >= 1200) {
            const percentageIncrease = (screenWidth - 1200) / 1200;
            const newMarginLeftInCalendar = `${marginLeftInCalendar + marginLeftInCalendar * percentageIncrease * 2.65}px`;
            const newMarginRightInCalendarCheckBox = `${marginRightInCalendarCheckBox + marginRightInCalendarCheckBox * percentageIncrease * 4.125}px`;
            document.documentElement.style.setProperty('--marginLeftInCalendar', newMarginLeftInCalendar);
            document.documentElement.style.setProperty('--marginRightInCalendarCheckBox', newMarginRightInCalendarCheckBox);
        }
    }
    updateAnimationX();
    useEffect(() => {
        window.addEventListener('load', updateAnimationX);
        window.addEventListener('resize', updateAnimationX);
        return () => {
            window.removeEventListener('load', updateAnimationX);
            window.removeEventListener('resize', updateAnimationX);
        };
    }, []);
    useEffect(() => {
        if (!suggest) {
            setIsWork(false);
        }
    }, [suggest]);
    const { t } = useTranslation('Calendar');
    return (_jsxs("div", Object.assign({ className: suggest ? 'block_checkbox_work vertical-block' : 'block_checkbox_work' }, { children: [_jsx("input", { type: "checkbox", id: "checkbox_work", className: "checkbox_input", checked: isWork, onClick: setAmountOfClicksInCheckbox, onChange: () => setIsWork(!isWork) }), _jsx("label", Object.assign({ className: `checkbox_label_work ${suggest ? 'animate' : ''}`, htmlFor: "checkbox_work" }, { children: t('traveling') }))] })));
};
