import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isOnHotelierCatalog, isOnUserCatalog } from 'navigation/CONSTANTS';
import { useAuthContext } from 'context/AuthContext';
import { useMedia } from 'hooks/UI';
import { DrawerPickers } from '../Pickers';
import { IconHeader } from '../IconHeader';
import { FooterLinks } from 'components/Common/Footer/FooterLinks';
import { DepositIndicator } from '../../Subheaders/SubheaderHotelier/DepositIndicator';
import { SearchBar } from '../../Subheaders/SubheaderHotelierCatalog/SearchBar';
import { RentListHotelierCatalog } from 'pages/MainHotelier/RentList';
import { UserCatalogBookingOptionsList } from '../../Subheaders';
import scss from './DrawerBody.module.scss';
import { AllRightsReserved } from 'components/Common/Footer/AllRightsReserved';
import { DrawerThemeSwitch } from './DrawerThemeSwitch';
const ThumbUp = () => (_jsx(DepositIndicator, { depositIndicatorClassName: scss.depositIndicatorMobile, tooltipClassName: scss.depositIndicatorButtonTooltipOverlay, buttonClassName: scss.depositIndicatorButtonMobile, overlayClassName: scss.depositIndicatorOverlayMobile }));
const IconAndTextForHoteliers = () => {
    const { t } = useTranslation();
    return (_jsxs("div", Object.assign({ className: scss.userCategory }, { children: [_jsx(IconHeader, { iconId: "house-small-grey", className: scss.forUserCategoryIcon }), _jsx("span", Object.assign({ className: scss.forUserCategory }, { children: t('forHoteliers') }))] })));
};
const HotelierRow = () => {
    const [{ isHotelier }] = useAuthContext();
    const { pathname } = useLocation();
    const { isMobile } = useMedia();
    const showThumb = isHotelier && !isOnHotelierCatalog(pathname);
    return (_jsxs("div", Object.assign({ className: scss.depositIndicatorWrapper }, { children: [_jsx(IconAndTextForHoteliers, {}), showThumb && _jsx(ThumbUp, {}), isMobile && _jsx(DrawerThemeSwitch, {})] })));
};
const FooterLinksMobile = () => (_jsxs("div", Object.assign({ className: scss.navMobile }, { children: [_jsx(FooterLinks, { mode: "dark" }), _jsx(AllRightsReserved, { mode: "dark" })] })));
const RentListDrawer = ({ closeDrawer }) => (_jsx("div", Object.assign({ className: scss.rentListDrawerWrapper }, { children: _jsx(RentListHotelierCatalog, { mode: "dark", closeDrawer: closeDrawer }) })));
const UserBookingOptionsListDrawer = ({ closeDrawer }) => (_jsx("div", Object.assign({ className: scss.userBookingOptionsListDrawer }, { children: _jsx(UserCatalogBookingOptionsList, { mode: "dark", closeDrawer: closeDrawer }) })));
export const DrawerBody = ({ closeDrawer }) => {
    const { isDesktop, isMobile } = useMedia();
    const { pathname } = useLocation();
    const showHotelierCatalogUI = !isDesktop && isOnHotelierCatalog(pathname);
    const showUserCatalogUI = !isDesktop && isOnUserCatalog(pathname);
    return (_jsxs("div", Object.assign({ className: scss.drawerBody }, { children: [showHotelierCatalogUI || showUserCatalogUI ? _jsx(SearchBar, {}) : null, isMobile && _jsx(DepositIndicator, {}), _jsx(DrawerPickers, {}), _jsxs("div", Object.assign({ className: scss.drawerBodyWrapper }, { children: [_jsx(HotelierRow, {}), showHotelierCatalogUI && _jsx(RentListDrawer, { closeDrawer: closeDrawer }), showUserCatalogUI && _jsx(UserBookingOptionsListDrawer, { closeDrawer: closeDrawer }), _jsx(FooterLinksMobile, {})] }))] })));
};
