// used in i18.ts, to define JSON keys in translation files, do not delete
export const ns = Object.freeze([
    'common',
    'validation',
    'auth',
    'mainHotelier',
    'mainAgent',
    'userCatalog',
    'customSelect',
    'tooltips',
    'userCabinet',
    'hotelierMenu',
    'sendRequestModal',
    'userCard',
    'hotelierCabinet',
]);
// const exampleTranslationJSON: TranslationJSON = {
//   common: {
//     chooseObjectLocation: 'aaa',
//     addingObjectAvgDuration: 'bbb'
//   },
//   validation: { hotel: 'jjj', hostel: 'kkk', country: 'lll' },
// };
