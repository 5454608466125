import { SELECT_THEME } from './themes.types';
const initialState = {
    selectedTheme: 'light',
};
export const themeReducer = (state = initialState, action) => {
    switch (action.type) {
        case SELECT_THEME:
            return Object.assign(Object.assign({}, state), { selectedTheme: action.payload });
        default:
            return state;
    }
};
