import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import FilterButton from 'components/FilterButton/FilterButton';
import { FilterHouse } from './components/FilterHouse/FilterHouse';
import s from './ProposeFilter.module.scss';
import { useEffect, useState } from 'react';
export const bookingOptions = [
    'hotels',
    'apartmentsDaily',
    'housesDaily',
    'roomsDaily',
    'hostels',
    'apartHotels',
    'capsuleHotels',
    'guestHouses',
];
const MobileProposeFilter = () => (_jsx("div", Object.assign({ className: `${s.filter__container} ${s.mobile}` }, { children: _jsxs("div", Object.assign({ className: s.filter__mobContainer }, { children: [_jsx("div", Object.assign({ className: s.filter__sort }, { children: _jsx(FilterButton, {}) })), _jsxs("div", Object.assign({ className: s.filter__options }, { children: [_jsx("div", Object.assign({ className: s.filter__row }, { children: _jsx(FilterHouse, { filters: bookingOptions.slice(0, 2) }) })), _jsx("div", Object.assign({ className: s.filter__row }, { children: _jsx(FilterHouse, { filters: bookingOptions.slice(2, 5) }) })), _jsx("div", Object.assign({ className: s.filter__row }, { children: _jsx(FilterHouse, { filters: bookingOptions.slice(5, 8) }) }))] }))] })) })));
const DesktopProposeFilter = () => (_jsxs("div", Object.assign({ className: s.filter__container }, { children: [_jsx(FilterButton, {}), _jsx(FilterHouse, { filters: bookingOptions })] })));
export const ProposeFilter = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const isMobile = windowWidth <= 767;
    return isMobile ? _jsx(MobileProposeFilter, {}) : _jsx(DesktopProposeFilter, {});
};
