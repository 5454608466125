import { createSlice, createSelector } from '@reduxjs/toolkit';
import { languageCodesLowercase } from 'types';
import { makeEntity } from 'utils';
const [EN, UA, PL, RU] = languageCodesLowercase;
const languagesArray = [
    { value: EN, text: 'English' },
    { value: UA, text: 'Українська' },
    { value: PL, text: 'Polski' },
    { value: RU, text: 'Русский' },
];
const languages = Object.freeze(makeEntity(languagesArray, 'value'));
const currenciesArray = [
    { text: 'Гривня', value: 'UAH' },
    { text: 'Dollar', value: 'USD' },
    { text: 'Euro', value: 'EUR' },
    { text: 'Złoty', value: 'PLN' },
    { text: 'Yuán', value: 'CNY' },
    { text: '円', value: 'JPY' },
    { text: 'Pound sterling', value: 'GBP' },
    { text: 'บาท', value: 'THB' },
    { text: 'Теңгесі', value: 'KZT' },
    { text: 'Lirası', value: 'TRY' },
    // {  text: 'Indian Rupee', value: 'INR', },
    // {  text: 'Canadian dollar', value: 'CAD', },
    // {  text: 'Brazilian real', value: 'BRL', },
];
const currencies = Object.freeze(makeEntity(currenciesArray, 'value'));
const initialState = {
    languages,
    currencies,
    activeLanguageId: EN,
    activeCurrencyId: currencies.ids[0],
};
const userSettingsSlice = createSlice({
    name: 'userSettings',
    initialState,
    reducers: {
        chooseLanguage: (state, { payload }) => {
            state.activeLanguageId = payload;
        },
        chooseCurrency: (state, { payload }) => {
            state.activeCurrencyId = payload;
        },
    },
});
export const { chooseLanguage, chooseCurrency } = userSettingsSlice.actions;
export const userSettingsReducer = userSettingsSlice.reducer;
export const selectActiveLanguageId = (state) => state.userSettings.activeLanguageId;
export const selectActiveLanguage = (state) => {
    var _a;
    const id = selectActiveLanguageId(state);
    return (_a = state.userSettings.languages.entities[id]) !== null && _a !== void 0 ? _a : languagesArray[0];
};
export const selectActiveLang = (state) => selectActiveLanguage(state).value;
export const selectActiveLangText = (state) => selectActiveLanguage(state).text;
export const selectLanguages = (state) => state.userSettings.languages;
export const selectLanguagesIds = (state) => selectLanguages(state).ids;
export const selectLanguagesEntities = (state) => selectLanguages(state).entities;
export const selectLanguageById = createSelector(selectLanguages, (_, id) => id, (languages, id) => languages.entities[id]);
export const selectCurrencies = (state) => state.userSettings.currencies;
export const selectCurrenciesIds = (state) => selectCurrencies(state).ids;
export const selectCurrenciesEntities = (state) => selectCurrencies(state).entities;
export const selectCurrencyById = createSelector(selectCurrencies, (_, id) => id, (currenciesEntity, id) => { var _a; return (_a = currenciesEntity.entities) === null || _a === void 0 ? void 0 : _a[id]; });
export const selectActiveCurrencyId = (state) => state.userSettings.activeCurrencyId;
export const selectActiveCurrency = (state) => {
    var _a;
    const id = selectActiveCurrencyId(state);
    return (_a = state.userSettings.currencies.entities[id]) !== null && _a !== void 0 ? _a : currenciesArray[0];
};
export const selectActiveCurrencyCode = (state) => selectActiveCurrency(state).value;
export const selectActiveCurrencyText = (state) => selectActiveCurrency(state).text;
