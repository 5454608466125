import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import s from './SendRequestModal.module.scss';
import smallDogIcon from '../../../../../../assets/icons/userCabinetSendReq/animals/smallDog.svg';
import mediumDogIcon from '../../../../../../assets/icons/userCabinetSendReq/animals/mediumDog.svg';
import largeDogIcon from '../../../../../../assets/icons/userCabinetSendReq/animals/largeDog.svg';
import catIcon from '../../../../../../assets/icons/userCabinetSendReq/animals/cat.svg';
import raccoonIcon from '../../../../../../assets/icons/userCabinetSendReq/animals/raccoon.svg';
import nonPredatorIcon from '../../../../../../assets/icons/userCabinetSendReq/animals/nonPredator.svg';
const animalsData = {
    smallDog: { text: 'С маленьким псом', icon: smallDogIcon },
    mediumDog: { text: 'Со средним псом', icon: mediumDogIcon },
    largeDog: { text: 'С большим псом', icon: largeDogIcon },
    cat: { text: 'С котом', icon: catIcon },
    raccoon: { text: 'С енотом', icon: raccoonIcon },
    nonPredator: { text: 'С не хищным животным, меньше кота', icon: nonPredatorIcon },
};
const Animals = () => {
    return (_jsxs("div", { children: [_jsx("h2", { children: "\u041C\u043E\u0436\u0435\u0442\u0435 \u043E\u0441\u0442\u0430\u043D\u043E\u0432\u0438\u0442\u044C\u0441\u044F \u0443 \u043D\u0430\u0441 \u0441:" }), _jsx("ul", { children: Object.entries(animalsData).map(([key, { text, icon }], index) => (_jsxs("li", Object.assign({ className: s.Animals__item }, { children: [_jsx("img", { className: s.Animals__icon, src: icon, alt: key }), _jsx("p", Object.assign({ className: s.Animals__text }, { children: text }))] }), index))) })] }));
};
export default Animals;
