import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMedia, usePopover } from 'hooks/UI';
import { useTranslation } from 'react-i18next';
import room1x1 from '../image/icon/1x1.png';
import SelectBed from '../../../MainPage/Assents/image/SelectBed.svg';
// import { getImage } from '../image/getImage';
import s from '../BedOptions.module.scss';
export const HostelRoom = ({ bed, title }) => {
    const { t } = useTranslation('userCard');
    const [parentPopperAttributes] = usePopover({
        offset: [0, 13],
        placement: 'top',
    });
    const { isMobile, isTablet } = useMedia();
    const deviceType = isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop';
    return (_jsxs("div", Object.assign({}, parentPopperAttributes, { className: `${s.hostel_block} hostel_${deviceType}`, onClick: e => {
            if (deviceType !== 'desktop') {
                const currentTarget = e.currentTarget;
                currentTarget.classList.add('active');
                setTimeout(() => {
                    currentTarget.classList.remove('active');
                }, 1500);
            }
        } }, { children: [_jsxs("div", Object.assign({ className: s.hostel__descr }, { children: [_jsxs("p", Object.assign({ className: s.hostel__descr_text }, { children: [t('roommateTotal'), ' ', _jsx("span", { children: bed.descriptionInResponseData.total })] })), _jsx("svg", Object.assign({ className: s.hostel__descr_icon }, { children: _jsx("use", { href: `${SelectBed}#singleBed` }) }))] })), _jsxs("div", Object.assign({ className: s.hostel__bed_container }, { children: [_jsx("p", Object.assign({ className: s.hostel__bed_number }, { children: bed.descriptionInResponseData.free })), _jsx("img", { src: room1x1, alt: title })] })), _jsx("div", Object.assign({ className: s.room_title }, { children: _jsx("p", { children: t('roommate') }) }))] })));
};
