import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { fourBedRoomFirstRoomDoubleBedCountIncrement, fourBedRoomFirstRoomDoubleBedCountDecrement, fourBedRoomFirstRoomSingleBedCountIncrement, fourBedRoomFirstRoomSingleBedCountDecrement, fourBedRoomSecondRoomDoubleBedCountIncrement, fourBedRoomSecondRoomDoubleBedCountDecrement, fourBedRoomSecondRoomSingleBedCountIncrement, fourBedRoomSecondRoomSingleBedCountDecrement, fourBedRoomThirdRoomDoubleBedCountIncrement, fourBedRoomThirdRoomDoubleBedCountDecrement, fourBedRoomThirdRoomSingleBedCountIncrement, fourBedRoomThirdRoomSingleBedCountDecrement, fourBedRoomFourthRoomDoubleBedCountIncrement, fourBedRoomFourthRoomDoubleBedCountDecrement, fourBedRoomFourthRoomSingleBedCountIncrement, fourBedRoomFourthRoomSingleBedCountDecrement, } from '../../../../redux/bedRooms/bedRooms.actions.js';
import './FourBedRoom.scss';
import SelectBed from '../../Assents/image/SelectBed.svg';
import { useTranslation } from 'react-i18next';
import { useScreenResizing } from 'hooks/UI/useScreenResizing.js';
export default function FourBedRoom({ backToSelectRoom, readyBtnFourBedRoom }) {
    const viewPort = useScreenResizing();
    const isMobile = viewPort.width > 319 && viewPort.width < 768;
    const { t } = useTranslation('Calendar');
    const [showFourBedroomFirstBlockAttetion, setShowFourBedroomFistBlockAttetion] = useState(false);
    const [showFourBedroomSecondBlockAttetion, setShowFourBedroomSecondBlockAttetion] = useState(false);
    const [showFourBedroomThirdBlockAttetion, setShowFourBedroomThirdBlockAttetion] = useState(false);
    const [showFourBedroomFourthBlockAttetion, setShowFourBedroomFourthBlockAttetion] = useState(false);
    //one bedroom
    const dispatch = useDispatch();
    const firstBedRoomDoubleBedCount = useSelector(state => state.bedRoomReducer.fourBedRoomFirstRoomDoubleCount);
    const firstBedRoomSingleBedCount = useSelector(state => state.bedRoomReducer.fourBedRoomFirstRoomSingleBedCount);
    const fistBedRoomCountAllBed = firstBedRoomDoubleBedCount + firstBedRoomSingleBedCount;
    const firstBedRoomDoubleCountDecrement = () => {
        if (firstBedRoomDoubleBedCount === 0) {
            return firstBedRoomDoubleBedCount;
        }
        else {
            dispatch(fourBedRoomFirstRoomDoubleBedCountDecrement(1));
        }
    };
    const firstBedRoomDoubleCountIncrement = () => {
        if (firstBedRoomDoubleBedCount < 3 && fistBedRoomCountAllBed < 3) {
            dispatch(fourBedRoomFirstRoomDoubleBedCountIncrement(1));
            setShowFourBedroomFistBlockAttetion(false);
        }
        else {
            return firstBedRoomDoubleBedCount;
        }
    };
    const firstSingleBedCountDecrement = () => {
        if (firstBedRoomSingleBedCount === 0) {
            return firstBedRoomSingleBedCount;
        }
        else {
            dispatch(fourBedRoomFirstRoomSingleBedCountDecrement(1));
        }
    };
    const firstSingleBedCountIncrement = () => {
        if (firstBedRoomSingleBedCount < 3 && fistBedRoomCountAllBed < 3) {
            dispatch(fourBedRoomFirstRoomSingleBedCountIncrement(1));
            setShowFourBedroomFistBlockAttetion(false);
        }
        else {
            return firstBedRoomSingleBedCount;
        }
    };
    //second bedroom
    const secondBedRoomDoubleBedCount = useSelector(state => state.bedRoomReducer.fourBedRoomSecondRoomDoubleCount);
    const secondBedRoomSingleBedCount = useSelector(state => state.bedRoomReducer.fourBedRoomSecondRoomSingleBedCount);
    const secondBedRoomCountAllBed = secondBedRoomDoubleBedCount + secondBedRoomSingleBedCount;
    const secondBedRoomDoubluCountDecrement = () => {
        if (secondBedRoomDoubleBedCount === 0) {
            return secondBedRoomDoubleBedCount;
        }
        else {
            dispatch(fourBedRoomSecondRoomDoubleBedCountDecrement(1));
        }
    };
    const secondBedRoomDoubluCountIncrement = () => {
        if (secondBedRoomDoubleBedCount < 3 && secondBedRoomCountAllBed < 3) {
            dispatch(fourBedRoomSecondRoomDoubleBedCountIncrement(1));
            setShowFourBedroomSecondBlockAttetion(false);
        }
        else {
            return secondBedRoomDoubleBedCount;
        }
    };
    const secondSingleBedCountDecrement = () => {
        if (secondBedRoomSingleBedCount === 0) {
            return secondBedRoomSingleBedCount;
        }
        else {
            dispatch(fourBedRoomSecondRoomSingleBedCountDecrement(1));
        }
    };
    const secondSingleBedCountIncrement = () => {
        if (secondBedRoomSingleBedCount < 3 && secondBedRoomCountAllBed < 3) {
            dispatch(fourBedRoomSecondRoomSingleBedCountIncrement(1));
            setShowFourBedroomSecondBlockAttetion(false);
        }
        else {
            return secondBedRoomSingleBedCount;
        }
    };
    //third bedroom
    const thirdBedRoomDoubleBedCount = useSelector(state => state.bedRoomReducer.fourBedRoomThirdRoomDoubleCount);
    const thirdBedRoomSingleBedCount = useSelector(state => state.bedRoomReducer.fourBedRoomThirdRoomSingleBedCount);
    const thirdBedRoomCountAllBed = thirdBedRoomDoubleBedCount + thirdBedRoomSingleBedCount;
    const thirdBedRoomDoubluCountDecrement = () => {
        if (thirdBedRoomDoubleBedCount === 0) {
            return thirdBedRoomDoubleBedCount;
        }
        else {
            dispatch(fourBedRoomThirdRoomDoubleBedCountDecrement(1));
        }
    };
    const thirdBedRoomDoubluCountIncrement = () => {
        if (thirdBedRoomDoubleBedCount < 3 && thirdBedRoomCountAllBed < 3) {
            dispatch(fourBedRoomThirdRoomDoubleBedCountIncrement(1));
            setShowFourBedroomThirdBlockAttetion(false);
        }
        else {
            return thirdBedRoomDoubleBedCount;
        }
    };
    const thirdSingleBedCountDecrement = () => {
        if (thirdBedRoomSingleBedCount === 0) {
            return thirdBedRoomSingleBedCount;
        }
        else {
            dispatch(fourBedRoomThirdRoomSingleBedCountDecrement(1));
        }
    };
    const thirdSingleBedCountIncrement = () => {
        if (thirdBedRoomSingleBedCount < 3 && thirdBedRoomCountAllBed < 3) {
            dispatch(fourBedRoomThirdRoomSingleBedCountIncrement(1));
            setShowFourBedroomThirdBlockAttetion(false);
        }
        else {
            return thirdBedRoomSingleBedCount;
        }
    };
    //fourth bedroom
    const fourthBedRoomDoubleBedCount = useSelector(state => state.bedRoomReducer.fourBedRoomFourthRoomDoubleCount);
    const fourthBedRoomSingleBedCount = useSelector(state => state.bedRoomReducer.fourBedRoomFourthRoomSingleBedCount);
    const fourthBedRoomCountAllBed = fourthBedRoomDoubleBedCount + fourthBedRoomSingleBedCount;
    const fourthBedRoomDoubluCountDecrement = () => {
        if (fourthBedRoomDoubleBedCount === 0) {
            return fourthBedRoomDoubleBedCount;
        }
        else {
            dispatch(fourBedRoomFourthRoomDoubleBedCountDecrement(1));
        }
    };
    const fourthBedRoomDoubluCountIncrement = () => {
        if (fourthBedRoomDoubleBedCount < 3 && fourthBedRoomCountAllBed < 3) {
            dispatch(fourBedRoomFourthRoomDoubleBedCountIncrement(1));
            setShowFourBedroomFourthBlockAttetion(false);
        }
        else {
            return fourthBedRoomDoubleBedCount;
        }
    };
    const fourthSingleBedCountDecrement = () => {
        if (fourthBedRoomSingleBedCount === 0) {
            return fourthBedRoomSingleBedCount;
        }
        else {
            dispatch(fourBedRoomFourthRoomSingleBedCountDecrement(1));
        }
    };
    const fourthSingleBedCountIncrement = () => {
        if (fourthBedRoomSingleBedCount < 3 && fourthBedRoomCountAllBed < 3) {
            dispatch(fourBedRoomFourthRoomSingleBedCountIncrement(1));
            setShowFourBedroomFourthBlockAttetion(false);
        }
        else {
            return fourthBedRoomSingleBedCount;
        }
    };
    const disableBtn = (firstBedRoomDoubleBedCount || firstBedRoomSingleBedCount) &&
        (secondBedRoomDoubleBedCount || secondBedRoomSingleBedCount) &&
        (thirdBedRoomDoubleBedCount || thirdBedRoomSingleBedCount) &&
        (fourthBedRoomDoubleBedCount || fourthBedRoomSingleBedCount)
        ? false
        : true;
    //handle btn
    const btnClick = () => {
        readyBtnFourBedRoom();
    };
    const showAttention = () => {
        if (!firstBedRoomDoubleBedCount && !firstBedRoomSingleBedCount) {
            setShowFourBedroomFistBlockAttetion(true);
        }
        if (!secondBedRoomDoubleBedCount && !secondBedRoomSingleBedCount) {
            setShowFourBedroomSecondBlockAttetion(true);
        }
        if (!thirdBedRoomDoubleBedCount && !thirdBedRoomSingleBedCount) {
            setShowFourBedroomThirdBlockAttetion(true);
        }
        if (!fourthBedRoomDoubleBedCount && !fourthBedRoomSingleBedCount) {
            setShowFourBedroomFourthBlockAttetion(true);
        }
    };
    return (_jsxs("div", Object.assign({ className: "fourBedRooms__section" }, { children: [_jsxs("div", Object.assign({ className: "fourBedRooms__group" }, { children: [_jsxs("div", Object.assign({ className: "fourBedRooms__div" }, { children: [_jsxs("p", Object.assign({ className: "fourBedRooms__text" }, { children: [t('bedroom'), " 1"] })), _jsx("div", Object.assign({ className: showFourBedroomFirstBlockAttetion
                                    ? 'fourBedRooms__block fourBedRooms__block--active'
                                    : 'fourBedRooms__block' }, { children: _jsxs("div", Object.assign({ className: "fourBedRooms__selected__block" }, { children: [_jsxs("div", Object.assign({ className: "fourBedRooms__selected__bed" }, { children: [_jsxs("div", Object.assign({ className: "fourBedRooms__firstDoubleBedRoom__hover" }, { children: [_jsx("svg", Object.assign({ className: "fourBedRooms__icon" }, { children: _jsx("use", { href: `${SelectBed}#doubleBed` }) })), _jsx("div", Object.assign({ className: "fourBedRooms__firstDoubleBedRoom__overlay" }, { children: t('doubleBed') }))] })), _jsx("button", Object.assign({ className: firstBedRoomDoubleBedCount
                                                        ? 'fourBedRooms__btn fourBedRooms__btn--double'
                                                        : 'fourBedRooms__btn__start fourBedRooms__btn__start--double', onClick: firstBedRoomDoubleCountDecrement }, { children: _jsx("svg", Object.assign({ className: "fourBedRooms__decrement" }, { children: _jsx("use", { href: `${SelectBed}#minus` }) })) })), _jsx("span", Object.assign({ className: "fourBedRooms__counter" }, { children: firstBedRoomDoubleBedCount })), _jsx("button", Object.assign({ className: "fourBedRooms__btn", onClick: firstBedRoomDoubleCountIncrement }, { children: _jsx("svg", Object.assign({ className: "fourBedRooms__increment" }, { children: _jsx("use", { href: `${SelectBed}#plus` }) })) }))] })), _jsxs("div", Object.assign({ className: "fourBedRooms__selected__bed fourBedRooms__selected__bed--modif" }, { children: [_jsxs("div", Object.assign({ className: "fourBedRooms__firstSingleBed__hover" }, { children: [_jsx("svg", Object.assign({ className: "fourBedRooms__icon fourBedRooms__icon--single" }, { children: _jsx("use", { href: `${SelectBed}#singleBed` }) })), _jsx("div", Object.assign({ className: "fourBedRooms__firstSingleBed__overlay" }, { children: t('singleBed') }))] })), _jsx("button", Object.assign({ className: firstBedRoomSingleBedCount
                                                        ? 'fourBedRooms__btn fourBedRooms__btn--single'
                                                        : 'fourBedRooms__btn__start fourBedRooms__btn__start--single', onClick: firstSingleBedCountDecrement }, { children: _jsx("svg", Object.assign({ className: "fourBedRooms__decrement" }, { children: _jsx("use", { href: `${SelectBed}#minus` }) })) })), _jsx("span", Object.assign({ className: "fourBedRooms__counter" }, { children: firstBedRoomSingleBedCount })), _jsx("button", Object.assign({ className: "fourBedRooms__btn", onClick: firstSingleBedCountIncrement }, { children: _jsx("svg", Object.assign({ className: "fourBedRooms__increment" }, { children: _jsx("use", { href: `${SelectBed}#plus` }) })) }))] }))] })) }))] })), _jsxs("div", Object.assign({ className: "fourBedRooms__div" }, { children: [_jsxs("p", Object.assign({ className: "fourBedRooms__text" }, { children: [t('bedroom'), " 2"] })), _jsx("div", Object.assign({ className: showFourBedroomSecondBlockAttetion
                                    ? 'fourBedRooms__block fourBedRooms__block--active'
                                    : 'fourBedRooms__block' }, { children: _jsxs("div", Object.assign({ className: "fourBedRooms__selected__block" }, { children: [_jsxs("div", Object.assign({ className: "fourBedRooms__selected__bed" }, { children: [_jsxs("div", Object.assign({ className: "fourBedRooms__secondDoubleBedRoom__hover" }, { children: [_jsx("svg", Object.assign({ className: "fourBedRooms__icon" }, { children: _jsx("use", { href: `${SelectBed}#doubleBed` }) })), _jsx("div", Object.assign({ className: "fourBedRooms__secondDoubleBedRoom__overlay" }, { children: t('doubleBed') }))] })), _jsx("button", Object.assign({ className: secondBedRoomDoubleBedCount
                                                        ? 'fourBedRooms__btn fourBedRooms__btn--double'
                                                        : 'fourBedRooms__btn__start fourBedRooms__btn__start--double', onClick: secondBedRoomDoubluCountDecrement }, { children: _jsx("svg", Object.assign({ className: "fourBedRooms__decrement" }, { children: _jsx("use", { href: `${SelectBed}#minus` }) })) })), _jsx("span", Object.assign({ className: "fourBedRooms__counter" }, { children: secondBedRoomDoubleBedCount })), _jsx("button", Object.assign({ className: "fourBedRooms__btn", onClick: secondBedRoomDoubluCountIncrement }, { children: _jsx("svg", Object.assign({ className: "fourBedRooms__increment" }, { children: _jsx("use", { href: `${SelectBed}#plus` }) })) }))] })), _jsxs("div", Object.assign({ className: "fourBedRooms__selected__bed fourBedRooms__selected__bed--modif" }, { children: [_jsxs("div", Object.assign({ className: "fourBedRooms__secondSingleBed__hover" }, { children: [_jsx("svg", Object.assign({ className: "fourBedRooms__icon fourBedRooms__icon--single" }, { children: _jsx("use", { href: `${SelectBed}#singleBed` }) })), _jsx("div", Object.assign({ className: "fourBedRooms__secondSingleBed__overlay" }, { children: t('singleBed') }))] })), _jsx("button", Object.assign({ className: secondBedRoomSingleBedCount
                                                        ? 'fourBedRooms__btn fourBedRooms__btn--single'
                                                        : 'fourBedRooms__btn__start fourBedRooms__btn__start--single', onClick: secondSingleBedCountDecrement }, { children: _jsx("svg", Object.assign({ className: "fourBedRooms__decrement" }, { children: _jsx("use", { href: `${SelectBed}#minus` }) })) })), _jsx("span", Object.assign({ className: "fourBedRooms__counter" }, { children: secondBedRoomSingleBedCount })), _jsx("button", Object.assign({ className: "fourBedRooms__btn", onClick: secondSingleBedCountIncrement }, { children: _jsx("svg", Object.assign({ className: "fourBedRooms__increment" }, { children: _jsx("use", { href: `${SelectBed}#plus` }) })) }))] }))] })) }))] })), _jsxs("div", Object.assign({ className: "fourBedRooms__div" }, { children: [_jsxs("p", Object.assign({ className: "fourBedRooms__text" }, { children: [t('bedroom'), " 3"] })), _jsx("div", Object.assign({ className: showFourBedroomThirdBlockAttetion
                                    ? 'fourBedRooms__block fourBedRooms__block--active'
                                    : 'fourBedRooms__block' }, { children: _jsxs("div", Object.assign({ className: "fourBedRooms__selected__block" }, { children: [_jsxs("div", Object.assign({ className: "fourBedRooms__selected__bed" }, { children: [_jsxs("div", Object.assign({ className: "fourBedRooms__thirdDoubleBedRoom__hover" }, { children: [_jsx("svg", Object.assign({ className: "fourBedRooms__icon" }, { children: _jsx("use", { href: `${SelectBed}#doubleBed` }) })), _jsx("div", Object.assign({ className: "fourBedRooms__thirdDoubleBedRoom__overlay" }, { children: t('doubleBed') }))] })), _jsx("button", Object.assign({ className: thirdBedRoomDoubleBedCount
                                                        ? 'fourBedRooms__btn fourBedRooms__btn--double'
                                                        : 'fourBedRooms__btn__start fourBedRooms__btn__start--double', onClick: thirdBedRoomDoubluCountDecrement }, { children: _jsx("svg", Object.assign({ className: "fourBedRooms__decrement" }, { children: _jsx("use", { href: `${SelectBed}#minus` }) })) })), _jsx("span", Object.assign({ className: "fourBedRooms__counter" }, { children: thirdBedRoomDoubleBedCount })), _jsx("button", Object.assign({ className: "fourBedRooms__btn", onClick: thirdBedRoomDoubluCountIncrement }, { children: _jsx("svg", Object.assign({ className: "fourBedRooms__increment" }, { children: _jsx("use", { href: `${SelectBed}#plus` }) })) }))] })), _jsxs("div", Object.assign({ className: "fourBedRooms__selected__bed fourBedRooms__selected__bed--modif" }, { children: [_jsxs("div", Object.assign({ className: "fourBedRooms__thirdSingleBed__hover" }, { children: [_jsx("svg", Object.assign({ className: "fourBedRooms__icon fourBedRooms__icon--single" }, { children: _jsx("use", { href: `${SelectBed}#singleBed` }) })), _jsx("div", Object.assign({ className: "fourBedRooms__thirdSingleBed__overlay" }, { children: t('singleBed') }))] })), _jsx("button", Object.assign({ className: thirdBedRoomSingleBedCount
                                                        ? 'fourBedRooms__btn fourBedRooms__btn--single'
                                                        : 'fourBedRooms__btn__start fourBedRooms__btn__start--single', onClick: thirdSingleBedCountDecrement }, { children: _jsx("svg", Object.assign({ className: "fourBedRooms__decrement" }, { children: _jsx("use", { href: `${SelectBed}#minus` }) })) })), _jsx("span", Object.assign({ className: "fourBedRooms__counter" }, { children: thirdBedRoomSingleBedCount })), _jsx("button", Object.assign({ className: "fourBedRooms__btn", onClick: thirdSingleBedCountIncrement }, { children: _jsx("svg", Object.assign({ className: "fourBedRooms__increment" }, { children: _jsx("use", { href: `${SelectBed}#plus` }) })) }))] }))] })) }))] })), _jsxs("div", Object.assign({ className: "fourBedRooms__div" }, { children: [_jsxs("p", Object.assign({ className: "fourBedRooms__text" }, { children: [t('bedroom'), " 4"] })), _jsx("div", Object.assign({ className: showFourBedroomFourthBlockAttetion
                                    ? 'fourBedRooms__block fourBedRooms__block--active'
                                    : 'fourBedRooms__block' }, { children: _jsxs("div", Object.assign({ className: "fourBedRooms__selected__block" }, { children: [_jsxs("div", Object.assign({ className: "fourBedRooms__selected__bed" }, { children: [_jsxs("div", Object.assign({ className: "fourBedRooms__fourthDoubleBedRoom__hover" }, { children: [_jsx("svg", Object.assign({ className: "fourBedRooms__icon" }, { children: _jsx("use", { href: `${SelectBed}#doubleBed` }) })), _jsx("div", Object.assign({ className: "fourBedRooms__fourthDoubleBedRoom__overlay" }, { children: t('doubleBed') }))] })), _jsx("button", Object.assign({ className: fourthBedRoomDoubleBedCount
                                                        ? 'fourBedRooms__btn fourBedRooms__btn--double'
                                                        : 'fourBedRooms__btn__start fourBedRooms__btn__start--double', onClick: fourthBedRoomDoubluCountDecrement }, { children: _jsx("svg", Object.assign({ className: "fourBedRooms__decrement" }, { children: _jsx("use", { href: `${SelectBed}#minus` }) })) })), _jsx("span", Object.assign({ className: "fourBedRooms__counter" }, { children: fourthBedRoomDoubleBedCount })), _jsx("button", Object.assign({ className: "fourBedRooms__btn", onClick: fourthBedRoomDoubluCountIncrement }, { children: _jsx("svg", Object.assign({ className: "fourBedRooms__increment" }, { children: _jsx("use", { href: `${SelectBed}#plus` }) })) }))] })), _jsxs("div", Object.assign({ className: "fourBedRooms__selected__bed fourBedRooms__selected__bed--modif" }, { children: [_jsxs("div", Object.assign({ className: "fourBedRooms__fourthSingleBed__hover" }, { children: [_jsx("svg", Object.assign({ className: "fourBedRooms__icon fourBedRooms__icon--single" }, { children: _jsx("use", { href: `${SelectBed}#singleBed` }) })), _jsx("div", Object.assign({ className: "fourBedRooms__fourthSingleBed__overlay" }, { children: t('singleBed') }))] })), _jsx("button", Object.assign({ className: fourthBedRoomSingleBedCount
                                                        ? 'fourBedRooms__btn fourBedRooms__btn--single'
                                                        : 'fourBedRooms__btn__start fourBedRooms__btn__start--single', onClick: fourthSingleBedCountDecrement }, { children: _jsx("svg", Object.assign({ className: "fourBedRooms__decrement" }, { children: _jsx("use", { href: `${SelectBed}#minus` }) })) })), _jsx("span", Object.assign({ className: "fourBedRooms__counter" }, { children: fourthBedRoomSingleBedCount })), _jsx("button", Object.assign({ className: "fourBedRooms__btn", onClick: fourthSingleBedCountIncrement }, { children: _jsx("svg", Object.assign({ className: "fourBedRooms__increment" }, { children: _jsx("use", { href: `${SelectBed}#plus` }) })) }))] }))] })) }))] }))] })), disableBtn ? (_jsx("button", Object.assign({ className: "fourBedRooms__confirmBtn", type: "submit", onClick: showAttention }, { children: t('done') }))) : (_jsx("button", Object.assign({ className: disableBtn
                    ? 'fourBedRooms__confirmBtn'
                    : 'fourBedRooms__confirmBtn fourBedRooms__confirmBtn__eneble fourBedRooms__confirmBtn__active', type: "submit", disabled: disableBtn, onClick: btnClick }, { children: t('done') }))), isMobile && (_jsx("div", Object.assign({ className: "arrow__roomsicon__block", onClick: backToSelectRoom }, { children: _jsx("svg", Object.assign({ className: "arrow__icon" }, { children: _jsx("use", { href: `${SelectBed}#arrow` }) })) })))] })));
}
