import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import s from './ModalGallery.module.scss';
export default function ModalGallery({ size = 'medium', children, album, collums = '4', childrenPosition }) {
    const [bigPhoto, bigPhotoSet] = useState(album[0].name);
    const [gallery] = useState(album);
    const changePhoto = p => {
        return bigPhotoSet(p);
    };
    const sizeGallery = (size === 'small' && s.smallS) || (size === 'medium' && s.mediumS);
    const collumsStyle = collums === '3' && s.treeCollums;
    return (_jsxs("div", Object.assign({ className: s.gallery }, { children: [_jsxs("div", Object.assign({ className: `${s.content_wrapper}` }, { children: [_jsx("img", { src: bigPhoto, alt: "img of propose", className: `${s.big__photo} ${sizeGallery}` }), childrenPosition === 'bottom left' && children] })), _jsxs("div", { children: [childrenPosition === 'top rigth' && children, _jsx("div", Object.assign({ className: `${s.photo_gallery} ${sizeGallery}  ${collumsStyle}` }, { children: gallery.map((p, index) => {
                            if (index <= 8) {
                                return (_jsx("img", { src: p.name, alt: "mini img of propose", className: `${s.small_photo} ${collumsStyle}`, onClick: () => changePhoto(p.name) }, p.id));
                            }
                            return _jsx(_Fragment, {});
                        }) }))] })] })));
}
