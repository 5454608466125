var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import classNames from 'classnames';
const Image = (_a) => {
    var { src, alt, className, width, height } = _a, attrs = __rest(_a, ["src", "alt", "className", "width", "height"]);
    if (!src) {
        // src = `https://via.placeholder.com/${width}x${height}`;
        src = `https://i.ibb.co/nfGwMww/Rectangle-96.jpg/${width}x${height}`; // заглушка временная
    }
    const classes = classNames(className);
    return (_jsx("img", Object.assign({ src: src, alt: alt, className: classes, width: width, height: height }, attrs)));
};
Image.defaultProps = {
    src: '',
    alt: 'image name',
    className: '',
    width: 100,
    height: 100,
};
export default Image;
