import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import s from './HoteliersProposesPage.module.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UserRequestCard } from 'components/UserRequestCard';
// import { useToggleModal } from 'components/UserCabinet/ModalW/Modal/useToggleModal';
// import { ModalDetailPropose } from 'components/UserCabinet/ModalW/DetailPropose/ModalDetailPropose';
import { useState } from 'react';
import { goProposeToTheEndOgArray } from 'components/BookingPropose/hooks/goProposeToTheEndOfArray';
import { HoteliersProposeCard } from 'components/BookingPropose/components';
import { ProposeFilter } from 'components/BookingPropose/components';
import { ModalWindowHoteliers } from '../HoteliersProposeCard/components/ModalWindowHoteliers/ModalWindowHoteliers';
export const HoteliersProposesPage = ({ list, setProposeList, createBooking, setShowNotice, isProposeLoading, }) => {
    const navigate = useNavigate();
    // const [isOpen, onOpen, onClose] = useToggleModal();
    // const [selectCard, setSelectCard] = useState<TCard>({});
    const [selectedCardData, setSelectedCardData] = useState(null);
    const { t } = useTranslation('userBooking');
    if (!list || list.length === 0)
        return null;
    const handleCardClick = (propose) => {
        const isTabletOrBelow = window.innerWidth <= 1199;
        if (!propose.bookingNow && isTabletOrBelow) {
            setSelectedCardData(propose);
        }
        else {
            navigate('/main');
        }
    };
    return (_jsxs("div", Object.assign({ className: s.container__main }, { children: [_jsx("div", Object.assign({ className: s.container__usercard }, { children: _jsx(UserRequestCard, {}) })), _jsxs("div", Object.assign({ className: s.container__proposeList }, { children: [_jsx(ProposeFilter, {}), _jsx("div", Object.assign({ className: s.container__items }, { children: !isProposeLoading &&
                            list.map((propose) => (_jsx(HoteliersProposeCard, { apartmentsSize: propose.apartmentsSize, data: propose, onClickArrowDown: () => goProposeToTheEndOgArray({
                                    id: propose._id,
                                    array: list,
                                    setNewArray: setProposeList,
                                }), listLength: list.length, onCardClick: () => {
                                    if (!propose.bookingNow) {
                                        handleCardClick(propose);
                                    }
                                } }, propose._id))) })), selectedCardData && (_jsx(ModalWindowHoteliers, { onClick: () => setSelectedCardData(null), selectedCardData: selectedCardData }))] }))] })));
};
