import { jsx as _jsx } from "react/jsx-runtime";
import s from './Verification.module.scss';
import '../../components/AddObject/AddObject.scss';
import { ObjectAddDocuments } from 'components/AddObject/ObjectAddDocuments';
const Verification = () => {
    const history = new URLSearchParams(window.location.search);
    const time = history.get('time');
    const id = history.get('id');
    return (_jsx("div", Object.assign({ className: s.container }, { children: _jsx(ObjectAddDocuments, { time: time, id: id }) })));
};
export default Verification;
