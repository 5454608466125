import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useRef } from 'react';
import s from '../../HoteliersProposeCard.module.scss';
import ReactPlayer from 'react-player/lazy';
import iconPlayButton from '../../../../../../../assets/icons/proposeCard/play_button.svg';
export const VideoPreview = ({ video, title }) => {
    const [play, setPlay] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [showOverlay, setShowOverlay] = useState(true);
    const playerRef = useRef(null);
    const handleMouseEnter = () => {
        setIsHovered(true);
        setPlay(true);
        setShowOverlay(false);
        // if (playerRef.current) {
        // 	playerRef.current.seekTo(0);
        // 	playerRef.current.getInternalPlayer()?.playVideo();
        // }
    };
    const handleMouseLeave = () => {
        setIsHovered(false);
        setPlay(false);
        setShowOverlay(true);
        // if (playerRef.current) {
        // 	playerRef.current.getInternalPlayer()?.pauseVideo();
        // }
    };
    useEffect(() => {
        let timeoutId;
        if (!isHovered) {
            timeoutId = setTimeout(() => {
                setShowOverlay(true);
            }, 4000);
        }
        return () => {
            clearTimeout(timeoutId);
        };
    }, [isHovered]);
    return (_jsxs("div", Object.assign({ className: s.card__video, onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave }, { children: [showOverlay && _jsx("div", { className: `${s.video__overlay} ${s.loading}` }), _jsx(ReactPlayer, { ref: playerRef, width: '107', height: '107', url: video, muted: true, volume: 0, light: true, playing: true, className: s.videoCard, playIcon: _jsx("img", { src: iconPlayButton, className: s.playIcon }), config: {
                    youtube: {
                        playerVars: {
                            autoplay: isHovered ? 1 : 0,
                            controls: 0,
                            showinfo: 0,
                            modestbranding: 1,
                            loop: 1,
                            playlist: video.slice(-11),
                        },
                    },
                } })] })));
};
