import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import sendIcon from 'assets/icons/proposeCard/send.svg';
import s from '../subcard.module.scss';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { createMessage } from './createMessage';
import { useTranslation } from 'react-i18next';
const testHotelierMessage = {
    name: 'HotelPlaza',
    data: '00:12 22/12/2023',
    text: 'Садитесь на метро и едьте побыстрее к нам у нас хорошо',
};
export const ChatHotelier = () => {
    const [messages, setMessages] = useState([]);
    const [textarea, setTextarea] = useState('');
    const userName = useSelector((state) => state.object.name);
    const { t } = useTranslation('userBooking');
    const handleSubmit = (e) => {
        e.preventDefault();
        const newMessage = createMessage({ name: userName, text: textarea.trim() });
        setMessages([newMessage, ...messages]);
        e.currentTarget.reset();
        //test - need to del
        setTimeout(() => {
            setMessages([testHotelierMessage, newMessage, ...messages]);
        }, 2000);
        //
    };
    return (_jsxs("div", Object.assign({ className: s.chat }, { children: [_jsxs("div", Object.assign({ className: s.chat_block }, { children: [messages.length === 0 && `${t('chatTitle')}`, messages.length > 0 && (_jsx("ul", Object.assign({ className: s.chat_text__list }, { children: messages.map((el, id) => (_jsxs("li", Object.assign({ className: el.name !== userName ? `${s.chat_text} ${s.chat_text_accent}` : s.chat_text }, { children: [_jsxs("span", Object.assign({ className: s.chat_text_username }, { children: [el.name, " "] })), _jsxs("span", { children: [el.data, ":"] }), _jsx("span", Object.assign({ className: s.chat_text_message }, { children: el.text }))] }), id))) })))] })), _jsxs("form", Object.assign({ className: s.chat_message_container, onSubmit: handleSubmit }, { children: [_jsx("textarea", { className: s.chat_message, required: true, value: textarea, name: "message", rows: 4, minLength: 2, maxLength: 5000, onChange: e => {
                            setTextarea(e.currentTarget.value);
                        }, onKeyDown: e => {
                            var _a;
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                if (textarea.trim().length > 2) {
                                    const newMessage = createMessage({ name: userName, text: textarea });
                                    setMessages([newMessage, ...messages]);
                                    (_a = e.currentTarget.form) === null || _a === void 0 ? void 0 : _a.reset();
                                    setTextarea('');
                                    //test - need to del
                                    setTimeout(() => {
                                        setMessages([testHotelierMessage, newMessage, ...messages]);
                                    }, 2000);
                                    //
                                }
                            }
                        } }), _jsx("button", Object.assign({ className: s.chat_btn_send, type: "submit" }, { children: _jsx("img", { src: sendIcon, className: s.chat_icon, alt: "send message" }) }))] }))] })));
};
