import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import s from './FilterHouse.module.scss';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
export const FilterHouse = ({ filters }) => {
    const { t } = useTranslation('userCatalog');
    const [selectedFilter, setSelectedFilter] = useState([]);
    const isActive = (filtersName) => {
        return selectedFilter.includes(filtersName);
    };
    const handleSelect = (filtersName) => {
        if (isActive(filtersName)) {
            const newFilter = selectedFilter.filter((filter) => filter !== filtersName);
            setSelectedFilter(newFilter);
        }
        else {
            setSelectedFilter((prevFilters) => [...prevFilters, filtersName]);
        }
    };
    return (_jsx("div", Object.assign({ className: s.filter__container }, { children: filters.map((filtersName, id) => {
            const containerHouseActive = isActive(filtersName) ? `${s.house__container} ${s.active}` : s.house__container;
            return (_jsxs("div", Object.assign({ className: containerHouseActive, onClick: () => handleSelect(filtersName) }, { children: [_jsx("p", Object.assign({ className: s.house__text }, { children: t(filtersName) })), _jsx("div", Object.assign({ className: s.house__overlay }, { children: isActive(filtersName) ? t('shownOnPage') : t('notShownOnPage') }))] }), id));
        }) })));
};
