import axios from 'axios';
export const instance = axios.create({
    baseURL: 'https://server-your-price-booking.onrender.com',
});
export function fetchHotels() {
    return instance.get(`/api/hotels`);
}
export function fetchHotelsById(id) {
    return instance.get(`/api/hotels/get-hotels-by-id-hotelier/${id}
	`);
}
